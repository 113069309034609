import { GAS_PRICE_MULTIPLIER } from 'appConstants';
import i18n from 'i18next';
import { takeLatest } from 'redux-saga/effects';
import { ContractsNames } from 'services/WalletService/config';
import { notifyText } from 'services/WalletService/config/constants';
import { error, request, success } from 'store/api/actions';
import { setActiveModal } from 'store/modals/reducer';
import userActionTypes from 'store/user/actionTypes';
import { getTokenBalanceSaga } from 'store/user/sagas/getTokenBalance';
import userSelector from 'store/user/selectors';
import { call, put, select } from 'typed-redux-saga';
import { Modals, StakingAbi } from 'types';
import { getContractDataByItsName, getToastMessage, toDecimals } from 'utils';

import { onUnstake } from '../actions';
import actionTypes from '../actionTypes';

import { getStakingDataSaga } from './getStakingData';
import { getUserStakeDataSaga } from './getUserStakeData';

export function* unStakeSaga({ type, payload: { web3Provider } }: ReturnType<typeof onUnstake>) {
  yield* put(request(type));

  const { chainType, address: myAddress } = yield select(userSelector.getUser);

  const [stakingAbi, stakingContractAddress] = getContractDataByItsName(ContractsNames.staking, chainType);

  try {
    const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(stakingAbi, stakingContractAddress);

    yield put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const gasPrice = yield* call(web3Provider.eth.getGasPrice);
    const { transactionHash } = yield* call(stakingContract.methods.unstake().send, {
      from: myAddress,
      to: stakingContractAddress,
      gasPrice: +gasPrice * GAS_PRICE_MULTIPLIER,
      maxFeePerGas: toDecimals(4, 9),
      maxPriorityFeePerGas: toDecimals(4, 9),
    });

    yield put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );

    yield* call(getTokenBalanceSaga, {
      type: userActionTypes.GET_TOKEN_BALANCE,
      payload: { web3Provider },
    });
    yield* call(getStakingDataSaga, {
      type: actionTypes.GET_STAKING_DATA,
      payload: undefined,
    });
    yield* call(getUserStakeDataSaga, {
      type: actionTypes.GET_USER_STAKE_DATA,
      payload: { web3Provider },
    });

    getToastMessage('success', notifyText.unStake.success(i18n.t));

    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    yield put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
      }),
    );
    getToastMessage('error', notifyText.unStake.error(i18n.t));
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UNSTAKE, unStakeSaga);
}
