// ===== Colors
export const BG_BUTTON_BLUE = '#2463EB';
export const BG_BUTTON_BLUE_LIGHT = 'linear-gradient(86.18deg, #4DA3FF 4.27%, #0C7FF9 97.92%)';
export const BG_BUTTON_WHITE = 'rgba(255, 255, 255, 1)';
export const BG_BUTTON_GRAY = 'rgba(255, 255, 255, 0.04)';
export const BG_ICON_BUTTON_GRAY = 'rgba(255, 255, 255, 0.09)';

// ===== Borders
export const BORDER_BUTTON_BLUE = '1px solid  #4DA3FF';
export const BORDER_BUTTON_GRAY = '1px solid #575676';
