import { FC } from 'react';
import { Box } from '@mui/material';

import srcBackground from './backgroundExit.png';
import srcIcon from './exit.png';

export const ExitIcon: FC = () => (
  <Box position="relative" width="62px" height="62px">
    <img
      src={srcBackground}
      alt="DashboardIconBackground"
      style={{ position: 'absolute', top: -6, left: -6, width: 'auto', height: '72px' }}
    />
    <img
      src={srcIcon}
      alt="DashboardIcon"
      style={{
        position: 'absolute',
        top: 7,
        width: 'auto',
        height: '54px',
      }}
    />
  </Box>
);
