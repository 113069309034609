import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Skeleton, Stack, StackProps, SxProps, Typography } from '@mui/material';
import { TokenTabsSkeleton } from 'modules/crowdsale/components';
import { useFormatTokensListReturnType } from 'modules/crowdsale/hooks/useFormatTokensList/useFormatTokensList.types';
import { BORDER_DEFAULT_GRAY, BORDER_RADIUS_DEFAULT, BORDER_RADIUS_SMALL, FontWeights } from 'theme';
import { flexHelper } from 'utils/flexHelper';

import { TyzTokenPriceInfo } from '../TyzTokenPriceInfo';

export type PricesInfoProps = {
  tokensList: useFormatTokensListReturnType;
  min: string;
  max: string;
  currentTYZTokenPrice: string;
  isLoading?: boolean;
} & StackProps &
  SxProps;

export const PricesInfo: FC<PricesInfoProps> = ({
  tokensList,
  min,
  max,
  currentTYZTokenPrice,
  isLoading = false,
  sx,
  ...stackProps
}) => {
  const { t } = useTranslation();

  return (
    <Stack {...stackProps} alignItems="center" sx={{ width: '100%', ...sx }}>
      <TyzTokenPriceInfo tokenPrice={currentTYZTokenPrice} isLoading={isLoading} sx={{ my: 4 }} />

      <Typography variant="body2" className="blue" fontWeight={FontWeights.fontWeightRegular} mb={4}>
        {t('buyTokenPage.currentRates')}
      </Typography>

      <Grid container justifyContent="space-between" alignItems="center" spacing={1.5} width="100% !important">
        {isLoading && !tokensList.length && <TokenTabsSkeleton />}
        {tokensList.map(({ symbol, value, image }) => (
          <Grid item key={symbol + value} xs={4} sm={2} md={2}>
            <Box
              sx={{
                minWidth: { xs: '100%', sm: '100%', md: 120 },
                height: 46,
                background: 'transparent',
                border: BORDER_DEFAULT_GRAY,
                borderRadius: BORDER_RADIUS_SMALL,
                flexDirection: 'row',
                ...flexHelper(),
              }}
            >
              <Box component="img" src={image} sx={{ width: 26, height: 26 }} />
              <Typography
                ml={{ xs: 0.5, md: 1 }}
                variant="body2"
                className="s white"
                // noWrap
                maxWidth={{ xs: 67, md: 70 }}
                overflow="hidden"
              >
                ${value}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          mt: 3,
          width: '98.5% !important',
          height: 44,
          borderRadius: BORDER_RADIUS_DEFAULT,
          border: BORDER_DEFAULT_GRAY,
        }}
      >
        <Grid item container justifyContent="center" xs={6}>
          {isLoading ? (
            <Skeleton variant="text" width={70} height={20} />
          ) : (
            <Typography variant="body2" className="s white">
              Min: {min} TYZ
            </Typography>
          )}
        </Grid>

        <Grid item container justifyContent="center" xs={6}>
          {isLoading ? (
            <Skeleton variant="text" width={70} height={20} />
          ) : (
            <Typography variant="body2" className="s white">
              Max: {max} TYZ
            </Typography>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};
