import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowRightBtn: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 15"
    sx={{
      ...sx,
      width: '16px',
      height: '15px',
    }}
  >
    <path
      d="M8.67738 14.5256L15.5666 7.63636L8.67738 0.747159L7.21431 2.19602L11.5964 6.57812H0.950106V8.6946H11.5964L7.21431 13.0696L8.67738 14.5256Z"
      fill="white"
    />
  </SvgIcon>
);
