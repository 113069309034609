export const langs = [
  {
    value: 'en',
    label: 'English',
    short: 'ENG',
  },
  {
    value: 'ru',
    label: 'Русский',
    short: 'RUS',
  },
];
