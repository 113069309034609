import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const OutIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 15 20"
    sx={{
      width: '15px',
      height: '20px',
      path: {
        fill: '#fff',
      },
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.48733 2.07983H2V17.92H7.48733C8.03961 17.92 8.48733 18.3677 8.48733 18.92C8.48733 19.4723 8.03961 19.92 7.48733 19.92H1.81092C1.33063 19.92 0.870019 19.7292 0.530405 19.3896C0.190792 19.05 0 18.5894 0 18.1091V1.89075C0 1.41046 0.190793 0.949851 0.530405 0.610239C0.870017 0.270627 1.33063 0.079834 1.81092 0.079834H7.48733C8.03961 0.079834 8.48733 0.527549 8.48733 1.07983C8.48733 1.63212 8.03961 2.07983 7.48733 2.07983ZM9.03557 5.03551C9.42609 4.64498 10.0593 4.64498 10.4498 5.03551L14.7071 9.29282C15.0976 9.68334 15.0976 10.3165 14.7071 10.707L10.4498 14.9643C10.0593 15.3549 9.42609 15.3549 9.03557 14.9643C8.64504 14.5738 8.64504 13.9407 9.03557 13.5501L11.5858 10.9999L5.48535 10.9999C4.93307 10.9999 4.48535 10.5522 4.48535 9.99994C4.48535 9.44765 4.93307 8.99994 5.48535 8.99994L11.5858 8.99994L9.03557 6.44972C8.64504 6.0592 8.64504 5.42603 9.03557 5.03551Z"
      fill="currentColor"
    />
  </SvgIcon>
);
