const ADD_ADMIN = 'ADD_ADMIN' as const;
const ADD_GAME = 'ADD_GAME' as const;
const ADD_NOTE = 'ADD_NOTE' as const;
const ADD_STORE = 'ADD_STORE' as const;
const BLOCK_USER = 'BLOCK_USER' as const;
const CLEAR_SELECTED_USER_INFO = 'CLEAR_SELECTED_USER_INFO' as const;
const DEMOTE_ADMIN = 'DEMOTE_ADMIN' as const;
const PROMOTE_ADMIN = 'PROMOTE_ADMIN' as const;
const DELETE_GAME = 'DELETE_GAME' as const;
const DELETE_STORE = 'DELETE_STORE' as const;
const EDIT_STORE = 'EDIT_STORE' as const;
const GET_ADMIN_PAYMENTS_HISTORY = 'GET_ADMIN_PAYMENTS_HISTORY' as const;
const GET_ADMIN_WITHDRAWAL_HISTORY = 'GET_ADMIN_WITHDRAWAL_HISTORY' as const;
const GET_ADMINS = 'GET_ADMINS' as const;
const GET_GAMES = 'GET_GAMES' as const;
const GET_NOTES = 'GET_NOTES' as const;
const GET_PAYMENTS_HISTORY = 'GET_PAYMENTS_HISTORY' as const;
const GET_PROFILE_INFO = 'GET_PROFILE_INFO' as const;
const GET_SELECTED_USER_INFO = 'GET_SELECTED_USER_INFO' as const;
const GET_STORES = 'GET_STORES' as const;
const GET_WITHDRAWAL_LIMITS = 'GET_WITHDRAWAL_LIMITS' as const;
const GET_WITHDRAWALS = 'GET_WITHDRAWALS' as const;
const MAKE_REFERRAL_SWAP = 'MAKE_REFERRAL_SWAP' as const;
const ON_ADMIN_ADDED = 'ON_ADMIN_ADDED' as const;
const ON_ADMIN_DEMOTED = 'ON_ADMIN_DEMOTED' as const;
const ON_ADMIN_PROMOTED = 'ON_ADMIN_PROMOTED' as const;
const ON_ADMIN_EDITED = 'ON_ADMIN_EDITED,' as const;
const ON_GAME_ADDED = 'ON_GAME_ADDED,' as const;
const ON_GAME_DELETED = 'ON_GAME_DELETED' as const;
const ON_NEW_ADMIN_UPDATED = 'ON_NEW_ADMIN_UPDATED' as const;
const ON_NOTE_ADDED = 'ON_NOTE_ADDED' as const;
const ON_STORE_ADDED = 'ON_STORE_ADDED' as const;
const ON_STORE_DELETED = 'ON_STORE_DELETED' as const;
const ON_STORE_EDITED = 'ON_STORE_EDITED,' as const;
const ON_UPDATE_MAX_WITHDRAWAL = 'ON_UPDATE_MAX_WITHDRAWAL' as const;
const ON_UPDATE_MIN_WITHDRAWAL = 'ON_UPDATE_MIN_WITHDRAWAL' as const;
const ON_UPDATE_WITHDRAWAL_LIMITS = 'ON_UPDATE_WITHDRAWAL_LIMITS' as const;
const ON_UPDATE_WITHDRAWAL_PERIOD = 'ON_UPDATE_WITHDRAWAL_PERIOD' as const;
const ON_USER_STATUS_CHANGED = 'ON_USER_STATUS_CHANGED' as const;
const SET_AVATAR = 'SET_AVATAR' as const;
const SET_PROFILE_INFO = 'SET_PROFILE_INFO' as const;
const TAKE_BALANCE = 'TAKE_BALANCE' as const;
const UPDATE_MAX_WITHDRAWAL = 'UPDATE_MAX_WITHDRAWAL' as const;
const UPDATE_MIN_WITHDRAWAL = 'UPDATE_MIN_WITHDRAWAL' as const;
const UPDATE_WITHDRAWAL_PERIOD = 'UPDATE_WITHDRAWAL_PERIOD' as const;
const RESOLVE_BLOCKED_TRANSACTION = 'RESOLVE_BLOCKED_TRANSACTION' as const;
const GET_CONFIRMATIONS = 'GET_CONFIRMATIONS' as const;
const ON_CONFIRMATION_RESOLVED = 'ON_CONFIRMATION_RESOLVED' as const;
const ON_GET_SELECTED_USER_INFO = 'ON_GET_SELECTED_USER_INFO' as const;
const ON_FETCH_USER = 'ON_FETCH_USER' as const;
const ON_SWAP_EXCEEDED = 'ON_SWAP_EXCEEDED' as const;
const ON_WALLET_IN_USE = 'ON_WALLET_IN_USE' as const;
const ON_TAKE_BALANCE = 'ON_TAKE_BALANCE' as const;
const DROP_2FA = 'DROP_2FA' as const;
const ON_DROP_2FA = 'ON_DROP_2FA' as const;
const GET_APPROVED_WITHDRAWAL = 'GET_APPROVED_WITHDRAWAL' as const;
const ON_UPDATE_AVATAR = 'ON_UPDATE_AVATAR' as const;

export default {
  ADD_ADMIN,
  ADD_GAME,
  ADD_NOTE,
  ADD_STORE,
  BLOCK_USER,
  CLEAR_SELECTED_USER_INFO,
  DEMOTE_ADMIN,
  PROMOTE_ADMIN,
  DELETE_GAME,
  DELETE_STORE,
  EDIT_STORE,
  GET_ADMIN_PAYMENTS_HISTORY,
  GET_ADMIN_WITHDRAWAL_HISTORY,
  GET_ADMINS,
  GET_GAMES,
  GET_NOTES,
  GET_PAYMENTS_HISTORY,
  GET_PROFILE_INFO,
  GET_SELECTED_USER_INFO,
  GET_STORES,
  GET_WITHDRAWAL_LIMITS,
  GET_WITHDRAWALS,
  MAKE_REFERRAL_SWAP,
  ON_ADMIN_ADDED,
  ON_ADMIN_DEMOTED,
  ON_ADMIN_PROMOTED,
  ON_ADMIN_EDITED,
  ON_GAME_ADDED,
  ON_GAME_DELETED,
  ON_NEW_ADMIN_UPDATED,
  ON_NOTE_ADDED,
  ON_STORE_ADDED,
  ON_STORE_DELETED,
  ON_STORE_EDITED,
  ON_UPDATE_MAX_WITHDRAWAL,
  ON_UPDATE_MIN_WITHDRAWAL,
  ON_UPDATE_WITHDRAWAL_LIMITS,
  ON_UPDATE_WITHDRAWAL_PERIOD,
  ON_USER_STATUS_CHANGED,
  SET_AVATAR,
  SET_PROFILE_INFO,
  TAKE_BALANCE,
  UPDATE_MAX_WITHDRAWAL,
  UPDATE_MIN_WITHDRAWAL,
  UPDATE_WITHDRAWAL_PERIOD,
  ON_GET_SELECTED_USER_INFO,
  RESOLVE_BLOCKED_TRANSACTION,
  GET_CONFIRMATIONS,
  ON_CONFIRMATION_RESOLVED,
  ON_FETCH_USER,
  ON_SWAP_EXCEEDED,
  ON_WALLET_IN_USE,
  ON_TAKE_BALANCE,
  DROP_2FA,
  ON_DROP_2FA,
  GET_APPROVED_WITHDRAWAL,
  ON_UPDATE_AVATAR,
};
