import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { COLOR_TEXT_BLUE_200 } from 'theme/colors';

export const getMuiLoadingButtonOverrides = (): ComponentsOverrides['MuiLoadingButton'] => ({
  root: {
    transition: 'none',
    '& .MuiCircularProgress-svg': {
      color: COLOR_TEXT_BLUE_200,
    },
  },
});

export const getMuiLoadingButtonDefaultProps = (): ComponentsProps['MuiLoadingButton'] => ({
  loading: true,
  variant: 'contained',
});
