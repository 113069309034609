import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TyzIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 17 16"
    sx={{
      width: '17px',
      height: '16px',
      ...sx,
    }}
  >
    <g clipPath="url(#clip0_467_5488)">
      <circle cx="8.07031" cy="8" r="7.5" fill="#2463EB" stroke="white" />
      <path
        d="M13.0703 9.95845H8.5096V9.90218L13.033 5.89319V5.5H3.07031V6.04155H3.41163V6.04849H4.94736V10.5H5.60675V6.04849H6.55321L7.85058 8.37839V9.58828L7.24913 10.1218V10.5H13.0703V9.95845ZM7.70778 6.87945L7.27569 6.04155H9.17562L8.73283 6.87945C8.53801 7.25802 8.35794 7.58434 8.22326 7.91102H8.2085C8.05242 7.56205 7.89523 7.25802 7.70778 6.87945ZM8.5096 8.37072L9.87707 6.04155H11.8191V6.06384L8.5096 9.00289V8.37072Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_467_5488">
        <rect width="16" height="16" fill="white" transform="translate(0.0703125)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
