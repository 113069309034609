import { FC, ReactNode, useEffect, useState } from 'react';
import { Box, BoxProps, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FontFamilies, FontWeights } from 'theme';

import { CustomBox } from '../CustomBox';

export const InfoBox: FC<
  {
    topText: string;
    bottomText: string;
    icon?: ReactNode;
    onClick?: () => void;
    width: number;
    height?: number;
  } & BoxProps
> = ({ topText, bottomText, icon, onClick, sx, width, height = 68 }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [topTextSize, setTopTextSize] = useState('16px');
  const [bottomTextSize, setBottomTextSize] = useState('20px');
  const [marginLeft, setMarginLeft] = useState('16px');
  const [padding, setPadding] = useState('4px');

  useEffect(() => {
    if (height !== 68) {
      setMarginLeft('4px');
      setPadding('2px');
      setTopTextSize('14px');
      setBottomTextSize('16px');
    } else if (isSmallScreen) {
      setMarginLeft('8px');
      setPadding('6px');
      setTopTextSize('15px');
      setBottomTextSize('18px');
    } else {
      setMarginLeft('16px');
      setPadding('4px');
      setTopTextSize('16px');
      setBottomTextSize('20px');
    }
  }, [isSmallScreen, height]);

  return (
    <CustomBox width={width} height={height} sx={{ borderRadius: isSmallScreen ? '8px' : '12px', ...sx }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          marginLeft: { marginLeft },
          padding: { padding },
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '25px' }}>
          <Typography
            fontFamily={FontFamilies.secondary}
            fontWeight={FontWeights.fontWeightMedium}
            fontSize={topTextSize}
            marginRight="8px"
          >
            {topText}
          </Typography>
          {icon && (
            <IconButton
              onClick={onClick}
              sx={{
                transition: 'transform 0.15s ease-in-out',
                '&:active': {
                  transform: 'scale(0.92)',
                },
              }}
            >
              {icon}
            </IconButton>
          )}
        </Box>
        <Typography
          fontFamily={FontFamilies.secondary}
          fontWeight={FontWeights.fontWeightBold}
          fontSize={bottomTextSize}
        >
          {bottomText}
        </Typography>
      </Box>
    </CustomBox>
  );
};
