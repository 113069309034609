import { FC, MouseEvent, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { Logo3d } from 'assets/img';
import { Modal, ModalProps, ToggleBtns } from 'components';
import { noop } from 'lodash';
import { BG_BLUE_ACCENT_DARK, BG_GRAY_LIGHT, COLOR_TEXT_BLUE_200, FontWeights } from 'theme';
import { flexHelper } from 'utils';

import { disclaimerStepsArr, disclaimerTabItems } from './StakingDisclaimer.helpers';

export type StakingDisclaimerProps = {
  onSetAgree: () => void;
} & ModalProps;

export const StakingDisclaimer: FC<StakingDisclaimerProps> = ({ open, onSetAgree }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  const handleNextClick = () => {
    if (activeStep === 2) {
      onSetAgree();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBackClick = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleChangeActiveTab = useCallback((event: MouseEvent<HTMLElement>) => {
    const { value } = event.target as HTMLButtonElement;
    if (value !== undefined) {
      setActiveStep(+value);
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => noop}
      closable={false}
      // title={t('header.connectWallet.title')}
      sx={{
        width: { xs: '100%', sm: '100%', md: 768 },
        maxWidth: { xs: '100%', sm: '100%', md: 768 },
        height: { xs: 'auto', sm: 'auto', md: 498 },
        background: BG_BLUE_ACCENT_DARK,
      }}
    >
      <Box
        sx={{
          height: { xs: 'auto', sm: 'auto', md: 445 },
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          ...flexHelper(),
          background: BG_BLUE_ACCENT_DARK,
        }}
      >
        <Box
          sx={(theme) => ({
            position: 'relative',
            flexDirection: 'row',
            ...flexHelper(),
            height: '100%',
            '&:after': {
              position: 'absolute',
              top: -24,
              right: -20,
              content: '""',
              width: '1px',
              height: 498,
              background: BG_GRAY_LIGHT,

              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            },
          })}
        >
          <Box component="img" src={Logo3d} sx={{ width: 209, height: 190 }} />
        </Box>

        <Box
          sx={{
            ml: { xs: 0, sm: 0, md: 5 },
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            ...flexHelper('space-between', 'center'),
          }}
        >
          {disclaimerStepsArr.map(({ subtitle, link1, link2 }, index) => {
            if (index === activeStep) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index}>
                  <Typography mb={2} variant="h3" className="white" fontWeight={FontWeights.fontWeightRegular}>
                    {t(`disclaimer.steps.${activeStep}.title`)}
                  </Typography>

                  {subtitle && (
                    <Typography mb={2} variant="body2" fontSize={12} className="white" textTransform="uppercase">
                      {t(`disclaimer.steps.${activeStep}.subtitle`)}
                    </Typography>
                  )}

                  <Typography variant="body2" className="gray">
                    <Trans
                      i18nKey={t(`disclaimer.steps.${activeStep}.text`)}
                      components={{
                        link1: link1 ? (
                          <Link
                            href={link1}
                            target="_blank"
                            rel="noreferrer"
                            sx={{ mx: 0.5, color: COLOR_TEXT_BLUE_200 }}
                          />
                        ) : (
                          <Box />
                        ),
                        link2: link2 ? (
                          <Link
                            href={link2}
                            target="_blank"
                            rel="noreferrer"
                            sx={{ mx: 0.5, color: COLOR_TEXT_BLUE_200 }}
                          />
                        ) : (
                          <Box />
                        ),
                        ul: <ul />,
                        li: <li />,
                      }}
                    />
                  </Typography>
                </Box>
              );
            }
            return null;
          })}

          <Box
            sx={{
              mt: { xs: 2, sm: 2, md: 0 },
              width: '100%',
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column', md: 'row' },
              justifyContent: { xs: 'center', sm: 'center', md: 'space-between' },
              alignItems: 'center',
            }}
          >
            <ToggleBtns
              buttonsItems={disclaimerTabItems}
              value={activeStep}
              onClick={handleChangeActiveTab}
              sx={{ my: { xs: 2, sm: 2, md: 0 } }}
            />

            <Stack direction="row" justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }} alignItems="center">
              {activeStep > 0 && (
                <Button variant="outlined" size="small" onClick={handleBackClick} sx={{ mr: 2, minWidth: 116 }}>
                  {t('disclaimer.buttonBack')}
                </Button>
              )}

              <Button size="small" onClick={handleNextClick} sx={{ minWidth: 116 }}>
                {activeStep === 2 ? t('disclaimer.buttonFinish') : t('disclaimer.buttonNext')}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
