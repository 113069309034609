import i18next from 'i18next';
import { takeLatest } from 'redux-saga/effects';
import { notifyText } from 'services/WalletService/config/constants';
import apiActions, { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { setActiveModal } from 'store/modals/reducer';
import swapSelector from 'store/swap/selectors';
import userSelector from 'store/user/selectors';
import { call, put, select } from 'typed-redux-saga';
import { Modals, SwapState, UserState } from 'types';
import { getToastMessage, toDecimals } from 'utils';

import { onClaim } from '../actions';
import actionTypes from '../actionTypes';

export function* claimSaga({ type, payload: { web3Provider } }: ReturnType<typeof onClaim>) {
  yield* put(request(type));
  const { t } = i18next;

  const { address: userAddress }: UserState = yield select(userSelector.getUser);
  const { commissionAmount, dealerAmount }: SwapState = yield select(swapSelector.getSwapData);

  try {
    const { data } = yield call(baseApi.claim, {
      commissionAmount,
      dealerAmount,
    });

    if (data.initial_tx) {
      const { transactionHash } = yield call(web3Provider.eth.sendTransaction, {
        data: data.initial_tx.data,
        to: data.initial_tx.to,
        value: data.initial_tx.value,
        from: userAddress,
        maxFeePerGas: toDecimals(4, 9),
        maxPriorityFeePerGas: toDecimals(4, 9),
      });

      yield put(
        setActiveModal({
          activeModal: Modals.SendSuccess,
          open: true,
          txHash: transactionHash,
        }),
      );
      getToastMessage('success', notifyText.claim.success(t));

      yield* put(success(type));
    } else {
      getToastMessage('error', notifyText.claim.txError(t));
      yield* put(apiActions.error(type));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    yield put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
      }),
    );
    getToastMessage('error', notifyText.claim.error(t));

    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CLAIM, claimSaga);
}
