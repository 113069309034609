import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useShallowSelector } from 'hooks';
import referralSelector from 'store/referral/selectors';

export const RequestProcessing = () => {
  const isRequestPending = useShallowSelector(referralSelector.getProp('activeRequests')) > 0;
  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isRequestPending}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
