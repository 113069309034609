import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowDownIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 8"
    sx={{
      width: '14px',
      height: '8px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L6.29289 6.68539C6.68342 7.10487 7.31658 7.10487 7.70711 6.68539L13 1"
      stroke="#646778"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export const ArrowDownIconWhite: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 8"
    sx={{
      width: '14px',
      height: '8px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L6.29289 6.68539C6.68342 7.10487 7.31658 7.10487 7.70711 6.68539L13 1"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);
