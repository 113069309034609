import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BackButtonIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 48 48"
    sx={{
      width: '48px',
      height: '48px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="23" stroke="#191836" strokeWidth="2" />
    <rect x="12" y="12" width="24" height="24" rx="12" fill="#191836" fillOpacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.362 29.0002C25.1 29.0002 24.839 28.8982 24.643 28.6952L20.78 24.6953C20.402 24.3022 20.407 23.6793 20.793 23.2933L24.793 19.2932C25.183 18.9022 25.816 18.9022 26.207 19.2932C26.597 19.6842 26.597 20.3162 26.207 20.7072L22.902 24.0122L26.081 27.3053C26.465 27.7032 26.454 28.3362 26.057 28.7192C25.862 28.9072 25.612 29.0002 25.362 29.0002Z"
      fill="#191836"
    />
  </SvgIcon>
);
