import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ExclamationMarkIcon } from 'modules/dashboard/components/Icon';
import { FontFamilies, FontWeights } from 'theme';

import { resolveBlockedTransaction } from '../../../../../../../store/dashboard/actions';
import { Popup } from '../../Popup';

export const LockedAmountPopup = ({
  isOpen,
  closePopup,
  id,
}: {
  isOpen: boolean;
  closePopup: () => void;
  id: number;
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  return (
    <Popup
      isOpen={isOpen}
      closePopup={closePopup}
      header=""
      height={isSmallScreen ? '436px' : '364px'}
      width={isSmallScreen ? '342px' : '585px'}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <ExclamationMarkIcon sx={{ marginTop: '13px' }} />
        <Typography
          color="white"
          sx={{
            fontWeight: FontWeights.fontWeightBold,
            fontFamily: FontFamilies.secondary,
            fontSize: '22px',
            marginTop: '28px',
          }}
        >
          {t('dashboard.confirmation.actionSelection')}
        </Typography>
        <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} sx={{ marginTop: '13px' }}>
          <Button
            onClick={() => {
              dispatch(resolveBlockedTransaction({ id, approve: false }));
              closePopup();
            }}
            sx={{ width: '240px', height: '56px', padding: 0, margin: 2 }}
          >
            <Typography
              sx={{
                textTransform: 'none',
                fontWeight: FontWeights.fontWeightMedium,
                fontFamily: FontFamilies.secondary,
                fontSize: '20px',
                color: 'white',
              }}
            >
              {t('dashboard.confirmation.take')}
            </Typography>
          </Button>
          <Button
            onClick={() => {
              dispatch(resolveBlockedTransaction({ id, approve: true }));
              closePopup();
            }}
            sx={{ width: '240px', height: '56px', padding: 0, margin: 2 }}
          >
            <Typography
              sx={{
                textTransform: 'none',
                fontWeight: FontWeights.fontWeightMedium,
                fontFamily: FontFamilies.secondary,
                fontSize: '20px',
                color: 'white',
              }}
            >
              {t('dashboard.confirmation.return')}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Popup>
  );
};
