import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditButton: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 37 36"
    sx={{
      width: '37px',
      height: '36px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="36" height="36" rx="8" fill="url(#paint0_linear_4189_4353)" />
    <path
      d="M22.5318 10.6808C22.5318 10.6808 23.0039 10.2938 23.2859 10.1769C23.5679 10.0601 23.8702 10 24.1755 10C24.4807 10 24.783 10.0601 25.065 10.1769C25.3471 10.2938 25.6033 10.465 25.8192 10.6808C26.035 10.8967 26.2062 11.1529 26.3231 11.435C26.4399 11.717 26.5 12.0193 26.5 12.3245C26.5 12.6298 26.4399 12.9321 26.3231 13.2141C26.2062 13.4961 25.8192 13.9682 25.8192 13.9682C25.8192 13.9682 18.352 23.6 14.7242 25.0632C13.829 25.4242 12.6204 25.7474 11.6781 25.9722C10.9759 26.1397 10.3603 25.5241 10.5278 24.8219C10.7526 23.8796 11.0758 22.671 11.4368 21.7758C12.9 18.148 22.5318 10.6808 22.5318 10.6808Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4189_4353"
        x1="14"
        y1="18"
        x2="21.8016"
        y2="-26.2729"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3C8CE7" />
        <stop offset="1" stopColor="#00EAFF" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
