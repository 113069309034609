/* eslint-disable max-len */
import i18next from 'i18next';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { disconnectWalletState, updateUserState } from 'store/user/reducer';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize, getToastMessage, shortenPhrase } from 'utils';

import { login } from '../actions';
import actionTypes from '../actionTypes';

export function* loginSaga({ type, payload: { address, web3Provider, provider } }: ReturnType<typeof login>) {
  yield* put(apiActions.request(type));
  let signature;
  try {
    const { data: metamaskMessage } = yield* call(baseApi.getMetamaskMessage);
    // const signedMessage: string = yield* call(web3Provider.eth.personal.sign, metamaskMessage, address, '');
    try {
      yield call(web3Provider.eth.sign, metamaskMessage, address, (err: any, result: any) => {
        signature = result;
      });
    } catch {
      yield call(web3Provider.eth.personal.sign, metamaskMessage, address, '', (err: any, result: any) => {
        signature = result;
      });
    }

    const response = yield* call(baseApi.metamaskLogin, {
      address,
      msg: metamaskMessage,
      signed_msg: signature,
    });

    const { key } = response.data;

    if (key === undefined) {
      getToastMessage('error', `${response.data.detail}`);
      yield* put(disconnectWalletState());
      return;
    }

    const {
      data: { balance: tesBalance, ...accountData },
    } = yield* call(baseApi.getAccountInfo);

    yield* put(
      updateUserState({
        address,
        key,
        provider,
        ...camelize({ tesBalance, ...accountData }),
      }),
    );

    getToastMessage('success', `${i18next.t('walletNotif.connect_info')}${shortenPhrase(address, 5, 5)}`);

    yield* put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield* put(apiActions.error(type, err));
    yield* put(disconnectWalletState());
  }
}

export default function* listener() {
  yield* takeLatest(actionTypes.LOGIN, loginSaga);
}
