import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DiscordIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 21 24"
    sx={{
      width: '21px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.46 0H18.54C19.896 0 21 1.104 21 2.472V24L18.42 21.72L16.968 20.376L15.432 18.948L16.068 21.168H2.46C1.104 21.168 0 20.064 0 18.696V2.472C0 1.104 1.104 0 2.46 0ZM13.116 14.7C13.476 15.156 13.908 15.672 13.908 15.672C16.56 15.588 17.58 13.848 17.58 13.848C17.58 9.984 15.852 6.852 15.852 6.852C14.124 5.556 12.48 5.592 12.48 5.592L12.312 5.784C14.352 6.408 15.3 7.308 15.3 7.308C14.052 6.624 12.828 6.288 11.688 6.156C10.824 6.06 9.996 6.084 9.264 6.18C9.20114 6.18 9.14743 6.18915 9.0869 6.19945C9.07809 6.20095 9.06914 6.20248 9.06 6.204C8.64 6.24 7.62 6.396 6.336 6.96C5.892 7.164 5.628 7.308 5.628 7.308C5.628 7.308 6.624 6.36 8.784 5.736L8.664 5.592C8.664 5.592 7.02 5.556 5.292 6.852C5.292 6.852 3.564 9.984 3.564 13.848C3.564 13.848 4.572 15.588 7.224 15.672C7.224 15.672 7.668 15.132 8.028 14.676C6.504 14.22 5.928 13.26 5.928 13.26C5.928 13.26 6.048 13.344 6.264 13.464L6.26401 13.464C6.276 13.476 6.288 13.488 6.312 13.5C6.33 13.512 6.348 13.521 6.366 13.53C6.384 13.539 6.402 13.548 6.42 13.56C6.72 13.728 7.02 13.86 7.296 13.968C7.788 14.16 8.376 14.352 9.06 14.484C9.96 14.652 11.016 14.712 12.168 14.496C12.732 14.4 13.308 14.232 13.908 13.98C14.328 13.824 14.796 13.596 15.288 13.272C15.288 13.272 14.688 14.256 13.116 14.7ZM7.104 11.4001C7.104 10.6681 7.644 10.0681 8.328 10.0681C9.012 10.0681 9.564 10.6681 9.552 11.4001C9.552 12.1321 9.012 12.7321 8.328 12.7321C7.656 12.7321 7.104 12.1321 7.104 11.4001ZM11.484 11.4001C11.484 10.6681 12.024 10.0681 12.708 10.0681C13.392 10.0681 13.932 10.6681 13.932 11.4001C13.932 12.1321 13.392 12.7321 12.708 12.7321C12.036 12.7321 11.484 12.1321 11.484 11.4001Z"
      fill="#8A90AB"
    />
  </SvgIcon>
);
