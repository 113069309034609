import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { routes } from 'appConstants';
import { useShallowSelector, useSmoothTopScroll } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { getCrowdsaleAddress, getTokensList } from 'store/crowdsale/actions';
import { getAccountInfo } from 'store/user/actions';
import userSelector from 'store/user/selectors';
import { Chains, WalletProviders } from 'types';
import Cookies from 'universal-cookie';

import { CookiePopup } from '../../../../components';
import { Footer, Header, LoadingScreen, TransactionModal } from '..';

export interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { connect, disconnect } = useWalletConnectorContext();
  const address = useShallowSelector(userSelector.getProp('address'));

  const [isLoading, setIsLoading] = useState(true);

  const firstPathAtPathname = useMemo(() => pathname.split('/')[1], [pathname]);
  useSmoothTopScroll(firstPathAtPathname);

  const handleConnectWallet = useCallback(
    (provider: WalletProviders) => {
      connect(provider, Chains.MultiChain);
    },
    [connect],
  );

  useEffect(() => {
    dispatch(getTokensList());
    dispatch(getCrowdsaleAddress());
    if (address !== '') {
      dispatch(getAccountInfo());
    }
  }, [address, dispatch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsLoading(false), 3000);
    return () => clearTimeout(timeoutId);
  });

  const dashboardPath = '/referrals/dashboard';
  const twentyTwoRegistrationPage = '/game22/signup';
  const isDashboardPage = pathname.startsWith(dashboardPath);
  const is22RegistrationPage = pathname.startsWith(twentyTwoRegistrationPage);

  const [cookiesPopup, setCookiesPopup] = useState(true);
  const cookies = useMemo(() => new Cookies(), []);
  useEffect(() => {
    const optionalCookies = cookies.get('optional_cookies');
    if (optionalCookies) {
      setCookiesPopup(false);
    }
  }, [cookies]);

  return isLoading && !isDashboardPage ? (
    <LoadingScreen />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {!isDashboardPage && (
        <Header onConnectWallet={handleConnectWallet} onDisconnect={disconnect} fullHeader={!is22RegistrationPage} />
      )}
      <TransactionModal />
      {cookiesPopup && !isDashboardPage && (
        <CookiePopup
          setCookies={(optionalCookies) => {
            cookies.set('optional_cookies', optionalCookies, { maxAge: 60 * 60 * 24 * 365, sameSite: 'strict' });
            setCookiesPopup(false);
          }}
        />
      )}
      {children}
      {!isDashboardPage && pathname !== routes.notFound.root.path && <Footer />}
    </Box>
  );
};
