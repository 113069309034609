import { FC } from 'react';
import { Box, SxProps } from '@mui/material';

import Icon from './login.png';

type LoginLogoProps = {
  className?: string;
  sx?: SxProps;
};

export const LoginLogo: FC<LoginLogoProps> = ({ className, sx }) => {
  return <Box component="img" className={className} src={Icon} alt="Login" sx={{ ...sx }} />;
};
