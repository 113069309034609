/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useEffect, useState } from 'react';
import { baseReferralURL } from 'appConstants';

import { ReferralTreeData, SearchableTree } from '../../components';

export const Network: FC = () => {
  const [data, setData] = useState<ReferralTreeData | null>(null);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<ReferralTreeData | null> => {
      const url = `${baseReferralURL}ref_api/v1/account/tree`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          return null;
        }

        return await response.json();
      } catch (err) {
        return null;
      }
    };

    setError(false);
    setData(null);
    fetchData().then((tree) => {
      setError(!tree);
      setData(tree);
    });
  }, [reload]);

  return (
    <div className="p-10 phone:p-0 phone:pt-10 phone:pb-5 rounded-lg">
      <SearchableTree
        data={data}
        reload={() => setReload(!reload)}
        error={error}
        close={null}
        openProfile={null}
        info
      />
    </div>
  );
};
