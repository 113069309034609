import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_TEXT_WHITE_100 } from '../index';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiLinkOverrides = (theme?: Theme): ComponentsOverrides['MuiLink'] => ({
  root: {
    textDecoration: 'none',
    color: COLOR_TEXT_WHITE_100,
  },
});

export const getMuiLinkDefaultProps = (): ComponentsProps['MuiLink'] => ({});
