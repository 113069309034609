import { User, UserCard } from './UserCard';

export const UserGrid = ({ users, selectUser }: { users: User[]; selectUser: (code: string) => void }) => {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 gap-4">
      {users.map((user) => (
        <UserCard key={user.code} user={user} selectUser={selectUser} />
      ))}
    </div>
  );
};
