/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { useShallowSelector } from 'hooks';
import csvDownload from 'json-to-csv-export';
import { TableSelector } from 'modules/dashboard/containers';
import {
  dateComparator,
  levelComparator,
  numberComparator,
  statusComparator,
  stringComparator,
} from 'modules/dashboard/util';
import {
  clearSelectedUserInfo,
  getAdminPaymentsHistory,
  getAdminWithdrawalHistory,
  getSelectedUserInfo,
} from 'store/dashboard/actions';
import selector from 'store/dashboard/selectors';
import { TableItem } from 'types/dashboard';
import { Level } from 'types/store/dashboard';

import { LoadingProfilePage } from '../NetworkPage/Profile';

export interface RemoteParams {
  activeItems: TableItem[];
  endDate: Date | null;
  pageIndex: number;
  searchQuery: string;
  setEndDate: (date: Date | null) => void;
  setPageIndex: (pageIndex: number) => void;
  setSearchQuery: (searchedString: string) => void;
  setSelectedOption: (option: string) => void;
  setSortColumn: (columnIndex: number) => void;
  setSortOrder: (order: 'ASC' | 'DESC') => void;
  setStartDate: (date: Date | null) => void;
  startDate: Date | null;
  totalPages: number;
}

export const AdminDashboardPage: FC = () => {
  const { t } = useTranslation();
  const { adminPaymentHistory, adminWithdrawalHistory } = useShallowSelector(selector.getDashboard);
  const dispatch = useDispatch();

  const [activeItems, setActiveItems] = useState<TableItem[]>([]);
  const [activeTable, setActiveTable] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [loadingnData, setLoadingData] = useState(true);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [sortColumn, setSortColumn] = useState(2);
  const [sortOrder, setSortOrder] = useState<'DESC' | 'ASC'>('DESC');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [transactionsItems, setTransactionsItems] = useState<TableItem[]>([]);
  const [waitingForLoading, setWaitingForLoading] = useState<boolean>(false);

  const previousQueryParamsRef = useRef('');

  const rowsPerPage = 5;

  const translateStatus = (status: string) => t(`dashboard.adminDashboard.${status.toLowerCase().trim()}`);

  const getItems = () => {
    if (activeTable === 0) {
      if (!adminPaymentHistory || !adminPaymentHistory.history) {
        return undefined;
      }

      return adminPaymentHistory.history.map((receipt, index) => {
        let isBlocked = '-';
        if (receipt.isBlocked !== null) {
          isBlocked = t(
            `${receipt.isBlocked ? t('dashboard.clientDashboard.blocked') : t('dashboard.clientDashboard.unlocked')}`,
          );
        }
        return {
          id: index,
          date: receipt.date,
          status: receipt.isBlocked === null ? undefined : receipt.isBlocked,
          data: [
            receipt.email,
            receipt.amount.toString(),
            format(new Date(receipt.date), 'dd.MM.yyyy'),
            receipt.fromWhom,
            receipt.project,
            isBlocked,
          ],
        };
      });
    }

    if (!adminWithdrawalHistory || !adminWithdrawalHistory.history) {
      return undefined;
    }

    return adminWithdrawalHistory.history.map((receipt, index) => ({
      id: index,
      date: receipt.date,
      data: [
        receipt.email,
        receipt.referralCode ?? '-',
        receipt.level !== null ? t(`dashboard.levels.${Level[receipt.level].toString().toLowerCase()}`) : '-',
        `${+receipt.amount}`,
        format(new Date(receipt.date), 'dd.MM.yyyy'),
        translateStatus(receipt.status),
      ],
    }));
  };

  const getStartPage = () => {
    if (activeTable === 0) {
      return adminPaymentHistory ? adminPaymentHistory.startPage : -1;
    }
    return adminWithdrawalHistory ? adminWithdrawalHistory.startPage : -1;
  };

  const getEndPage = () => {
    if (activeTable === 0) {
      return adminPaymentHistory ? adminPaymentHistory.endPage : -1;
    }
    return adminWithdrawalHistory ? adminWithdrawalHistory.endPage : -1;
  };

  const getPageCount = () => {
    if (activeTable === 0) {
      return adminPaymentHistory ? adminPaymentHistory.pageCount : -1;
    }
    return adminWithdrawalHistory ? adminWithdrawalHistory.pageCount : -1;
  };

  const getUTCDate = (date: Date | null) =>
    date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)) : null;

  const getQueryParams = () => {
    return {
      currentPosition,
      sortColumn,
      sortOrder,
      project: selectedProject === 'All' ? '' : selectedProject,
      searchString: searchQuery,
      startDate: getUTCDate(startDate),
      endDate: getUTCDate(endDate),
      getAll: false,
    };
  };

  useEffect(() => {
    previousQueryParamsRef.current = '';
    setLoadingData(true);
    setSortOrder('DESC');
    setCurrentPosition(0);
    setPageIndex(1);
    if (activeTable === 0) {
      setSortColumn(2);
      dispatch(getAdminPaymentsHistory(getQueryParams()));
    } else {
      setSortColumn(4);
      dispatch(getAdminWithdrawalHistory(getQueryParams()));
    }
  }, [activeTable]);

  useEffect(() => {
    const currentQueryParams = getQueryParams();
    const currentQueryParamsKey = JSON.stringify(currentQueryParams);
    if (previousQueryParamsRef.current === currentQueryParamsKey) {
      return;
    }
    if ((startDate === null && endDate === null) || (startDate !== null && endDate !== null)) {
      previousQueryParamsRef.current = currentQueryParamsKey;
      setLoadingData(true);
      if (activeTable === 0) {
        dispatch(getAdminPaymentsHistory(currentQueryParams));
      } else {
        dispatch(getAdminWithdrawalHistory(currentQueryParams));
      }
    }
  }, [searchQuery, sortColumn, sortOrder, dispatch, selectedProject, startDate, endDate, currentPosition]);

  const updateItems = () => {
    const items = getItems();
    if (items) {
      const startPage = getStartPage();
      if (startPage < 0) return;
      setTransactionsItems(items);
      setActiveItems(items.slice((pageIndex - startPage - 1) * rowsPerPage, (pageIndex - startPage) * rowsPerPage));
    }
  };

  useEffect(() => {
    updateItems();
  }, [t]);

  useEffect(() => {
    const startPage = getStartPage();
    const endPage = getEndPage();
    if (startPage < 0 || endPage < 0) return;
    if (pageIndex <= startPage || pageIndex >= endPage) {
      setCurrentPosition(pageIndex);
    } else {
      updateItems();
    }
  }, [pageIndex, activeTable]);

  useEffect(() => {
    const pageCount = getPageCount();
    if (pageCount >= 0) {
      setTotalPages(pageCount);
      if (pageIndex > pageCount) {
        setPageIndex(1);
      }
      updateItems();
    }
    setLoadingData(false);
  }, [adminPaymentHistory, adminWithdrawalHistory]);
  const { selectedUserInfo, isFetchingUser } = useShallowSelector(selector.getDashboard);
  const [selectedProfileEmail, setProfile] = useState('');

  const profilePage = selectedUserInfo !== null || isFetchingUser;

  const handleReturn = () => {
    dispatch(clearSelectedUserInfo());
    setProfile('');
  };

  useEffect(() => {
    if (selectedProfileEmail !== '') {
      dispatch(getSelectedUserInfo({ email: selectedProfileEmail }));
    }
  }, [selectedProfileEmail]);

  const download = () => {
    const currentQueryParams = getQueryParams();
    currentQueryParams.getAll = true;
    setWaitingForLoading(true);
    if (activeTable === 0) {
      dispatch(getAdminPaymentsHistory(currentQueryParams));
    } else {
      dispatch(getAdminWithdrawalHistory(currentQueryParams));
    }
  };

  const withdrawalColumns = [
    t('dashboard.adminDashboard.email'),
    t('dashboard.adminDashboard.referralCode'),
    t('dashboard.adminDashboard.level'),
    t('dashboard.adminDashboard.paymentAmount'),
    t('dashboard.adminDashboard.date'),
    t('dashboard.adminDashboard.status'),
  ];

  const paymentColumns = [
    t('dashboard.adminDashboard.email'),
    t('dashboard.adminDashboard.paymentAmount'),
    t('dashboard.adminDashboard.date'),
    t('dashboard.adminDashboard.fromWhom'),
    t('dashboard.clientDashboard.project'),
    t('dashboard.adminDashboard.status'),
  ];

  useEffect(() => {
    if (!adminPaymentHistory) return;
    if (adminPaymentHistory.startPage !== 0 || adminPaymentHistory.endPage !== adminPaymentHistory.pageCount) return;
    const data = adminPaymentHistory.history.map((receipt) => {
      let isBlocked = '-';
      if (receipt.isBlocked !== null) {
        isBlocked = t(
          `${receipt.isBlocked ? t('dashboard.clientDashboard.blocked') : t('dashboard.clientDashboard.unlocked')}`,
        );
      }
      return [
        receipt.email,
        receipt.amount.toString(),
        format(new Date(receipt.date), 'dd.MM.yyyy'),
        receipt.fromWhom,
        receipt.project,
        isBlocked,
      ];
    });
    if (waitingForLoading) {
      setWaitingForLoading(false);
      csvDownload({
        data,
        filename: 'payments',
        delimiter: ',',
        headers: paymentColumns,
      });
    }

    updateItems();
  }, [adminPaymentHistory]);

  useEffect(() => {
    if (!adminWithdrawalHistory) return;
    if (adminWithdrawalHistory.startPage !== 0 || adminWithdrawalHistory.endPage !== adminWithdrawalHistory.pageCount)
      return;
    const data = adminWithdrawalHistory.history.map((receipt) => [
      receipt.email,
      receipt.referralCode ?? '-',
      receipt.level !== null ? t(`dashboard.levels.${Level[receipt.level].toString().toLowerCase()}`) : '-',
      `${+receipt.amount}`,
      format(new Date(receipt.date), 'dd.MM.yyyy'),
      translateStatus(receipt.status),
    ]);
    if (waitingForLoading) {
      setWaitingForLoading(false);
      csvDownload({
        data,
        filename: 'withdrawals',
        delimiter: ',',
        headers: withdrawalColumns,
      });
    }
    updateItems();
  }, [adminWithdrawalHistory]);

  const comparators = [
    stringComparator,
    stringComparator,
    levelComparator(t),
    numberComparator,
    dateComparator,
    statusComparator(t),
  ];

  const tables = [
    {
      name: t('dashboard.adminDashboard.payments'),
      info: {
        items: transactionsItems,
        columns: paymentColumns,
        columnComparators: comparators,
        defaultFilename: 'payment',
      },
    },
    {
      name: t('dashboard.adminDashboard.withdrawals'),
      info: {
        items: transactionsItems,
        columns: withdrawalColumns,
        columnComparators: comparators,
        defaultFilename: 'withdrawal',
      },
    },
  ];

  return (
    <Box position="relative">
      {profilePage && (
        <Box className="mt-10">
          <LoadingProfilePage handleReturn={handleReturn} userInfo={selectedUserInfo} />
        </Box>
      )}
      <Box className={profilePage ? 'hidden' : ''}>
        <TableSelector
          activeTable={activeTable}
          loadingData={loadingnData}
          remoteParams={{
            activeItems,
            endDate,
            pageIndex,
            searchQuery,
            setEndDate,
            setPageIndex,
            setSearchQuery,
            setSelectedOption: setSelectedProject,
            setSortColumn,
            setSortOrder,
            setStartDate,
            startDate,
            totalPages,
          }}
          setActiveTable={setActiveTable}
          tables={tables}
          viewUser={setProfile}
          customDownloadHandler={download}
        />
      </Box>
    </Box>
  );
};
