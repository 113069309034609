import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Button, SxProps, Typography } from '@mui/material';
import { Balance } from 'components';
import { BORDER_RADIUS_DEFAULT, FontWeights } from 'theme';
import { flexHelper } from 'utils';

type UserBalanceProps = {
  userAddress?: string;
  isActive?: boolean;
  balance: string;
  onSetMax?: () => void;
  icon?: string;
  ticker?: string;
  isLoading?: boolean;
} & BoxProps &
  SxProps;

export const UserBalance: FC<UserBalanceProps> = ({
  balance,
  icon,
  ticker = 'TYZ',
  onSetMax,
  userAddress,
  isActive,
  isLoading = false,
  sx,
  ...boxProps
}) => {
  const { t } = useTranslation();
  return isLoading ? (
    <Box sx={{ height: 36, mt: 3, mb: 2 }} />
  ) : (
    <Box
      {...boxProps}
      sx={{
        width: '100%',
        flexDirection: 'row',
        ...flexHelper('flex-end'),
        ...sx,
      }}
    >
      <Typography variant="body2" className="s white">
        {t('globalHelpers.balance')} {ticker.toUpperCase()}
      </Typography>

      <Balance sx={{ ml: 1, mr: onSetMax ? 1 : 0 }} balance={balance} icon={icon} />

      {onSetMax && (
        <Button
          size="small"
          variant="outlined"
          disabled={!isActive || !userAddress?.length}
          sx={{
            textTransform: 'uppercase',
            fontWeight: FontWeights.fontWeightRegular,
            fontSize: 14,
            borderRadius: BORDER_RADIUS_DEFAULT,
          }}
          onClick={onSetMax}
        >
          {t('globalHelpers.buttonMax')}
        </Button>
      )}
    </Box>
  );
};
