import { FC, PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { MainLogoIcon } from 'components';
import { COLOR_TEXT_WHITE_100 } from 'theme';

const RouterLinkStyled = styled(RouterLink)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  textDecoration: 'none',
});

export type MainLogoProps = {
  withText?: boolean;
  className?: string;
} & PropsWithChildren;

export const MainLogo: FC<MainLogoProps> = ({ withText = true, children, className }) => {
  return (
    <RouterLinkStyled className={className} to={routes.home.root.path}>
      <MainLogoIcon />
      {withText && (
        <Typography ml={1} variant="h2" className="s" color={COLOR_TEXT_WHITE_100}>
          TYZ
        </Typography>
      )}
      {children}
    </RouterLinkStyled>
  );
};
