import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CheckMarkIcon } from 'modules/dashboard/components/Icon';
import { FontFamilies, FontWeights } from 'theme';

import { Popup } from '../../Popup';
import { PopupProps } from '../../PopupProps';

export const AddAdminPopup: FC<PopupProps & { email: string }> = ({ isOpen, closePopup, email }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  const popupWidth = isSmallScreen ? 342 : 448;
  const popupHeight = 288;
  const checkMarkSize = 100;

  return (
    <Popup
      isOpen={isOpen}
      closePopup={closePopup}
      header={t('dashboard.settings.addingAdmin.profileAdded')}
      height={`${popupHeight}px`}
      width={`${popupWidth}px`}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          overflow: 'hidden',
          marginTop: '22px',
        }}
      >
        <CheckMarkIcon sx={{ width: `${checkMarkSize}px`, height: `${checkMarkSize}px` }} />
        <Typography
          fontFamily={FontFamilies.secondary}
          fontWeight={FontWeights.fontWeightMedium}
          fontSize="20px"
          color="white"
          sx={{ margin: '24px' }}
        >
          {email}
        </Typography>
      </Box>
    </Popup>
  );
};
