import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { TesIcon, TyzIcon } from 'assets/img';
import BigNumber from 'bignumber.js';
import { Card, Icon, UserBalance } from 'components';
import { useShallowSelector, useValidateInputField, ValidationTypes } from 'hooks';
import { onSwap } from 'store/swap/actions';
import userSelector from 'store/user/selectors';
import { BORDER_DEFAULT_GRAY, BORDER_RADIUS_PROGRESS_BAR, COLOR_TEXT_WHITE_100 } from 'theme';
import { flexHelper, toAmountWithPrice } from 'utils';
import Web3 from 'web3';

import { SwapTextFields } from './components';

export type SwapTokenFormProps = {
  web3Provider: Web3;
  onOpenLoginModal: () => void;
  onOpenConnectModal: () => void;
  isSwapOwner: boolean;
  isSwapping: boolean;
  isClaiming: boolean;
  isBoughtSuccess: boolean;
  isGetTokenBalanceLoading: boolean;
};

export const SwapTokenForm: FC<SwapTokenFormProps> = ({
  web3Provider,
  onOpenLoginModal,
  onOpenConnectModal,
  isSwapOwner,
  isSwapping,
  isClaiming,
  isBoughtSuccess,
  isGetTokenBalanceLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    address: userAddress,
    tesBalance,
    email,
    isVerified,
    tokenBalance,
  } = useShallowSelector(userSelector.getUser);

  const [sendToken, setSendToken] = useState({
    balance: tokenBalance,
    symbol: 'TYZ',
    image: TyzIcon,
    price: '1',
    decimals: 2,
    minValue: 1,
  });
  const [receiveToken, setReceiveToken] = useState({
    balance: tesBalance,
    symbol: 'TES',
    image: TesIcon,
    price: '100',
    decimals: 0,
    minValue: 1,
  });

  useEffect(() => {
    setSendToken((prev) => {
      return { ...prev, balance: prev.symbol === 'TYZ' ? tokenBalance : tesBalance };
    });
    setReceiveToken((prev) => {
      return { ...prev, balance: prev.symbol === 'TES' ? tesBalance : tokenBalance };
    });
  }, [tokenBalance, tesBalance]);

  const [sendValue, setSendValue, setOriginSendValue] = useValidateInputField({
    maxValue: +sendToken.balance,
    type: ValidationTypes.number,
  });

  const [receiveValue, setReceiveValue, setOriginReceiveValue] = useValidateInputField({
    maxValue: +toAmountWithPrice(
      sendToken.balance,
      receiveToken.price,
      sendToken.price,
      receiveToken.decimals,
      'string',
    ),
    type: ValidationTypes.number,
  });

  const handleSwapPositions = () => {
    setSendToken(receiveToken);
    setReceiveToken(sendToken);
    setOriginSendValue(receiveValue);
    setOriginReceiveValue(sendValue);
  };

  const handleSetMaxSendValue = () => {
    setOriginSendValue(new BigNumber(+sendToken.balance).decimalPlaces(sendToken.decimals, 1).toFixed());
    setOriginReceiveValue(
      toAmountWithPrice(
        new BigNumber(+sendToken.balance).decimalPlaces(sendToken.decimals, 1).toFixed(),
        receiveToken.price,
        sendToken.price,
        receiveToken.decimals,
        'string',
      ) as string,
    );
  };

  const handleSwap = () => {
    if (!email || !isVerified) {
      onOpenLoginModal();
    } else {
      dispatch(
        onSwap({
          web3Provider,
          amount: +sendValue,
          to: receiveToken.symbol,
        }),
      );
    }
  };

  useEffect(() => {
    if (isBoughtSuccess) {
      setOriginSendValue('');
      setOriginReceiveValue('');
    }
  }, [isBoughtSuccess, setOriginReceiveValue, setOriginSendValue]);

  return (
    <Box sx={{ width: '100%', flexDirection: 'column', ...flexHelper('flex-start') }}>
      <Card
        type="lightTransparent"
        sx={{
          p: 0,
          width: { xs: '100%', sm: '100%', md: 848 },
          minHeight: isSwapOwner ? 570 : 770,
          background: 'transparent',
          borderRadius: BORDER_RADIUS_PROGRESS_BAR,
        }}
      >
        <Card
          type="lightTransparent"
          sx={{
            mt: 4.5,
            p: { xs: 2, md: 3 },
            width: '100%',
            minHeight: 430,
            flexDirection: 'column',
            ...flexHelper(),
            border: BORDER_DEFAULT_GRAY,
            borderRadius: BORDER_RADIUS_PROGRESS_BAR,
          }}
        >
          <UserBalance
            userAddress={userAddress}
            balance={sendToken.balance || '0'}
            icon={sendToken?.image}
            ticker={sendToken?.symbol}
            onSetMax={handleSetMaxSendValue}
            isLoading={!userAddress.length || isGetTokenBalanceLoading}
            isActive={!!sendToken.balance}
            sx={{
              mt: { xs: 2, sm: 2, md: 4 },
              mb: 1,
            }}
          />
          <SwapTextFields
            userAddress={userAddress}
            activeSendToken={sendToken}
            activeReceiveToken={receiveToken}
            sendValue={sendValue}
            receiveValue={receiveValue}
            sendingBalance={sendToken.balance}
            // ----
            onChangeSendValue={setSendValue}
            onChangeReceiveValue={setReceiveValue}
            onSetOriginSendValue={setOriginSendValue}
            onSetOriginReceiveValue={setOriginReceiveValue}
            onSwapPositions={handleSwapPositions}
          />
          <UserBalance
            userAddress={userAddress}
            balance={receiveToken.balance || '0'}
            icon={receiveToken?.image}
            ticker={receiveToken?.symbol}
            sx={{ mt: 1, pb: 3.5 }}
            isLoading={!userAddress.length}
          />
          {userAddress.length ? (
            <LoadingButton
              size="large"
              fullWidth
              disabled={sendValue === '' || receiveValue === '' || isClaiming}
              loading={isSwapping}
              onClick={handleSwap}
              sx={{ maxWidth: '385px' }}
            >
              {t('swapTokenPage.buttonSwap')}
            </LoadingButton>
          ) : (
            <Button
              size="large"
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<Icon.WalletIcon />}
              onClick={onOpenConnectModal}
              sx={{ '&:hover': { svg: { path: { fill: COLOR_TEXT_WHITE_100 } } } }}
            >
              {t('header.connectWallet.buttonTitle')}
            </Button>
          )}
        </Card>
      </Card>
    </Box>
  );
};
