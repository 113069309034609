import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckMarkIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 100 100"
    sx={{
      width: '100px',
      height: '100ppx',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 92C73.196 92 92 73.196 92 50C92 26.804 73.196 8 50 8C26.804 8 8 26.804 8 50C8 73.196 26.804 92 50 92ZM50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM23.1105 54.2668L23.1857 54.3558L38.578 69.9443C40.9283 72.3246 44.7714 72.3232 47.12 69.9411L76.815 39.8228L76.8895 39.7347C77.6493 38.8352 78.0411 37.6856 77.9966 36.516C77.952 35.3463 77.4737 34.2291 76.6458 33.3898C75.8168 32.5494 74.6975 32.0501 73.5092 32.0036C72.3207 31.957 71.1651 32.3675 70.2725 33.1423L70.1856 33.2178L42.8168 60.9306L29.8151 47.7505L29.7275 47.6745C28.8349 46.8996 27.6793 46.4892 26.4908 46.5357C25.3025 46.5822 24.1832 47.0816 23.3542 47.922C22.5263 48.7612 22.048 49.8785 22.0034 51.0482C21.9589 52.2177 22.3507 53.3674 23.1105 54.2668Z"
      fill="#4DA3FF"
    />
  </SvgIcon>
);
