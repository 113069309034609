import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routes as appRoutes } from 'appConstants/routes';
import { useShallowSelector } from 'hooks';
import { getLazyRoutes } from 'modules/router/utils';
import userSelector from 'store/user/selectors';

export const RouteManager: FC = () => {
  const address = useShallowSelector(userSelector.getProp('address'));
  const lazyRoutes = useMemo(() => getLazyRoutes(appRoutes, !!address.length), [address.length]);
  const [routes, setRoutes] = useState<ReactElement[] | null>();

  useEffect(() => {
    setRoutes(lazyRoutes);
  }, [lazyRoutes]);

  return routes ? (
    <Routes>
      {routes}
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  ) : null;
};
