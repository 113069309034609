import { ChangeEvent, ChangeEventHandler, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { CompareArrows, SelectedToken } from 'components';
import { BG_GRAY_LIGHT } from 'theme';
import { flexHelper, toAmountWithPrice } from 'utils';

import { SwapTokenInfo } from '../../SwapTokenForm.types';

type SwapTextFieldsProps = {
  userAddress: string;
  activeSendToken: SwapTokenInfo;
  activeReceiveToken: SwapTokenInfo;
  sendValue: string;
  receiveValue: string;
  sendingBalance: string;

  onChangeSendValue: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onChangeReceiveValue: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onSetOriginSendValue: (originValue: string) => void;
  onSetOriginReceiveValue: (originValue: string) => void;
  onSwapPositions: () => void;
};

export const SwapTextFields: FC<SwapTextFieldsProps> = ({
  userAddress,
  activeSendToken,
  activeReceiveToken,
  sendValue,
  receiveValue,
  onChangeSendValue,
  onChangeReceiveValue,
  onSetOriginSendValue,
  onSetOriginReceiveValue,
  onSwapPositions,
  sendingBalance,
}) => {
  const { t } = useTranslation();
  const isInputDisabled = !userAddress.length;

  const handleChangeSendValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    if (+sendingBalance < Number(value)) {
      return;
    }
    if (
      value.includes('.') &&
      (activeSendToken.decimals === 0 ? true : value.split('.')[1].length > activeSendToken.decimals)
    ) {
      return;
    }
    if (new BigNumber(activeSendToken.minValue).isGreaterThan(value)) {
      return;
    }

    onChangeSendValue(event);

    if (!value.length) {
      onSetOriginSendValue('');
      onSetOriginReceiveValue('');
      return;
    }
    if (!Number(value) || (value.includes('.') && !value.split('.')[1].length)) {
      return;
    }

    onSetOriginReceiveValue(
      toAmountWithPrice(
        value,
        activeReceiveToken.price,
        activeSendToken.price,
        activeReceiveToken.decimals,
        'string',
      ) as string,
    );
  };

  const handleChangeReceiveValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    if (
      Number(
        toAmountWithPrice(
          sendingBalance,
          activeReceiveToken.price,
          activeSendToken.price,
          activeReceiveToken.decimals,
          'string',
        ),
      ) < +value
    ) {
      return;
    }

    if (
      value.includes('.') &&
      (activeReceiveToken.decimals === 0 ? true : value.split('.')[1].length > activeReceiveToken.decimals)
    ) {
      return;
    }
    if (new BigNumber(activeReceiveToken.minValue).isGreaterThan(value)) {
      return;
    }

    onChangeReceiveValue(event);

    if (!value.length) {
      onSetOriginSendValue('');
      onSetOriginReceiveValue('');
      return;
    }
    if (!Number(value) || (value.includes('.') && !value.split('.')[1].length)) {
      return;
    }

    onSetOriginSendValue(
      toAmountWithPrice(
        value,
        activeSendToken.price,
        activeReceiveToken.price,
        activeSendToken.decimals,
        'string',
      ) as string,
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        flexDirection: 'column',
        ...flexHelper(),
      }}
    >
      <TextField
        value={sendValue}
        onChange={handleChangeSendValue}
        variant="outlined"
        placeholder="0.00"
        fullWidth
        disabled={isInputDisabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography variant="body2" className="white" fontSize={{ xs: 12, sm: 12, md: 16 }}>
                {t('swapTokenPage.send')}
              </Typography>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <SelectedToken symbol={activeSendToken?.symbol} icon={activeSendToken?.image} />
            </InputAdornment>
          ),
        }}
      />
      <Divider
        role="presentation"
        sx={{ '&:before, &:after': { borderTopColor: BG_GRAY_LIGHT }, width: '100%', my: 1.5 }}
      >
        <IconButton className="contained" onClick={onSwapPositions}>
          <CompareArrows />
        </IconButton>
      </Divider>
      <TextField
        value={receiveValue}
        onChange={handleChangeReceiveValue}
        variant="outlined"
        placeholder="0.00"
        fullWidth
        disabled={isInputDisabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography variant="body2" className="white" fontSize={{ xs: 12, sm: 12, md: 16 }}>
                {t('swapTokenPage.receive')}
              </Typography>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <SelectedToken symbol={activeReceiveToken?.symbol} icon={activeReceiveToken?.image} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
