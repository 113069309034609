import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DownloadIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 18"
    sx={{
      width: '18px',
      height: '18px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 11.6667V14C17 15.6569 15.6569 17 14 17H4C2.34315 17 1.00001 15.6569 1.00001 14L1 11.6667M4.55556 7.22222L9 11.6667M9 11.6667L13.4444 7.22222M9 11.6667V1"
      stroke="#191836"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
