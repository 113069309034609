import { ComponentsOverrides, Theme } from '@mui/material';

import {
  BG_BLUE,
  BG_TABS,
  BG_TABS_OUTLINED,
  BG_WHITE,
  BORDER_RADIUS_TABS,
  BORDER_RADIUS_TABS_OUTLINED,
  COLOR_BORDER_CONTAINED,
  COLOR_TEXT_BLACK_100,
  COLOR_TEXT_BLUE_100,
  COLOR_TEXT_GRAY_100,
  COLOR_TEXT_GRAY_200,
  COLOR_TEXT_WHITE_100,
} from '../index';

// TODO FIX color and fonts imports after merge. not working in my branch
export const getMuiTabsOverrides = (theme: Theme): ComponentsOverrides['MuiTabs'] => ({
  indicator: {
    display: 'none',
  },
  root: {
    position: 'relative',
    '&.withLine': {
      '&:before': {
        position: 'absolute',
        content: '""',
        top: '18px',
        left: '12px',
        width: 'calc(100% - 24px)',
        height: 1,
        background: '#BFBFBF',
      },
    },
    '&.outlined': {
      '.MuiTab-root': {
        background: BG_TABS_OUTLINED,
        borderRadius: BORDER_RADIUS_TABS_OUTLINED,
        border: 'none',
        color: COLOR_TEXT_BLACK_100,
        padding: theme.spacing(1.25, 3.75),
        marginLeft: theme.spacing(1.25),
        '&:first-of-type': {
          marginLeft: 0,
        },
        '&.Mui-selected': {
          background: BG_BLUE,
        },
      },
    },
    '&.outlinedSecondary': {
      padding: 0,
      '.MuiTabs-scroller': {
        // padding: theme.spacing(0, 5),
      },
      '.MuiTab-root': {
        background: COLOR_TEXT_BLUE_100,
        color: COLOR_TEXT_GRAY_200,
        border: `1px solid ${COLOR_TEXT_GRAY_200}`,
        borderRadius: '96px',
        // padding: theme.spacing(0.75, 3),
        marginLeft: theme.spacing(3.5),
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '24px',
        fontFamily: '"TT Norms", sans-serif', // TODO fix import after merge
        position: 'relative',
        overflow: 'visible',
        marginRight: 0,
        '&.Mui-selected': {
          background: BG_WHITE,
          color: COLOR_TEXT_BLUE_100,
        },
        '&:after': {
          position: 'absolute',
          right: theme.spacing(-3.5),
          width: theme.spacing(3.5),
          height: 1,
          background: COLOR_TEXT_GRAY_200,
          content: '""',
        },
        '&:first-of-type': {
          marginLeft: theme.spacing(3.5),
        },
        '&:last-of-type': {
          marginRight: theme.spacing(3.5),
          '&:after': {
            display: 'none',
          },
        },
      },
    },
    '.MuiTabs-flexContainer': {
      display: 'block',
      position: 'relative',
    },
  },
});

export const getMuiTabOverrides = (theme: Theme): ComponentsOverrides['MuiTab'] => ({
  root: {
    color: COLOR_TEXT_WHITE_100,
    textTransform: 'capitalize',
    lineHeight: '34px',
    // TODO : magkoffe50 : change on FontWeights.fontWeightMedium (now typeErrorr)
    // https://manzoni.atlassian.net/jira/software/projects/TYZ/boards/187?selectedIssue=TYZ-37
    fontWeight: 500,
    border: `1px solid ${COLOR_BORDER_CONTAINED}`,
    background: BG_WHITE,
    borderRadius: BORDER_RADIUS_TABS,
    minHeight: 34,
    padding: theme.spacing(0, 2),
    margin: theme.spacing(0, 1.5),
    // [theme.breakpoints.down('md')]: {
    //   marginLeft: theme.spacing(3),
    // },
    '&:hover': {
      background: BG_TABS,
      border: `1px solid transparent`,
      transition: '500ms',
    },

    '&.Mui-selected': {
      background: BG_TABS,
      border: `1px solid transparent`,
      color: 'white',
    },
  },
  textColorSecondary: {
    color: COLOR_TEXT_GRAY_100,
    '&:hover': {
      color: COLOR_TEXT_WHITE_100,
    },
  },
});
