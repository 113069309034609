import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { styled, Typography, TypographyProps } from '@mui/material';
import { BORDER_BUTTON_GRAY, BORDER_RADIUS_MEDIUM, COLOR_TEXT_WHITE_100 } from 'theme';

export type NavItemStyledProps = {
  path: string;
  title: string;
  tSx?: TypographyProps['sx'];
} & TypographyProps;

const GridNavItem = styled(NavLink)({
  color: COLOR_TEXT_WHITE_100,
  border: 'none',
  textDecoration: 'none',
  maxWidth: 'fit-content',
});

export const NavItemStyled: FC<NavItemStyledProps> = ({ path, title, tSx, ...typographyProps }) => {
  return (
    <GridNavItem to={path}>
      {({ isActive }) => (
        <Typography
          {...typographyProps}
          px={1}
          variant="body2"
          color={COLOR_TEXT_WHITE_100}
          borderRadius={BORDER_RADIUS_MEDIUM}
          border={isActive ? BORDER_BUTTON_GRAY : '1px solid transparent'}
          sx={{ '&:hover': { border: BORDER_BUTTON_GRAY }, ...tSx }}
        >
          {title}
        </Typography>
      )}
    </GridNavItem>
  );
};
