import { FC } from 'react';
import { styled } from '@mui/material';
import { BackgroundWrapper } from 'components/BackgroundWrapper';
import { Loading } from 'components/Icon/components';
import { flexHelper } from 'utils';

import { SpinnerSizeProps } from './Spinner.types';
import { spinnerStyleState } from './styleState';

const LoaderIconStyled = styled(Loading)({
  animation: 'rotate 1s linear infinite',
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(1turn)',
    },
  },
});

export interface SpinnerProps {
  size?: SpinnerSizeProps;
}

export const Spinner: FC<SpinnerProps> = ({ size = 's' }) => {
  return (
    <>
      {(size === 's' || size === 'm' || size === 'l') && <LoaderIconStyled sx={{ ...spinnerStyleState.size[size] }} />}

      {size === 'xl' && (
        <BackgroundWrapper
          container
          sx={{ height: '100vh' }}
          containerSx={{ height: '100vh', flexDirection: 'row', ...flexHelper() }}
        >
          <LoaderIconStyled sx={{ ...spinnerStyleState.size[size] }} />
        </BackgroundWrapper>
      )}
    </>
  );
};
