import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, createTheme, Tab, Tabs, ThemeProvider } from '@mui/material';
import { GameTab } from 'modules/dashboard/components';
import { getGames } from 'store/dashboard/actions';
import { Game } from 'types/store/dashboard';

interface GamesListProps {
  onSelectGame: (gameId: string, gameName: string) => void;
  handleSelectGame: (gameId: string) => void;
  games: Game[];
  activeButton: string;
  value: number;
}

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'inherit',
            textDecoration: 'none',
            boxShadow: 'none',
          },
          '&:hover::after': {
            content: 'none',
          },
        },
      },
    },
  },
});

export const GamesList: FC<GamesListProps> = ({ onSelectGame, games, activeButton, handleSelectGame, value }) => {
  useEffect(() => {
    if (games.length > 0 && activeButton === '') {
      handleSelectGame(games[0].id);
    }
  }, [games, onSelectGame, activeButton]);

  return (
    <ThemeProvider theme={theme}>
      <Tabs
        value={value}
        onChange={(_event, newValue) => handleSelectGame(games[newValue].id)}
        variant="scrollable"
        ScrollButtonComponent={() => null}
        sx={{
          width: '100%',
        }}
      >
        {games.map((game) => (
          <Tab
            key={game.id}
            sx={{
              borderRadius: '18px',
              width: '230px',
              height: '76px',
              padding: 0,
              marginRight: '38px',
              minWidth: 'unset',
              minHeight: 'unset',
            }}
            label={
              <GameTab
                gameName={game.name}
                icon={game.img}
                onClick={() => handleSelectGame(game.id)}
                isActive={activeButton === game.id}
              />
            }
          />
        ))}
      </Tabs>
    </ThemeProvider>
  );
};
