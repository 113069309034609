import { FC } from 'react';
import { useShallowSelector } from 'hooks';
import selector from 'store/dashboard/selectors';

import { AdminDashboardPage } from '../AdminDashboardPage';
import { ClientDashboardPage } from '../ClientDashboardPage';

export const ClientPage: FC = () => {
  const { profile } = useShallowSelector(selector.getDashboard);

  return profile?.role === 'user' ? <ClientDashboardPage /> : <AdminDashboardPage />;
};
