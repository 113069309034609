import { FC } from 'react';
import Box from '@mui/material/Box';

interface IconProps {
  srcIcon: string;
  srcBackground: string;
  shadowHeight: string;
  shadowWidth: string;
  shadowLeft: string;
  shadowTop: string;
  iconTop?: string;
}

export const DashboardIconPng: FC<IconProps> = ({
  srcIcon,
  srcBackground,
  shadowHeight,
  shadowWidth,
  shadowLeft,
  shadowTop,
  iconTop,
}) => (
  <Box position="relative" width="56px" height="100%">
    <img
      src={srcBackground}
      alt="DashboardIconBackground"
      style={{ position: 'absolute', top: shadowTop, left: shadowLeft, width: shadowWidth, height: shadowHeight }}
    />
    <img
      src={srcIcon}
      alt="DashboardIcon"
      style={{
        position: 'absolute',
        top: iconTop ?? 0,
        left: 0,
        width: 'auto',
        height: '100%',
      }}
    />
  </Box>
);
