import { addNote, addNoteToList, error, request, success } from 'store/dashboard/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

export function* addNoteSaga({ type, payload: { noteText, noteHeader, userEmail } }: ReturnType<typeof addNote>) {
  yield* put(request(type, { noteText, noteHeader, userEmail }));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.addNote, { noteText, noteHeader, userEmail });
      yield* put(addNoteToList(data));
      yield* put(success(type));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ADD_NOTE, addNoteSaga);
}
