/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';
import { ReferralError } from 'types/referral/error';

import { referralErrors } from './errors';

export const handleBackendErrors = (error: any): ReferralError | undefined => {
  // eslint-disable-next-line no-console
  console.error(error);
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    const errorCode = axiosError.response?.data.error;
    if (errorCode) {
      return referralErrors[errorCode];
    }
  }
  return undefined;
};
