import { FC } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, BoxProps, Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CopyIcon } from 'modules/dashboard/components';
import { deleteStore } from 'store/dashboard/actions';
import { FontFamilies, FontWeights } from 'theme';

interface StoreInfoProps {
  name: string;
  linkText: string;
  storeId: string;
  isAdmin: boolean;
  openPopup: () => void;
  onSelectStore: (selectedId: string) => void;
}

const almostBlack = '#191836';

const buttonStyles = {
  background: 'white',
  borderRadius: '20px',
  height: '41px',
  width: '142px',
  minWidth: 'auto',
  maxWidth: 'auto',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
};

const Buttons: FC<
  { storeId: string; openPopup: () => void; onSelectStore: (selectedId: string) => void } & BoxProps
> = ({ storeId, openPopup, onSelectStore, sx }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteStoreSubmit = () => {
    dispatch(deleteStore({ storeId }));
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={sx}>
      <Button
        onClick={() => {
          openPopup();
          onSelectStore(storeId);
        }}
        sx={{ marginRight: '34px', ...buttonStyles }}
      >
        <Typography
          fontFamily={FontFamilies.secondary}
          fontWeight={FontWeights.fontWeightMedium}
          fontSize="20px"
          color={almostBlack}
          sx={{ textTransform: 'none' }}
        >
          {t('dashboard.settings.addingStore.edit')}
        </Typography>
      </Button>
      <Button onClick={handleDeleteStoreSubmit} sx={{ ...buttonStyles }}>
        <Typography
          fontFamily={FontFamilies.secondary}
          fontWeight={FontWeights.fontWeightMedium}
          fontSize="20px"
          color={almostBlack}
          sx={{ textTransform: 'none' }}
        >
          {t('dashboard.settings.addingStore.delete')}
        </Typography>
      </Button>
    </Box>
  );
};

const CopyLink: FC<{ link: string } & BoxProps> = ({ link, sx }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={sx}>
      <CopyToClipboard text={link}>
        <Button sx={{ marginRight: '34px', ...buttonStyles }}>
          <Typography
            fontFamily={FontFamilies.secondary}
            fontWeight={FontWeights.fontWeightMedium}
            fontSize="20px"
            color={almostBlack}
            sx={{ textTransform: 'none' }}
          >
            {t('dashboard.projects.copy')}
          </Typography>
        </Button>
      </CopyToClipboard>
    </Box>
  );
};

export const StoreInfo: FC<StoreInfoProps & BoxProps> = ({
  name,
  linkText,
  sx,
  storeId,
  isAdmin,
  openPopup,
  onSelectStore,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
          height: '38px',
          ...sx,
        }}
      >
        <Typography
          color={almostBlack}
          fontFamily={FontFamilies.secondary}
          fontWeight={FontWeights.fontWeightMedium}
          fontSize="20px"
          width={isSmallScreen ? '176px' : '200px'}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          lineHeight="24px"
        >
          {name}
        </Typography>
        <Stack alignItems="end">
          {isSmallScreen && !isAdmin && (
            <CopyToClipboard text={linkText}>
              <CopyIcon fill="#4DA3FF" stroke="#4DA3FF" />
            </CopyToClipboard>
          )}
          <Link
            maxWidth={isSmallScreen ? '176px' : '240px'}
            href={linkText}
            target="_blank"
            rel="noopener noreferrer"
            color="#4DA3FF"
            fontFamily={FontFamilies.secondary}
            fontWeight={FontWeights.fontWeightMedium}
            fontSize={isSmallScreen ? '17px' : '20px'}
            lineHeight="24px"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {linkText}
          </Link>
        </Stack>
        {!isSmallScreen && isAdmin && <Buttons openPopup={openPopup} onSelectStore={onSelectStore} storeId={storeId} />}
        {!isSmallScreen && !isAdmin && <CopyLink link={linkText} />}
      </Box>
      {isSmallScreen && isAdmin && (
        <Buttons
          openPopup={openPopup}
          onSelectStore={onSelectStore}
          storeId={storeId}
          sx={{
            marginTop: '16px',
          }}
        />
      )}
      <Box
        sx={{
          marginTop: '8px',
          marginBottom: isSmallScreen ? '24px' : '8px',
          width: '100%',
          height: '2px',
          backgroundColor: 'white',
        }}
      />
    </Box>
  );
};
