import { ComponentsOverrides, Theme } from '@mui/material';

import {
  COLOR_TEXT_BLACK_100,
  COLOR_TEXT_BLUE_100,
  COLOR_TEXT_BLUE_200,
  COLOR_TEXT_GRAY_200,
  COLOR_TEXT_GRAY_400,
  COLOR_TEXT_GRAY_500,
  COLOR_TEXT_PURPLE_100,
  COLOR_TEXT_WHITE_100,
  COLOR_TEXT_WHITE_GRADIENT,
} from '../colors';

export enum FontWeights {
  fontWeightRegular = 400,
  fontWeightMedium = 500,
  fontWeightBold = 700,
  fontWeightExtraBold = 800,
}

export const colorVariations = {
  '&.white': {
    color: COLOR_TEXT_WHITE_100,
  },
  '&.black': {
    color: COLOR_TEXT_BLACK_100,
  },
  '&.blue': {
    color: COLOR_TEXT_BLUE_200,
  },
  '&.gray': {
    color: COLOR_TEXT_GRAY_200,
  },
  '&.gray-2': {
    color: COLOR_TEXT_GRAY_400,
  },
  '&.purple': {
    color: COLOR_TEXT_PURPLE_100,
  },
  '&.darkBlue': {
    color: COLOR_TEXT_BLUE_100,
  },
};

const weightVariations = {
  '&.regular': {
    fontWeight: FontWeights.fontWeightRegular,
  },
  '&.medium': {
    fontWeight: FontWeights.fontWeightMedium,
  },
  '&.semi-bold': {
    fontWeight: FontWeights.fontWeightBold,
  },
  '&.bold': {
    fontWeight: FontWeights.fontWeightExtraBold,
  },
};

export enum FontFamilies {
  primary = '"Roboto", sans-serif',
  secondary = '"TT Norms", sans-serif',
}

export const getTypographyOptions = (theme: Theme): ComponentsOverrides['MuiTypography'] => ({
  root: {
    color: COLOR_TEXT_BLACK_100,
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.fontWeightRegular,
    textAlign: 'left',
    fontStyle: 'normal',
    ...colorVariations,
    ...weightVariations,
  },

  h1: {
    fontSize: 172,
    lineHeight: '209px',
    fontWeight: FontWeights.fontWeightBold,
    fontFamily: FontFamilies.secondary,

    [theme.breakpoints.down('md')]: {
      fontSize: 56,
      lineHeight: '72px',
    },

    '&.gradient': {
      background: COLOR_TEXT_WHITE_GRADIENT,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },

    '&.m': {
      fontSize: 100,
      lineHeight: '100px',
      fontWeight: FontWeights.fontWeightExtraBold,
      [theme.breakpoints.down('md')]: {
        fontSize: 56,
        lineHeight: '56px',
      },
    },
    '&.s': {
      fontSize: 60,
      lineHeight: '70px',
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        lineHeight: '30px',
      },
    },
  },

  h2: {
    fontSize: 40,
    lineHeight: '41px',
    fontWeight: FontWeights.fontWeightExtraBold,
    fontFamily: FontFamilies.secondary,

    '&.m': {
      fontSize: 32,
      lineHeight: '50px',
      fontWeight: FontWeights.fontWeightMedium,
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
        lineHeight: '34px',
      },
    },
    '&.s': {
      fontSize: 28,
      lineHeight: '34px',
      fontWeight: FontWeights.fontWeightExtraBold,
      fontFamily: FontFamilies.secondary,
    },
  },

  h3: {
    fontSize: 24,
    lineHeight: '34px',
    fontWeight: FontWeights.fontWeightBold,
    fontFamily: FontFamilies.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      lineHeight: '20px',
    },
  },

  h4: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: FontWeights.fontWeightMedium,
    fontFamily: FontFamilies.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '17px',
    },
  },

  body1: {
    fontSize: 18,
    lineHeight: '30px',
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,
    '&.m': {
      lineHeight: '26px',
      color: COLOR_TEXT_WHITE_100,
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        lineHeight: '18px',
      },
    },
  },

  body2: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: FontWeights.fontWeightMedium,
    fontFamily: FontFamilies.primary,
    color: COLOR_TEXT_GRAY_500,

    '&.m': {
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: FontWeights.fontWeightRegular,
      fontFamily: FontFamilies.secondary,
    },
    '&.s': {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: FontWeights.fontWeightRegular,
    },
  },

  subtitle1: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,
    '&.m': {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
});
