import { activateTwoFa, error, request, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import { updateReferralState } from 'store/referral/reducer';
import { call, put, takeLatest } from 'typed-redux-saga';

import actionTypes from '../actionTypes';

export function* activateTwoFaSaga({ type, payload: { method } }: ReturnType<typeof activateTwoFa>) {
  yield* put(request(type));

  try {
    const response = yield* call(baseApi.activateTwoFa, method);

    yield* put(
      updateReferralState({
        twoFaDetails: response.data.details,
        twoFaAlternativeDetails: response.data.alternativeDetails,
      }),
    );
    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ACTIVATE_TWO_FA, activateTwoFaSaga);
}
