import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { BORDER_RADIUS_DEFAULT } from '../variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiSkeletonOverrides = (theme: Theme): ComponentsOverrides['MuiSkeleton'] => ({
  root: {},
  rectangular: {
    borderRadius: BORDER_RADIUS_DEFAULT,
  },
});

export const getMuiSkeletonDefaultProps = (): ComponentsProps['MuiSkeleton'] => ({
  animation: 'wave',
  variant: 'rectangular',
});
