import { Chains, IChainType, IConnectWallet, IContracts } from 'types';
import { AbiItem } from 'web3-utils';

import { crowdsaleAbi, erc20Abi, factoryAbi, gnosisAbi, stakingAbi, swapAbi } from './abi';

export const chains: {
  [key: string]: {
    [key: string]: {
      name: string;
      nativeCurrency: {
        name: string;
        symbol: string;
        decimals: number;
      };
      rpc: string;
      blockExplorerUrl: string;
      chainId: number;
      provider: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      };
      img?: string;
    };
  };
} = {
  MultiChain: {
    mainnet: {
      name: 'Binance-Smart-Chain',
      chainId: 56,
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpc: 'https://bsc-dataseed.binance.org/',
      blockExplorerUrl: 'https://bscscan.com',
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              rpc: {
                56: 'https://bsc-dataseed.binance.org/',
              },
              chainId: 56,
              wcConfig: {
                chains: [56],
                projectId: process.env.REACT_APP_WC_PROJECT_ID,
                showQrModal: true,
                methods: ['eth_sendTransaction', 'eth_signTypedData_v4', 'personal_sign', 'eth_sign'],
                qrModalOptions: {
                  themeVariables: {
                    '--wcm-z-index': '9999',
                  },
                },
              },
            },
          },
        },
      },
    },
    testnet: {
      name: 'Sepolia',
      chainId: 11155111,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'sETH',
        decimals: 18,
      },
      rpc: 'https://ethereum-sepolia-rpc.publicnode.com',
      blockExplorerUrl: 'https://sepolia.etherscan.io',
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              rpc: {
                11155111: 'https://ethereum-sepolia-rpc.publicnode.com',
              },
              chainId: 11155111,
              wcConfig: {
                chains: [11155111],
                projectId: process.env.REACT_APP_WC_PROJECT_ID,
                showQrModal: true,
                methods: ['eth_sendTransaction', 'eth_signTypedData_v4', 'personal_sign', 'eth_sign'],
                qrModalOptions: {
                  themeVariables: {
                    '--wcm-z-index': '9999',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export const connectWallet = (newChainName: Chains, type: IChainType): IConnectWallet => {
  const chain = chains[newChainName][type];
  return {
    network: {
      chainName: chain.name,
      chainID: chain.chainId,
      nativeCurrency: chain.nativeCurrency,
      rpc: chain.rpc,
      blockExplorerUrl: chain.blockExplorerUrl,
    },
    provider: chain.provider,
    settings: { providerType: true },
  };
};

export enum ContractsNames {
  token,
  factory,
  crowdsale,
  staking,
  swap,
  gnosis,
}

export type IContractsNames = keyof typeof ContractsNames;

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts: {
    [ContractsNames.token]: {
      testnet: {
        address: {
          [Chains.MultiChain]: '0xE90Cb47065ed2C7B8a55E4C0ac325008fd0821B6',
        },
        abi: erc20Abi as AbiItem[],
        symbol: 'TYZ',
        decimals: 18,
        img: `${window.location.origin}/tokenIcon.png`,
      },
      mainnet: {
        address: {
          [Chains.MultiChain]: '0x0d265949881d2120a9a1a1a0528941f4761a6aad',
        },
        abi: erc20Abi as AbiItem[],
        symbol: 'TYZ',
        decimals: 18,
        img: `${window.location.origin}/tokenIcon.png`,
      },
    },
    [ContractsNames.factory]: {
      testnet: {
        address: {
          [Chains.MultiChain]: '0x78C533B82E7A98492F40b59a4710A05DF26Ab9d6',
        },
        abi: factoryAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.MultiChain]: '0x0c806E27EA267ee4Bba9D156A53856D4AB5da813',
        },
        abi: factoryAbi as AbiItem[],
      },
    },
    [ContractsNames.staking]: {
      testnet: {
        address: {
          [Chains.MultiChain]: '0xDf959d0d1fdA9869c004a9580D42d6bcB1243cd2',
        },
        abi: stakingAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.MultiChain]: '0x67c61dfd40AD0F5B6320A9306D9F3A812eDBbfbB',
        },
        abi: stakingAbi as AbiItem[],
      },
    },
    [ContractsNames.crowdsale]: {
      testnet: {
        address: {
          [Chains.MultiChain]: '0x05aF0D37Ae2a37CA8a6bEd736A29Ed821059d556',
        },
        abi: crowdsaleAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.MultiChain]: '0x1B63d478E04C73b2670287A04898CC95cf42318F',
        },
        abi: crowdsaleAbi as AbiItem[],
      },
    },
    [ContractsNames.swap]: {
      testnet: {
        address: {
          [Chains.MultiChain]: '0xaC61d2b6a123a29083b116A43d02E18aDAF4818C',
        },
        abi: swapAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.MultiChain]: '0xCF131a01c3bCBE14aD72e5EC0cd753fC62E24A9c',
        },
        abi: swapAbi as AbiItem[],
      },
    },
    [ContractsNames.gnosis]: {
      testnet: {
        address: {
          [Chains.MultiChain]: '0x6D5306C5b45509AbaDC0045dA92f837A659653DC',
        },
        abi: gnosisAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.MultiChain]: '0x082261A51b6b3b66438501904127aFBb4c093360',
        },
        abi: gnosisAbi as AbiItem[],
      },
    },
  },
};
