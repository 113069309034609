/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { MainLogo } from 'modules/layout/components';
import { ImageBackground, LockImage } from 'modules/referral/assets';

const MainLogoStyled = styled(MainLogo)(({ theme }) => ({
  position: 'absolute',
  bottom: 74,
  right: 104,
}));

export const LockWithbackGround = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        height: {
          xs: 155,
          md: 708,
        },
        width: {
          xs: 342,
          md: 320,
        },
        position: 'relative',
        padding: 0,
      }}
    >
      <ImageBackground
        width={{
          xs: 342,
          md: 320,
        }}
        height={{
          xs: 155,
          md: 708,
        }}
      />
      <LockImage
        sx={{
          position: 'absolute',
          height: {
            xs: 153,
            md: 241,
          },
          width: {
            xs: 192,
            md: 308,
          },
          top: {
            xs: 5,
            md: 209,
          },
          right: {
            xs: 75,
            md: 6,
          },
        }}
      />
      {isMd && <MainLogoStyled />}
    </Box>
  );
};
