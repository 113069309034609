import i18next from 'i18next';
import { takeLatest } from 'redux-saga/effects';
import { ContractsNames } from 'services/WalletService/config';
import { notifyText } from 'services/WalletService/config/constants';
import { error, request, success } from 'store/api/actions';
import crowdsaleSelector from 'store/crowdsale/selectors';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { call, put, select } from 'typed-redux-saga';
import { CrowdsaleAbi, Modals } from 'types';
import { getContractDataByItsName, getToastMessage } from 'utils';

import { onBuyToken } from '../actions';
import actionTypes from '../actionTypes';

import { getTokensBalancesSaga } from './getTokensBalances';

export function* refundSaga({ type, payload: { web3Provider } }: ReturnType<typeof onBuyToken>) {
  yield* put(request(type));
  const { t } = i18next;

  const { chainType, address: myAddress } = yield select(userSelector.getUser);
  const crowdsaleContractAddress: string = yield select(crowdsaleSelector.getProp('address'));

  const [crowdsaleAbi] = getContractDataByItsName(ContractsNames.crowdsale, chainType);

  try {
    const crowdsaleContract: CrowdsaleAbi = yield new web3Provider.eth.Contract(crowdsaleAbi, crowdsaleContractAddress);

    yield put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const { transactionHash } = yield* call(crowdsaleContract.methods.refund().send, {
      from: myAddress,
      to: crowdsaleContractAddress,
    });

    yield* call(getTokensBalancesSaga, {
      type: actionTypes.GET_TOKENS_BALANCES,
      payload: { web3Provider },
    });

    yield put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: false,
        txHash: transactionHash,
      }),
    );
    getToastMessage('success', notifyText.refund.success(t));

    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    yield put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
      }),
    );
    getToastMessage('error', notifyText.refund.error(t));

    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.REFUND, refundSaga);
}
