import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps, IconButton } from '@mui/material';

export const CustomBox: FC<
  BoxProps & PropsWithChildren & { width: number; height: number; isButton?: boolean; onClickEvent?: () => void }
> = ({ width, height, children, isButton = false, sx, onClickEvent }) => {
  const style = { width: `${width}px`, height: `${height}px`, borderRadius: '12px', background: 'white', ...sx };
  return isButton ? (
    <IconButton
      onClick={onClickEvent}
      sx={{
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
        transition: 'transform 0.15s ease-in-out',
        '&:active': {
          transform: 'scale(0.92)',
        },
        ...style,
      }}
    >
      {children}
    </IconButton>
  ) : (
    <Box sx={style}>{children}</Box>
  );
};
