import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, BoxProps, Button, IconButton, Input, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useModal, useShallowSelector } from 'hooks';
import { DateRangePickerWrapper, ProfileDateSelectorButton } from 'modules/dashboard/components/DateRangePickerWrapper';
import { AvaIcon, EditIconWhite } from 'modules/dashboard/components/Icon';
import { Project } from 'modules/dashboard/components/TableColumn';
import { ConnectWalletModal } from 'modules/layout/containers';
import { useWalletConnectorContext } from 'services';
import { getPaymentsHistory, getWithdrawals, setProfileAvatar } from 'store/dashboard/actions';
import selector from 'store/dashboard/selectors';
import userSelector from 'store/user/selectors';
import { FontFamilies, FontWeights } from 'theme';

import { CopyIcon } from '../../../../../../components';
import { Popup } from '../Popup';
import { PopupProps } from '../PopupProps';

import { ProjectWindow } from './ProjectWindow';
import { SwapPopup } from './SwapPopup';

const ProfileTextField: FC<BoxProps & { text: string; fontWeight?: number; fontSize?: string }> = ({
  sx,
  text,
  fontWeight = FontWeights.fontWeightMedium,
  fontSize = '20px',
}) => {
  return (
    <Typography
      fontFamily={FontFamilies.secondary}
      sx={{ color: 'white', textAlign: 'center', fontSize: { fontSize }, fontWeight: { fontWeight }, ...sx }}
    >
      {text}
    </Typography>
  );
};

export const InfoPopup: FC<PopupProps> = ({ isOpen, closePopup }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { profile, withdrawals, paymentsHistory } = useShallowSelector(selector.getDashboard);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [dimensions, setDimensions] = useState({ width: 100, height: 100 });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { withdrawalLimits } = useShallowSelector(selector.getDashboard);
  const [selectedOption, setSelectedOption] = useState<string>(Project.ALL);
  const [totalAmountOfPayments, setTotalAmountOfPayments] = useState<number | null>(null);
  const [totalWithdrawn, setTotalWithdrawn] = useState<number | null>(null);

  const userAddress = useShallowSelector(userSelector.getProp('address'));
  const isWalletConnected = userAddress.length > 0;

  const [isSwapOpen, setSwapOpen] = useState(false);
  const [isConnectModalVisible, openConnectModal, closeConnectModal] = useModal(false);
  const { walletService, connect } = useWalletConnectorContext();

  const clickSwap = () => {
    if (isWalletConnected) {
      setSwapOpen(true);
      return;
    }
    openConnectModal();
  };
  const closeSwap = () => {
    setSwapOpen(false);
  };

  useEffect(() => {
    if (withdrawals === null) {
      dispatch(getWithdrawals());
    }
    if (paymentsHistory === null) {
      dispatch(getPaymentsHistory());
    }
  }, [dispatch]);

  useEffect(() => {
    if (startDate === null && endDate === null) {
      const today = new Date();
      const lastMonth = new Date(today);
      lastMonth.setMonth(today.getMonth() - 1);
      setStartDate(lastMonth);
      setEndDate(today);
    }
  }, [startDate, endDate, profile, dispatch]);

  useEffect(() => {
    if (startDate && endDate) {
      const dateAccurateToDays = (date: Date) => {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
      };
      if (paymentsHistory) {
        setTotalAmountOfPayments(
          paymentsHistory
            .filter((payment) => {
              let projectMatching = false;
              if (selectedOption === Project.ALL) {
                projectMatching = true;
              } else {
                projectMatching = selectedOption === payment.project;
              }
              return (
                dateAccurateToDays(payment.date) >= dateAccurateToDays(startDate) &&
                dateAccurateToDays(payment.date) <= dateAccurateToDays(endDate) &&
                projectMatching
              );
            })
            .reduce((sum, payment) => sum + payment.amount, 0),
        );
      }
      if (withdrawals) {
        setTotalWithdrawn(
          withdrawals
            .filter(
              (withdrawal) =>
                dateAccurateToDays(withdrawal.date) >= dateAccurateToDays(startDate) &&
                dateAccurateToDays(withdrawal.date) <= dateAccurateToDays(endDate),
            )
            .reduce((sum, withdrawal) => sum + +withdrawal.amount, 0),
        );
      }
    }
  }, [selectedOption, startDate, endDate, dispatch, paymentsHistory, withdrawals]);

  useEffect(() => {
    if (selectedImage !== null && profile !== null) {
      const reader = new FileReader();
      reader.onload = () => {
        const imgSrc = reader.result as string;
        dispatch(setProfileAvatar({ avatar: imgSrc }));
      };
      reader.readAsDataURL(selectedImage);

      const img = new Image();
      img.src = URL.createObjectURL(selectedImage);
      img.onload = () => {
        const ratio = img.width / img.height;
        if (img.width < img.height) {
          setDimensions({ width: 100, height: 100 / ratio });
        } else {
          setDimensions({ width: 100 * ratio, height: 100 });
        }
      };
    }
  }, [selectedImage]);

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          setSelectedImage(file);
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const getFormatedNumber = (input: number) => {
    const number = Number(input);
    if (Number.isNaN(number)) {
      return '-';
    }
    const parts = number.toFixed(2).split('.');
    return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')} TES`;
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Popup
      isOpen={isOpen}
      closePopup={closePopup}
      header={t('dashboard.infoButton')}
      height="691px"
      width={isSmallScreen ? '342px' : '452px'}
    >
      <Box display="flex" flexDirection="column" alignItems="center" marginTop="22px">
        <Box width="118px" height="118px" position="relative" display="flex" justifyContent="center">
          <Box
            borderRadius="50%"
            width="100px"
            height="100px"
            border={profile && !profile.isBlocked ? '3px solid #00FF19' : '1px'}
            overflow="hidden"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {profile && (selectedImage || profile.avatar !== '') ? (
              <Box
                component="img"
                src={selectedImage ? URL.createObjectURL(selectedImage) : profile.avatar}
                alt="Profile"
                sx={{
                  filter: profile.isBlocked ? 'grayscale(100%)' : '',
                  width: dimensions.width,
                  height: dimensions.height,
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box overflow="hidden" sx={{ background: 'white' }}>
                <AvaIcon sx={{ width: '100px', height: '100px' }} />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              left: '100px',
              bottom: '18px',
              width: '18px',
              height: '18px',
            }}
          >
            <IconButton
              component="label"
              sx={{
                width: '100%',
                height: '100%',
                padding: 0,
                background: 'transparent',
                cursor: 'pointer',
                transition: 'transform 0.15s ease-in-out',
                '&:active': {
                  transform: 'scale(0.9)',
                },
              }}
            >
              <EditIconWhite sx={{ width: 17, height: 17 }} />
              <Input
                type="file"
                inputProps={{ accept: 'image/*' }}
                onChange={handleFileSelect}
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  padding: 0,
                  opacity: 0,
                  zIndex: -1,
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <ProfileTextField
          text={profile ? profile.email : '-'}
          fontSize="24px"
          fontWeight={FontWeights.fontWeightBold}
        />
        <ProfileTextField
          text={t('dashboard.adminDashboard.referralCode')}
          fontSize="18px"
          fontWeight={FontWeights.fontWeightRegular}
          sx={{ marginTop: '6px' }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          <ProfileTextField
            text={profile ? profile.code : '-'}
            fontSize="22px"
            fontWeight={FontWeights.fontWeightMedium}
          />
          <IconButton
            onClick={() => {
              if (profile) copyToClipboard(profile.code);
            }}
            size="small"
            sx={{ position: 'absolute', right: '-2rem' }}
          >
            <CopyIcon sx={{ fontSize: 'medium', color: '#4DA3FF' }} />
          </IconButton>
        </Box>
        <ProfileTextField text={profile ? `${profile.balance} TES` : '-'} sx={{ marginTop: '15px' }} />
        <Box display="flex" justifyContent="center" alignItems="center" margin="13px">
          <Button
            onClick={clickSwap}
            sx={{
              minWidth: isSmallScreen ? '140px' : '148px',
              borderRadius: '8px',
              marginRight: isSmallScreen ? '9px' : '26px',
            }}
          >
            <ProfileTextField text={t('dashboard.info.swap')} fontSize="17px" />
          </Button>
          <Box sx={{ width: isSmallScreen ? '140px' : '148px', marginLeft: isSmallScreen ? '9px' : '26px' }}>
            <ProfileTextField
              text={t('dashboard.info.minWithdrawal')}
              maxHeight="20px"
              fontSize="14px"
              fontWeight={FontWeights.fontWeightMedium}
              sx={{ wordWrap: 'break-word', width: '100%', lineHeight: '1.2' }}
            />
            <ProfileTextField
              text={getFormatedNumber(withdrawalLimits.minWithdrawal)}
              fontSize={isSmallScreen ? '18px' : '20px'}
              sx={{ width: '100%', whiteSpace: 'nowrap' }}
            />
          </Box>
        </Box>
        <DateRangePickerWrapper
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          width="232px"
          height="30px"
          DateSelectorButtonComponent={ProfileDateSelectorButton}
          celendarSx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
        <ProjectWindow
          label={t('dashboard.info.project')}
          options={Object.values(Project)}
          sx={{ marginTop: '18px' }}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <ProfileTextField
          text={t('dashboard.info.earned')}
          sx={{ fontWeight: FontWeights.fontWeightBold, marginTop: '13px' }}
        />
        <ProfileTextField text={getFormatedNumber(totalAmountOfPayments ?? 0)} />
        <ProfileTextField
          text={t('dashboard.info.withdrawn')}
          sx={{ fontWeight: FontWeights.fontWeightBold, marginTop: '13px' }}
        />
        <ProfileTextField text={getFormatedNumber(totalWithdrawn ?? 0)} />
      </Box>
      <SwapPopup isOpen={isSwapOpen} closePopup={closeSwap} />
      {isConnectModalVisible && (
        <ConnectWalletModal open={isConnectModalVisible} onClose={closeConnectModal} onConnectWallet={connect} />
      )}
    </Popup>
  );
};
