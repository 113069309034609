import { UseGetConditionalsReturnType } from './useGetConditionals.types';

export const useGetConditionals = (
  totalBought: number,
  softCap: number,
  saleEndTime: number,
  tyzTokenBalance: number,
): UseGetConditionalsReturnType => {
  const isAllowToClaim = !(Date.now() > saleEndTime * 1000 && totalBought < softCap) && tyzTokenBalance > 0;
  const isAllowToRefund = totalBought < softCap && Date.now() > saleEndTime * 1000 && tyzTokenBalance > 0;

  return { isAllowToClaim, isAllowToRefund };
};
