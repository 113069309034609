import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowRightIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 32 32"
    sx={{
      width: '32px',
      height: '32px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 24C32 28.4183 28.4183 32 24 32L0 32L2.79753e-06 -2.79753e-06L24 -6.99382e-07C28.4183 -3.13124e-07 32 3.58172 32 8L32 24Z"
      fill="white"
    />
    <path
      d="M13 22L18.6854 16.7071C19.1049 16.3166 19.1049 15.6834 18.6854 15.2929L13 10"
      stroke="#BFBFBF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);
