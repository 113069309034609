import BigNumber from 'bignumber.js/bignumber';

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export const toAmountWithPrice = (
  balance: string | number,
  amount?: string | number,
  price?: string | number,
  decimals = 18,
  format: 'BN' | 'string' | 'number' = 'BN',
) => {
  if (format === 'string') {
    return new BigNumber(balance)
      .multipliedBy(new BigNumber(amount || 0).dividedBy(new BigNumber(price || 0)))
      .decimalPlaces(decimals)
      .toString();
  }
  if (format === 'number') {
    return Number(
      new BigNumber(balance)
        .multipliedBy(new BigNumber(amount || 0).dividedBy(new BigNumber(price || 0)))
        .decimalPlaces(decimals)
        .toString(),
    );
  }
  return new BigNumber(balance).multipliedBy(new BigNumber(amount || 0).dividedBy(new BigNumber(price || 0)));
};
