/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { InputField, PlusIcon } from 'modules/dashboard/components';
import { AddAdminPopup, AdminListPopup } from 'modules/dashboard/components/Popups';
import { IsValidPassword } from 'modules/game22/components';
import { addAdmin, updateNewAdmin } from 'store/dashboard/actions';
import selector from 'store/dashboard/selectors';
import { FontFamilies, FontWeights } from 'theme';
import { getToastMessage } from 'utils';

export const AddingAdmin: FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const buttonStyles = {
    padding: '22px',
    borderRadius: '22px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    transition: 'transform 0.15s ease-in-out',
    '&:active': {
      transform: 'scale(0.92)',
    },
  };

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [emailInput, setInputEmail] = useState('');
  const [passwordInput, setInputPassword] = useState('');
  const [isAdminsListPopupOpen, setIsAdminsListOpen] = useState(false);
  const [isAddAdminPopupOpen, setIsAddAdminOpen] = useState(false);

  const validateEmail = (email: string): boolean => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  const handleInputEmail = (newValue: string) => setInputEmail(newValue);
  const handleInputPassword = (newValue: string) => setInputPassword(newValue);

  const handleAddAdminSubmit = (email: string, password: string) => {
    dispatch(addAdmin({ email, password }));
  };

  const closeAdminsListPopup = () => {
    setIsAdminsListOpen(false);
  };
  const openAdminListPopup = () => {
    setIsAdminsListOpen(true);
  };

  const handleAddAdminPopupClose = () => {
    dispatch(updateNewAdmin());
  };

  const AddAdmin = () => {
    const isValidEmail = validateEmail(emailInput);
    const isValidPassword = IsValidPassword(passwordInput);
    if (isValidEmail && isValidPassword) {
      handleAddAdminSubmit(emailInput, passwordInput);
    } else if (!isValidEmail) {
      getToastMessage('error', t('dashboard.settings.addingAdmin.invalidEmail'));
    } else {
      getToastMessage('error', t('dashboard.settings.addingAdmin.invalidPassword'));
    }
  };

  const { admin } = useShallowSelector(selector.getDashboard);

  useEffect(() => {
    if (admin !== null) {
      setIsAddAdminOpen(true);
    } else {
      setIsAddAdminOpen(false);
    }
  }, [admin]);

  useEffect(() => {
    if (!isAddAdminPopupOpen && admin === null) {
      setInputEmail('');
      setInputPassword('');
    }
  }, [isAddAdminPopupOpen]);

  return (
    <Box
      sx={{
        marginBottom: isSmallScreen ? '64px' : '0px',
        marginLeft: isSmallScreen ? '0px' : '25px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: isSmallScreen ? 'center' : 'flex-start',
      }}
    >
      <AdminListPopup isOpen={isAdminsListPopupOpen} closePopup={closeAdminsListPopup} />
      <AddAdminPopup isOpen={isAddAdminPopupOpen} closePopup={handleAddAdminPopupClose} email={emailInput} />
      <Box sx={{ width: '100%' }}>
        <InputField
          value={emailInput}
          onChange={handleInputEmail}
          headerText="Email"
          sx={{
            marginTop: '-2px',
            width: `min(calc(100% - ${isSmallScreen ? 0 : 216}px), 1000px)`,
          }}
          type="email"
          isSmallScreen={isSmallScreen}
        />
        <InputField
          value={passwordInput}
          onChange={handleInputPassword}
          headerText={t('dashboard.settings.addingAdmin.password')}
          sx={{
            marginTop: isSmallScreen ? '36px' : '22px',
            width: `min(calc(100% - ${isSmallScreen ? 0 : 216}px), 1000px)`,
          }}
          type="password"
          isSmallScreen={isSmallScreen}
        />
      </Box>
      <IconButton
        onClick={AddAdmin}
        sx={{
          marginTop: isSmallScreen ? '36px' : '18px',
          width: '338px',
          height: '44px',
          gap: '10px',
          ...buttonStyles,
        }}
      >
        <PlusIcon width="28px" height="28px" />
        <Typography
          fontFamily={FontFamilies.secondary}
          fontSize="20px"
          fontWeight={FontWeights.fontWeightMedium}
          color="#191836"
          sx={{ textAlign: 'center' }}
          width="258px"
        >
          {t('dashboard.settings.addingAdmin.addAdmin')}
        </Typography>
      </IconButton>
      <IconButton
        sx={{
          marginTop: isSmallScreen ? '24px' : '14px',
          width: '290px',
          height: '40px',
          ...buttonStyles,
        }}
        onClick={openAdminListPopup}
      >
        <Typography
          fontFamily={FontFamilies.secondary}
          fontSize="20px"
          fontWeight={FontWeights.fontWeightMedium}
          color="#191836"
          sx={{ textAlign: 'center' }}
        >
          {t('dashboard.settings.addingAdmin.adminList')}
        </Typography>
      </IconButton>
    </Box>
  );
};
