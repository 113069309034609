import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { SupportPopup } from 'modules/dashboard/components/Popups';
import selector from 'store/dashboard/selectors';

import { InfoButon, SupportButton } from '../../../dashboard/containers';
import { LoadingTree } from '../LoadingTree';
import { ReferralTree, ReferralTreeData } from '../ReferralTree';
import { SearchBar } from '../SearchBar';

enum SearchIteration {
  ExactMatch = 0,
  StartsWith = 1,
  Contains = 2,
}

export const SearchableTree = ({
  data,
  reload,
  error,
  close,
  openProfile,
  info,
}: {
  data: ReferralTreeData | null;
  reload: () => void;
  error: boolean;
  close: (() => void) | null;
  openProfile: ((email: string) => void) | null;
  info: boolean;
}) => {
  const { profile } = useShallowSelector(selector.getDashboard);
  const [role, setRole] = useState('user');

  useEffect(() => {
    if (profile !== null) {
      setRole(profile.role);
    }
  }, [profile]);
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [searchData, setSearchData] = useState<ReferralTreeData | null>(data);

  useEffect(() => {
    setSearchData(data);
  }, [data]);
  const [isSupportPopupOpen, setSupportPopupOpen] = useState(false);

  const searchForUser = (searchString: string, iteration: SearchIteration) => {
    if (!searchString) {
      setSearchData(data);
      return;
    }

    const queue = [data];
    let found = false;

    const regexSafe = searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    while (queue.length > 0 && !found) {
      const currentNode = queue.shift();

      if (
        (iteration === SearchIteration.ExactMatch &&
          (currentNode?.email === searchString || currentNode?.code === searchString)) ||
        (iteration === SearchIteration.StartsWith &&
          (currentNode?.email.startsWith(searchString) || currentNode?.code.startsWith(searchString))) ||
        (iteration === SearchIteration.Contains && currentNode?.email.includes(regexSafe))
      ) {
        setSearchData(currentNode);
        found = true;
        break;
      }

      if (currentNode?.children) {
        currentNode.children.forEach((child) => queue.push(child));
      }
    }

    if (!found) {
      if (iteration < SearchIteration.Contains) {
        searchForUser(searchString, iteration + 1);
      } else {
        setSearchData(null);
      }
    }
  };

  return (
    <div className="flex flex-col">
      <SupportPopup isOpen={isSupportPopupOpen} closePopup={() => setSupportPopupOpen(false)} />
      {info && (
        <div className="hidden phone:flex mt-4 self-end">
          <InfoButon />
        </div>
      )}
      <div className="flex justify-between mb-4 mt-4">
        <SearchBar onSearch={(what) => searchForUser(what, SearchIteration.ExactMatch)} />
        {info && (
          <div className="phone:hidden">
            <InfoButon />
          </div>
        )}
      </div>
      {!data ? (
        <LoadingTree error={error} reload={() => reload()} close={close} />
      ) : (
        <ReferralTree data={searchData} focus={[]} reload={() => reload()} close={close} openProfile={openProfile} />
      )}
      <div className="mt-4 self-end phone:hidden">
        {role === 'user' && !isSmallScreen && <SupportButton openPopup={() => setSupportPopupOpen(true)} />}
      </div>
    </div>
  );
};
