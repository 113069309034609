import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BoxProps, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import csvDownload from 'json-to-csv-export';
import { DownloadIcon } from 'modules/dashboard/components';
import { COLOR_TEXT_BLUE_100, FontFamilies, FontWeights } from 'theme';

export const downloadData = (data: string[][], columns: string[], filename: string) => {
  const dataToConvert = {
    data,
    filename,
    delimiter: ',',
    headers: columns,
  };
  return csvDownload(dataToConvert);
};

export const getFileName = (
  searchString: string,
  startDate: Date | null,
  endDate: Date | null,
  defaultFilename: string,
) => {
  const isDateSpecified = startDate && endDate && true;
  const isSearchSpecified = searchString !== '';
  const dateFilename = isDateSpecified ? `${format(startDate, 'dd.MM.yyyy')}-${format(endDate, 'dd.MM.yyyy')}` : '';
  const baseFilename =
    isSearchSpecified && isDateSpecified ? `${searchString}_${dateFilename}` : searchString + dateFilename;
  const filename = baseFilename.length > 0 ? baseFilename : defaultFilename;
  return filename;
};

type DownloadButtonProps = {
  data: string[][];
  columns: string[];
  searchString: string;
  startDate: Date | null;
  endDate: Date | null;
  defaultFilename: string;
  customHandler: (() => void) | null;
} & BoxProps;

export const DownloadButton: FC<DownloadButtonProps> = ({
  data,
  columns,
  searchString,
  startDate,
  endDate,
  defaultFilename,
  customHandler,
  sx,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const filename = getFileName(searchString, startDate, endDate, defaultFilename);

  return (
    <IconButton
      onClick={() => {
        if (customHandler) customHandler();
        else downloadData(data, columns, filename);
      }}
      sx={{
        width: '149px',
        height: isSmallScreen ? '32px' : '40px',
        borderRadius: isSmallScreen ? '16px' : '20px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
        '&:hover': { backgroundColor: '#f0f0f0' },
        transition: 'transform 0.15s ease-in-out',
        '&:active': { transform: 'scale(0.92)' },
        ...sx,
      }}
    >
      <DownloadIcon sx={{ mt: '2px', ml: '4px' }} />
      <Typography
        sx={{
          ml: 1,
          font: FontFamilies.secondary,
          fontSize: 18,
          color: COLOR_TEXT_BLUE_100,
          fontWeight: FontWeights.fontWeightMedium,
        }}
      >
        {t('dashboard.history.download')}
      </Typography>
    </IconButton>
  );
};
