import { BNB_TOKEN_ADDRESS, HIGH_GAS_PRIRCE } from 'appConstants';
import BigNumber from 'bignumber.js';
import i18next from 'i18next';
import { takeLatest } from 'redux-saga/effects';
import { ContractsNames } from 'services/WalletService/config';
import { erc20Abi } from 'services/WalletService/config/abi';
import { notifyText } from 'services/WalletService/config/constants';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import crowdsaleSelector from 'store/crowdsale/selectors';
import { setActiveModal } from 'store/modals/reducer';
import userActionTypes from 'store/user/actionTypes';
import { approveSaga } from 'store/user/sagas/approve';
import userSelector from 'store/user/selectors';
import { call, put, select } from 'typed-redux-saga';
import { CrowdsaleAbi, CrowdsaleState, Erc20Abi, Modals } from 'types';
import { ContractErrors } from 'types/store/errors';
import { getContractDataByItsName, getToastMessage, toAmountWithPrice, toDecimals } from 'utils';
import { AbiItem } from 'web3-utils';

import { onBuyToken } from '../actions';
import actionTypes from '../actionTypes';

import { getCrowdsaleDataSaga } from './getCrowdsaleDataSaga';
import { getTokensBalancesSaga } from './getTokensBalances';

export function* buyTokenSaga({
  type,
  payload: {
    web3Provider,
    sendingTokenAddress,
    receiveValue,
    sendValue,
    maxSendBalance,
    currentSendTokenValue,
    currentTYZTokenPrice,
  },
}: ReturnType<typeof onBuyToken>) {
  yield* put(request(type));
  const { t } = i18next;

  const isBNBToken = sendingTokenAddress === BNB_TOKEN_ADDRESS;
  let estimatedGas = 0;
  let gasPrice = '0';
  let estimatedReceiveValue = '0';

  const { address: crowdsaleContractAddress }: CrowdsaleState = yield select(crowdsaleSelector.getCrowdsale);
  const { chainType, address: myAddress } = yield select(userSelector.getUser);

  const [, receivingTokenAddress] = getContractDataByItsName(ContractsNames.token, chainType);
  const [crowdsaleAbi] = getContractDataByItsName(ContractsNames.crowdsale, chainType);

  try {
    const crowdsaleContract: CrowdsaleAbi = yield new web3Provider.eth.Contract(crowdsaleAbi, crowdsaleContractAddress);

    const receivingTokenContract: Erc20Abi = yield new web3Provider.eth.Contract(
      erc20Abi as AbiItem[],
      receivingTokenAddress,
    );

    const receivingTokenDecimals = yield* call(receivingTokenContract.methods.decimals().call);
    const receivingAmountWithDecimals = toDecimals(receiveValue, +receivingTokenDecimals);

    if (isBNBToken && maxSendBalance === sendValue) {
      const { data } = yield* call(baseApi.getBuyingSignature, {
        token_address: sendingTokenAddress,
        amount_to_receive: receivingAmountWithDecimals,
        crowdsale_address: crowdsaleContractAddress,
      });

      estimatedGas = yield* call(
        crowdsaleContract.methods.buy(
          sendingTokenAddress,
          data.amount_to_pay,
          data.amount_to_receive,
          data.signature_expiration_timestamp,
          data.signature,
        ).estimateGas,
        {
          from: myAddress,
          to: crowdsaleContractAddress,
          value: data.amount_to_pay,
        },
      );

      if (estimatedGas < HIGH_GAS_PRIRCE) estimatedGas = HIGH_GAS_PRIRCE;
      gasPrice = yield* call(web3Provider.eth.getGasPrice);
      const gas = new BigNumber(+gasPrice * estimatedGas).dividedBy(new BigNumber(10).exponentiatedBy(18)).toFixed(6);

      const estimatedSendValue = new BigNumber(sendValue).minus(gas).toFixed();

      estimatedReceiveValue = toDecimals(
        toAmountWithPrice(estimatedSendValue, currentSendTokenValue, currentTYZTokenPrice, 18, 'string') as string,
        +receivingTokenDecimals,
      );
    } else {
      estimatedReceiveValue = receivingAmountWithDecimals;
    }
    const { data } = yield* call(baseApi.getBuyingSignature, {
      token_address: sendingTokenAddress,
      amount_to_receive: estimatedReceiveValue,
      crowdsale_address: crowdsaleContractAddress,
    });

    if (data?.result) {
      yield put(
        setActiveModal({
          activeModal: Modals.init,
          open: false,
        }),
      );
      yield* put(error(type));
      throw new Error(data.result);
    }

    if (!isBNBToken) {
      yield* call(approveSaga, {
        type: userActionTypes.APPROVE,
        payload: {
          web3Provider,
          spenderAddress: sendingTokenAddress,
          amount: data.amount_to_pay,
          tokenAddress: crowdsaleContractAddress,
          isWithDecimals: true,
        },
      });
    }

    yield put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const { transactionHash } = yield* call(
      crowdsaleContract.methods.buy(
        sendingTokenAddress,
        data.amount_to_pay,
        data.amount_to_receive,
        data.signature_expiration_timestamp,
        data.signature,
      ).send,
      !isBNBToken
        ? {
            from: myAddress,
            to: crowdsaleContractAddress,
            maxFeePerGas: toDecimals(4, 9),
            maxPriorityFeePerGas: toDecimals(4, 9),
          }
        : {
            from: myAddress,
            to: crowdsaleContractAddress,
            value: data.amount_to_pay,
            maxFeePerGas: toDecimals(4, 9),
            maxPriorityFeePerGas: toDecimals(4, 9),
          },
    );

    yield* call(getCrowdsaleDataSaga, {
      type: actionTypes.GET_CROWDSALE_DATA,
      payload: { updateCrowdsaleAddress: false },
    });

    yield* call(getTokensBalancesSaga, {
      type: actionTypes.GET_TOKENS_BALANCES,
      payload: { web3Provider },
    });

    yield put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );
    getToastMessage('success', notifyText.buyToken.success(t));

    yield* put(success(type));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(err);

    if (err.code === 4001 && err.message !== ContractErrors.approveReject) {
      yield put(
        setActiveModal({
          activeModal: Modals.SendRejected,
          open: true,
        }),
      );
    }

    getToastMessage('error', notifyText.buyToken.error(t));
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.BUY_TOKEN, buyTokenSaga);
}
