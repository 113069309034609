import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ExclamationMarkIcon } from 'modules/dashboard/components/Icon';
import { takeBalance } from 'store/dashboard/actions';
import { FontFamilies, FontWeights } from 'theme';

import { Popup } from '../../Popup';
import { PopupProps } from '../../PopupProps';

export const WithdrawalPopup: FC<PopupProps & { email: string }> = ({ isOpen, closePopup, email }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  const [popupHeight, setPopupHeight] = useState(364);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSmallScreen) {
      setPopupHeight(386);
    } else if (email.length > 18) {
      setPopupHeight(400);
    } else {
      setPopupHeight(364);
    }
  }, [email, isSmallScreen]);

  const handleTakeBalance = () => {
    dispatch(takeBalance({ email }));
    closePopup();
  };

  const buttonSize = isSmallScreen
    ? { minWidth: '137px', width: '137px', height: '43px' }
    : { width: '237px', height: '55px' };

  const buttonTextStyle = {
    fontFamily: FontFamilies.secondary,
    fontWeight: FontWeights.fontWeightMedium,
    fontSize: isSmallScreen ? '16px' : '20px',
    color: 'white',
    textTransform: 'none',
  };

  return (
    <Popup
      isOpen={isOpen}
      closePopup={closePopup}
      header=""
      width={isSmallScreen ? '340px' : '585px'}
      height={`${popupHeight}px`}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="start">
        <ExclamationMarkIcon sx={{ marginTop: '12px' }} />
        <Box marginTop="12px">
          {popupHeight !== 364 ? (
            <>
              <Typography
                color="white"
                marginTop="20px"
                fontFamily={FontFamilies.secondary}
                fontSize="24px"
                fontWeight={FontWeights.fontWeightMedium}
                textAlign="center"
              >
                {t('dashboard.profile.withdrawMoney')}
              </Typography>
              <Typography
                width={isSmallScreen ? '290px' : '100%'}
                color="white"
                marginTop={isSmallScreen ? '4px' : '10px'}
                fontFamily={FontFamilies.secondary}
                fontSize="24px"
                fontWeight={FontWeights.fontWeightMedium}
                textAlign="center"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {email}?
              </Typography>
            </>
          ) : (
            <Typography
              color="white"
              marginTop="20px"
              fontFamily={FontFamilies.secondary}
              fontSize="24px"
              fontWeight={FontWeights.fontWeightMedium}
              textAlign="center"
            >
              {t('dashboard.profile.withdrawMoney')} {email}?
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="center" width="100%" marginTop="32px">
          <Button onClick={handleTakeBalance} sx={{ ...buttonSize, marginRight: isSmallScreen ? '18px' : '36px' }}>
            <Typography sx={buttonTextStyle}>{t('dashboard.profile.yes')}</Typography>
          </Button>
          <Button onClick={closePopup} sx={{ ...buttonSize }}>
            <Typography sx={buttonTextStyle}>{t('dashboard.profile.no')}</Typography>
          </Button>
        </Box>
      </Box>
    </Popup>
  );
};
