import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_TEXT_WHITE_100 } from 'theme/colors';
import {
  BG_INPUT_GRAY_HOVER,
  BORDER_COLOR_INPUT_GRAY_DEFAULT,
  BORDER_RADIUS_SMALL,
  SELECT_ICON_COLOR,
} from 'theme/variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiSelectOverrides = (theme: Theme): ComponentsOverrides['MuiSelect'] => ({
  select: {
    '&:focus': {
      borderColor: BORDER_COLOR_INPUT_GRAY_DEFAULT,
      background: BG_INPUT_GRAY_HOVER,
    },
  },
  filled: {
    boxShadow: 'none',
    background: BORDER_COLOR_INPUT_GRAY_DEFAULT,
    color: COLOR_TEXT_WHITE_100,
    fontSize: 16,
    lineHeight: '24px',
    border: '1px solid',
    borderRadius: BORDER_RADIUS_SMALL,
    borderColor: 'transparent',
    padding: '15px 17px',
    '&:hover': {
      borderColor: BORDER_COLOR_INPUT_GRAY_DEFAULT,
      background: BG_INPUT_GRAY_HOVER,
    },
  },
  iconFilled: {
    fill: SELECT_ICON_COLOR,
  },
});

export const getMuiSelectDefaultProps = (): ComponentsProps['MuiSelect'] => ({
  disableUnderline: true,
  style: {
    backgroundColor: 'transparent',
  },
});
