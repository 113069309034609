import { handleBackendErrors } from 'modules/referral/utils';
import { error, request, requestPasswordReset, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';

import actionTypes from '../actionTypes';
import { completeResetPasswordRequest, setError } from '../reducer';

export function* requestPasswordResetSaga({ type, payload: { email } }: ReturnType<typeof requestPasswordReset>) {
  yield* put(request(type));

  try {
    yield* call(baseApi.requestPasswordResetEmail, { email });
    yield* put(completeResetPasswordRequest());
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type));
    const parsedError = handleBackendErrors(err);
    if (parsedError) {
      yield* put(setError({ [parsedError.source]: parsedError.type }));
    }
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.REQUEST_PASSWORD_RESET, requestPasswordResetSaga);
}
