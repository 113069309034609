import { BuyToken } from 'modules/crowdsale/pages';
import { DashboardPage } from 'modules/dashboard/pages';
import { RegistrationPage as Game22RegistrationPage } from 'modules/game22/pages';
import { AML, ComingSoon, FAQ, Home, NotFound, PrivacyPolicy, TermsOfService } from 'modules/landing/pages';
import { GamesPage, LoginPage, RegistrationPage, UserInfoPage } from 'modules/referral/pages';
import { Staking } from 'modules/staking/pages';
import { SwapToken } from 'modules/swap/pages';
import { Modules, RoutesProps } from 'types';

/**
 @Description
 key 'dirName' - should be equal to component dir name
 @example MyInvestments - name of dir and dirName: MyInvestments
 */

export const routes: RoutesProps = {
  home: {
    dirName: 'Home',
    root: {
      id: 1,
      title: 'home',
      path: '/',
      component: <Home />,
      module: Modules.landing,
    },
  },
  buyToken: {
    dirName: 'BuyToken',
    root: {
      id: 1,
      title: 'buy Token',
      path: '/buy-token',
      component: <BuyToken />,
      module: Modules.crowdsale,
    },
  },
  swapToken: {
    dirName: 'SwapToken',
    root: {
      id: 1,
      title: 'swap',
      path: '/swap-token',
      component: <SwapToken />,
      module: Modules.swap,
      isNavItem: true,
    },
  },
  staking: {
    dirName: 'Staking',
    root: {
      id: 2,
      title: 'staking',
      path: '/staking',
      component: <Staking />,
      module: Modules.staking,
    },
  },
  comingSoon: {
    dirName: 'ComingSoon',
    root: {
      id: 3,
      title: 'Coming Soon',
      path: '/coming-soon',
      component: <ComingSoon />,
      module: Modules.landing,
    },
  },
  notFound: {
    dirName: 'NotFound',
    root: {
      id: 3,
      title: 'Not Found',
      path: '/404',
      component: <NotFound />,
      module: Modules.landing,
    },
  },
  faq: {
    dirName: 'FAQ',
    root: {
      id: 3,
      title: 'faq',
      path: '/faq',
      component: <FAQ />,
      module: Modules.landing,
      isNavItem: true,
    },
  },
  services: {
    dirName: 'ComingSoon',
    root: {
      id: 4,
      title: 'services',
      path: '/coming-soon',
      component: <ComingSoon />,
      module: Modules.landing,
      isNavItem: true,
    },
  },
  privacyPolicy: {
    dirName: 'PrivacyPolicy',
    root: {
      id: 4,
      title: 'Privacy Policy',
      path: '/privacy-policy',
      component: <PrivacyPolicy />,
      module: Modules.landing,
    },
  },
  termsOfService: {
    dirName: 'TermsOfService',
    root: {
      id: 4,
      title: 'Terms of service',
      path: '/terms-of-service',
      component: <TermsOfService />,
      module: Modules.landing,
    },
  },
  aml: {
    dirName: 'AML',
    root: {
      id: 4,
      title: 'AML',
      path: '/aml',
      component: <AML />,
      module: Modules.landing,
    },
  },
  referralSystem: {
    dirName: 'RegistrationPage',
    root: {
      id: 5,
      title: 'referralSystem',
      path: '/referrals/signup',
      component: <RegistrationPage />,
      module: Modules.referral,
    },
  },
  referralLogin: {
    dirName: 'LoginPage',
    root: {
      id: 6,
      title: 'referralLogin',
      path: '/referrals/signin',
      component: <LoginPage />,
      module: Modules.referral,
      isNavItem: true,
    },
  },
  referralUserInfo: {
    dirName: 'UserInfoPage',
    root: {
      id: 7,
      title: 'referralUserInfo',
      path: '/referrals/user',
      component: <UserInfoPage />,
      module: Modules.referral,
    },
  },
  project: {
    dirName: 'RegistrationPage',
    root: {
      id: 9,
      title: 'Game22Registration',
      path: '/game22/signup',
      component: <Game22RegistrationPage />,
      module: Modules.game22,
    },
  },
  dashboardPage: {
    dirName: 'DashboardPage',
    root: {
      id: 9,
      title: 'Dashboard',
      path: '/referrals/dashboard/*',
      component: <DashboardPage />,
      module: Modules.dashboard,
    },
  },
  gamesPage: {
    dirName: 'GamesPage',
    root: {
      id: 10,
      title: 'Games',
      path: '/referrals/games',
      component: <GamesPage />,
      module: Modules.referral,
    },
  },
};
