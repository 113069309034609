import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, IconButton, Input, useMediaQuery, useTheme } from '@mui/material';
import { WhitePlusIcon } from 'modules/dashboard/components/Icon';
import { addGame } from 'store/dashboard/actions';
import { getToastMessage } from 'utils';

import { Popup } from '../../Popup';
import { PopupProps } from '../../PopupProps';
import { PopupInput } from '../PopupInput';

export const GameAddingPopup: FC<PopupProps> = ({ isOpen, closePopup }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [gameName, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value);

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          setImageDimensions({ width: img.width, height: img.height });
          setSelectedImage(file);
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  let imgStyle: React.CSSProperties = { width: 'auto', height: 'auto' };
  if (imageDimensions.width > imageDimensions.height) {
    imgStyle = { width: '195px', height: 'auto' };
  } else {
    imgStyle = { height: '195px', width: 'auto' };
  }

  const onClose = () => {
    setSelectedImage(null);
    setInputValue('');
    closePopup();
  };

  const dispatch = useDispatch();

  const handleAddGameSubmit = (name: string) => {
    const isTextEntered = name !== '';
    const isTooLong = name.length > 25;
    if (selectedImage && isTextEntered && !isTooLong) {
      const reader = new FileReader();
      reader.onload = () => {
        const imgSrc = reader.result as string;
        dispatch(addGame({ name, img: imgSrc }));
      };
      reader.readAsDataURL(selectedImage);
      onClose();
    } else if (isTooLong) {
      getToastMessage('error', t('dashboard.settings.addingGame.tooManyCharacters'));
    } else if (!isTextEntered) {
      getToastMessage('error', t('dashboard.settings.addingGame.noGameName'));
    } else {
      getToastMessage('error', t('dashboard.settings.addingGame.iconNotSelected'));
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      closePopup={onClose}
      header={t('dashboard.settings.addingGame.addGame')}
      height="495px"
      width={isSmallScreen ? '342px' : '448px'}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton
          sx={{
            marginTop: '24px',
            position: 'relative',
            borderRadius: '20px',
            background: 'rgba(217, 217, 217, 0.1)',
            width: '200px',
            height: '200px',
            overflow: 'hidden',
          }}
          component="label"
        >
          <Input
            type="file"
            inputProps={{ accept: 'image/*' }}
            onChange={handleFileSelect}
            sx={{
              position: 'absolute',
              opacity: 0,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          />
          {selectedImage && <img src={URL.createObjectURL(selectedImage)} alt="selected img" style={imgStyle} />}
          {!selectedImage && <WhitePlusIcon />}
        </IconButton>
        <PopupInput
          width={isSmallScreen ? '294px' : '400px'}
          label={t('dashboard.settings.addingGame.name')}
          onInputChange={handleInputChange}
          inputValue={gameName}
        />
        <Button onClick={() => handleAddGameSubmit(gameName)} sx={{ borderRadius: '10px', marginTop: '24px' }}>
          OK
        </Button>
      </Box>
    </Popup>
  );
};
