import { FC } from 'react';
import { Box, BoxProps } from '@mui/material';

import Icon from './TesIcon.png';

export const TesIcon: FC<BoxProps> = ({ sx }) => {
  return (
    <Box
      component="img"
      alt="Tes coin logo"
      src={Icon}
      sx={{
        width: '25px',
        height: '24px',
        alignItems: 'center',
        ...sx,
      }}
    />
  );
};
