import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { TyzIcon } from 'assets/img';
import { BORDER_DEFAULT_GRAY, BORDER_RADIUS_DEFAULT, FontWeights } from 'theme';
import { flexHelper } from 'utils/flexHelper';

type SelectedTokenProps = {
  icon?: string;
  symbol?: string;
};

export const SelectedToken: FC<SelectedTokenProps> = ({ icon, symbol }) => {
  return (
    <Box
      sx={{
        px: 1,
        width: 'fit-content',
        height: 48,
        flexDirection: 'row',
        ...flexHelper('flex-start'),
        background: 'transparent',
        border: BORDER_DEFAULT_GRAY,
        borderRadius: BORDER_RADIUS_DEFAULT,
      }}
    >
      <Box component="img" src={icon || TyzIcon} sx={{ mr: 1, width: 32, height: 32 }} />
      <Typography variant="body1" className="s white" fontSize={12} fontWeight={FontWeights.fontWeightExtraBold}>
        {symbol || 'TYZ'}
      </Typography>
    </Box>
  );
};
