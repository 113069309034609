import { ReferralErrorMapping } from 'types/referral/error';

export const referralErrors: ReferralErrorMapping = {
  3: {
    source: 'registrationEmail',
    type: 'alreadyRegistered',
  },
  4: {
    source: 'registrationEmailConfirmationCode',
    type: 'wrongConfirmationCode',
  },
  5: {
    source: 'requestPasswordResetEmail',
    type: 'unknownEmail',
  },
  6: {
    source: 'registrationReferralCode',
    type: 'userExistsWithDifferentReferralCode',
  },
  7: {
    source: 'registrationReferralCode',
    type: 'unknownReferralCode',
  },
  8: {
    source: 'registrationReferralCode',
    type: 'unableToInvite',
  },
  9: {
    source: 'registrationTwoFaConfirmationCode',
    type: 'wrongTwoFaConfirmationCode',
  },
  10: {
    source: 'resetPassword',
    type: 'linkExpired',
  },
  11: {
    source: 'loginForm',
    type: 'wrongUsernameOrPassword',
  },
  14: {
    source: 'loginForm',
    type: 'userBlocked',
  },
};
