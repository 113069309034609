import { handleBackendErrors } from 'modules/referral/utils';
import { confirmTwoFaActivation, error, request, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import { setError, setTwoFaAction, updateReferralState, updateTwoFa } from 'store/referral/reducer';
import { call, put, takeLatest } from 'typed-redux-saga';

import actionTypes from '../actionTypes';

export function* confirmTwoFaActivationSaga({
  type,
  payload: { code, method, goTo },
}: ReturnType<typeof confirmTwoFaActivation>) {
  yield* put(request(type));

  try {
    yield* call(baseApi.confirmTwoFaActivation, { code, method }, method);

    yield* put(
      updateReferralState({
        twoFaType: method,
      }),
    );
    yield* put(updateTwoFa());
    yield* put(setTwoFaAction('force'));
    if (goTo) {
      window.location.href = goTo;
    }
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type));
    const parsedError = handleBackendErrors(err);
    if (parsedError) {
      yield* put(setError({ [parsedError.source]: parsedError.type }));
    }
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CONFIRM_TWO_FA_ACTIVATION, confirmTwoFaActivationSaga);
}
