import i18next from 'i18next';
import { blockAdminFromList, blockUser, error, onUserStatusChanged, request, success } from 'store/dashboard/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage, getToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

export function* blockUserSaga({ type, payload: { email, lock } }: ReturnType<typeof blockUser>) {
  yield* put(request(type, { email, lock }));

  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.blockUser, { email, lock });
      yield* put(blockAdminFromList(data));
      yield* put(onUserStatusChanged({ email: data.email, status: data.isLocked }));
      getToastMessage('info', i18next.t(lock ? 'dashboard.profile.onUserBlocked' : 'dashboard.profile.onUserUnlocked'));
      yield* put(success(type));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.BLOCK_USER, blockUserSaga);
}
