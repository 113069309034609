/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setError } from 'store/referral/reducer';
import { getToastMessage } from 'utils';

import { useError } from '../useError';

export const usePopupError = () => {
  const dispatch = useDispatch();
  const popupError = useError('popupError');
  useEffect(() => {
    if (popupError !== '') {
      getToastMessage('error', popupError);
      dispatch(setError({ popupError: '' }));
    }
  }, [popupError]);
};
