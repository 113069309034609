import { FC, useState } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ActiveIcon: FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 48 48"
      sx={{
        width: '48px',
        height: '48px',
        ...sx,
      }}
    >
      <path d="m48 24a24 24 0 0 1-24 24 24 24 0 0 1-24-24 24 24 0 0 1 24-24 24 24 0 0 1 24 24z" fill="#fff" />
      <path d="m24 12c6.648 0 12 5.352 12 12s-5.352 12-12 12-12-5.352-12-12 5.352-12 12-12z" fill="#fff" />
      <path
        d="m25.362 29c-0.262 0-0.523-0.102-0.719-0.305l-3.863-4c-0.378-0.393-0.373-1.016 0.013-1.402l4-4c0.39-0.391 1.023-0.391 1.414 0 0.39 0.391 0.39 1.023 0 1.414l-3.305 3.305 3.179 3.293c0.384 0.398 0.373 1.031-0.024 1.414-0.195 0.188-0.445 0.281-0.695 0.281"
        clipRule="evenodd"
        fill="#191836"
        fillRule="evenodd"
      />
      <mask x="20" y="18" width="7" height="11" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse">
        <path
          d="m25.362 29c-0.262 0-0.523-0.102-0.719-0.305l-3.863-4c-0.378-0.393-0.373-1.016 0.013-1.402l4-4c0.39-0.391 1.023-0.391 1.414 0 0.39 0.391 0.39 1.023 0 1.414l-3.305 3.305 3.179 3.293c0.384 0.398 0.373 1.031-0.024 1.414-0.195 0.188-0.445 0.281-0.695 0.281"
          clipRule="evenodd"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
    </SvgIcon>
  );
};

const InactiveIcon: FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 48 48"
      sx={{
        width: '48px',
        height: '48px',
        ...sx,
      }}
    >
      <circle cx="24" cy="24" r="23.5" fill="#191836" fillOpacity="0.1" stroke="#575676" />
      <rect x="12" y="12" width="24" height="24" rx="12" fill="#191836" fillOpacity="0.1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3625 29C25.1005 29 24.8395 28.898 24.6435 28.695L20.7805 24.695C20.4025 24.302 20.4075 23.679 20.7935 23.293L24.7935 19.293C25.1835 18.902 25.8165 18.902 26.2075 19.293C26.5975 19.684 26.5975 20.316 26.2075 20.707L22.9025 24.012L26.0815 27.305C26.4655 27.703 26.4545 28.336 26.0575 28.719C25.8625 28.907 25.6125 29 25.3625 29"
        fill="white"
      />
      <mask
        id="mask0_4302_9334"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="20"
        y="18"
        width="7"
        height="11"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.3625 29C25.1005 29 24.8395 28.898 24.6435 28.695L20.7805 24.695C20.4025 24.302 20.4075 23.679 20.7935 23.293L24.7935 19.293C25.1835 18.902 25.8165 18.902 26.2075 19.293C26.5975 19.684 26.5975 20.316 26.2075 20.707L22.9025 24.012L26.0815 27.305C26.4655 27.703 26.4545 28.336 26.0575 28.719C25.8625 28.907 25.6125 29 25.3625 29"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4302_9334)" />
    </SvgIcon>
  );
};

type BackArrowProps = {
  onClick: () => void;
} & SvgIconProps;

export const BackArrow: FC<BackArrowProps> = ({ sx, onClick, ...props }) => {
  const [hover, setHover] = useState(false);
  const mouseProps = {
    onClick,
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
  };
  const cursor = hover ? 'pointer' : 'default';
  return hover ? (
    <ActiveIcon sx={{ ...sx, cursor }} {...props} {...mouseProps} />
  ) : (
    <InactiveIcon sx={{ ...sx, cursor }} {...props} {...mouseProps} />
  );
};
