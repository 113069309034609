import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MediumIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 14"
    sx={{
      width: '25px',
      height: '14px',
      ...sx,
    }}
  >
    <path
      d="M13.8507 7.00006C13.8507 10.7708 10.8202 13.8275 7.08207 13.8275C3.34396 13.8275 0.313232 10.7701 0.313232 7.00006C0.313232 3.23004 3.34373 0.172363 7.08207 0.172363C10.8204 0.172363 13.8507 3.22935 13.8507 7.00006Z"
      fill="#8A90AB"
    />
    <path
      d="M21.6194 7.00002C21.6194 10.5493 20.1042 13.4278 18.235 13.4278C16.3658 13.4278 14.8506 10.5493 14.8506 7.00002C14.8506 3.4507 16.3656 0.572266 18.2348 0.572266C20.1039 0.572266 21.6192 3.44978 21.6192 7.00002"
      fill="#8A90AB"
    />
    <path
      d="M25 6.99995C25 10.1793 24.4672 12.7582 23.8097 12.7582C23.1522 12.7582 22.6196 10.18 22.6196 6.99995C22.6196 3.81994 23.1525 1.2417 23.8097 1.2417C24.467 1.2417 25 3.81971 25 6.99995Z"
      fill="#8A90AB"
    />
  </SvgIcon>
);
