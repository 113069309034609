import { FC } from 'react';
import { Box } from '@mui/material';

import srcBackground from './backgroundConfirmation.png';
import srcIcon from './confirmation.png';

export const ConfirmationIcon: FC = () => (
  <Box position="relative" width="62px" height="62px">
    <img
      src={srcBackground}
      alt="DashboardIconBackground"
      style={{ position: 'absolute', top: 0, left: -4, width: 'auto', height: '62px' }}
    />
    <img
      src={srcIcon}
      alt="DashboardIcon"
      style={{
        position: 'absolute',
        top: 6,
        width: 'auto',
        height: '49px',
      }}
    />
  </Box>
);
