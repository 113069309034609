import { FC } from 'react';
import { TableInfo } from 'types/dashboard';

import { TableWrapper } from '../DashboardTable';
import { TableType } from '../DashboardTable/useTable';

export const TablePage: FC<TableInfo & { placeHolder?: string | undefined }> = ({
  items,
  columns,
  columnComparators,
  defaultFilename,
  placeHolder = undefined,
}) => {
  return (
    <TableWrapper
      dropDownColumnIndex={5}
      items={items}
      columns={columns}
      columnComparators={columnComparators}
      defaultFilename={defaultFilename}
      viewProfile={null}
      tableType={TableType.Standart}
      fixDownloadButton
      placeHolder={placeHolder}
    />
  );
};
