import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Icon } from 'components';
import { FontWeights } from 'theme';
import { flexHelper } from 'utils/flexHelper';

/**
@Description
you should put prop ether "isMax" for right position or "position" prop for changing position
*/

type ProgressLevelProps = {
  title: string;
  subtitle: string;
  type: 'top' | 'bottom';
  position?: number;
  isMax?: boolean;
};

// const VerticalLineStyled = styled(Box)({
//   position: 'absolute',
//   width: 2,
//   height: 42,
//   background: BG_WHITE,
// });

export const ProgressLevel: FC<ProgressLevelProps> = ({ title, subtitle, type, isMax = false, position = 0 }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: !isMax ? `${position}%` : null,
        right: isMax ? -40 : null,
        minWidth: 160,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {/* <VerticalLineStyled
          sx={{
            top: -42,
            left: isMax ? 78 : 6,
          }}
        /> */}

        <Box
          sx={{
            paddingRight: '17px',
            position: 'absolute',
            top: type === 'top' ? -100 : null,
            bottom: type === 'bottom' ? -55 : null,
            flexDirection: 'column',
            ...flexHelper('center', isMax ? 'flex-end' : 'flex-start'),
          }}
        >
          {type === 'bottom' && <Icon.Triangle />}
          <Typography
            variant="body2"
            className="s white"
            fontWeight={FontWeights.fontWeightBold}
            textAlign={isMax ? 'right' : 'left'}
          >
            {title}
          </Typography>

          <Stack direction="row" sx={{ width: '100%' }}>
            <Typography variant="body2" className="s white" textAlign={isMax ? 'right' : 'left'} noWrap maxWidth={100}>
              {subtitle}
            </Typography>
            <Typography variant="body2" className="s white" ml={0.5}>
              TYZ
            </Typography>
          </Stack>

          {type === 'top' && <Icon.Triangle sx={{ transform: 'rotate(180deg)' }} />}
        </Box>
      </Box>
    </Box>
  );
};
