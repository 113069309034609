import { GAS_PRICE_MULTIPLIER, isMainnet } from 'appConstants';
import i18n from 'i18next';
import { ContractsNames } from 'services/WalletService/config';
import { notifyText } from 'services/WalletService/config/constants';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { setActiveModal } from 'store/modals/reducer';
import userActionTypes from 'store/user/actionTypes';
import { approveSaga } from 'store/user/sagas/approve';
import { getAccountInfoSaga } from 'store/user/sagas/getAccountInfo';
import { getTokenBalanceSaga } from 'store/user/sagas/getTokenBalance';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, UserState } from 'types';
import { ContractErrors } from 'types/store/errors';
import { getContractDataByItsName, getToastMessage, toDecimals } from 'utils';

import { onSwap } from '../actions';
import actionTypes from '../actionTypes';

export function* swapSaga({ type, payload: { web3Provider, amount, to } }: ReturnType<typeof onSwap>) {
  yield* put(apiActions.request(type));

  const { chainType, address: userAddress }: UserState = yield select(userSelector.getUser);
  const [, swapContractAddress] = getContractDataByItsName(ContractsNames.swap, chainType);
  const [, tyzTokenAddress] = getContractDataByItsName(ContractsNames.token, chainType);
  const { t } = i18n;

  try {
    const amountWithDecimals = toDecimals(amount);

    if (to === 'TES') {
      yield* call(approveSaga, {
        type: userActionTypes.APPROVE,
        payload: {
          web3Provider,
          spenderAddress: tyzTokenAddress,
          amount: amountWithDecimals,
          tokenAddress: swapContractAddress,
          isWithDecimals: true,
        },
      });
    }

    yield* put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );
    const { data } = yield call(baseApi.swap, { to, amount });

    if (data.initial_tx) {
      try {
        const gasPrice = yield* call(web3Provider.eth.getGasPrice);
        const maxGasPrice = isMainnet ? toDecimals(4, 9) : toDecimals(300, 9);
        const { transactionHash } = yield call(web3Provider.eth.sendTransaction, {
          data: data.initial_tx.data,
          to: data.initial_tx.to,
          value: data.initial_tx.value,
          from: userAddress,
          gasPrice: +gasPrice * GAS_PRICE_MULTIPLIER,
          maxFeePerGas: maxGasPrice,
          maxPriorityFeePerGas: maxGasPrice,
        });
        yield put(
          setActiveModal({
            activeModal: Modals.SendSuccess,
            open: true,
            txHash: transactionHash,
          }),
        );
        getToastMessage('success', notifyText.swap.success(t));

        yield* call(getTokenBalanceSaga, {
          type: userActionTypes.GET_TOKEN_BALANCE,
          payload: { web3Provider },
        });
        yield* call(getAccountInfoSaga, {
          type: userActionTypes.GET_ACCOUNT_INFO,
          payload: undefined,
        });

        yield* put(apiActions.success(type));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);

        getToastMessage('error', notifyText.swap.error(t));
        yield* put(apiActions.error(type, err));

        if (err.code === 4001 && err.message !== ContractErrors.approveReject) {
          yield put(
            setActiveModal({
              activeModal: Modals.SendRejected,
              open: true,
            }),
          );
          return;
        }
        yield put(
          setActiveModal({
            activeModal: Modals.init,
            txHash: '',
            open: false,
          }),
        );
      }
    } else {
      if (data.detail) {
        yield* put(
          setActiveModal({
            activeModal: Modals.init,
            txHash: '',
            open: false,
          }),
        );
        if (data.detail === 'SwapExceeded') {
          getToastMessage('error', i18n.t('dashboard.swap.exceeded'));
        } else if (data.detail === 'SwapTooSmall') {
          getToastMessage('error', i18n.t('dashboard.swap.tooSmall'));
        } else if (data.detail === 'User account is locked from making claims') {
          getToastMessage('error', i18n.t('dashboard.swap.balanceLocked'));
        }
        yield* put(apiActions.error(type));
        return;
      }
      getToastMessage('error', notifyText.swap.txError(t));

      setActiveModal({
        activeModal: Modals.init,
        txHash: '',
        open: false,
      });

      yield* put(apiActions.error(type));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(err);

    if (err.code === 4001 && err.message !== ContractErrors.approveReject) {
      yield put(
        setActiveModal({
          activeModal: Modals.SendRejected,
          open: true,
        }),
      );
    }

    getToastMessage('error', notifyText.swap.error(t));
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SWAP, swapSaga);
}
