import { URL } from 'appConstants';
import ajax from 'store/referral/ajax';
import {
  AddAdminReq,
  AddGameReq,
  AddNoteReq,
  AddStoreReq,
  AdminPaymentsHistoryReq,
  AdminWithdrawalHistoryReq,
  BlockUserReq,
  DeleteGameReq,
  DeleteStoreReq,
  DemoteAdminReq,
  Drop2faReq,
  EditStoreReq,
  MakeReferralSwapReq,
  PromoteAdminReq,
  ResolveBlockedTransactionReq,
  SelectedUserInfoReq,
  SetAvaReq,
  TakeBalanceReq,
  UpdateMaxWithdrawalReq,
  UpdateMinWithdrawalReq,
  UpdateWithdrawalPeriodReq,
} from 'types/requests';

export const baseApi = {
  setAvatar(data: SetAvaReq) {
    return ajax({
      method: 'post',
      url: URL.setAvatar,
      data,
    });
  },
  takeBalance(data: TakeBalanceReq) {
    return ajax({
      method: 'post',
      url: URL.takeBalance,
      data,
    });
  },
  getNotes(params: { email: string }) {
    return ajax({
      method: 'get',
      url: URL.getNotes,
      params,
    });
  },
  addNote(data: AddNoteReq) {
    return ajax({
      method: 'post',
      url: URL.addNote,
      data,
    });
  },
  updateMaxWithdrawal(data: UpdateMaxWithdrawalReq) {
    return ajax({
      method: 'post',
      url: URL.updateMaxWithdrawal,
      data,
    });
  },
  updateMinWithdrawal(data: UpdateMinWithdrawalReq) {
    return ajax({
      method: 'post',
      url: URL.updateMinWithdrawal,
      data,
    });
  },
  updateWithdrawalPeriod(data: UpdateWithdrawalPeriodReq) {
    return ajax({
      method: 'post',
      url: URL.updateWithdrawalPeriod,
      data,
    });
  },
  addAdmin(data: AddAdminReq) {
    return ajax({
      method: 'post',
      url: URL.addAdmin,
      data,
    });
  },
  addStore(data: AddStoreReq) {
    return ajax({
      method: 'post',
      url: URL.addStore,
      data,
    });
  },
  addGame(data: AddGameReq) {
    return ajax({
      method: 'post',
      url: URL.addGame,
      data,
    });
  },
  getGames() {
    return ajax({
      method: 'get',
      url: URL.getGames,
    });
  },
  deleteGame(data: DeleteGameReq) {
    return ajax({
      method: 'post',
      url: URL.deleteGame,
      data,
    });
  },
  getStores() {
    return ajax({
      method: 'get',
      url: URL.getStores,
    });
  },
  getAdmins() {
    return ajax({
      method: 'get',
      url: URL.getAdmins,
    });
  },
  blockUser(data: BlockUserReq) {
    return ajax({
      method: 'post',
      url: URL.blockUser,
      data,
    });
  },
  deleteStore(data: DeleteStoreReq) {
    return ajax({
      method: 'post',
      url: URL.deleteStore,
      data,
    });
  },
  editStore(data: EditStoreReq) {
    return ajax({
      method: 'post',
      url: URL.editStore,
      data,
    });
  },
  demoteAdmin(data: DemoteAdminReq) {
    return ajax({
      method: 'post',
      url: URL.demoteAdmin,
      data,
    });
  },
  promoteAdmin(data: PromoteAdminReq) {
    return ajax({
      method: 'post',
      url: URL.promoteAdmin,
      data,
    });
  },
  drop2fa(data: Drop2faReq) {
    return ajax({
      method: 'post',
      url: URL.drop2fa,
      data,
    });
  },
  getLimits() {
    return ajax({
      method: 'get',
      url: URL.getLimits,
    });
  },
  getWithdrawals() {
    return ajax({
      method: 'get',
      url: URL.getWithdrawals,
    });
  },
  getProfileInfo() {
    return ajax({
      method: 'get',
      url: URL.getProfileInfo,
    });
  },
  getPaymentsHistory() {
    return ajax({
      method: 'get',
      url: URL.getPaymentsHistory,
    });
  },
  getAdminPaymentsHistory(params: AdminPaymentsHistoryReq) {
    return ajax({
      method: 'get',
      url: URL.getAdminPaymentsHistory,
      params,
    });
  },
  getAdminWithdrawalHistory(params: AdminWithdrawalHistoryReq) {
    return ajax({
      method: 'get',
      url: URL.getAdminWithdrawalHistory,
      params,
    });
  },
  getSelectedUserInfo(params: SelectedUserInfoReq) {
    return ajax({
      method: 'get',
      url: URL.getSelectedUserInfo,
      params,
    });
  },
  makeReferralSwap(data: MakeReferralSwapReq) {
    return ajax({
      method: 'post',
      url: URL.makeReferralSwap,
      data,
    });
  },
  getConfirmations() {
    return ajax({
      method: 'get',
      url: URL.getConfirmations,
    });
  },
  resolveBlockedTransaction(data: ResolveBlockedTransactionReq) {
    return ajax({
      method: 'post',
      url: URL.resolveBlockedTransaction,
      data,
    });
  },
  getApprovedWithdrawal(email: string) {
    return ajax({
      method: 'get',
      url: URL.getApprovedWithdrawal,
      params: {
        email,
      },
    });
  },
};
