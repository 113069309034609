import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BurgerIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 36 36"
    sx={{
      width: '36px',
      height: '36px',
      path: {
        fill: '#fff',
      },
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.92251 9H30.077C30.86 9 31.5005 9.6405 31.5005 10.4235V10.5765C31.5005 11.3595 30.86 12 30.077 12H5.92251C5.13951 12 4.50051 11.3595 4.50051 10.5765V10.4235C4.50051 9.6405 5.13951 9 5.92251 9ZM30.077 16.5002H5.92249C5.13949 16.5002 4.50049 17.1407 4.50049 17.9237V18.0767C4.50049 18.8597 5.13949 19.5002 5.92249 19.5002H30.077C30.86 19.5002 31.5005 18.8597 31.5005 18.0767V17.9237C31.5005 17.1407 30.86 16.5002 30.077 16.5002ZM30.077 23.9998H5.92249C5.13949 23.9998 4.50049 24.6403 4.50049 25.4233V25.5763C4.50049 26.3593 5.13949 26.9998 5.92249 26.9998H30.077C30.86 26.9998 31.5005 26.3593 31.5005 25.5763V25.4233C31.5005 24.6403 30.86 23.9998 30.077 23.9998Z"
      fill="currentColor"
    />
    <mask id="mask0_804_2469" maskUnits="userSpaceOnUse" x="4" y="9" width="28" height="18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.92251 9H30.077C30.86 9 31.5005 9.6405 31.5005 10.4235V10.5765C31.5005 11.3595 30.86 12 30.077 12H5.92251C5.13951 12 4.50051 11.3595 4.50051 10.5765V10.4235C4.50051 9.6405 5.13951 9 5.92251 9ZM30.077 16.5002H5.92249C5.13949 16.5002 4.50049 17.1407 4.50049 17.9237V18.0767C4.50049 18.8597 5.13949 19.5002 5.92249 19.5002H30.077C30.86 19.5002 31.5005 18.8597 31.5005 18.0767V17.9237C31.5005 17.1407 30.86 16.5002 30.077 16.5002ZM30.077 23.9998H5.92249C5.13949 23.9998 4.50049 24.6403 4.50049 25.4233V25.5763C4.50049 26.3593 5.13949 26.9998 5.92249 26.9998H30.077C30.86 26.9998 31.5005 26.3593 31.5005 25.5763V25.4233C31.5005 24.6403 30.86 23.9998 30.077 23.9998Z"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#mask0_804_2469)" />
  </SvgIcon>
);
