import { FC, PropsWithChildren } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { CloseButtonIcon } from 'modules/dashboard/components/Icon';
import { FontFamilies, FontWeights, TRANSITION_DEFAULT_TIME } from 'theme';

import { PopupProps } from '../PopupProps';

interface Props extends PopupProps {
  header: string;
  width: string;
  height: string;
  hidePopupHeader?: boolean;
  overflow?: boolean;
}

export const Popup: FC<PropsWithChildren<Props>> = ({
  isOpen,
  closePopup,
  header,
  children,
  height,
  width,
  hidePopupHeader = false,
  overflow = true,
}) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        zIndex: 100,
        transition: 'opacity 0.3s ease',
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? 'auto' : 'none',
      }}
    >
      <Box
        sx={{
          maxHeight: '100vh',
          overflow: overflow ? 'auto' : 'hidden',
          width: { width },
          height: { height },
          backgroundColor: '#353755',
          borderRadius: '20px',
          transition: 'opacity 0.3s ease, width 0.3s ease, height 0.3s ease',
          position: 'relative',
          padding: '24px',
        }}
      >
        {!hidePopupHeader && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              height: '40px',
              top: 0,
            }}
          >
            <Typography
              fontFamily={FontFamilies.primary}
              fontWeight={FontWeights.fontWeightBold}
              fontSize="24px"
              color="white"
            >
              {header}
            </Typography>
            <IconButton
              onClick={closePopup}
              sx={{
                transition: 'transform 0.15s ease-in-out',
                '&:active': {
                  transform: 'scale(0.92)',
                },
              }}
            >
              <CloseButtonIcon
                sx={{
                  width: '40px',
                  height: '40px',
                  transition: TRANSITION_DEFAULT_TIME,
                  '&:hover': { transform: 'rotate(90deg)' },
                }}
              />
            </IconButton>
          </Box>
        )}
        {children}
      </Box>
    </Box>
  );
};
