import type {} from '@mui/lab/themeAugmentation';
import { createTheme, ThemeOptions } from '@mui/material';
import { COLOR_TEXT_BLACK_100, getTypographyOptions } from 'theme';
import {
  getMuiButtonDefaultProps,
  getMuiButtonOverrides,
  getMuiIconButtonDefaultProps,
  getMuiIconButtonOverrides,
  getMuiToggleButtonDefaultProps,
  getMuiToggleButtonOverrides,
} from 'theme/Button';
import { getMuiContainerDefaultProps, getMuiContainerOverrides } from 'theme/Container';
import { breakpointOptions, getMuiGridDefaultProps, getMuiGridOverrides } from 'theme/Grid';

import { getMuiLoadingButtonDefaultProps, getMuiLoadingButtonOverrides } from './Button/LoadingButton.theme';
import { getMuiAccordion, getMuiAccordionDetails, getMuiAccordionPropsDefaultProps } from './Accordion';
import { getMuiBackdropDefaultProps, getMuiBackdropOverrides } from './Backdrop';
import { getMuiCssBaselineDefaultProps, getMuiCssBaselineOverrides } from './CssBaseLine';
import { getMuiDialogDefaultProps, getMuiDialogOverrides } from './Dialog';
import { getMuiLinkDefaultProps, getMuiLinkOverrides } from './Link';
import { getMuiListDefaultProps, getMuiListItemOverrides, getMuiListItemTextDefaultProps } from './List';
import { getMuiMenu, getMuiMenuDefaultProps, getMuiMenuItem } from './Menu';
import { getMuiPopoverDefaultProps, getMuiPopoverOverrides } from './Popover';
import { getMuiSelectDefaultProps, getMuiSelectOverrides } from './Select';
import { getMuiSkeletonDefaultProps, getMuiSkeletonOverrides } from './Skeleton';
import { getMuiTabOverrides, getMuiTabsOverrides } from './Tabs';
import { getMuiTextFieldDefaultProps, getMuiTextFieldOverrides } from './TextField';

export const getTheme = () => {
  const themeBase = createTheme({
    palette: {
      primary: {
        main: COLOR_TEXT_BLACK_100,
      },
    },
    breakpoints: breakpointOptions,
  });

  return createTheme(themeBase, {
    components: {
      MuiTypography: {
        styleOverrides: getTypographyOptions(themeBase),
      },
      MuiGrid: {
        defaultProps: getMuiGridDefaultProps(),
        styleOverrides: getMuiGridOverrides(),
      },
      MuiContainer: {
        defaultProps: getMuiContainerDefaultProps(),
        styleOverrides: getMuiContainerOverrides(),
      },
      MuiBackdrop: {
        defaultProps: getMuiBackdropDefaultProps(),
        styleOverrides: getMuiBackdropOverrides(themeBase),
      },
      MuiButton: {
        defaultProps: getMuiButtonDefaultProps(),
        styleOverrides: getMuiButtonOverrides(themeBase),
      },
      MuiIconButton: {
        defaultProps: getMuiIconButtonDefaultProps(),
        styleOverrides: getMuiIconButtonOverrides(),
      },
      MuiToggleButton: {
        defaultProps: getMuiToggleButtonDefaultProps(),
        styleOverrides: getMuiToggleButtonOverrides(themeBase),
      },
      MuiLoadingButton: {
        defaultProps: getMuiLoadingButtonDefaultProps(),
        styleOverrides: getMuiLoadingButtonOverrides(),
      },
      MuiDialog: {
        defaultProps: getMuiDialogDefaultProps(),
        styleOverrides: getMuiDialogOverrides(),
      },
      MuiCssBaseline: {
        defaultProps: getMuiCssBaselineDefaultProps(),
        styleOverrides: getMuiCssBaselineOverrides(themeBase),
      },
      MuiPopover: {
        defaultProps: getMuiPopoverDefaultProps(),
        styleOverrides: getMuiPopoverOverrides(themeBase),
      },
      MuiTextField: {
        defaultProps: getMuiTextFieldDefaultProps(),
        styleOverrides: getMuiTextFieldOverrides(themeBase),
      },
      MuiLink: {
        defaultProps: getMuiLinkDefaultProps(),
        styleOverrides: getMuiLinkOverrides(themeBase),
      },
      MuiAccordion: {
        defaultProps: getMuiAccordionPropsDefaultProps(),
        styleOverrides: getMuiAccordion(themeBase),
      },
      MuiAccordionDetails: {
        styleOverrides: getMuiAccordionDetails(themeBase),
      },
      MuiTabs: {
        styleOverrides: getMuiTabsOverrides(themeBase),
      },
      MuiTab: {
        styleOverrides: getMuiTabOverrides(themeBase),
      },
      MuiList: {
        defaultProps: getMuiListDefaultProps(),
      },
      MuiListItem: {
        defaultProps: getMuiListDefaultProps(),
        styleOverrides: getMuiListItemOverrides(themeBase),
      },
      MuiListItemText: {
        defaultProps: getMuiListItemTextDefaultProps(),
      },
      MuiSkeleton: {
        defaultProps: getMuiSkeletonDefaultProps(),
        styleOverrides: getMuiSkeletonOverrides(themeBase),
      },
      MuiSelect: {
        defaultProps: getMuiSelectDefaultProps(),
        styleOverrides: getMuiSelectOverrides(themeBase),
      },
      MuiMenu: {
        defaultProps: getMuiMenuDefaultProps(),
        styleOverrides: getMuiMenu(themeBase),
      },
      MuiMenuItem: {
        styleOverrides: getMuiMenuItem(),
      },
    },
  } as ThemeOptions);
};
