// ===== Backgrounds
export const BG_WHITE = '#ffffff';
export const BG_BLUE = '#4DA3FF';
export const BG_BLUE_ACCENT_DARK = '#191836';
export const BG_BLUE_ACCENT_DARK_200 = '#19183642';
export const BG_BLACK_MENU = '#0F0E2C';
export const BG_GRAY_MODAL = '#333654';
export const BG_GRAY_LIGHT = '#575676';
export const BG_GRAY_600 = '#FFFFFF1F';
export const BG_GRAY_700 = '#FFFFFF1A';
export const BG_GRAY_800 = '#EAECF930';
export const BG_BLACK_OPACITY = 'rgba(0, 0, 0, 0.2)';
export const BG_BLUE_LIGHT = '#C1D7F8';

export const BG_CARD_LIGHT_SM = 'linear-gradient(180deg, #F0F4FB 0%, #D6E7FC 100%)';
export const BG_CARD_LIGHT_FULL = '#F2F5FB';
export const BG_CARD_LIGHT_TRANSPARENT = '#FFFFFF08';
// export const BG_CARD_LIGHT_TRANSPARENT = '#91c3fd33';
export const BG_TABS = 'linear-gradient(86.18deg, #4DA3FF 4.27%, #0C7FF9 97.92%)';
export const BG_TABS_OUTLINED = 'rgba(234, 236, 249, 0.19)';
export const BG_SLIDER_LAYER =
  '#191836 radial-gradient(48.02% 48.02% at 97.26% 51.98%, rgba(77, 163, 255, 0.2) 0%, rgba(77, 163, 255, 0.094) 100%)';
export const BG_PARTNER_CARD = '#E9F3FF';

// ===== Colors
export const COLOR_TEXT_WHITE_100 = '#FFFFFF';
export const COLOR_TEXT_WHITE_GRADIENT = '-webkit-linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)';
export const COLOR_TEXT_BLACK_100 = '#000000';
export const COLOR_TEXT_PURPLE_100 = '#454295';
export const COLOR_TEXT_GRAY_100 = '#4F4F4F';
export const COLOR_TEXT_GRAY_200 = '#8A90AB';
export const COLOR_TEXT_GRAY_300 = '#E0E0E0';
export const COLOR_TEXT_GRAY_400 = '#9B9BA4';
export const COLOR_TEXT_GRAY_500 = '#989898';
export const COLOR_TEXT_BLUE_100 = '#191836';
export const COLOR_TEXT_BLUE_200 = '#91C3FD';
export const COLOR_TEXT_BLUE_GRADIENT = '-webkit-linear-gradient(270deg, #3C8CE7 0 100%, #00EAFF 100%)';
export const COLOR_TEXT_RED = 'rgba(226, 71, 37, 1)';
export const COLOR_TEXT_RED_ERROR = '#B60000';
export const COLOR_TEXT_GREEN = 'rgba(26, 192, 87, 1)';
