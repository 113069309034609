import { FC, ReactNode, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonProps, Typography } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { CopyIcon } from 'components/Icon/components';
import { COLOR_TEXT_WHITE_100 } from 'theme';

type Variant = 'input' | 'icon';
export interface CopyProps {
  variant?: Variant;
  copyText: string;
  children?: ReactNode;
}

const COPY_TEXT_SHOW_TIME = 2000;

export const Copy: FC<CopyProps & Pick<ButtonProps, 'sx'>> = ({ variant = 'input', copyText, children, sx }) => {
  const [helperText, setHelperText] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setHelperText('');
    }, COPY_TEXT_SHOW_TIME);
    return () => clearTimeout(timer);
  }, [helperText]);

  const handleCopyAddress = () => {
    setHelperText(t('globalHelpers.copied'));
  };
  return (
    <>
      {variant === 'input' && (
        <CopyToClipboard text={copyText}>
          <Button
            fullWidth
            variant="outlined"
            sx={(theme) => ({
              padding: theme.spacing(1.5, 2),
              justifyContent: 'space-between',
              height: 46,
              fontSize: 14,
              color: COLOR_TEXT_WHITE_100,
              '&:active': {
                transform: 'none',
              },
              ...(sx as CSSProperties),
            })}
            onClick={handleCopyAddress}
          >
            <Typography variant="body2" color={COLOR_TEXT_WHITE_100} width="90%">
              {helperText || children}
            </Typography>

            <CopyIcon />
          </Button>
        </CopyToClipboard>
      )}
      {variant === 'icon' && (
        <CopyToClipboard text={copyText}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {children}
            <Button
              variant="text"
              startIcon={<CopyIcon />}
              sx={{
                p: 0,
                height: 'fit-content',
              }}
              onClick={handleCopyAddress}
            />
          </Box>
        </CopyToClipboard>
      )}
    </>
  );
};
