import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FontFamilies } from 'theme';

interface PopupInputProps {
  label: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue: string;
  width: string;
}

export const PopupInput: FC<PopupInputProps> = ({ label, onInputChange, inputValue, width }) => {
  return (
    <Box sx={{ width: { width } }}>
      <Typography
        marginTop="22px"
        color="white"
        fontFamily={FontFamilies.primary}
        fontWeight={600}
        fontSize="20px"
        textAlign="center"
      >
        {label}
      </Typography>
      <Box sx={{ height: '52px', width: '100%', border: '1px solid #FFFFFF', borderRadius: '26px', marginTop: '6px' }}>
        <input
          onChange={onInputChange}
          value={inputValue}
          style={
            {
              color: 'white',
              width: '100%',
              height: '100%',
              border: 'none',
              padding: '10px',
              backgroundColor: 'transparent',
              fontSize: '20px',
              textAlign: 'center',
              '&:-webkit-autofill': {
                backgroundColor: 'transparent',
              },
              '&:-webkit-autofill:hover': {
                backgroundColor: 'transparent',
              },
              '&:-webkit-autofill:focus': {
                backgroundColor: 'transparent',
              },
            } as any
          }
        />
      </Box>
    </Box>
  );
};
