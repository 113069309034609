/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Popup } from '../../../../dashboard/components/Popups';

import AppStore from './appStore.png';
import ComingSoon from './comingSoon.png';
import PlayStore from './playStore.png';

interface GamePopupProps {
  isOpen: boolean;
  closePopup: () => void;
  popupImg: string;
  data: {
    title: string;
    comingSoon: boolean;
    appStore: string;
    playStore: string;
    website: string;
  };
}

export const GamePopup: FC<GamePopupProps> = ({ isOpen, closePopup, popupImg, data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const url = data.website.replaceAll('/', '').replace('https:', '').replace('http:', '').toUpperCase();

  if (!isOpen) return <div />;

  return (
    <div
      className="fixed left-0 top-0 z-10"
      style={{ width: '100vw', height: '100vh', backgroundColor: 'rgb(0,0,0, 0.4)' }}
    >
      <Popup
        isOpen
        closePopup={closePopup}
        header=""
        height={isSmallScreen ? (data.comingSoon ? '284px' : '400px') : data.comingSoon ? '384px' : '448px'}
        width={isSmallScreen ? '342px' : '585px'}
      >
        <Box display="flex" flexDirection="column" alignItems="center" padding="24px" marginTop="-72px">
          <Box display="flex" justifyContent="center" alignItems="center" marginBottom="24px">
            <img
              src={popupImg}
              alt={`${data.title} logo`}
              style={{
                width: isSmallScreen ? '86px' : '96px',
                height: isSmallScreen ? '86px' : '96px',
                marginRight: isSmallScreen ? '0' : '16px',
              }}
            />
            {!isSmallScreen && (
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'Roboto',
                  fontSize: '48px',
                  color: 'white',
                  lineHeight: '1',
                }}
              >
                {data.title}
              </Typography>
            )}
          </Box>
          {isSmallScreen && (
            <Typography
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Roboto',
                fontSize: '32px',
                color: 'white',
                lineHeight: '1',
                marginTop: '-16px',
                marginBottom: '16px',
              }}
            >
              {data.title}
            </Typography>
          )}

          {data.comingSoon ? (
            <Typography
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Roboto',
                fontSize: '32px',
                color: 'white',
                marginTop: isSmallScreen ? '32px' : '64px',
              }}
            >
              <img src={ComingSoon} alt="Coming soon" />
            </Typography>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
              <a
                href={data.appStore}
                onClick={(e) => {
                  if (data.appStore === '') e.preventDefault();
                }}
                className="-mx-10 -mb-0.5"
              >
                <img src={AppStore} alt="App Store" style={{ width: '500px' }} />
              </a>
              <a
                href={data.playStore}
                onClick={(e) => {
                  if (data.playStore === '') e.preventDefault();
                }}
                className="-mx-10 mb-1"
              >
                <img src={PlayStore} alt="Google Play" style={{ width: '500px' }} />
              </a>
              <a href={data.website} className="-mb-6 text-2xl underline" style={{ color: '#4DA3FF' }}>
                {url}
              </a>
            </Box>
          )}
        </Box>
      </Popup>
    </div>
  );
};
