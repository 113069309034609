import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useShallowSelector } from 'hooks';
import {
  ConfirmationIcon,
  DashboardIcon,
  ExitIcon,
  HistoryIcon,
  NetworkIcon,
  ProjectIcon,
  SettingsIcon,
} from 'modules/dashboard/components';
import { DashboardButton, PanelBackground } from 'modules/dashboard/components/DashboardPanel';
import { Page } from 'modules/dashboard/pages';
import selector from 'store/dashboard/selectors';

interface DashboardPanelProps {
  setActivePage: (activePage: Page) => void;
  activePage: Page;
  width: number;
  toMainPage: () => void;
  confirmationCount: number;
  margin: number;
  openSupportPopup: () => void;
}

export const DashboardPanel: FC<DashboardPanelProps> = ({
  setActivePage,
  activePage,
  width,
  toMainPage,
  confirmationCount,
  margin,
  openSupportPopup,
}) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const { profile } = useShallowSelector(selector.getDashboard);

  const panel = (
    <PanelBackground
      openSupportPopup={openSupportPopup}
      isPanelOpen={isPanelOpen}
      toMainPage={toMainPage}
      setPanelOpen={setPanelOpen}
      width={width}
      role={profile?.role ?? ''}
    >
      <Box
        sx={{
          paddingLeft: '12px',
          marginTop: '73px',
          display: 'flex',
          flexDirection: 'column',
          gap: '36px',
        }}
      >
        <DashboardButton
          text={t('dashboard.mainPage')}
          isActive={activePage === Page.client}
          onClick={() => setActivePage(Page.client)}
          buttonIcon={<DashboardIcon />}
        />
        <DashboardButton
          text={t('dashboard.networkPage')}
          isActive={activePage === Page.network}
          onClick={() => setActivePage(Page.network)}
          buttonIcon={<NetworkIcon />}
        />
        {profile && profile.role === 'admin' ? (
          <>
            <DashboardButton
              text={t('dashboard.notificationPage')}
              isActive={activePage === Page.confirmation}
              onClick={() => setActivePage(Page.confirmation)}
              counter={confirmationCount}
              buttonIcon={<ConfirmationIcon />}
            />
            <DashboardButton
              text={t('dashboard.settingsPage')}
              isActive={activePage === Page.settings}
              onClick={() => setActivePage(Page.settings)}
              buttonIcon={<SettingsIcon />}
            />
          </>
        ) : (
          <>
            <DashboardButton
              text={t('dashboard.historyPage')}
              isActive={activePage === Page.history}
              onClick={() => setActivePage(Page.history)}
              buttonIcon={<HistoryIcon />}
            />
            <DashboardButton
              text={t('dashboard.projectsPage')}
              isActive={activePage === Page.projects}
              onClick={() => setActivePage(Page.projects)}
              buttonIcon={<ProjectIcon />}
            />
          </>
        )}
        <DashboardButton
          text={t('dashboard.exit')}
          isActive={activePage === Page.root}
          onClick={() => setActivePage(Page.root)}
          buttonIcon={<ExitIcon />}
        />
      </Box>
    </PanelBackground>
  );
  return isSmallScreen ? (
    <>
      <IconButton
        onClick={() => setPanelOpen(true)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
          position: 'absolute',
          top: `${margin}px`,
          right: `${margin}px`,
          zIndex: 49,
          width: '36px',
          height: '28px',
          '& div': {
            height: '3px',
            backgroundColor: 'black',
            margin: '2px 0',
            position: 'absolute',
            right: 0,
          },
          '& div:nth-of-type(1)': {
            width: '36px',
            top: 0,
          },
          '& div:nth-of-type(2)': {
            width: '27px',
            top: '14px',
          },
          '& div:nth-of-type(3)': {
            width: '18px',
            top: '28px',
          },
        }}
      >
        <Box />
        <Box />
        <Box />
      </IconButton>
      {panel}
    </>
  ) : (
    panel
  );
};
