import { buttonClasses, ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import {
  BG_BUTTON_BLUE,
  BG_BUTTON_BLUE_LIGHT,
  BG_BUTTON_GRAY,
  BG_WHITE,
  BORDER_BUTTON_BLUE,
  BORDER_BUTTON_GRAY,
  BORDER_RADIUS_MEDIUM,
  COLOR_TEXT_BLACK_100,
  COLOR_TEXT_GRAY_200,
  COLOR_TEXT_WHITE_100,
  FontFamilies,
  FontWeights,
  TRANSITION_DEFAULT_TIME,
} from 'theme';

export const getMuiButtonOverrides = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: {
    padding: theme.spacing(1, 2),
    textAlign: 'center',
    minWidth: 130,
    lineHeight: '24px',
    transition: TRANSITION_DEFAULT_TIME,
    borderRadius: BORDER_RADIUS_MEDIUM,
    boxShadow: 'none',
    fontWeight: FontWeights.fontWeightMedium,
    fontFamily: FontFamilies.secondary,
    textTransform: 'uppercase',
    fontSize: 16,

    '&:hover': {
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
    },
  },

  startIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    marginRight: '4px',
  },

  endIcon: {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
    marginLeft: '4px',
  },

  sizeSmall: {
    minWidth: 77,
    height: 36,
  },

  sizeMedium: {
    padding: theme.spacing(0, 2),
    minWidth: 172,
    height: 44,
  },

  sizeLarge: {
    padding: theme.spacing(0, 2),
    minWidth: 103,
    height: 56,
    borderRadius: BORDER_RADIUS_MEDIUM,
  },

  outlined: {
    color: COLOR_TEXT_WHITE_100,
    backgroundColor: BG_BUTTON_GRAY,
    border: BORDER_BUTTON_GRAY,

    '&:hover': {
      backgroundColor: BG_BUTTON_GRAY,
      border: BORDER_BUTTON_BLUE,
    },

    '&:active': {
      transform: 'scale(0.9)',
      transition: 'all 0.05s ease-in-out',
    },

    [`&.${buttonClasses.disabled}`]: {
      color: COLOR_TEXT_GRAY_200,
      background: 'transparent',
      border: BORDER_BUTTON_GRAY,
    },
  },

  contained: {
    color: COLOR_TEXT_WHITE_100,
    background: BG_BUTTON_BLUE_LIGHT,
    transition: 'none',

    '&:hover': {
      background: BG_BUTTON_BLUE,
    },

    '&:active': {
      transform: 'scale(0.9)',
      transition: 'all 0.05s ease-in-out',
    },

    [`&.${buttonClasses.disabled}`]: {
      color: COLOR_TEXT_GRAY_200,
      background: 'transparent',
      border: BORDER_BUTTON_GRAY,
    },
  },

  containedSecondary: {
    color: COLOR_TEXT_BLACK_100,
    background: BG_WHITE,
    transition: 'none',

    '&:hover': {
      color: COLOR_TEXT_WHITE_100,
      background: BG_BUTTON_BLUE,
    },

    '&:active': {
      transform: 'scale(0.9)',
      transition: 'all 0.05s ease-in-out',
    },

    [`&.${buttonClasses.disabled}`]: {
      color: COLOR_TEXT_GRAY_200,
      background: 'transparent',
      border: BORDER_BUTTON_GRAY,
    },
  },

  text: {
    background: 'none',
    backgroundColor: 'transparent',
    transition: '0',
    boxSizing: 'border-box',
    borderColor: 'transparent',
    borderRadius: 0,
    padding: 0,
    margin: 0,
    minWidth: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&.secondary': {
      ...theme.typography.subtitle1,
      fontFamily: FontFamilies.primary,
      padding: 0,
      textTransform: 'none',
      height: 'unset',
    },
  },
});

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'contained',
});
