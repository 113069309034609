import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { useShallowSelector } from 'hooks';
import { InputField } from 'modules/referral/components';
import { requestPasswordReset } from 'store/referral/actions';
import { clearErrors } from 'store/referral/reducer';
import referralSelector from 'store/referral/selectors';
import { COLOR_TEXT_WHITE_100, FontFamilies } from 'theme';

export const RequestResetPasswordModal: FC<ModalProps> = ({ onClose, open }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  const handlePasswordResetRequest = () => {
    dispatch(clearErrors());
    dispatch(requestPasswordReset({ email }));
  };

  const isRequestCompleted = useShallowSelector(referralSelector.getProp('passwordResetRequestCompleted'));

  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const inputWidth = isMd ? 320 : 210;
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('referralSystem.signin.passwordRecovery')}
      sx={{
        height: {
          xs: 355,
          md: 330,
        },
        width: {
          xs: 342,
          md: 448,
        },
      }}
      titleSx={{
        lineHeight: '24px',
      }}
    >
      <Box>
        <Stack justifyContent="center" alignItems="center">
          <InputField
            data={email}
            setData={setEmail}
            name="email"
            label={t('referralSystem.signin.email')}
            type="text"
            errorLabel="requestPasswordResetEmail"
            alignLabel="center"
            width={inputWidth}
            textAlign="center"
            sx={{ mt: '24px' }}
          />

          <Button onClick={handlePasswordResetRequest} sx={{ mt: 4, height: 44, width: 153 }}>
            {t('referralSystem.signin.send')}
          </Button>

          {isRequestCompleted && (
            <Typography
              sx={{
                mt: 1,
                color: COLOR_TEXT_WHITE_100,
                font: FontFamilies.secondary,
              }}
            >
              {t('referralSystem.signin.resetPasswordRequestCompleted')}
            </Typography>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};
