import { FC, memo, useState } from 'react';
import { Box } from '@mui/material';
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { BG_BLUE_LIGHT } from 'theme';

type DisplayDayProps = {
  startDate?: Date;
  endDate?: Date;
  onYearPickerToggle?: (isOpen: boolean) => void;
} & PickersDayProps<Date>;

const DisplayDay: FC<DisplayDayProps> = memo(({ startDate, endDate, day, outsideCurrentMonth, ...other }) => {
  const isSelected = startDate && endDate && !outsideCurrentMonth && startDate <= day && day <= endDate;
  const innerDayData = <PickersDay {...other} selected={false} outsideCurrentMonth={outsideCurrentMonth} day={day} />;
  if (!isSelected) {
    return innerDayData;
  }
  return <Box sx={{ background: BG_BLUE_LIGHT }}>{innerDayData}</Box>;
});

DisplayDay.displayName = 'DisplayDay';

type DateRangePickerProps = {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
  onYearPickerToggle?: (isOpen: boolean) => void;
};

export const DateRangePicker: FC<DateRangePickerProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onYearPickerToggle,
}) => {
  const [selectedDays, setSelectedDays] = useState(0);

  const debouncedOnChange = (newDate: Date) => {
    const nextSelectedDays = selectedDays + 1;
    setSelectedDays(nextSelectedDays);
    if (nextSelectedDays % 2 === 1) {
      setStartDate(newDate);
      setEndDate(null);
    } else if (startDate && newDate < startDate) {
      setEndDate(startDate);
      setStartDate(newDate);
    } else {
      setEndDate(newDate);
    }
  };

  const disableFuture = true;

  return (
    <DateCalendar
      disableHighlightToday
      onChange={debouncedOnChange}
      onViewChange={(view) => onYearPickerToggle && onYearPickerToggle(view === 'year')}
      disableFuture={disableFuture}
      slots={{
        day: DisplayDay,
      }}
      slotProps={{
        day: {
          startDate,
          endDate,
        } as any,
      }}
    />
  );
};
