import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_TEXT_WHITE_100 } from 'theme/colors';
import {
  BORDER_COLOR_INPUT_GRAY_DEFAULT,
  BORDER_RADIUS_SMALL,
  MENU_PAPER_BG,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiMenu = (theme: Theme): ComponentsOverrides['MuiMenu'] => ({
  paper: {
    backgroundColor: `${MENU_PAPER_BG} !important`,
    borderRadius: `${BORDER_RADIUS_SMALL} !important`,
    borderColor: `${BORDER_COLOR_INPUT_GRAY_DEFAULT} !important`,
    border: '1px solid',
  },
});

export const getMuiMenuItem = (): ComponentsOverrides['MuiMenuItem'] => ({
  root: {
    transition: TRANSITION_DEFAULT_TIME,
    color: COLOR_TEXT_WHITE_100,
  },
});

export const getMuiMenuDefaultProps = (): ComponentsProps['MuiMenu'] => ({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
});
