/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { FontFamilies, FontWeights } from 'theme';

interface InputFieldProps {
  value: string;
  onChange: (newValue: string) => void;
  headerText?: string | null;
  sx?: SxProps;
  type?: 'text' | 'password' | 'email';
  isSmallScreen: boolean;
  placeholder?: string;
  height?: number;
  onSearch?: (input: string) => void;
}

export const InputField: React.FC<InputFieldProps> = ({
  value,
  onChange,
  headerText = null,
  sx,
  type,
  isSmallScreen,
  placeholder = '',
  height = 54,
  onSearch,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (onSearch) {
        onSearch(value);
      }
    }
  };

  return (
    <Box sx={sx}>
      {headerText && (
        <Typography
          fontFamily={FontFamilies.secondary}
          fontWeight={FontWeights.fontWeightMedium}
          fontSize="24px"
          marginBottom="7px"
          textAlign={isSmallScreen ? 'center' : 'left'}
        >
          {headerText}
        </Typography>
      )}
      <Box
        sx={{
          position: 'relative',
          height: `${height}px`,
          width: '100%',
          border: '2px solid #191836',
          borderRadius: `${Math.floor(height / 2)}px`,
          zIndex: 10,
        }}
      >
        <input
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={handleInputKeyDown}
          style={
            {
              color: '#191836',
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: `${Math.floor(height / 2)}px`,
              padding: `0 ${Math.floor(height / 2)}px`,
              backgroundColor: 'transparent',
              fontSize: '20px',
              fontFamily: FontFamilies.secondary,
              fontWeight: FontWeights.fontWeightMedium,
              '&:-webkit-autofill': {
                backgroundColor: 'transparent',
              },
              '&:-webkit-autofill:hover': {
                backgroundColor: 'transparent',
              },
              '&:-webkit-autofill:focus': {
                backgroundColor: 'transparent',
              },
            } as any
          }
        />
        {onSearch && (
          <IconButton
            onClick={() => onSearch(value)}
            sx={{
              position: 'absolute',
              right: `${Math.floor(height / 4) - 2}px`,
              top: `${Math.floor(height / 4) - 4}px`,
              color: '#191836',
              '&:hover': {
                color: 'gray-700',
              },
              transition: 'transform 0.15s ease-in-out',
              '&:active': {
                transform: 'scale(0.9)',
              },
            }}
          >
            <SearchRoundedIcon style={{ fontSize: '28px' }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
