const STAKE = 'STAKE' as const;
const GET_STAKING_DATA = 'GET_STAKING_DATA' as const;
const GET_USER_STAKE_DATA = 'GET_USER_STAKE_DATA' as const;
const UNSTAKE = 'UNSTAKE' as const;

export default {
  STAKE,
  GET_STAKING_DATA,
  GET_USER_STAKE_DATA,
  UNSTAKE,
};
