/* eslint-disable no-console */
import { error, getNotes, request, success } from 'store/dashboard/actions';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { updateNotesList } from '../reducer';
import { ShowUnauthorizedMessage } from '../utils';

export function* getNotesSaga({ type, payload: { email } }: ReturnType<typeof getNotes>) {
  yield* put(request(type, { email }));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.getNotes, { email });
      yield* put(success(type));
      yield* put(updateNotesList(data));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield all([takeLatest(actionTypes.GET_NOTES, getNotesSaga)]);
}
