import { FC, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, createTheme, Stack, useMediaQuery } from '@mui/material';
import { emailRegex, routes } from 'appConstants';
import { useModal, useShallowSelector } from 'hooks';
import { Project } from 'modules/dashboard/components';
import { CodeVerificationForm } from 'modules/game22/components';
import {
  EmailTwoFaModal,
  GoogleAuthModal,
  InputField,
  LockWithbackGround,
  PageName,
  TwoFaSelectionModal,
} from 'modules/referral/components';
import PasswordValidator from 'password-validator';
import { activateTwoFa, getTwoFaStatus, logout, requestRegistrationCode, sendCode } from 'store/referral/actions';
import referralSelector from 'store/referral/selectors';
import { TwoFa } from 'types';
import Cookies from 'universal-cookie';
import { getToastMessage } from 'utils';

import { Spinner } from '../../../../components';
import { setTwoFaAction, setTwoFaLoading } from '../../../../store/referral/reducer';

export type RegistrationData = {
  email: string;
  password: string;
  repeatedPassword: string;
  referralCode: string;
};

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: '5px',
        },
      },
    },
  },
});

type RegistrationFormProps = {
  handleSubmit: (data: RegistrationData) => void;
};

export const IsValidPassword = (password: string) => {
  const validator = new PasswordValidator()
    .min(8)
    .max(40)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols()
    .has()
    .not()
    .spaces();
  const validCharacters = /^[\x20-\x7E]+$/;
  if (!validCharacters.test(password)) {
    return false;
  }
  return validator.validate(password) as boolean;
};

export const RegistrationForm: FC<RegistrationFormProps> = ({ handleSubmit }) => {
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const refCode = searchParams.get('code') ?? '';
  const [registrationData, setRegistrationData] = useState<RegistrationData>({
    email: '',
    password: '',
    repeatedPassword: '',
    referralCode: refCode,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const { twoFaUpdate, email, twoFaType, twoFaToken, twoFaAction, twoFaLoading } = useShallowSelector(
    referralSelector.getReferral,
  );
  const emailConfirmed = cookies.get('logged_in') !== undefined;
  const [isTwoFaModalVisible, openTwoFaModal, closeTwoFaModal] = useModal(false);

  const requestCode = () => {
    dispatch(activateTwoFa({ method: 'email' }));
  };

  useEffect(() => {
    if (emailConfirmed) {
      if ((twoFaToken !== '' && twoFaAction === 'none') || twoFaAction === 'force') {
        dispatch(setTwoFaAction('finished'));
        navigate(routes.dashboardPage.root.path);
      } else if (twoFaAction === 'finished' || twoFaAction === 'none') {
        dispatch(setTwoFaLoading(true));
        dispatch(getTwoFaStatus());
      } else if (twoFaAction === 'updating') {
        openTwoFaModal();
        requestCode();
      }
    }
  }, [twoFaAction]);

  const [time, setTime] = useState(0);
  useEffect(() => {
    let interval: any;
    if (time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [time]);

  useEffect(() => {
    if (emailConfirmed) {
      openTwoFaModal();
      if (time <= 0) {
        requestCode();
      }
    }
  }, [emailConfirmed, twoFaUpdate]);

  const quitTwoFaSelect = () => {
    dispatch(logout(null));
    closeTwoFaModal();
  };
  const [twoFaMethod, setTwoFaMethod] = useState<TwoFa>('none');
  const [isConfirmTwoFaModalVisible, openConfirmTwoFaModal, closeConfirmTwoFaModal] = useModal(false);
  const selectTwoFaMethod = (method: TwoFa) => {
    setTwoFaMethod(method);
    closeTwoFaModal();
    openConfirmTwoFaModal();
  };

  const handleChangeInput = (value: string, type: keyof RegistrationData) => {
    setRegistrationData((prevState) => ({ ...prevState, [type]: value }));
  };

  const handleRegistration = (event: FormEvent) => {
    dispatch(logout(null));
    event.preventDefault();

    if (!emailRegex.test(registrationData.email)) {
      getToastMessage('error', t('game22.signup.badEmail'));
      return;
    }

    if (registrationData.password !== registrationData.repeatedPassword) {
      getToastMessage('error', t('game22.signup.passwordsNotMatch'));
      return;
    }

    const isValid = IsValidPassword(registrationData.password) as boolean;
    if (!isValid) {
      const message = t('game22.signup.passwordRules');
      getToastMessage('error', message);
      return;
    }

    handleSubmit(registrationData);
  };

  useEffect(() => {
    if (twoFaType !== 'none') {
      getToastMessage('success', t('game22.signup.successfullyRegistered'));
    }
  }, [twoFaType]);

  const requestEmailConfirmation = () => {
    setTime(30);
    dispatch(requestRegistrationCode({ email: registrationData.email, password: registrationData.password }));
  };
  const sendEmailConfirmation = (code: string) => {
    dispatch(
      sendCode({ email: registrationData.email, password: registrationData.password, code, project: Project.GAME22 }),
    );
  };

  const alignLabels = isMd ? 'start' : 'center';
  const inputTextAlign = isMd ? 'left' : 'center';
  const width = isMd ? 556 : 304;

  if (twoFaLoading) {
    return <Spinner size="xl" />;
  }

  if (twoFaType !== 'none') {
    return <Box />;
  }

  if (email && email !== '' && !emailConfirmed) {
    return (
      <CodeVerificationForm requestEmail={requestEmailConfirmation} sendCode={sendEmailConfirmation} time={time} />
    );
  }

  return (
    <Stack direction={{ xs: 'column', md: 'row' }}>
      <Box
        sx={{
          height: {
            xs: 639,
            md: 708,
          },
          width: {
            xs: 342,
            md: 702,
          },
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <PageName name={t('referralSystem.signup.signup')} />

          <Stack justifyContent="center" alignItems="start" sx={{ width: { xs: 342, md: 702 } }}>
            <InputField
              data={registrationData.email}
              setData={(value) => handleChangeInput(value, 'email')}
              name="email"
              label={t('game22.signup.email')}
              type="text"
              errorLabel="registrationEmail"
              alignLabel={alignLabels}
              width={width}
              textAlign={inputTextAlign}
              sx={{ ml: { xs: '19px', md: '73px' } }}
              showHelperText={false}
            />
            <InputField
              data={registrationData.password}
              setData={(value) => handleChangeInput(value, 'password')}
              name="password"
              label={t('game22.signup.password')}
              type="password"
              errorLabel="registrationPassword"
              alignLabel={alignLabels}
              width={width}
              textAlign={inputTextAlign}
              sx={{ ml: { xs: '19px', md: '73px' } }}
              showHelperText={false}
            />
            <InputField
              data={registrationData.repeatedPassword}
              setData={(value) => handleChangeInput(value, 'repeatedPassword')}
              name="repeatedPassword"
              label={t('game22.signup.password')}
              type="password"
              errorLabel="registrationRepeatedPassword"
              alignLabel={alignLabels}
              width={width}
              textAlign={inputTextAlign}
              sx={{ ml: { xs: '19px', md: '73px' } }}
              showHelperText={false}
            />
            <InputField
              data={registrationData.referralCode}
              setData={(value) => handleChangeInput(value, 'referralCode')}
              name="referralCode"
              label={t('game22.signup.referralCode')}
              type="text"
              errorLabel="registrationReferralCode"
              alignLabel="center"
              width={width}
              textAlign={inputTextAlign}
              sx={{ ml: { xs: '19px', md: '73px' } }}
              disabled={refCode !== ''}
              showHelperText={false}
            />
          </Stack>
          <Button
            onClick={handleRegistration}
            sx={{
              mt: {
                xs: '24px',
                md: '40px',
              },
              height: {
                xs: 44,
                md: 51,
              },
              width: {
                xs: 153,
                md: 200,
              },
            }}
          >
            {t('game22.signup.signup')}
          </Button>
        </Stack>
        <TwoFaSelectionModal open={isTwoFaModalVisible} onClose={quitTwoFaSelect} setMethod={selectTwoFaMethod} />
        <EmailTwoFaModal
          open={isConfirmTwoFaModalVisible && twoFaMethod === 'email'}
          onClose={() => {
            closeConfirmTwoFaModal();
            openTwoFaModal();
          }}
          requestCode={requestCode}
        />
        <GoogleAuthModal
          open={isConfirmTwoFaModalVisible && twoFaMethod === 'app'}
          onClose={() => {
            closeConfirmTwoFaModal();
            openTwoFaModal();
          }}
        />
      </Box>
      <LockWithbackGround />
    </Stack>
  );
};
