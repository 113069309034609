import { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { FontFamilies } from 'theme';

interface GameButtonParams {
  gameName: string;
  icon: string;
  onClick: () => void;
  isActive: boolean;
}

export const GameTab: FC<GameButtonParams> = ({ gameName, icon, onClick, isActive }) => {
  return (
    <IconButton
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '18px',
        background: isActive ? 'linear-gradient(to top right, #3C8CE7, #3C8CE7, #00EAFF)' : '#181836',
        transition: 'background 0s ease',
        '&:hover': {
          background: isActive ? 'linear-gradient(to top right, #3C8CE7, #3C8CE7, #00EAFF)' : 'rgba(24, 24, 54, 0.9)',
        },
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0px 12px',
        gap: 1.5,
      }}
      disableRipple
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={icon} alt={gameName} style={{ width: '56px', height: '56px' }} />
      </Box>
      <Typography
        width="136px"
        textAlign="start"
        textOverflow="ellipsis"
        overflow="hidden"
        color="white"
        fontFamily={FontFamilies.secondary}
        fontWeight={500}
        fontSize="23px"
      >
        {gameName}
      </Typography>
    </IconButton>
  );
};
