import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IConnect, IError } from '@amfi/connect-wallet/dist/interface';
import { useShallowSelector } from 'hooks';
import { Subscription } from 'rxjs';
import { connectWallet, contractsConfig, ContractsNames } from 'services/WalletService/config';
import { notifyText } from 'services/WalletService/config/constants';
import { login } from 'store/user/actions';
import { disconnectWalletState, updateUserState } from 'store/user/reducer';
import userSelector from 'store/user/selectors';
import { Chains, State, UserState, WalletProviders } from 'types';
import { getToastMessage } from 'utils';

import { WalletService } from '../WalletService';

interface IContextValue {
  connect: (provider: WalletProviders, chain: Chains) => Promise<void>;
  disconnect: () => void;
  addTokenToWallet: () => void;
  walletService: WalletService;
}

type IAccountInfo = IConnect | IError | { address: string };

const Web3Context = createContext({} as IContextValue);

const WalletConnectContext: FC<PropsWithChildren> = ({ children }) => {
  const [currentSubscriber, setCurrentSubscriber] = useState<Subscription>();
  const WalletConnect = useMemo(() => new WalletService(), []);
  const dispatch = useDispatch();
  const userState: UserState = useShallowSelector<State, UserState>(userSelector.getUser);
  const { address, provider: WalletProvider, chainType, network, key } = userState;
  const { t } = useTranslation();

  const disconnect = useCallback(() => {
    dispatch(disconnectWalletState());
    WalletConnect.resetConnect();
    if (currentSubscriber) {
      currentSubscriber.unsubscribe();
      getToastMessage('info', notifyText.disconnet.info(t));
    }
  }, [WalletConnect, currentSubscriber, dispatch]);

  const subscriberSuccess = useCallback(
    (res: { name: string }) => {
      if (document.visibilityState !== 'visible') {
        disconnect();
      }
      if (res.name === 'accountsChanged') {
        disconnect();
        getToastMessage('info', t('walletNotif.signMessage'));
      }
    },
    [disconnect],
  );

  const subscriberError = useCallback(
    (error: { code: number }) => {
      // eslint-disable-next-line no-console
      console.error(error);
      if (error.code !== 4) {
        if (error.code === 6) {
          getToastMessage('error', 'User disconnected.');
        }
        WalletConnect.resetConnect();
        dispatch(disconnectWalletState());
      }
    },
    [WalletConnect, dispatch],
  );

  const connect = useCallback(
    async (provider: WalletProviders, chain: Chains) => {
      const connected = await WalletConnect.initWalletConnect(provider, chain, chainType);

      try {
        if (connected) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const sub: any = WalletConnect.eventSubscribe().subscribe(subscriberSuccess, subscriberError);
          const accountInfo: IAccountInfo = await WalletConnect.getAccount();
          const accountAddress = (accountInfo as IConnect).address;
          if (accountAddress) {
            if (!key.length) {
              dispatch(
                login({
                  address: accountAddress,
                  web3Provider: WalletConnect.Web3(),
                  provider: (accountInfo as IError).type,
                }),
              );
            } else {
              dispatch(
                updateUserState({
                  address: accountAddress,
                  provider: (accountInfo as IError).type,
                }),
              );
            }
          }
          setCurrentSubscriber(sub);
        } else {
          throw Error();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (!window.ethereum) {
          window.open(
            `https://metamask.app.link/dapp/${window.location.hostname + window.location.pathname}/?utm_source=mm`,
          );
          return;
        }

        if (error.code === 4 && error.type === 'MetaMask') {
          const chainParams = connectWallet(Chains.MultiChain, chainType);
          window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainParams.network.chainID.toString(16)}`,
                chainName: chainParams.network.chainName,
                nativeCurrency: chainParams.network.nativeCurrency,
                rpcUrls: [chainParams.network.rpc],
                blockExplorerUrls: [chainParams.network.blockExplorerUrl],
              },
            ],
          });
        }
      }
    },
    [WalletConnect, chainType, dispatch, key.length, subscriberError, subscriberSuccess, userState],
  );

  const addTokenToWallet = useCallback(async () => {
    try {
      if (window.ethereum) {
        const tokenAddress = contractsConfig.contracts[ContractsNames.token][chainType].address[Chains.MultiChain];
        const {
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          img: tokenImage,
        } = contractsConfig.contracts[ContractsNames.token][chainType];

        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: tokenImage, // A string url of the token logo
            },
          },
        });

        if (wasAdded) {
          getToastMessage('success', `${tokenSymbol?.toUpperCase()} added to your wallet`);
        } else {
          getToastMessage('error', 'Something went wrong, check your metamask wallet');
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [chainType]);

  useEffect(() => {
    // connect user if he connected previously
    if (WalletProvider && address.length) {
      connect(WalletProvider as WalletProviders, network);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Web3Context.Provider value={{ connect, disconnect, addTokenToWallet, walletService: WalletConnect }}>
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);

export { WalletConnectContext, useWalletConnectorContext };
