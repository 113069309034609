import i18n from 'i18next';
import {
  editWithdrawalLimits,
  error,
  onUpdateWithdrawalPeriod,
  request,
  success,
  updateWithdrawalPeriod,
} from 'store/dashboard/actions';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { DashboardState } from 'types/store/dashboard';
import Cookies from 'universal-cookie';
import { errorToastMessage, getToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

export function* updateWithdrawalPeriodSaga({
  type,
  payload: { withdrawalPeriod },
}: ReturnType<typeof updateWithdrawalPeriod>) {
  const withdrawalLimits: DashboardState['withdrawalLimits'] = yield* select(
    (state) => state.dashboard.withdrawalLimits,
  );
  yield* put(onUpdateWithdrawalPeriod(withdrawalLimits.withdrawalPeriod));
  yield* put(request(type, withdrawalPeriod));

  try {
    const cookies = new Cookies();

    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.updateWithdrawalPeriod, { withdrawalPeriod });
      getToastMessage('info', i18n.t('dashboard.settings.withdrawalLimits.onWithdrawalPeriodUpdated'));

      console.log(data);
      yield* put(editWithdrawalLimits(data));
      yield* put(success(type));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    yield* put(editWithdrawalLimits(withdrawalLimits));
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UPDATE_WITHDRAWAL_PERIOD, updateWithdrawalPeriodSaga);
}
