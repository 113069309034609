import { FC } from 'react';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BackButtonSmall, BackButtonWhiteIcon } from 'modules/dashboard/components/Icon';
import { FontFamilies, FontWeights } from 'theme';

export const NoteInfoPopup: FC<{
  header: string;
  email: string;
  date: string;
  text: string;
  handleReturn: () => void;
}> = ({ header, email, date, text, handleReturn }) => {
  const textStyle = {
    color: 'white',
    position: 'absolute',
    bottom: '4px',
    fontFamily: FontFamilies.secondary,
    fontWeight: FontWeights.fontWeightMedium,
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const formatDate = (dateString: string) => {
    const fDate = new Date(dateString);
    const day = String(fDate.getDate()).padStart(2, '0');
    const month = String(fDate.getMonth() + 1).padStart(2, '0');
    const year = fDate.getFullYear();
    return `${day}.${month}.${year}`;
  };

  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? 'column' : 'row'}
      padding={isSmallScreen ? '2px 4px' : '2px 10px'}
    >
      <IconButton
        onClick={handleReturn}
        sx={{
          position: 'absolute',
          left: isSmallScreen ? '12px' : '27px',
          top: isSmallScreen ? '15px' : '23px',
          transition: 'transform 0.15s ease-in-out',
          '&:active': {
            transform: 'scale(0.92)',
          },
        }}
      >
        {isSmallScreen ? <BackButtonSmall /> : <BackButtonWhiteIcon />}
      </IconButton>

      <Box
        width={isSmallScreen ? '100%' : '80%'}
        height="43px"
        marginLeft={isSmallScreen ? '0' : '52px'}
        position="relative"
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        alignItems="center"
      >
        {isSmallScreen ? (
          <>
            <Box display="flex" position="absolute" right="-21px" top="-15px">
              <Typography
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                sx={{
                  marginRight: '2px',
                  width: '146px',
                  color: 'white',
                  fontFamily: FontFamilies.secondary,
                  fontWeight: FontWeights.fontWeightMedium,
                  fontSize: '16px',
                }}
              >
                {email}
              </Typography>
              <Typography
                sx={{
                  width: '90px',
                  color: 'white',
                  fontFamily: FontFamilies.secondary,
                  fontWeight: FontWeights.fontWeightMedium,
                  fontSize: '16px',
                }}
              >
                {formatDate(date)}
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="272px"
              position="relative"
              marginTop="28px"
            >
              <Typography
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                width="100%"
                textAlign="center"
                sx={{ ...textStyle, position: 'static' }}
                fontSize="24px"
              >
                {header}
              </Typography>
              <Box width="100%" height="2px" sx={{ background: 'white', margin: '6px 0' }} />
            </Box>
          </>
        ) : (
          <>
            <Typography
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              width="470px"
              sx={{ ...textStyle, left: '40px', fontSize: '24px' }}
            >
              {header}
            </Typography>
            <Typography sx={{ ...textStyle, right: '80px', width: '190px', fontSize: '16px', textAlign: 'end' }}>
              {email}
            </Typography>
            <Typography sx={{ ...textStyle, right: '-38px', fontSize: '16px' }}>{formatDate(date)}</Typography>
            <Box width="100%" height="2px" position="absolute" bottom={0} left="40px" sx={{ background: 'white' }} />
          </>
        )}
      </Box>

      <Typography
        sx={{
          position: 'absolute',
          color: 'white',
          fontSize: '20px',
          fontFamily: FontFamilies.secondary,
          fontWeight: FontWeights.fontWeightMedium,
          top: isSmallScreen ? '110px' : '120px',
          width: '86%',
          maxHeight: isSmallScreen ? '200px' : '446px',
          overflow: 'auto',
          paddingBottom: '14px',
          paddingRight: '34px',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
