/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Avatar } from '@mui/material';

import DefaultAvatar from '../ReferralTree/ava.svg';

export interface User {
  email: string;
  code: string;
  blocked: boolean;
  avatar: string;
}

const truncate = (data: string, maxLength: number) => {
  if (data.length > maxLength) {
    return `${data.substring(0, maxLength - 3)}...`;
  }
  return data;
};

export const UserCard = ({ user, selectUser }: { user: User; selectUser: (code: string) => void }) => (
  <div
    className="flex items-center p-4 border rounded-xl cursor-pointer bg-white w-full hover:bg-gray-300 hover:text-gray-50 text-gray-400 h-24"
    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    onClick={() => selectUser(user.code)}
  >
    <div
      className={`w-16 h-16 cursor-pointer rounded-full ${user.blocked ? 'hover:bg-gray-300' : ''}`}
      style={{ backgroundColor: user.blocked ? '' : '#00FF19', minWidth: '4rem', minHeight: '4rem' }}
    >
      <div
        className="rounded-full overflow-hidden"
        style={{
          width: user.blocked ? '4rem' : '3.625rem',
          height: user.blocked ? '4rem' : '3.625rem',
          marginLeft: user.blocked ? '' : '0.1875rem',
          marginTop: user.blocked ? '' : '0.1875rem',
        }}
      >
        <Avatar
          alt={truncate(user.email, 16)}
          src={user.avatar.trim().length === 0 ? DefaultAvatar : user.avatar}
          style={{
            filter: user.blocked ? 'grayscale(100%)' : '',
            height: '4rem',
            width: '4rem',
            marginLeft: user.blocked ? '' : '-0.1875rem',
            marginTop: user.blocked ? '' : '-0.1875rem',
          }}
          onDragStart={(e) => e.preventDefault()}
        />
      </div>
    </div>
    <div className="flex flex-col justify-between ml-4 h-full w-full min-w-0 pointer-events-none">
      <div className="flex justify-between items-center overflow-hidden">
        <div style={{ color: '#191836' }} className="w-full min-w-0">
          <div className="font-bold text-lg -mt-1" style={{ userSelect: 'none' }}>
            {truncate(user.email, 29)}
          </div>
          <div className="font-medium text-lg mt-2.5" style={{ transform: 'translateY(0.25rem)', userSelect: 'none' }}>
            {user.code}
          </div>
        </div>
        <div className="self-end" style={{ fontSize: '32px', transform: 'translateY(0.5rem)' }}>
          <KeyboardArrowRightIcon />
        </div>
      </div>
    </div>
  </div>
);
