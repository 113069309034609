type LanguageItem = {
  link1: string;
  link2?: string;
};
export type Links = {
  ru: LanguageItem;
  en: LanguageItem;
};
type FaqItem = {
  id: number;
  title: string;
  withImportBtn?: boolean;
  links: Links | null;
};
export const faq: FaqItem[] = [
  {
    id: 0,
    title: 'What are cryptocurrencies?',
    links: null,
  },
  {
    id: 1,
    title: 'How to set up a crypto wallet?',
    links: {
      ru: {
        link1:
          'https://metamask.zendesk.com/hc/ru/articles/360015489531-%D0%9D%D0%B0%D1%87%D0%B0%D0%BB%D0%BE-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D1%81-MetaMask',
        link2: 'https://academy.binance.com/ru/articles/how-to-use-walletconnect',
      },
      en: {
        link1: 'https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask',
        link2: 'https://academy.binance.com/en/articles/how-to-use-walletconnect',
      },
    },
  },
  {
    id: 2,
    title: 'How to add TYZ token to crypto wallet?',
    withImportBtn: true,
    links: {
      ru: {
        link1:
          'https://metamask.zendesk.com/hc/ru/articles/360015489031-%D0%9A%D0%B0%D0%BA-%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%B8%D1%82%D1%8C-%D0%BD%D0%B5%D0%B7%D0%B0%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5-%D1%82%D0%BE%D0%BA%D0%B5%D0%BD%D1%8B-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%B8%D0%B5-%D1%82%D0%BE%D0%BA%D0%B5%D0%BD%D1%8B-%D0%B2-MetaMask',
      },
      en: {
        link1:
          'https://metamask.zendesk.com/hc/en-us/articles/360015489031-How-to-add-unlisted-tokens-custom-tokens-in-MetaMask',
      },
    },
  },
  {
    id: 3,
    title: 'What is "22 Game"?',
    links: {
      ru: {
        link1: 'https://tyz-ecosystem.gitbook.io/tyz-eco/v/ru-tyz-ecosystem/arkhitektura-ekosistemy/22game',
      },
      en: {
        link1: 'https://tyz-ecosystem.gitbook.io/tyz-eco/ecosystem-architecture/22game',
      },
    },
  },
  {
    id: 4,
    title: 'What is crowdsale?',
    links: {
      ru: {
        link1: 'https://tyz-ecosystem.gitbook.io/tyz-eco/v/ru-tyz-ecosystem/arkhitektura-ekosistemy/crowdsale',
      },
      en: {
        link1: 'https://tyz-ecosystem.gitbook.io/tyz-eco/ecosystem-architecture/crowdsale',
      },
    },
  },
  {
    id: 5,
    title: 'What is staking?',
    links: {
      ru: {
        link1: 'https://tyz-ecosystem.gitbook.io/tyz-eco/v/ru-tyz-ecosystem/arkhitektura-ekosistemy/staking',
      },
      en: {
        link1: 'https://tyz-ecosystem.gitbook.io/tyz-eco/ecosystem-architecture/staking',
      },
    },
  },
  {
    id: 6,
    title: 'Why are TYZ tokens needed?',
    links: {
      ru: {
        link1:
          'https://tyz-ecosystem.gitbook.io/tyz-eco/v/ru-tyz-ecosystem/chto-takoe-ekosistema-tyz/gde-i-kak-ispolzovat-tokeny-tyz',
      },
      en: {
        link1: 'https://tyz-ecosystem.gitbook.io/tyz-eco/what-is-the-tyz-ecosystem/where-and-how-to-use-tyz-tokens',
      },
    },
  },
  {
    id: 7,
    title: 'What is a listing?',
    links: {
      ru: {
        link1: 'https://tyz-ecosystem.gitbook.io/tyz-eco/v/ru-tyz-ecosystem/tokenomika/listing',
      },
      en: {
        link1: 'https://tyz-ecosystem.gitbook.io/tyz-eco/tokenomics/listing',
      },
    },
  },
];
