import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Button, SxProps, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CustomBox } from 'modules/dashboard/containers';
import { FontFamilies, FontWeights } from 'theme';

import { DateRangePicker } from '../DateRangePicker';

import { DateSelectorButton, DefaultDateSelectorButton } from './DateSelectorButton';

interface DateRangePickerWrapperProps {
  startDate: Date | null;
  endDate: Date | null;
  width: string;
  height: string;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  rightShift?: string;
  DateSelectorButtonComponent?: DateSelectorButton;
  celendarSx?: SxProps;
}

export const DateRangePickerWrapper: FC<BoxProps & DateRangePickerWrapperProps> = ({
  sx,
  width,
  startDate,
  endDate,
  height,
  setStartDate,
  setEndDate,
  rightShift = '0px',
  DateSelectorButtonComponent = DefaultDateSelectorButton,
  celendarSx = {},
}) => {
  const { t } = useTranslation();

  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false);
  const [yearPickerIsOpen, setYearPickerIsOpen] = useState<boolean>(false);
  const [dateComponentKey, setDateComponentKey] = useState(0);

  const handleYearPickerToggle = (isOpen: boolean) => {
    setYearPickerIsOpen(isOpen);
  };

  const handleOpenCalendar = () => {
    setCalendarIsOpen(!calendarIsOpen);
  };

  useEffect(() => {
    if (!calendarIsOpen && endDate === null) {
      setStartDate(null);
    }
  }, [calendarIsOpen]);

  const clearDateFilter = () => {
    setEndDate(null);
    setCalendarIsOpen(false);
    setDateComponentKey((dateComponentKey + 1) % 2);
  };

  return (
    <Box width={width} height={height} sx={{ position: 'relative', ...sx }}>
      <DateSelectorButtonComponent
        calendarIsOpen={calendarIsOpen}
        handleOpenCalendar={handleOpenCalendar}
        startDate={startDate}
        endDate={endDate}
      />
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: calendarIsOpen ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0)',
          zIndex: 109,
          transition: 'background-color 0.3s ease',
          pointerEvents: calendarIsOpen ? 'auto' : 'none',
        }}
        onClick={handleOpenCalendar}
      />
      <CustomBox
        width={320}
        height={376}
        sx={{
          position: 'absolute',
          top: '100%',
          right: rightShift,
          zIndex: 110,
          marginTop: '16px',
          background: `white`,
          boxShadow: '0px 6px 36px rgba(0, 0, 0, 0.25)',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          opacity: calendarIsOpen ? 1 : 0,
          transition: 'opacity 0.3s ease',
          pointerEvents: calendarIsOpen ? 'auto' : 'none',
          ...celendarSx,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            key={dateComponentKey}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onYearPickerToggle={handleYearPickerToggle}
          />
        </LocalizationProvider>
        {startDate && endDate && !yearPickerIsOpen && (
          <Button
            onClick={clearDateFilter}
            sx={{
              minWidth: '106px',
              width: '106px',
              height: '36px',
              background: '#4BA2F2',
              color: 'black',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
              borderRadius: '18px',
              textTransform: 'none',
              marginBottom: '14px',
              '&:hover': {
                background: 'rgba(80, 162, 250, 0.8)',
              },
            }}
          >
            <Typography fontFamily={FontFamilies.secondary} fontWeight={FontWeights.fontWeightMedium} fontSize="16px">
              {t('dashboard.profile.clear')}
            </Typography>
          </Button>
        )}
      </CustomBox>
    </Box>
  );
};
