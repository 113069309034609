/* eslint-disable no-nested-ternary */
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { EditIcon, ReturnIcon } from 'modules/dashboard/components';
import { BlockUserPopup, NotesPopup, WithdrawalPopup } from 'modules/dashboard/components/Popups/Components/Profile';
import { User } from 'types/store/dashboard';

import { demoteAdmin, promoteAdmin } from '../../../../../store/dashboard/actions';
import { InfoButon } from '../../InfoButton';

import { CustomButton } from './CustomBox';
import { InfoBox } from './InfoBox';
import { MainProfileBox } from './MainProfileBox';

const CustomColumn: FC<PropsWithChildren & { marginLeft: string }> = ({ children, marginLeft }) => (
  <Box display="flex" flexDirection="column" alignItems="flex-start" marginLeft={marginLeft} height="100%">
    {children}
  </Box>
);

interface ProfileInfoProps {
  changeFrame: (frameId: number) => void;
  user: User;
  twoFaEnabled: boolean;
}

export const ProfileInfo: FC<ProfileInfoProps> = ({ changeFrame, user, twoFaEnabled }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isNotesOpen, setNotesIsOpen] = useState(false);
  const [isBlockUserOpen, setBlockUserIsOpen] = useState(false);
  const [isWithdrawalPopupOpen, setWithdrawalPopupOpen] = useState(false);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [balance, setBalance] = useState('0');
  const [blockWidth, setBlockWidth] = useState(216);
  const [footerButtonsSize, setFooterButtonsSize] = useState({ width: 216, height: 40 });

  useEffect(() => {
    setBlockWidth(isSmallScreen ? 163 : 216);
    setFooterButtonsSize(isSmallScreen ? { width: 103, height: 30 } : { width: 216, height: 40 });
  }, [isSmallScreen]);

  useEffect(() => {
    if (user && user.balance != null) {
      setBalance(String(user.balance).replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    }
  }, [user]);

  const openPopup = (setOpen: (isOpen: boolean) => void) => setOpen(true);
  const closePopup = (setOpen: (isOpen: boolean) => void) => setOpen(false);

  const handleClick = (clickedButtonIndex: number) => {
    changeFrame(clickedButtonIndex);
    setSelectedButtonIndex(clickedButtonIndex);
  };

  const levelBlock = (
    <InfoBox
      width={blockWidth}
      topText={t('dashboard.profile.level')}
      bottomText={t(`dashboard.levels.${user.level.toString().toLowerCase()}`)}
    />
  );
  const balanceBlock = (
    <InfoBox
      height={isSmallScreen ? 56 : 68}
      width={blockWidth}
      topText={t('dashboard.profile.balance')}
      bottomText={balance}
      icon={<ReturnIcon />}
      onClick={() => openPopup(setWithdrawalPopupOpen)}
      sx={{ marginTop: '12px' }}
    />
  );
  const statusBlock = (
    <InfoBox
      width={blockWidth}
      topText={t('dashboard.profile.status')}
      bottomText={
        user.isBlocked
          ? t('dashboard.profile.inactive')
          : user.role === 'admin'
          ? t('dashboard.profile.admin')
          : t('dashboard.profile.active')
      }
    />
  );
  const lockBlock = (
    <CustomButton
      width={blockWidth}
      height={isSmallScreen ? 28 : 40}
      text={user.isBlocked ? t('dashboard.profile.unlock') : t('dashboard.profile.block')}
      textAlign="center"
      onClick={() => openPopup(setBlockUserIsOpen)}
    />
  );
  const promoteBlock = (
    <CustomButton
      width={blockWidth}
      height={isSmallScreen ? 28 : 40}
      text={
        user.role === 'admin' ? t('dashboard.settings.addingAdmin.demote') : t('dashboard.settings.addingAdmin.promote')
      }
      textAlign="center"
      sx={{ marginTop: isSmallScreen ? '8px' : '14px' }}
      onClick={() => {
        if (user.role === 'admin') {
          dispatch(demoteAdmin({ email: user.email }));
        } else {
          dispatch(promoteAdmin({ email: user.email }));
        }
      }}
    />
  );
  const projectBlock = (
    <InfoBox
      width={216}
      topText={t('dashboard.profile.joinedWith')}
      bottomText={user.joinedWith === null ? '-' : user.joinedWith}
      sx={{ marginTop: isSmallScreen ? '16px' : '12px' }}
    />
  );
  const notesBlock = (
    <CustomButton
      width={blockWidth}
      height={isSmallScreen ? 28 : 40}
      text={t('dashboard.profile.notes')}
      textAlign="center"
      icon={<EditIcon sx={{ marginRight: '6px' }} />}
      onClick={() => openPopup(setNotesIsOpen)}
      sx={{ marginTop: isSmallScreen ? '8px' : '14px' }}
    />
  );
  const networkBlock = (
    <CustomButton
      width={footerButtonsSize.width}
      height={footerButtonsSize.height}
      text={t('dashboard.profile.network')}
      onClick={() => handleClick(0)}
      selected={selectedButtonIndex === 0}
      sx={{ marginTop: isSmallScreen ? '16px' : '50px' }}
    />
  );
  const withdrawalBlock = (
    <CustomButton
      width={footerButtonsSize.width}
      height={footerButtonsSize.height}
      sx={{ marginTop: isSmallScreen ? '16px' : '50px' }}
      text={t('dashboard.profile.withdrawal')}
      onClick={() => handleClick(1)}
      selected={selectedButtonIndex === 1}
    />
  );
  const paymentsBlock = (
    <CustomButton
      width={footerButtonsSize.width}
      height={footerButtonsSize.height}
      text={t('dashboard.profile.payments')}
      onClick={() => handleClick(2)}
      selected={selectedButtonIndex === 2}
      sx={{ marginTop: isSmallScreen ? '16px' : '50px' }}
    />
  );

  return (
    <Box
      width="100%"
      justifyContent="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={isSmallScreen ? '22px' : '0px'}
    >
      <BlockUserPopup
        isOpen={isBlockUserOpen}
        closePopup={() => closePopup(setBlockUserIsOpen)}
        email={user.email}
        isBlocked={user.isBlocked}
      />
      <NotesPopup isOpen={isNotesOpen} closePopup={() => closePopup(setNotesIsOpen)} user={user} />
      <WithdrawalPopup
        isOpen={isWithdrawalPopupOpen}
        closePopup={() => closePopup(setWithdrawalPopupOpen)}
        email={user.email}
      />
      <Box
        display="flex"
        alignItems="center"
        height={`${isSmallScreen ? 168 : 238}px`}
        marginTop="23px"
        position="relative"
      >
        <MainProfileBox
          height={isSmallScreen ? 168 : 238}
          isActive={!user.isBlocked}
          email={user.email}
          referralCode={user.code}
          avatar={user.avatar}
          twoFaEnabled={twoFaEnabled}
        />
        <CustomColumn marginLeft={isSmallScreen ? '16px' : '16px'}>
          {isSmallScreen ? (
            <>
              {lockBlock}
              {promoteBlock}
              {notesBlock}
              {balanceBlock}
            </>
          ) : (
            <>
              {levelBlock}
              {balanceBlock}
              {networkBlock}
            </>
          )}
        </CustomColumn>
        {!isSmallScreen && (
          <>
            <CustomColumn marginLeft="16px">
              {statusBlock}
              {projectBlock}
              {withdrawalBlock}
            </CustomColumn>
            <CustomColumn marginLeft="16px">
              {lockBlock}
              {promoteBlock}
              {notesBlock}
              {paymentsBlock}
            </CustomColumn>
          </>
        )}
        <InfoButon
          sx={{
            position: 'absolute',
            top: isSmallScreen ? -96 : -74,
            right: 0,
          }}
        />
      </Box>
      {isSmallScreen && (
        <>
          <Box display="flex" alignItems="center" height="68px" marginTop="16px">
            <CustomColumn marginLeft="0px">{levelBlock}</CustomColumn>
            <CustomColumn marginLeft="16px">{statusBlock}</CustomColumn>
          </Box>
          <Box display="flex" justifyContent="center" width="100%">
            {projectBlock}
          </Box>
          <Box display="flex" alignItems="center" height="40px" marginTop="4px">
            <CustomColumn marginLeft="0px">{networkBlock}</CustomColumn>
            <CustomColumn marginLeft="16px">{withdrawalBlock}</CustomColumn>
            <CustomColumn marginLeft="16px">{paymentsBlock}</CustomColumn>
          </Box>
        </>
      )}
    </Box>
  );
};
