import { FC } from 'react';
import { Modals } from 'types/store/modals';

export type ModalDataValues = {
  key?: string;
  title1: string;
  title2?: string;
  icon?: string | FC;
  body1?: string;
  body2?: string;
  isCopiable?: boolean;
};

export type ModalData = {
  [key in Modals]: ModalDataValues;
};

export const modalData: Partial<ModalData> = {
  [Modals.init]: {
    title1: '',
  },

  // Transaction steps modals
  [Modals.ApprovePending]: {
    key: 'modalApprovePending',
    title1: 'STEP 1/2',
    title2: 'APPROVE',
    icon: 'spinner',
    body1: 'Please press "Approve" button in metamask extension.',
    body2:
      'BEP-20 tokens are deployed with functionality that allows other smart contracts to move tokens. By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf. The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap.',
  },

  [Modals.ApproveRejected]: {
    key: 'modalApproveRejected',
    title1: 'STEP 1/2',
    title2: 'APPROVE',
    icon: 'warning',
    body1: 'You rejected Approve transaction in Metamask.',
    body2:
      'BEP-20 tokens are deployed with functionality that allows other smart contracts to move tokens. By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf. The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap.',
  },

  [Modals.SendPending]: {
    key: 'modalSendPending',
    title1: 'STEP 2/2',
    title2: 'SEND',
    icon: 'spinner',
    body1: 'Please press "Send" button in Metamask extension',
    body2: 'Your tokens will be transferred from your wallet to the contract address',
  },

  [Modals.SendSuccess]: {
    key: 'modalSendSuccess',
    title1: 'STEP 2/2',
    title2: 'SEND',
    icon: 'success',
    body1: 'Sent',
    body2: 'It takes some time for transaction to get confirmed.',
    isCopiable: true,
  },

  [Modals.SendRejected]: {
    key: 'modalSendRejected',
    title1: 'STEP 2/2',
    title2: 'SEND',
    icon: 'warning',
    body1: 'You rejected Send transaction in Metamask. Please send again to start over or close this window.',
  },
};
