import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { BackgroundWrapper } from 'components';
import { useModal, useShallowSelector } from 'hooks';
import { ConnectWalletModal, LoginModal } from 'modules/layout/containers';
import { SwapTokenForm } from 'modules/swap/containers';
import { ClaimBlock } from 'modules/swap/containers/ClaimBlock';
import { useWalletConnectorContext } from 'services';
import { getSwapData } from 'store/swap/actions';
import swapActionTypes from 'store/swap/actionTypes';
import swapSelector from 'store/swap/selectors';
import uiSelector from 'store/ui/selectors';
import userActionTypes from 'store/user/actionTypes';
import userSelector from 'store/user/selectors';
import { RequestStatus, SwapState } from 'types';
import { getRequestStatus } from 'utils';

export const SwapToken: FC = () => {
  const { t } = useTranslation();
  const { walletService, connect } = useWalletConnectorContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const uiState = useShallowSelector(uiSelector.getUI);
  const userAddress = useShallowSelector(userSelector.getProp('address'));
  const { totalCommissionAmount, totalDealerAmount, isSwapOwner, isClaimAvailable }: SwapState = useShallowSelector(
    swapSelector.getSwapData,
  );

  const isSwapping = getRequestStatus(uiState[swapActionTypes.SWAP]);
  const isClaiming = getRequestStatus(uiState[swapActionTypes.CLAIM]);
  const isGetTokenBalanceLoading = getRequestStatus(uiState[userActionTypes.GET_TOKEN_BALANCE]);
  const isSwappedSuccess = getRequestStatus(uiState[swapActionTypes.SWAP], RequestStatus.SUCCESS);

  const [isConnectModalVisible, openConnectModal, closeConnectModal] = useModal(false);
  const [isLoginModalVisible, openLoginModal, closeLoginModal] = useModal(false);

  useEffect(() => {
    if (userAddress.length) {
      dispatch(getSwapData({ web3Provider: walletService.Web3() }));
    }
  }, [dispatch, userAddress.length, walletService]);

  const handleNavigateToBuy = () => navigate(routes.buyToken.root.path);

  return (
    <BackgroundWrapper container sx={{ minHeight: '100vh' }}>
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Typography
          mt={19}
          variant="h1"
          textTransform="uppercase"
          textAlign="center"
          className="gradient"
          whiteSpace={{ xs: 'pre-wrap', sm: 'pre-wrap', md: 'nowrap' }}
          fontSize={{ xs: 56, sm: 56, md: 100, lg: 172 }}
        >
          {t('swapTokenPage.title')}
        </Typography>
        <Button variant="outlined" size="large" onClick={handleNavigateToBuy}>
          {t('swapTokenPage.buttonBuy')}
        </Button>
        <SwapTokenForm
          web3Provider={walletService.Web3()}
          onOpenLoginModal={openLoginModal}
          onOpenConnectModal={openConnectModal}
          isSwapOwner={isSwapOwner}
          isSwapping={isSwapping}
          isClaiming={isClaiming}
          isBoughtSuccess={isSwappedSuccess}
          isGetTokenBalanceLoading={isGetTokenBalanceLoading}
        />
        {isSwapOwner && (
          <ClaimBlock
            web3Provider={walletService.Web3()}
            dealerAmount={totalDealerAmount || 0}
            commissionAmount={totalCommissionAmount || 0}
            isSwapping={isSwapping}
            isClaiming={isClaiming}
            isClaimAvailable={isClaimAvailable}
          />
        )}
      </Stack>
      {isLoginModalVisible && <LoginModal open={isLoginModalVisible} onClose={closeLoginModal} />}
      {isConnectModalVisible && (
        <ConnectWalletModal open={isConnectModalVisible} onClose={closeConnectModal} onConnectWallet={connect} />
      )}
    </BackgroundWrapper>
  );
};
