/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { EmailLogo, GoogleAuthLogo } from 'modules/referral/assets';
import { BORDER_RADIUS_MEDIUM, COLOR_TEXT_WHITE_100, FontFamilies, FontWeights } from 'theme';
import { TwoFa } from 'types';

const TwoFaButton = styled(Button)(({ theme }) => ({
  height: 86,
  borderRadius: BORDER_RADIUS_MEDIUM,
  fontFamily: FontFamilies.primary,
  fontWeight: FontWeights.fontWeightMedium,
  fontSize: 24,
  textTransform: 'none',
}));

type TwoFaSelectionModalProps = {
  setMethod: (method: TwoFa) => void;
} & ModalProps;

export const TwoFaSelectionModal: FC<TwoFaSelectionModalProps> = ({ onClose, open, setMethod }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('referralSystem.signup.twoFactor')}
      sx={{
        minHeight: {
          xs: 256,
          md: 320,
        },
        width: {
          xs: 342,
          md: 448,
        },
      }}
    >
      {isMd && (
        <Typography
          variant="body2"
          color={COLOR_TEXT_WHITE_100}
          mt={3}
          sx={{ fontWeight: FontWeights.fontWeightRegular }}
        >
          {t('referralSystem.signup.selectTwoFactor')}
        </Typography>
      )}

      <Box sx={{ marginTop: isMd ? 0 : '8px' }}>
        <TwoFaButton
          variant="outlined"
          fullWidth
          startIcon={<EmailLogo />}
          onClick={() => setMethod('email')}
          sx={{ '.MuiButton-startIcon': { mr: 2 }, mt: '8px' }}
        >
          {t('referralSystem.signup.email')}
        </TwoFaButton>

        <TwoFaButton
          variant="outlined"
          fullWidth
          startIcon={<GoogleAuthLogo />}
          onClick={() => setMethod('app')}
          sx={{ '.MuiButton-startIcon': { mr: 2 }, mt: '8px' }}
        >
          {t('referralSystem.signup.googleAuthenticator')}
        </TwoFaButton>
      </Box>
    </Modal>
  );
};
