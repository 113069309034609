import type { Dispatch as DispatchReact } from 'react';
import { UsdRate } from 'types/api/UsdRate';

import { CrowdsaleState } from './crowdsale';
import { DashboardState } from './dashboard';
import { Game22State } from './game22';
import { ModalsInitialState } from './modals';
import { ReferralState } from './referral';
/* PLOP_INJECT_IMPORT_STATE */
import { StakingState } from './staking';
import { SwapState } from './swap';
import { UserState } from './user';

export * from './user';
export * from './ui';
export * from './modals';
/* PLOP_INJECT_IMPORT_TYPES */
export * from './staking';
export * from './crowdsale';
export * from './swap';
export * from './referral';
export * from './game22';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Action<T, P = any, M = void> = { type: T; payload?: P; meta?: M };
export type Dispatch = DispatchReact<{ type: string }>;

export type State = {
  user: UserState;
  modals: ModalsInitialState;
  /* PLOP_INJECT_MODIFY_STATE */
  staking: StakingState;
  crowdsale: CrowdsaleState;
  swap: SwapState;
  referral: ReferralState;
  dashboard: DashboardState;
  game22: Game22State;
};

export type UsdRateRequired = Required<UsdRate>;
