import { disableTwoFa, error, request, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import { updateReferralState } from 'store/referral/reducer';
import referralsSelector from 'store/referral/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';

export function* disableTwoFaSaga({ type }: ReturnType<typeof disableTwoFa>) {
  yield* put(request(type));

  try {
    const twoFaMethod: string = yield* select(referralsSelector.getProp('twoFaType'));
    yield* call(baseApi.disableTwoFa, twoFaMethod);
    yield* put(
      updateReferralState({
        twoFaType: 'none',
        twoFaToken: '',
      }),
    );
    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.DISABLE_TWO_FA, disableTwoFaSaga);
}
