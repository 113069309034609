import { URL } from 'appConstants';
import { TwoFa } from 'types';
import {
  ReferralRegistrationReq,
  ReferralRequestPasswordResetEmailReq,
  ReferralRequestRegistrationCodeReq,
  ReferralResetPasswordReq,
  ReferralSigninReq,
  ReferralSigninTwoFaReq,
} from 'types/requests';

import ajax from './ajax';

export const baseApi = {
  signup(data: ReferralRegistrationReq) {
    return ajax(
      {
        method: 'post',
        url: URL.referralSignup,
        data,
      },
      true,
    );
  },
  logout() {
    return ajax(
      {
        method: 'post',
        url: URL.referralLogout,
      },
      true,
    );
  },
  login(data: ReferralSigninReq) {
    return ajax(
      {
        method: 'post',
        url: URL.referralSignin,
        data,
      },
      true,
    );
  },
  loginTwoFa(data: ReferralSigninTwoFaReq) {
    return ajax({
      method: 'post',
      url: URL.referralSigninTwoFa,
      data,
    });
  },
  sendCode(data: { email: string; password: string; code: string }, project: string) {
    return ajax({
      method: 'post',
      url: URL.referralVerifyCode(project),
      data,
    });
  },
  requestRegistrationCode(data: ReferralRequestRegistrationCodeReq) {
    return ajax({
      method: 'post',
      url: URL.referralRequestRegistrationCode,
      data,
    });
  },
  requestPasswordResetEmail(data: ReferralRequestPasswordResetEmailReq) {
    return ajax({
      method: 'post',
      url: URL.referralRequestPasswordReset,
      data,
    });
  },
  resetPassword(data: ReferralResetPasswordReq) {
    return ajax({
      method: 'post',
      url: URL.referralResetPassword,
      data,
    });
  },
  getUserInfo() {
    return ajax({
      method: 'get',
      url: URL.referralGetInfo,
    });
  },
  getTwoFaMethod() {
    return ajax({
      method: 'get',
      url: URL.referralGetTwoFa,
    });
  },
  activateTwoFa(method: string) {
    return ajax({
      method: 'post',
      url: URL.referralActivateTwoFa(method),
    });
  },
  confirmTwoFaActivation(data: { code: string; method: TwoFa }, method: string) {
    return ajax({
      method: 'post',
      url: URL.referralConfirmActivateTwoFa(method),
      data,
    });
  },
  disableTwoFa(method: string) {
    return ajax({
      method: 'post',
      url: URL.referralDisableTwoFa(method),
    });
  },
  getTwoFaStatus() {
    return ajax({
      method: 'get',
      url: URL.getTwoFaStatus,
    });
  },
};
