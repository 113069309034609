/* eslint-disable react-hooks/rules-of-hooks,no-nested-ternary */
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { clearSelectedUserInfo, getSelectedUserInfo } from 'store/dashboard/actions';
import selector from 'store/dashboard/selectors';

import { setSelectedUserInfo } from '../../../../store/dashboard/reducer';
import { Network, NetworkAdmin } from '../../../referral/containers';

import { LoadingProfilePage } from './Profile';

export const NetworkPage: FC = () => {
  const dispatch = useDispatch();
  const { profile, selectedUserInfo, isFetchingUser } = useShallowSelector(selector.getDashboard);
  const [selectedProfileEmail, setProfile] = useState('');

  const profilePage = selectedUserInfo !== null || isFetchingUser;

  const handleReturn = () => {
    dispatch(clearSelectedUserInfo());
    setProfile('');
  };

  useEffect(() => {
    if (selectedProfileEmail !== '') {
      dispatch(getSelectedUserInfo({ email: selectedProfileEmail }));
    }
  }, [selectedProfileEmail]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedUserInfo(null));
    };
  }, [dispatch]);

  return (
    <Box position="relative">
      {profilePage ? (
        <LoadingProfilePage handleReturn={handleReturn} userInfo={selectedUserInfo} />
      ) : profile && profile.role === 'admin' ? (
        <NetworkAdmin openProfile={setProfile} />
      ) : (
        <Network />
      )}
    </Box>
  );
};
