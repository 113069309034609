import { FC } from 'react';
import { Card } from 'components';
import { BORDER_DEFAULT_GRAY, BORDER_RADIUS_PROGRESS_BAR } from 'theme';
import { flexHelper } from 'utils/flexHelper';

import { RequestProcessing } from './RequestProcessing';

type Props = {
  children?: React.ReactNode;
};

export const RootCard: FC<Props> = ({ children }) => {
  return (
    <div>
      <RequestProcessing />
      <Card
        type="lightTransparent"
        sx={{
          mx: 'auto',
          mt: 4.5,
          p: { xs: 2, md: 3 },
          width: 400,
          minHeight: 430,
          flexDirection: 'column',
          ...flexHelper(),
          border: BORDER_DEFAULT_GRAY,
          borderRadius: BORDER_RADIUS_PROGRESS_BAR,
        }}
      >
        {children}
      </Card>
    </div>
  );
};
