import { fork } from 'redux-saga/effects';

import approve from './approve';
import getAccountInfo from './getAccountInfo';
import getTokenBalance from './getTokenBalance';
import linkEmail from './linkEmail';
import login from './login';
import sendFeedback from './sendFeedback';
import verifyEmail from './verifyEmail';

export default function* userSagas() {
  yield fork(login);
  yield fork(approve);
  yield fork(getTokenBalance);
  yield fork(sendFeedback);
  yield fork(getAccountInfo);
  yield fork(linkEmail);
  yield fork(verifyEmail);
}
