import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowUpIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 8"
    sx={{
      width: '14px',
      height: '8px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 7L7.70711 1.31461C7.31658 0.895129 6.68342 0.895128 6.29289 1.31461L1 7"
      stroke="#646778"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export const ArrowUpIconWhite: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 8"
    sx={{
      width: '14px',
      height: '8px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 7L7.70711 1.31461C7.31658 0.895129 6.68342 0.895128 6.29289 1.31461L1 7"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);
