export const data = {
  ds: {
    title: 'Deck Stop',
    comingSoon: false,
    appStore: 'https://apps.apple.com/us/app/deck-stop/id6475201166',
    playStore: 'https://play.google.com/store/apps/details?id=com.motoplus.deck.stop',
    website: 'https://ds.cards/',
  },
  r48: {
    title: 'R48',
    comingSoon: false,
    appStore: '',
    playStore: 'https://play.google.com/store/apps/details?id=com.motoplus.r48',
    website: 'https://r48.online/',
  },
  game22: {
    title: '22 Game',
    comingSoon: false,
    appStore: 'https://apps.apple.com/tr/app/22-game-online-card-club-pro/id6444104243',
    playStore: 'https://play.google.com/store/apps/details?id=com.x22game.cardgame22',
    website: 'https://22xgame.com/ ',
  },
  fl: {
    title: 'FLASH',
    comingSoon: true,
    appStore: '',
    playStore: '',
    website: '',
  },
  bank: {
    title: 'Bank',
    comingSoon: true,
    appStore: '',
    playStore: '',
    website: '',
  },
};
