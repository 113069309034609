/* eslint-disable react/destructuring-assignment,prefer-destructuring */
import React from 'react';
import Tree from 'react-d3-tree';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import IconButton from '@mui/material/IconButton';

import { collapse, NodeInfo, TreeNode } from './TreeNode';

import './ReferralTree.css';

export interface ReferralTreeData {
  email: string;
  code: string;
  blocked: boolean;
  avatar: string;
  children: ReferralTreeData[];
}

export interface ReferralTreeProps {
  data: ReferralTreeData | null;
  openProfile: ((email: string) => void) | null;
  focus: number[];
  reload: () => void;
  close: (() => void) | null;
}

const collapseData = (root: NodeInfo, focus: number[]): string[] => {
  let node = root;
  const path = [node.attributes.code];
  collapse(node, false);
  for (let i = 0; i < focus.length; i += 1) {
    node = node.children[focus[i]];
    path.push(node.attributes.code);
    collapse(node, false);
  }
  return path;
};

const prepareData = (data: ReferralTreeData): NodeInfo => {
  return {
    name: data.email,
    attributes: {
      code: data.code,
      collapsed: true,
      online: !data.blocked,
      blocked: data.blocked,
      avatar: data.avatar.trim(),
    },
    children: data.children.map((child) => prepareData(child)),
  };
};

export class ReferralTree extends React.Component<ReferralTreeProps> {
  path: string[];

  queue: (() => void)[] = [];

  data: NodeInfo | null;

  zoom = 1.5;

  translate = { x: 450, y: 50 };

  reload: () => void;

  close: (() => void) | null;

  constructor(props: ReferralTreeProps) {
    super(props);
    this.reload = props.reload;
    this.close = props.close;
    if (props.data) {
      this.data = prepareData(props.data);
      this.path = collapseData(this.data, props.focus).reverse();
    } else {
      this.data = null;
      this.path = [];
    }
  }

  componentDidMount() {
    this.pollQueue();
  }

  componentDidUpdate(prevProps: ReferralTreeProps) {
    if (this.props.data !== prevProps.data || this.props.focus !== prevProps.focus) {
      this.reload = this.props.reload;
      if (this.props.data) {
        this.data = prepareData(this.props.data);
        this.path = collapseData(this.data, this.props.focus).reverse();
      } else {
        this.data = null;
        this.path = [];
      }
      this.forceUpdate();
    }
    this.pollQueue();
  }

  pollQueue() {
    const fn = this.queue.pop();
    if (fn) {
      fn();
      this.forceUpdate();
    }
  }

  zoomIn() {
    if (this.zoom === 5) return;
    if (this.zoom > 5) {
      this.zoom = 5;
      this.forceUpdate();
    } else {
      const newZoom = this.zoom * 1.15;
      this.zoom = newZoom > 5 ? 5 : newZoom;
      this.forceUpdate();
    }
  }

  zoomOut() {
    if (this.zoom === 0.5) return;
    if (this.zoom < 0.5) {
      this.zoom = 0.5;
      this.forceUpdate();
    } else {
      const newZoom = this.zoom / 1.15;
      this.zoom = newZoom < 0.5 ? 0.5 : newZoom;
      this.forceUpdate();
    }
  }

  render() {
    return (
      <div
        id="treeWrapper"
        className="w-full bg-white rounded-3xl mt-4 mb-4 shadow-lg -z-100 overflow-hidden relative"
        style={{ height: '36rem' }}
      >
        {this.close && (
          <div className="absolute top-0 left-0 pt-5 pl-4">
            <IconButton
              onClick={(e) => {
                if (this.close) this.close();
                e.stopPropagation();
              }}
              aria-label="zoom in"
            >
              <HighlightOffRoundedIcon htmlColor="#191836" style={{ fontSize: '40px', marginRight: '6px' }} />
            </IconButton>
          </div>
        )}
        <div className="absolute top-0 right-0 pt-5 pr-4">
          <IconButton
            onClick={(e) => {
              this.zoomIn();
              e.stopPropagation();
            }}
            aria-label="zoom in"
          >
            <AddCircleOutlineRoundedIcon htmlColor="#191836" style={{ fontSize: '40px', marginRight: '6px' }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              this.zoomOut();
              e.stopPropagation();
            }}
            aria-label="zoom out"
          >
            <RemoveCircleOutlineRoundedIcon
              fontSize="large"
              htmlColor="#191836"
              style={{ fontSize: '40px', marginRight: '6px' }}
            />
          </IconButton>
          <IconButton
            onClick={(e) => {
              this.reload();
              this.forceUpdate();
              e.stopPropagation();
            }}
            aria-label="zoom out"
          >
            <ReplayRoundedIcon
              fontSize="large"
              htmlColor="#191836"
              style={{ fontSize: '40px', transform: 'scale(-1, 1)' }}
            />
          </IconButton>
        </div>
        {this.data && (
          <Tree
            data={this.data}
            orientation="vertical"
            pathFunc="step"
            renderCustomNodeElement={(d) => {
              const info = d.nodeDatum as unknown as NodeInfo;
              if (this.path.length > 0) {
                if (info.attributes.code === this.path[this.path.length - 1]) {
                  this.queue.push(() => {
                    this.path.pop();
                    d.toggleNode();
                  });
                }
              }
              return <TreeNode info={info} toggle={d.toggleNode} openProfile={this.props.openProfile} />;
            }}
            collapsible
            zoomable
            nodeSize={{ x: 160, y: 100 }}
            translate={this.translate}
            scaleExtent={{ min: 0.5, max: 5 }}
            zoom={this.zoom}
            separation={{ siblings: 1, nonSiblings: 1.5 }}
            svgClassName="cursor-default tree-path"
            initialDepth={0}
            onUpdate={(update) => {
              if (update.zoom !== this.zoom) {
                this.zoom = update.zoom;
              }
              if (update.translate !== this.translate) {
                this.translate = update.translate;
              }
            }}
          />
        )}
      </div>
    );
  }
}
