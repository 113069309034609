import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const QuestionMark: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 26 27"
    sx={{
      width: '26px',
      height: '27px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.508 9.73008C9.79012 8.92808 10.347 8.25181 11.0799 7.82104C11.8129 7.39027 12.6747 7.2328 13.5126 7.37653C14.3505 7.52026 15.1106 7.95591 15.6581 8.60631C16.2056 9.25672 16.5053 10.0799 16.504 10.9301C16.504 13.3301 14.7657 13.0265 13.45 14.5301C13.1671 14.8534 13 15.4301 13 15.4301M13 19.3301H13.012M25 13.3301C25 19.9575 19.6274 25.3301 13 25.3301C6.37258 25.3301 1 19.9575 1 13.3301C1 6.70266 6.37258 1.33008 13 1.33008C19.6274 1.33008 25 6.70266 25 13.3301Z"
      stroke="#191836"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
