/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, SxProps, TextField, Typography } from '@mui/material';
import { useError } from 'modules/referral/hooks';
import { clearErrors } from 'store/referral/reducer';
import {
  BORDER_COLOR_RED_ERROR,
  BORDER_GRAY_200,
  COLOR_TEXT_GRAY_200,
  COLOR_TEXT_RED_ERROR,
  COLOR_TEXT_WHITE_100,
  FontFamilies,
  FontWeights,
} from 'theme';
import { ReferralState } from 'types';
import { getToastMessage } from 'utils';

type InputFieldProps = {
  data: string;
  setData: (value: string) => void;
  name: string;
  label: string;
  alignLabel: string;
  type: string;
  errorLabel: keyof ReferralState['errors'];
  width: number;
  textAlign: 'left' | 'right' | 'center';
  sx?: SxProps;
  disabled?: boolean;
  showHelperText?: boolean;
  showMessageOnError?: boolean;
};

export const InputField: FC<InputFieldProps> = ({
  data,
  setData,
  name,
  type,
  label,
  alignLabel,
  errorLabel,
  width,
  textAlign,
  sx,
  disabled = false,
  showHelperText = true,
  showMessageOnError = false,
}) => {
  const error = useError(errorLabel);
  const borderColor = error === '' ? BORDER_GRAY_200 : BORDER_COLOR_RED_ERROR;
  const inputPadding = textAlign === 'left' ? 20 : 0;

  const dispatch = useDispatch();

  useEffect(() => {
    if (showMessageOnError && error && error !== '') {
      getToastMessage('error', error);
    }
  }, [error]);

  useEffect(() => {
    if (showMessageOnError && error && error !== '') {
      dispatch(clearErrors());
    }
  }, [data]);

  return (
    <Stack
      sx={{
        mt: '36px',
        alignItems: alignLabel,
        ...sx,
      }}
    >
      <Typography
        sx={{
          color: COLOR_TEXT_WHITE_100,
          fontFamily: FontFamilies.secondary,
          fontSize: '24px',
          lineHeight: '24px',
        }}
      >
        {label}
      </Typography>
      <TextField
        required
        variant="standard"
        id={name}
        type={type}
        value={data}
        onChange={(e) => setData(e.target.value)}
        helperText={showHelperText && !showMessageOnError ? error : ''}
        InputProps={{
          inputProps: {
            style: {
              height: 51,
              width,
              paddingTop: 0,
              paddingLeft: inputPadding,
              textAlign,
              color: disabled ? COLOR_TEXT_GRAY_200 : COLOR_TEXT_WHITE_100,
              fontFamily: FontFamilies.secondary,
              fontWeight: FontWeights.fontWeightRegular,
              fontSize: 20,
            },
            disabled,
          },
          disableUnderline: true,
        }}
        FormHelperTextProps={{
          sx: {
            color: COLOR_TEXT_RED_ERROR,
            fontFamily: FontFamilies.secondary,
            fontWeight: FontWeights.fontWeightRegular,
            fontSize: 14,
            lineHeight: 1,
          },
        }}
        InputLabelProps={{
          sx: {
            marginBottom: '8px',
          },
        }}
        sx={{
          height: 51,
          width,
          mt: '8px',
          alignItems: 'start',
          padding: 0,
          border: borderColor,
          borderRadius: 25,
        }}
      />
    </Stack>
  );
};
