import { TFunction } from 'react-i18next';
import { formatISO, parse } from 'date-fns';

function comparator<T>(a: T, b: T) {
  if (a === b) {
    return 0;
  }
  return a < b ? 1 : -1;
}

export const stringComparator = comparator<string>;

export const numberComparator = (a: string, b: string) => {
  return comparator<number>(+a, +b);
};

export const dateComparator = (a: string, b: string) => {
  return comparator<Date>(parse(a, 'dd.MM.yyyy', new Date(2024, 1, 1)), parse(b, 'dd.MM.yyyy', new Date(2024, 1, 1)));
};

export const dateComparatorFormatISO = (a: string, b: string) => {
  return comparator<string>(formatISO(new Date(a)), formatISO(new Date(b)));
};

export const literalComparator = (sortedLiterals: string[]) => {
  const positionComparator = (a: string, b: string) => {
    const aIndex = sortedLiterals.findIndex((value) => value === a);
    const bIndex = sortedLiterals.findIndex((value) => value === b);
    if (aIndex === bIndex) {
      return 0;
    }
    return aIndex < bIndex ? 1 : -1;
  };
  return positionComparator;
};

export const levelComparator = (t: TFunction<'translation', undefined>) => {
  const levels = [
    t('dashboard.levels.bronze'),
    t('dashboard.levels.silver'),
    t('dashboard.levels.gold'),
    t('dashboard.levels.platinum'),
    t('dashboard.levels.brilliant'),
  ];
  return literalComparator(levels);
};

export const statusComparator = (t: TFunction<'translation', undefined>) => {
  const statuses = [
    t('dashboard.adminDashboard.failed'),
    t('dashboard.adminDashboard.pending'),
    t('dashboard.adminDashboard.committed'),
  ];
  return literalComparator(statuses);
};
