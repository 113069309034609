import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps, Theme } from '@mui/material';
import {
  BG_BLUE,
  BG_CARD_LIGHT_FULL,
  BG_CARD_LIGHT_SM,
  BG_CARD_LIGHT_TRANSPARENT,
  BG_WHITE,
  BORDER_RADIUS_CARD_FULL,
  BORDER_RADIUS_CARD_SM,
  BORDER_RADIUS_MEDIUM,
} from 'theme';

import { CardTypes } from './Card.types';

export type CardProps = {
  type: keyof typeof CardTypes;
  sx?: BoxProps['sx'];
} & PropsWithChildren &
  BoxProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setStyles = (type: keyof typeof CardTypes, theme?: Theme) => {
  const styles = {
    lightSmall: {
      borderRadius: BORDER_RADIUS_CARD_SM,
      border: `1px solid ${BG_WHITE}`,
      p: 3,
      background: BG_CARD_LIGHT_SM,
    },
    lightFull: {
      borderRadius: BORDER_RADIUS_CARD_FULL,
      background: BG_CARD_LIGHT_FULL,
      position: 'relative',
      overflow: 'hidden',
      '&::before, &::after': {
        position: 'absolute',
        content: '""',
        width: 218,
        height: 184,
        bottom: 0,
        filter: 'blur(119px)',
        opacity: '0.3',
        background: BG_BLUE,
      },
      '&::before': {
        left: 0,
      },
      '&::after': {
        right: 0,
      },
    },
    lightTransparent: {
      background: BG_CARD_LIGHT_TRANSPARENT,
      borderRadius: BORDER_RADIUS_MEDIUM,
      p: 3,
    },
  };
  return styles[type];
};

export const Card: FC<CardProps> = ({ type, children, sx, ...boxProps }) => {
  return (
    <Box
      {...boxProps}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sx={(theme): any => {
        return {
          ...setStyles(type, theme),
          ...sx,
        };
      }}
    >
      {children}
    </Box>
  );
};
