import React, { FC } from 'react';
import { BoxProps, TableBody } from '@mui/material';

interface PageProps {
  items: { id: number; component: React.ReactNode }[];
  itemsPerPage?: number;
  currentPage: number;
}

export const Page: FC<PageProps & BoxProps> = ({ items, itemsPerPage = 6, currentPage, sx }) => {
  const getPaginatedItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex);
  };

  return <TableBody sx={{ width: '100%', ...sx }}>{getPaginatedItems().map((item) => item.component)}</TableBody>;
};
