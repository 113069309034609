import { confirmTwoFaActivation, error, logout, request, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import { setTwoFaAction, setTwoFaLoading } from 'store/referral/reducer';
import { call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';

import actionTypes from '../actionTypes';

export function* getTwoFaStatusSaga({ type }: ReturnType<typeof confirmTwoFaActivation>) {
  yield* put(request(type));
  try {
    const { data } = yield* call(baseApi.getTwoFaStatus);

    if (data.active) {
      yield* put(setTwoFaAction('force'));
    } else {
      yield* put(logout(null));
    }
    yield* put(setTwoFaLoading(false));
    yield* put(success(type));
  } catch (err: any) {
    if (err.response.data.detail === '2FA should be confirmed.') {
      yield* put(logout(null));
    }
    yield* put(setTwoFaLoading(false));
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_TWO_FA_STATUS, getTwoFaStatusSaga);
}
