import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface PlusIconProps extends SvgIconProps {
  disabled?: boolean;
  width: string;
  height: string;
}

export const PlusIcon: FC<PlusIconProps> = ({ disabled = false, width, height }) => (
  <SvgIcon
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: { width },
      height: { height },
      fill: 'none',
      ...(disabled && {
        position: 'relative',
      }),
    }}
  >
    <path
      d="M18 11.6V18M18 18V24.4M18 18L24.4 18M18 18L11.6 18M34 18C34 26.8366 26.8366 34 18 34C9.16344 34 2 26.8366 2 18C2 9.16344 9.16344 2 18 2C26.8366 2 34 9.16344 34 18Z"
      stroke={disabled ? '#404040' : 'black'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {disabled && <rect x="0" y="0" width="100%" height="100%" fill="#ffffff" opacity="0.5" />}
  </SvgIcon>
);
