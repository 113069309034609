export * from './connect';
export * from './store';
export * from './contracts';
export * from './routes';

export type TNullable<T> = T | null;
export type TOptionable<T> = T | undefined;

export enum WalletProviders {
  metamask = 'MetaMask',
  walletConnect = 'WalletConnect',
}

export interface INotifyModalProps {
  currData?: {
    [k: string]: string;
  };
  closeModal: () => void;
}

export enum Modules {
  layout = 'layout',
  landing = 'landing',
  crowdsale = 'crowdsale',
  staking = 'staking',
  swap = 'swap',
  referral = 'referral',
  dashboard = 'dashboard',
  game22 = 'game22',
}

export type Nullable<T> = null | T;
export type Optional<T> = undefined | T;
