import { useShallowSelector } from 'hooks';
import crowdsaleSelector from 'store/crowdsale/selectors';
import { CrowdsaleState } from 'types';

import { useFormatTokensListReturnType } from './useFormatTokensList.types';

export const useFormatTokensList = (): useFormatTokensListReturnType => {
  const tokensListArray: CrowdsaleState['tokensList'] = useShallowSelector(crowdsaleSelector.getProp('tokensList'));
  return tokensListArray.map(({ address, decimals, fullName, image, lastUpdateAt, showSymbol, value }) => ({
    address: address || '0',
    decimals: decimals || 0,
    name: fullName || 'name',
    image: image || 'image',
    lastUpdateAt: lastUpdateAt || '0',
    symbol: showSymbol || 'symbol',
    value: value || 0,
  }));
};
