import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ExclamationMarkIcon } from 'modules/dashboard/components/Icon';
import { FontFamilies, FontWeights } from 'theme';

import { Popup } from '../Popup';
import { PopupProps } from '../PopupProps';

export const WalletInUsePopup: FC<PopupProps> = ({ isOpen, closePopup }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  return (
    <Popup
      isOpen={isOpen}
      closePopup={closePopup}
      header=""
      height={isSmallScreen ? '518px' : '430px'}
      width={isSmallScreen ? '342px' : '585px'}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box width="96px" height="92px" position="relative" marginTop={1}>
          <ExclamationMarkIcon sx={{ position: 'absolute', top: 0, left: 0, zIndex: 2 }} />
        </Box>
        <Typography
          textAlign="center"
          fontWeight={FontWeights.fontWeightBold}
          fontSize="22px"
          fontFamily={FontFamilies.secondary}
          color="white"
          mt={5}
        >
          {t('dashboard.info.walletInUse')}
        </Typography>
        <Typography
          textAlign="center"
          fontWeight={FontWeights.fontWeightMedium}
          fontSize="16px"
          fontFamily={FontFamilies.secondary}
          color="white"
          mt={1}
        >
          {t('dashboard.info.walletInUseBody')}
        </Typography>
      </Box>
    </Popup>
  );
};
