/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */
import { useState } from 'react';

import { GamePopup } from './Popup/GamePopup';
import Highlight from './highlight.png';

export const GameButton = ({
  img,
  popupImg,
  data,
  x,
  y,
}: {
  img: string;
  popupImg: string;
  data: { title: string; comingSoon: boolean; appStore: string; playStore: string; website: string };
  x: string;
  y: string;
}) => {
  const [popup, setPopup] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <>
      <GamePopup popupImg={popupImg} data={data} isOpen={popup} closePopup={() => setPopup(false)} />
      <img
        src={Highlight}
        alt=""
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          top: y,
          left: x,
          width: 'auto',
          height: '35%',
          transition: 'opacity 0.2s',
          opacity: hover ? '1' : '0',
          pointerEvents: 'none',
        }}
      />

      <img
        src={img}
        alt={data.title}
        style={{
          position: 'absolute',
          top: y,
          left: x,
          transform: 'translate(-50%, -50%)',
          width: 'auto',
          height: '17.5%',
          cursor: 'pointer',
        }}
        onClick={() => setPopup(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
    </>
  );
};
