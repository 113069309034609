import { ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userActionTypes from 'store/user/actionTypes';
import { getTokenBalanceSaga } from 'store/user/sagas/getTokenBalance';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { GnosisAbi, SwapAbi, UserState } from 'types';
import { createContract, getContractDataByItsName } from 'utils';

import { getSwapData } from '../actions';
import actionTypes from '../actionTypes';
import { updateSwapState } from '../reducer';

export function* getSwapDataSaga({ type, payload: { web3Provider } }: ReturnType<typeof getSwapData>) {
  yield* put(apiActions.request(type));
  const { chainType, address }: UserState = yield select(userSelector.getUser);

  const [swapAbi, swapContractAddress] = getContractDataByItsName(ContractsNames.swap, chainType);
  const [gnosisAbi, gnosisContractAddress] = getContractDataByItsName(ContractsNames.gnosis, chainType);
  try {
    const swapContract: SwapAbi = yield createContract(swapAbi, swapContractAddress, chainType);
    // TODO: uncomment next lines before release.
    // const gnosisContract: GnosisAbi = yield createContract(gnosisAbi, gnosisContractAddress, chainType);
    // const isSwapOwner: boolean = yield call(gnosisContract.methods.isOwner(address).call);

    // TODO: next line was previously commented. Make sure you understand what it does and remove or uncomment it.
    // const isSwapOwner: boolean = yield call(swapContract.methods.isOwner(address).call);

    const isSwapOwner = false;
    const { available: isClaimAvailable } = yield call(swapContract.methods.isAvailable().call);

    if (isSwapOwner) {
      const {
        data: { available_amount: commissionAmount, amount: totalCommissionAmount },
      } = yield call(baseApi.getSwapCommission);
      const {
        data: { available_amount: dealerAmount, amount: totalDealerAmount },
      } = yield call(baseApi.getSwapDealer);

      yield* put(
        updateSwapState({
          isSwapOwner,
          isClaimAvailable,
          commissionAmount,
          dealerAmount,
          totalCommissionAmount,
          totalDealerAmount,
        }),
      );
    } else {
      yield* put(
        updateSwapState({
          isSwapOwner,
          isClaimAvailable,
        }),
      );
    }

    yield* call(getTokenBalanceSaga, {
      type: userActionTypes.GET_TOKEN_BALANCE,
      payload: { web3Provider },
    });

    yield* put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_SWAP_DATA, getSwapDataSaga);
}
