/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferralState, TwoFa, TwoFaAction } from 'types/store/referral';
import Cookies from 'universal-cookie';

const initialState: ReferralState = {
  email: '',
  twoFaToken: '',
  twoFaType: 'none',
  twoFaDetails: '',
  twoFaAlternativeDetails: '',
  twoFaUpdate: false,
  twoFaAction: 'none',
  twoFaLoading: false,
  isEmailConfirmed: false,
  passwordResetState: false,
  passwordResetRequestCompleted: false,
  activeRequests: 0,
  errors: {
    registrationEmail: '',
    registrationPassword: '',
    registrationRepeatedPassword: '',
    registrationReferralCode: '',
    registrationEmailConfirmationCode: '',
    registrationTwoFaConfirmationCode: '',
    requestPasswordResetEmail: '',
    loginForm: '',
    resetPassword: '',
    resetRepeatedPassword: '',
    popupError: '',
  },
};

export const referralReducer = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    updateReferralState: (state, action: PayloadAction<Partial<ReferralState>>) => ({
      ...state,
      ...action.payload,
    }),
    startRequest: (state) => {
      state.activeRequests += 1;
    },
    finishRequest: (state) => {
      state.activeRequests -= 1;
    },
    onLogout: (state) => {
      const cookies = new Cookies();
      cookies.set('logged_in', '', { maxAge: 0, path: '/' });
      state.twoFaToken = '';
      state.twoFaAction = 'none';
      state.email = '';
    },
    setError: (state, action: PayloadAction<Partial<ReferralState['errors']>>) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    clearErrors: (state) => {
      state.errors = initialState.errors;
    },
    setTwoFaToken: (state, action: PayloadAction<string>) => {
      state.twoFaToken = action.payload;
    },
    setTwoFaAction: (state, action: PayloadAction<TwoFaAction>) => {
      state.twoFaAction = action.payload;
    },
    setTwoFaType: (state, action: PayloadAction<TwoFa>) => {
      state.twoFaType = action.payload;
    },
    setTwoFaLoading: (state, action: PayloadAction<boolean>) => {
      state.twoFaLoading = action.payload;
    },
    updateTwoFa: (state) => {
      state.twoFaUpdate = !state.twoFaUpdate;
    },
    setLoggedIn: (state) => {
      const cookies = new Cookies();
      cookies.set('logged_in', 'true', { maxAge: 60 * 60 * 24 * 365, sameSite: 'strict', path: '/' });
    },
    setPasswordReset: (state, action: PayloadAction<boolean>) => {
      state.passwordResetState = action.payload;
    },
    completeResetPasswordRequest: (state) => {
      state.passwordResetRequestCompleted = true;
    },
  },
});

export const {
  updateReferralState,
  startRequest,
  finishRequest,
  onLogout,
  setError,
  clearErrors,
  setTwoFaToken,
  setTwoFaAction,
  setTwoFaLoading,
  setTwoFaType,
  setLoggedIn,
  updateTwoFa,
  setPasswordReset,
  completeResetPasswordRequest,
} = referralReducer.actions;

export default referralReducer.reducer;
