/* eslint-disable no-new */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { addStore, editStore } from 'store/dashboard/actions';
import selector from 'store/dashboard/selectors';
import { getToastMessage } from 'utils';

import { Popup } from '../../Popup';
import { PopupProps } from '../../PopupProps';
import { PopupInput } from '../PopupInput';

interface StoreAddPopup {
  gameId: string;
  isEditing: boolean;
  storeId: string | null;
}

export const StoreAddPopup: FC<PopupProps & StoreAddPopup> = ({ isOpen, closePopup, gameId, isEditing, storeId }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const [storeName, setStoreValue] = useState('');
  const [link, setLinkValue] = useState('');

  const { stores } = useShallowSelector(selector.getDashboard);

  useEffect(() => {
    if (isOpen && stores) {
      if (isEditing && storeId !== null) {
        const storeById = stores.find((store) => store.id === storeId);
        if (storeById !== undefined) {
          setStoreValue(storeById.name);
          setLinkValue(storeById.link);
        }
      } else {
        setStoreValue('');
        setLinkValue('');
      }
    }
  }, [isOpen, isEditing, storeId, stores]);

  const handleStoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStoreValue(event.target.value);
  };

  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLinkValue(event.target.value);
  };

  const onClose = () => {
    setStoreValue('');
    setLinkValue('');
    closePopup();
  };

  const dispatch = useDispatch();

  const handleAddOrEditStoreSubmit = () => {
    const isStoreNameEntered = storeName !== '';
    const isTooLongName = storeName.length > 25;
    const isValidLink = isValidUrl(link);

    if (isStoreNameEntered && isValidLink && !isTooLongName) {
      if (isEditing) {
        if (storeId !== null) {
          dispatch(editStore({ storeId, name: storeName, link }));
        } else {
          getToastMessage('error', t('dashboard.settings.addingStore.storeIdMissing'));
        }
      } else {
        dispatch(addStore({ name: storeName, link, gameId }));
      }
      onClose();
    } else if (!isStoreNameEntered) {
      getToastMessage('error', t('dashboard.settings.addingStore.noStoreName'));
    } else if (!isValidLink) {
      getToastMessage('error', t('dashboard.settings.addingStore.invalidRef'));
    } else {
      getToastMessage('error', t('dashboard.settings.addingStore.tooLongName'));
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      closePopup={onClose}
      header={isEditing ? t('dashboard.settings.addingStore.editStore') : t('dashboard.settings.addingStore.addStore')}
      height="390px"
      width={isSmallScreen ? '342' : '448px'}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <PopupInput
          width={isSmallScreen ? '294px' : '400px'}
          label={t('dashboard.settings.addingStore.name')}
          onInputChange={handleStoreChange}
          inputValue={storeName}
        />
        <PopupInput
          width={isSmallScreen ? '294px' : '400px'}
          label={t('dashboard.settings.addingStore.link')}
          onInputChange={handleLinkChange}
          inputValue={link}
        />
        <Button sx={{ borderRadius: '10px', marginTop: '24px' }} onClick={handleAddOrEditStoreSubmit}>
          OK
        </Button>
      </Box>
    </Popup>
  );
};
