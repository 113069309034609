import { ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { StakingAbi, UserState } from 'types';
import { createContract, getContractDataByItsName } from 'utils';

import { getStakingData } from '../actions';
import actionTypes from '../actionTypes';
import { updateStakingState } from '../reducer';

export function* getStakingDataSaga({ type }: ReturnType<typeof getStakingData>) {
  yield* put(apiActions.request(type));
  const { chainType }: UserState = yield select(userSelector.getUser);

  const [stakingAbi, stakingContractAddress] = getContractDataByItsName(ContractsNames.staking, chainType);

  try {
    const stakingContract: StakingAbi = yield createContract(stakingAbi, stakingContractAddress, chainType);

    const stakingParamsArr = yield* all([
      call(stakingContract.methods.minStake().call), // 0
      call(stakingContract.methods.maxStake().call), // 1
      call(stakingContract.methods.rewAmount().call), // 2
      call(stakingContract.methods.rewPerDay().call), // 3
      call(stakingContract.methods.totalStaked().call), // 4
      call(stakingContract.methods.start().call), // 5
      call(stakingContract.methods.end().call), // 6
    ]);

    const { withdraw, early, staking } = yield* call(stakingContract.methods.isAvailable().call);

    yield* put(
      updateStakingState({
        minStake: stakingParamsArr[0],
        maxStake: stakingParamsArr[1],
        rewardAmount: stakingParamsArr[2],
        rewardPerDay: stakingParamsArr[3],
        totalStaked: stakingParamsArr[4],
        stakingStartTime: stakingParamsArr[5],
        stakingEndTime: stakingParamsArr[6],

        isStakeAvailable: staking,
        isUnstakeAvailable: withdraw,
        isEarlyUnstake: early,
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_STAKING_DATA, getStakingDataSaga);
}
