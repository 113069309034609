import { useTranslation } from 'react-i18next';
import { useShallowSelector } from 'hooks';
import referralSelector from 'store/referral/selectors';
import { ReferralState } from 'types';

export const useError = (key: keyof ReferralState['errors']) => {
  const { t } = useTranslation();
  const errorName = useShallowSelector(referralSelector.getError(key));
  if (errorName === '') {
    return '';
  }
  const path = `referralSystem.errors.${errorName}`;
  return t(path);
};
