import { baseReferralURL } from 'appConstants';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { call, CallEffect, put, PutEffect, SelectEffect } from 'redux-saga/effects';

import { finishRequest, startRequest } from './reducer';

const client: AxiosInstance = axios.create({
  baseURL: baseReferralURL,
  withCredentials: true,
});

export default function* ajax(
  config: AxiosRequestConfig,
  changeRequestCounter = false,
): Generator<SelectEffect | CallEffect | PutEffect> {
  try {
    if (changeRequestCounter) {
      yield put(startRequest());
    }
    return yield call<(configVar: AxiosRequestConfig) => Promise<AxiosResponse>>(client, config);
  } finally {
    if (changeRequestCounter) {
      yield put(finishRequest());
    }
  }
}
