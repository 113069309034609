import { FC, useEffect, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, BoxProps, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { PlusIcon } from 'modules/dashboard/components';
import { GameAddingPopup } from 'modules/dashboard/components/Popups';
import selector from 'store/dashboard/selectors';
import { Game } from 'types/store/dashboard';

import { GamesList } from './GamesList';

type AddingGamesProps = {
  onSelectGame: (gameId: string, gameName: string) => void;
  isAdmin: boolean;
} & BoxProps;

export const AddingGames: FC<AddingGamesProps> = ({ onSelectGame, isAdmin, sx }) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [games, setGames] = useState<Game[]>([]);

  const closePopup = () => {
    setIsOpen(false);
  };

  const openPopup = () => {
    setIsOpen(true);
  };

  const [value, setValue] = useState(0);
  const [activeButton, setActiveButton] = useState('');

  const state = useShallowSelector(selector.getDashboard);

  useEffect(() => {
    if (state.games) {
      setGames(state.games);
    }
  }, [state]);

  useEffect(() => {
    if (activeButton !== '') {
      const game = games.find((g) => g.id === activeButton);
      if (!game) {
        setActiveButton('');
        onSelectGame('', '');
        setValue(0);
      }
    }
  }, [games]);

  const handleSelectGame = (gameId: string) => {
    const index = games.findIndex((game) => game.id === gameId);
    onSelectGame(gameId, games[index].name);
    setActiveButton(gameId);
    setValue(index);
  };

  const handleScrollLeft = () => {
    const index = games.findIndex((game) => game.id === activeButton);
    handleSelectGame(games[index - 1].id);
  };

  const handleScrollRight = () => {
    const index = games.findIndex((game) => game.id === activeButton);
    handleSelectGame(games[index + 1].id);
  };

  const plusSize = isSmallScreen ? '28px' : '36px';

  return (
    <Box sx={{ position: 'relative', marginTop: isSmallScreen ? '13px' : '22px', ...sx }}>
      {isAdmin && <GameAddingPopup isOpen={isOpen} closePopup={closePopup} />}
      <Box
        width="100%"
        height="34px"
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          marginBottom: isSmallScreen ? '13px' : '9px',
        }}
      >
        {isAdmin && (
          <IconButton
            onClick={openPopup}
            sx={{
              position: 'absolute',
              left: 0,
              transition: 'transform 0.15s ease-in-out',
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
              '&:active': {
                transform: 'scale(0.92)',
              },
            }}
          >
            <PlusIcon width={plusSize} height={plusSize} />
          </IconButton>
        )}
        <Box sx={{ position: 'absolute', right: 0 }}>
          <IconButton onClick={handleScrollLeft} disabled={value === 0 || games.length === 0}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton onClick={handleScrollRight} disabled={value === games.length - 1 || games.length === 0}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
      <GamesList
        onSelectGame={onSelectGame}
        handleSelectGame={handleSelectGame}
        games={games}
        activeButton={activeButton}
        value={value}
      />
    </Box>
  );
};
