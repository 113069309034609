import { editStore, editStoreFromList, error, request, success } from 'store/dashboard/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

export function* editStoreSaga({ type, payload: { storeId, link, name } }: ReturnType<typeof editStore>) {
  yield* put(request(type, { storeId, name, link }));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.editStore, { storeId, name, link });
      yield* put(editStoreFromList(data));
      yield* put(success(type));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.EDIT_STORE, editStoreSaga);
}
