export interface Game {
  id: string;
  name: string;
  img: string;
}

export interface Store {
  id: string;
  name: string;
  link: string;
  gameId: string;
}

export interface Admin {
  id: string;
  email: string;
  isLocked: boolean;
  password: string;
}

export interface RawWithdrawal {
  amount: number;
  date: Date;
  status: string;
}

export interface WithdrawalLimits {
  minWithdrawal: number;
  maxWithdrawal: number;
  withdrawalPeriod: number;
}

export type Role = 'admin' | 'user' | null;

export interface Note {
  id: number;
  noteOwner: string;
  noteText: string;
  header: string;
  date: Date;
}

export enum Level {
  Brilliant = 1,
  Platinum = 2,
  Gold = 3,
  Silver = 4,
  Bronze = 5,
}

export interface User {
  id: string;
  avatar: string;
  balance: string;
  code: string;
  email: string;
  isBlocked: boolean;
  joinedWith: string;
  level: Level;
  role: 'admin' | 'user';
}

export interface PaymentInfo {
  email: string;
  level: Level;
  date: Date;
  amount: number;
  isBlocked: boolean | null;
  project: string;
}

export interface ConfirmationItem {
  id: number;
  email: string;
  referralCode: string;
  level: 'brilliant' | 'platinum' | 'gold' | 'silver' | 'bronze';
  amount: string;
  date: number;
  status: boolean;
}

export interface AdminPaymentInfo {
  email: string;
  amount: number;
  date: Date;
  fromWhom: string;
  project: string;
  isBlocked: boolean | null;
}

export interface AdminWithdrawalInfo {
  email: string;
  amount: number;
  date: Date;
  referralCode: string | null;
  level: Level | null;
  status: string;
}

export interface AdminPaymentHistory {
  history: AdminPaymentInfo[];
  pageCount: number;
  startPage: number;
  endPage: number;
}

export interface AdminWithdrawalHistory {
  history: AdminWithdrawalInfo[];
  pageCount: number;
  startPage: number;
  endPage: number;
}

export interface SelectedUserInfo {
  user: User;
  paymentsHistory: {
    id: number;
    amount: number;
    date: Date;
    fromWhom: string;
    project: string;
    isBlocked: boolean | null;
    referralCode?: string;
  }[];
  withdrawalHistory: {
    id: number;
    amount: number;
    date: Date;
    status: string;
  }[];
  twoFaEnabled: boolean;
}

export type DashboardState = {
  authToken: string;
  games: Game[] | null;
  stores: Store[] | null;
  admins: Admin[] | null;
  withdrawals: RawWithdrawal[] | null;
  admin: Admin | null;
  withdrawalLimits: WithdrawalLimits;
  selectedUserInfo: SelectedUserInfo | null;
  isFetchingUser: boolean;
  cachedUsers: SelectedUserInfo[];
  profileInfo: {
    notes: Note[];
  };
  profile: User | null;
  paymentsHistory: PaymentInfo[] | null;
  confirmations: ConfirmationItem[];
  adminPaymentHistory: AdminPaymentHistory | null;
  adminWithdrawalHistory: AdminWithdrawalHistory | null;
  swapExceeded: boolean;
  walletInUse: boolean;
};
