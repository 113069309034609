import i18n from 'i18next';
import {
  editWithdrawalLimits,
  error,
  onUpdateMinWithdrawal,
  request,
  success,
  updateMinWithdrawal,
} from 'store/dashboard/actions';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { DashboardState } from 'types/store/dashboard';
import Cookies from 'universal-cookie';
import { errorToastMessage, getToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

export function* updateMinWithdrawalSaga({ type, payload: { minWithdrawal } }: ReturnType<typeof updateMinWithdrawal>) {
  const withdrawalLimits: DashboardState['withdrawalLimits'] = yield* select(
    (state) => state.dashboard.withdrawalLimits,
  );
  yield* put(onUpdateMinWithdrawal(minWithdrawal));
  yield* put(request(type, minWithdrawal));

  try {
    const cookies = new Cookies();

    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.updateMinWithdrawal, { minWithdrawal });
      getToastMessage('info', i18n.t('dashboard.settings.withdrawalLimits.onMinWithdrawalUpdated'));

      yield* put(editWithdrawalLimits(data));
      yield* put(success(type));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    yield* put(editWithdrawalLimits(withdrawalLimits));
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UPDATE_MIN_WITHDRAWAL, updateMinWithdrawalSaga);
}
