import type { ReferralState, State } from 'types';

export default {
  getReferral: (state: State): ReferralState => state.referral,
  getProp:
    <T extends keyof ReferralState>(propKey: T) =>
    (state: State): ReferralState[typeof propKey] =>
      state.referral[propKey],
  getError:
    (errorKey: keyof ReferralState['errors']) =>
    (state: State): string =>
      state.referral.errors[errorKey],
};
