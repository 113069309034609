import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BG_BLUE_ACCENT_DARK } from 'theme/colors';

export const getMuiCssBaselineOverrides = (theme: Theme): ComponentsOverrides['MuiCssBaseline'] => ({
  html: {
    WebkitFontSmoothing: 'antialiased', // Antialiasing.
    MozOsxFontSmoothing: 'grayscale', // Antialiasing.
    boxSizing: 'border-box',
  },
  body: {
    position: 'relative',
    overflowX: 'hidden',
    margin: theme.spacing(0),
    background: BG_BLUE_ACCENT_DARK,
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
    lineHeight: '24px',
    width: '100vw',
    paddingRight: '0 !important',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },

    'span, div': {
      fontFeatureSettings: "'liga' off",
    },
    '.grecaptcha-badge': {
      display: 'none !important',
      [theme.breakpoints.up('md')]: {
        display: 'block !important',
      },
    },
  },
});

export const getMuiCssBaselineDefaultProps = (): ComponentsProps['MuiCssBaseline'] => ({});
