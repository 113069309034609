/* eslint-disable react-hooks/exhaustive-deps,@typescript-eslint/no-unused-vars,@typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Grid, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { routes } from 'appConstants';
import { useModal, useShallowSelector } from 'hooks';
import {
  ConfirmLoginModal,
  EmailTwoFaModal,
  GoogleAuthModal,
  InputField,
  LoginWithbackGround,
  PageName,
  RequestResetPasswordModal,
  ResetPasswordModal,
  TwoFaSelectionModal,
} from 'modules/referral/components';
import { activateTwoFa, getTwoFaStatus, logout, signin } from 'store/referral/actions';
import { clearErrors, setPasswordReset, setTwoFaAction, setTwoFaLoading, setTwoFaToken } from 'store/referral/reducer';
import referralSelector from 'store/referral/selectors';
import { COLOR_TEXT_GRAY_200, COLOR_TEXT_WHITE_100, FontFamilies } from 'theme';
import Cookies from 'universal-cookie';
import { flexHelper } from 'utils';

import { Spinner } from '../../../../components';
import { TwoFa } from '../../../../types';

const GrayTypography = styled(Typography)(({ theme }) => ({
  color: COLOR_TEXT_GRAY_200,
  '&:hover': {
    color: COLOR_TEXT_WHITE_100,
    cursor: 'pointer',
  },
  fontSize: '14px',
  fontFamily: FontFamilies.secondary,
  textAlign: 'center',
}));

export type LoginData = {
  email: string;
  password: string;
};

export const Login: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const { twoFaToken, twoFaAction, twoFaLoading } = useShallowSelector(referralSelector.getReferral);

  const [loginData, setLoginData] = useState<LoginData>({
    email: '',
    password: '',
  });

  const [twoFaPopup, setTwoFaPopup] = useState(false);
  const [twoFaMethod, setTwoFaMethod] = useState<TwoFa>('none');
  const [isConfirmTwoFaModalVisible, openConfirmTwoFaModal, closeConfirmTwoFaModal] = useModal(false);
  const quitTwoFaSelect = () => {
    dispatch(logout(null));
    setTwoFaPopup(false);
  };
  const quitTwoFaConfirm = () => {
    dispatch(logout(null));
    closeConfirmTwoFaModal();
  };
  const selectTwoFaMethod = (method: TwoFa) => {
    setTwoFaMethod(method);
    setTwoFaPopup(false);
    openConfirmTwoFaModal();
  };

  const handleChangeInput = (value: string, type: keyof LoginData) => {
    setLoginData((prevState) => ({ ...prevState, [type]: value }));
  };

  const handleLogin = () => {
    const { email, password } = loginData;
    dispatch(clearErrors());
    dispatch(signin({ email, password }));
  };

  const [isTwoFaModalVisible, openTwoFaModal, closeTwoFaModal] = useModal(false);
  useEffect(() => {
    if (twoFaToken && twoFaToken !== '' && twoFaAction !== 'finished') {
      openTwoFaModal();
    }
  }, [twoFaToken, twoFaAction]);

  const requestCode = () => {
    dispatch(activateTwoFa({ method: 'email' }));
  };

  const secondStepCompleted = useShallowSelector(referralSelector.getProp('twoFaUpdate'));
  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      if ((twoFaToken !== '' && twoFaAction === 'none') || twoFaAction === 'force') {
        dispatch(setTwoFaAction('finished'));
        navigate(routes.dashboardPage.root.path);
      } else if (twoFaAction === 'finished' || twoFaAction === 'none') {
        dispatch(setTwoFaLoading(true));
        dispatch(getTwoFaStatus());
      } else if (twoFaAction === 'updating') {
        setTwoFaPopup(true);
      }
    }
  }, [secondStepCompleted, twoFaToken, twoFaAction]);

  useEffect(() => {
    if (isConfirmTwoFaModalVisible && twoFaMethod === 'email') {
      requestCode();
    }
  }, [isConfirmTwoFaModalVisible, twoFaMethod]);

  const onSignupRedirect = () => {
    dispatch(logout('/referrals/signup'));
  };

  const [isRequestPasswordResetModalVisible, openRequestResetPasswordModal, closeRequestResetPasswordModal] =
    useModal(false);

  const onForgotPasswordClick = () => {
    dispatch(clearErrors());
    openRequestResetPasswordModal();
  };

  const [searchParams, _] = useSearchParams();
  const resetEmailToken = searchParams.get('token')!;
  const resetEmail = searchParams.get('email')!;
  const [isResetPasswordModalVisible, openResetPasswordModal, closeResetPasswordModal] = useModal(resetEmail !== null);
  const onResetPasswordModalClose = () => {
    searchParams.set('token', '');
    searchParams.set('email', '');
    closeResetPasswordModal();
  };
  const resetPasswordSuccess = useShallowSelector(referralSelector.getProp('passwordResetState'));
  useEffect(() => {
    if (resetPasswordSuccess) {
      searchParams.set('token', '');
      searchParams.set('email', '');
      dispatch(setPasswordReset(false));
      closeResetPasswordModal();
    }
  }, [resetPasswordSuccess]);

  const alignLabels = isMd ? 'start' : 'center';
  const width = isMd ? 556 : 304;

  const loginButton = (
    <Button
      onClick={handleLogin}
      sx={{
        mt: {
          xs: 2,
          md: 5,
        },
        height: {
          xs: 44,
          md: 44,
        },
        width: {
          xs: 153,
          md: 132,
        },
      }}
    >
      {t('referralSystem.signin.loginButton')}
    </Button>
  );

  const forgotPassword = (
    <GrayTypography
      onClick={onForgotPasswordClick}
      sx={{
        mt: {
          xs: 1,
          md: 3,
        },
        width: '100%',
      }}
    >
      {t('referralSystem.signin.forgotPassword')}
    </GrayTypography>
  );

  const signUpLink = (
    <GrayTypography
      onClick={onSignupRedirect}
      sx={{
        height: 44,
        width: 152,
        mt: {
          xs: 3,
          md: 4,
        },
      }}
    >
      {t('referralSystem.signin.noAccount')}
    </GrayTypography>
  );

  const mediumSubmit = (
    <Box sx={{ width: '100%' }}>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={6} alignItems="right" sx={{ ...flexHelper('end', 'end') }}>
          {loginButton}
        </Grid>
        <Grid item xs={6}>
          {signUpLink}
        </Grid>
      </Grid>
      {forgotPassword}
    </Box>
  );
  const smallSubmit = (
    <Box sx={{ width: '100%' }}>
      <Stack alignItems="center">
        {forgotPassword}
        {loginButton}
        {signUpLink}
      </Stack>
    </Box>
  );

  if (twoFaLoading) {
    return <Spinner size="xl" />;
  }

  return (
    <Stack direction={{ xs: 'column', md: 'row' }}>
      <Box
        sx={{
          height: {
            xs: 489,
            md: 515,
          },
          width: {
            xs: 342,
            md: 702,
          },
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <PageName name={t('referralSystem.signin.login')} />
          <Stack justifyContent="center" alignItems="start" sx={{ width: { xs: 342, md: 702 } }}>
            <InputField
              data={loginData.email}
              setData={(value) => handleChangeInput(value, 'email')}
              name="email"
              label={t('referralSystem.signin.email')}
              type="text"
              errorLabel="loginForm"
              alignLabel={alignLabels}
              width={width}
              textAlign="left"
              sx={{ ml: { xs: '19px', md: '73px' } }}
            />
            <InputField
              data={loginData.password}
              setData={(value) => handleChangeInput(value, 'password')}
              name="password"
              label={t('referralSystem.signin.password')}
              type="password"
              errorLabel="loginForm"
              alignLabel={alignLabels}
              width={width}
              textAlign="left"
              sx={{ ml: { xs: '19px', md: '73px' } }}
            />
            {isMd && mediumSubmit}
            {!isMd && smallSubmit}
          </Stack>
        </Stack>
      </Box>
      <LoginWithbackGround />
      <RequestResetPasswordModal open={isRequestPasswordResetModalVisible} onClose={closeRequestResetPasswordModal} />
      <ConfirmLoginModal open={isTwoFaModalVisible} onClose={closeTwoFaModal} />
      <ResetPasswordModal
        open={isResetPasswordModalVisible}
        email={resetEmail}
        token={resetEmailToken}
        onClose={onResetPasswordModalClose}
      />
      <TwoFaSelectionModal open={twoFaPopup} onClose={quitTwoFaSelect} setMethod={selectTwoFaMethod} />
      <EmailTwoFaModal
        open={isConfirmTwoFaModalVisible && twoFaMethod === 'email'}
        onClose={() => {
          quitTwoFaConfirm();
          setTwoFaPopup(true);
        }}
        goTo="/referrals/dashboard"
        requestCode={requestCode}
      />
      <GoogleAuthModal
        open={isConfirmTwoFaModalVisible && twoFaMethod === 'app'}
        onClose={() => {
          quitTwoFaConfirm();
          setTwoFaPopup(true);
        }}
        goTo="/referrals/dashboard"
      />
    </Stack>
  );
};
