import { FC } from 'react';
import { Box, Grid, SxProps, Typography } from '@mui/material';
import { BackArrow } from 'modules/referral/assets';
import { BG_TABS, COLOR_TEXT_WHITE_100, FontFamilies, FontWeights } from 'theme';
import { flexHelper } from 'utils';

type PageNameProps = {
  name: string;
  onArrowClick?: () => void;
  sx?: SxProps;
};

export const PageName: FC<PageNameProps> = ({ name, onArrowClick, sx }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container>
        <Grid item xs={3}>
          {onArrowClick && (
            <BackArrow
              onClick={onArrowClick}
              sx={{
                mt: {
                  xs: '20px',
                  md: '34px',
                },
                ml: {
                  xs: '20px',
                  md: '73px',
                },
                width: {
                  xs: '32px',
                },
                height: {
                  xs: '32px',
                },
                position: 'relative',
                justifyContent: 'start',
                alignItems: 'start',
              }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              height: 36,
              width: 'fit-content',
              paddingLeft: '16.5px',
              paddingRight: '16.5px',
              borderRadius: 18,
              background: BG_TABS,
              margin: 'auto',
              mt: {
                xs: '28px',
                md: '40px',
              },
              ...flexHelper(),
              ...sx,
            }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                color: COLOR_TEXT_WHITE_100,
                font: FontFamilies.secondary,
                fontWeight: FontWeights.fontWeightRegular,
                fontSize: 16,
                ...flexHelper(),
              }}
            >
              {name}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
