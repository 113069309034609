import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { links } from 'appConstants/storeLinks';
import { useShallowSelector } from 'hooks';
import { RegistrationData, RegistrationForm } from 'modules/game22/components';
import { signup } from 'store/game22/actions';
import referralSelector from 'store/referral/selectors';

export const Registration: FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { twoFaType } = useShallowSelector(referralSelector.getReferral);

  const handleRegistrationSubmit = (data: RegistrationData) => {
    dispatch(
      signup({
        email: data.email,
        password: data.password,
        confirm_password: data.repeatedPassword,
        referral_code: data.referralCode,
      }),
    );
  };

  const isStoreValid = Object.keys(links.game22).includes(searchParams.get('store') ?? '');
  if (twoFaType !== 'none') {
    if (isStoreValid) {
      window.location.href = links.game22[searchParams.get('store') as keyof typeof links.game22];
    } else {
      window.location.href = '/referrals/games';
    }
  }
  return <RegistrationForm handleSubmit={handleRegistrationSubmit} />;
};
