import React, { FC } from 'react';
import { Card } from 'components';
import { BORDER_DEFAULT_GRAY, BORDER_RADIUS_PROGRESS_BAR } from 'theme';
import { flexHelper } from 'utils/flexHelper';

type TextCardProps = {
  text: string | React.ReactNode;
};

export const TextCard: FC<TextCardProps> = ({ text }) => {
  return (
    <Card
      type="lightTransparent"
      sx={{
        mt: 1.5,
        p: { xs: 2, md: 3 },
        width: '100%',
        minHeight: 80,
        flexDirection: 'column',
        ...flexHelper(),
        border: BORDER_DEFAULT_GRAY,
        borderRadius: BORDER_RADIUS_PROGRESS_BAR,
      }}
    >
      {text}
    </Card>
  );
};
