import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { BackgroundWrapper } from 'components';
import { flexHelper } from 'utils/flexHelper';

export const NotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <BackgroundWrapper
      container
      sx={{
        minHeight: '100vh',
      }}
      containerSx={{
        height: '100%',
        flexDirection: 'column',
        ...flexHelper(),
        flexGrow: 1,
        py: { xs: 0, md: 11 },
      }}
    >
      <Typography variant="h1" textTransform="uppercase" textAlign="center" className="gradient" pt={11}>
        404
      </Typography>
      <Typography variant="h2" textTransform="uppercase" textAlign="center" className="white" pt={1}>
        {t('notFoundPage.title')}
      </Typography>
      <Typography variant="body2" textAlign="center" className="white" maxWidth={320} py={3}>
        {t('notFoundPage.subtitle')}
      </Typography>
      <Button component={Link} to="/">
        {t('notFoundPage.onHomeButton')}
      </Button>
    </BackgroundWrapper>
  );
};
