import BigNumber from 'bignumber.js/bignumber';

const TOTAL_STAKED_DEFAULT = 500000000000000000000000;
const REWARD_PER_DAY_DEFAULT = 9780000000000000000000;

export const calculateApr = (rewardPerDay: string, totalStaked: string) => {
  if (Number(totalStaked) === 0) {
    return new BigNumber(REWARD_PER_DAY_DEFAULT)
      .dividedBy(new BigNumber(TOTAL_STAKED_DEFAULT))
      .multipliedBy(new BigNumber(365))
      .multipliedBy(new BigNumber(100))
      .toFixed(0);
  }
  return new BigNumber(rewardPerDay)
    .dividedBy(new BigNumber(10).pow(24))
    .dividedBy(new BigNumber(totalStaked))
    .multipliedBy(new BigNumber(365))
    .multipliedBy(new BigNumber(100))
    .toFixed(5, 1);
};
