import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LogoSmall: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 33 32"
    sx={{
      width: 33,
      height: 32,
      path: {
        fill: '#fff',
      },
      rect: {
        fill: '#fff',
      },
      ...sx,
    }}
  >
    <g clipPath="url(#clip0_791_1633)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5 16C31.5 24.2843 24.7843 31 16.5 31C8.21573 31 1.5 24.2843 1.5 16C1.5 7.71573 8.21573 1 16.5 1C24.7843 1 31.5 7.71573 31.5 16ZM32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16ZM17.3786 19.9169H26.5V21H14.8576V20.2436L16.0605 19.1766V16.7568L13.4658 12.097H11.5729V21H10.2541V12.097H7.18263V12.0831H6.5V11H26.4255V11.7864L17.3786 19.8044V19.9169ZM14.9108 12.0831L15.7749 13.7589C15.8605 13.9317 15.943 14.0968 16.0229 14.257C16.2933 14.7984 16.5355 15.2834 16.7764 15.822H16.8059C17.0173 15.3093 17.2845 14.7971 17.5781 14.2344C17.6586 14.0801 17.7412 13.9219 17.825 13.7589L18.7106 12.0831H14.9108ZM20.1135 12.0831L17.3786 16.7414V18.0058L23.9975 12.1277V12.0831H20.1135Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_791_1633">
        <rect width="32" height="32" fill="currentColor" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
