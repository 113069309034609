import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BackButtonSmall, BackButtonWhiteIcon } from 'modules/dashboard/components/Icon';
import { addNote } from 'store/dashboard/actions';
import { FontFamilies, FontWeights } from 'theme';
import { getToastMessage } from 'utils';

export const NewNotePopup: FC<{
  handleReturn: () => void;
  email: string;
}> = ({ handleReturn, email }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const { t } = useTranslation();
  const [noteText, setNoteText] = useState('');
  const [noteHeader, setNoteHeader] = useState('');
  const maxHeaderLength = 45;
  const dispatch = useDispatch();

  const handleChangeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setNoteText(value);
  };
  const handleChangeHeader = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length <= maxHeaderLength) {
      setNoteHeader(value);
    } else {
      getToastMessage('warning', t('dashboard.profile.notif.longHeader'));
    }
  };

  const handleAddNoteSubmit = () => {
    dispatch(addNote({ noteText, noteHeader, userEmail: email }));
    handleReturn();
  };

  const saveButton = (
    <IconButton
      sx={{
        width: isSmallScreen ? '138px' : '152px',
        height: isSmallScreen ? '38px' : '42px',
        borderRadius: '21px',
        display: 'flex',
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
        transition: 'transform 0.15s ease-in-out',
        '&:active': {
          transform: 'scale(0.92)',
        },
        position: 'absolute',
        [isSmallScreen ? 'top' : 'bottom']: isSmallScreen ? '12px' : '8px',
        right: isSmallScreen ? '10px' : '-38px',
      }}
      onClick={() => handleAddNoteSubmit()}
    >
      <Typography
        fontFamily={FontFamilies.secondary}
        fontSize={isSmallScreen ? '18px' : '20px'}
        fontWeight={FontWeights.fontWeightMedium}
        color="#191836"
        sx={{ textAlign: 'center' }}
      >
        {t('dashboard.profile.save')}
      </Typography>
    </IconButton>
  );

  const headerInput = (
    <input
      placeholder={t('dashboard.profile.headerPlaceholder')}
      value={noteHeader}
      onChange={handleChangeHeader}
      style={
        {
          width: isSmallScreen ? '272px' : '560px',
          backgroundColor: 'transparent',
          position: 'absolute',
          color: 'white',
          fontFamily: FontFamilies.secondary,
          fontWeight: FontWeights.fontWeightMedium,
          textAlign: isSmallScreen ? 'center' : 'left',
          left: isSmallScreen ? '2px' : '40px',
          top: '2px',
          fontSize: '24px',
          '&:-webkit-autofill': {
            backgroundColor: 'transparent',
          },
          '&:-webkit-autofill:hover': {
            backgroundColor: 'transparent',
          },
          '&:-webkit-autofill:focus': {
            backgroundColor: 'transparent',
          },
        } as any
      }
    />
  );

  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? 'column' : 'row'}
      padding={isSmallScreen ? '2px 4px' : '2px 10px'}
    >
      <IconButton
        onClick={handleReturn}
        sx={{
          position: 'absolute',
          left: isSmallScreen ? '12px' : '27px',
          top: isSmallScreen ? '15px' : '23px',
          transition: 'transform 0.15s ease-in-out',
          '&:active': {
            transform: 'scale(0.92)',
          },
        }}
      >
        {isSmallScreen ? <BackButtonSmall /> : <BackButtonWhiteIcon />}
      </IconButton>

      <Box
        width={isSmallScreen ? '100%' : '80%'}
        height="43px"
        marginLeft={isSmallScreen ? '0' : '52px'}
        position="relative"
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        alignItems="center"
      >
        {isSmallScreen ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="272px"
            position="relative"
            marginTop="28px"
          >
            {headerInput}
            <Box width="100%" height="2px" sx={{ background: 'white', margin: '36px 0' }} />
          </Box>
        ) : (
          <Box>
            {headerInput}
            {saveButton}
            <Box width="100%" height="2px" position="absolute" bottom={0} right="-40px" sx={{ background: 'white' }} />
          </Box>
        )}
      </Box>
      <textarea
        rows={10}
        value={noteText}
        placeholder={t('dashboard.profile.noteTextPlaceholder')}
        onChange={(x) => handleChangeText(x)}
        style={{
          position: 'absolute',
          top: '100px',
          width: isSmallScreen ? '300px' : '960px',
          height: '426px',
          color: 'white',
          fontFamily: FontFamilies.secondary,
          fontWeight: FontWeights.fontWeightMedium,
          fontSize: '20px',
          backgroundColor: 'transparent',
          marginTop: '10px',
          paddingBottom: '10px',
          direction: 'ltr',
          resize: 'none',
        }}
      />
      {isSmallScreen && <Box>{saveButton}</Box>}
    </Box>
  );
};
