/* eslint-disable no-plusplus,@typescript-eslint/no-non-null-assertion,no-nested-ternary,react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { baseReferralURL } from 'appConstants';
import { SupportPopup } from 'modules/dashboard/components/Popups';
import selector from 'store/dashboard/selectors';

import { Pages, Spinner } from '../../../../components';
import { useShallowSelector } from '../../../../hooks';
import { InfoButon, SupportButton } from '../../../dashboard/containers';
import { SearchBar } from '../SearchBar';

import { User } from './UserCard';
import { UserGrid } from './UserGrid';

const chunkSize = 108;

interface ChunkState {
  previous: User[] | null;
  current: User[];
  next: User[] | null;
}

export const UserList = ({ selectUser }: { selectUser: (code: string) => void }) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(1280));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const usersPerPage = isSmallScreen ? 6 : isMediumScreen ? 12 : 18;
  const { t } = useTranslation();

  const [emptyList, setEmptyList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [chunks, setChunks] = useState<ChunkState>({ previous: null, current: [], next: null });
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [searchData, setSearchData] = useState('');
  const { profile } = useShallowSelector(selector.getDashboard);
  const [role, setRole] = useState('user');

  useEffect(() => {
    if (profile !== null) {
      setRole(profile.role);
    }
  }, [profile]);

  const getChunk = async (index: number, search: string) => {
    const url = `${baseReferralURL}ref_api/v1/account/users?type=data&search=${encodeURIComponent(
      search,
    )}&chunk=${encodeURIComponent(index)}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        return null;
      }

      return await response.json();
    } catch (err) {
      return null;
    }
  };

  const getTotalUsers = async (search: string) => {
    const url = `${baseReferralURL}ref_api/v1/account/users?type=count&search=${encodeURIComponent(search)}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        return null;
      }

      return (await response.json()).count;
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    let totalU = 0;
    setEmptyList(false);
    getTotalUsers(searchData).then((total) => {
      totalU = total;
      setTotalUsers(total);
      if (total === 0) setEmptyList(true);
    });
    getChunk(0, searchData).then((chunk) => {
      setUsers(chunk.slice(0, usersPerPage));
      setChunks({ previous: null, current: chunk, next: null });
      setCurrentChunkIndex(0);
      setCurrentPage(1);
      if (Math.ceil(totalU / chunkSize) > 1) {
        getChunk(1, searchData).then((nextChunk) => {
          setChunks((prevChunks) => ({
            ...prevChunks,
            next: nextChunk,
          }));
        });
      }
    });
  }, [searchData]);
  const [isSupportPopupOpen, setSupportPopupOpen] = useState(false);

  useEffect(() => {
    const chunkIndex = Math.floor(((currentPage - 1) * usersPerPage) / chunkSize);
    const innerIdx = (currentPage - 1) * usersPerPage - chunkIndex * chunkSize;
    const totalChunks = Math.ceil(totalUsers / chunkSize);
    const tempChunks = chunks;

    if (chunkIndex !== currentChunkIndex) {
      setUsers([]);
      if (chunkIndex === currentChunkIndex + 1 && chunks.next) {
        setChunks((prevChunks) => ({
          previous: prevChunks.current,
          current: prevChunks.next!,
          next: null,
        }));
        setUsers(tempChunks.next!.slice(innerIdx, innerIdx + usersPerPage));
        if (chunkIndex + 1 < totalChunks) {
          getChunk(chunkIndex + 1, searchData).then((nextChunk) => {
            setChunks((prevChunks) => ({
              ...prevChunks,
              next: nextChunk,
            }));
          });
        }
        setCurrentChunkIndex(chunkIndex);
      } else if (chunkIndex === currentChunkIndex - 1 && chunks.previous) {
        setChunks((prevChunks) => ({
          previous: null,
          current: prevChunks.previous!,
          next: prevChunks.current,
        }));
        setUsers(tempChunks.previous!.slice(innerIdx, innerIdx + usersPerPage));
        if (chunkIndex >= 1) {
          getChunk(chunkIndex - 1, searchData).then((previousChunk) => {
            setChunks((prevChunks) => ({
              ...prevChunks,
              previous: previousChunk,
            }));
          });
        }
        setCurrentChunkIndex(chunkIndex);
      } else {
        getChunk(chunkIndex, searchData).then((currentChunk) => {
          const promises = [
            chunkIndex > 0 ? getChunk(chunkIndex - 1, searchData) : Promise.resolve(null),
            chunkIndex < totalChunks - 1 ? getChunk(chunkIndex + 1, searchData) : Promise.resolve(null),
          ];
          Promise.all(promises).then(([previousChunk, nextChunk]) => {
            setChunks({
              previous: previousChunk,
              current: currentChunk,
              next: nextChunk,
            });
            setCurrentChunkIndex(chunkIndex);
            setUsers(currentChunk.slice(innerIdx, innerIdx + usersPerPage));
          });
        });
      }
    } else {
      setUsers(tempChunks.current.slice(innerIdx, innerIdx + usersPerPage));
    }
  }, [currentPage, totalUsers, usersPerPage]);

  const totalPages = Math.ceil(totalUsers / usersPerPage);

  return (
    <div className="relative pb-16 w-full" style={{ minHeight: '52rem' }}>
      <SupportPopup isOpen={isSupportPopupOpen} closePopup={() => setSupportPopupOpen(false)} />
      <div className="hidden phone:flex mt-4 justify-end">
        <InfoButon />
      </div>
      <div className="flex justify-between mb-4 mt-4">
        <SearchBar onSearch={(searchTerm) => setSearchData(searchTerm)} />
        <div className="phone:hidden">
          <InfoButon />
        </div>
      </div>
      {users.length > 0 ? (
        <UserGrid users={users} selectUser={selectUser} />
      ) : emptyList ? (
        <div className="w-full flex justify-center pointer-events-none text-gray-400 font-medium text-4xl">
          {t('dashboard.adminDashboard.networkEmptyList')}
        </div>
      ) : (
        <div className="w-full flex justify-center pointer-events-none" style={{ transform: 'translateY(75%)' }}>
          <Spinner size="l" />
        </div>
      )}
      <div className="absolute bottom-4 right-0">
        <Pages current={currentPage} max={totalPages} callback={(page) => setCurrentPage(page)} />
      </div>
      <div className="absolute bottom-4 left-0 justify-center items-center mt-4">
        {role === 'user' && !isSmallScreen && <SupportButton openPopup={() => setSupportPopupOpen(true)} />}
      </div>
    </div>
  );
};
