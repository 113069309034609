import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { routes } from 'appConstants';

import { logout } from '../../../../store/referral/actions';

export const Logout: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClick = () => {
    dispatch(logout('/referrals/signin'));
  };
  return <Button onClick={handleClick}>{t('referralSystem.logout')}</Button>;
};
