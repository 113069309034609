import { createAction } from '@reduxjs/toolkit';
import {
  AddAdminReq,
  AddGameReq,
  AddNoteReq,
  AddStoreReq,
  AdminPaymentsHistoryReq,
  AdminWithdrawalHistoryReq,
  BlockUserReq,
  DeleteGameReq,
  DeleteStoreReq,
  DemoteAdminReq,
  Drop2faReq,
  EditStoreReq,
  MakeReferralSwapReq,
  PromoteAdminReq,
  RequestWithWeb3Provider,
  ResolveBlockedTransactionReq,
  SelectedUserInfoReq,
  SetAvaReq,
  TakeBalanceReq,
  UpdateMaxWithdrawalReq,
  UpdateMinWithdrawalReq,
  UpdateWithdrawalPeriodReq,
} from 'types/requests';
import { Admin, Game, Note, SelectedUserInfo, Store, User, WithdrawalLimits } from 'types/store/dashboard';

import actionTypes from './actionTypes';

export const editWithdrawalLimits = createAction<WithdrawalLimits>(actionTypes.ON_UPDATE_WITHDRAWAL_LIMITS);
export const getWithdrawalLimits = createAction(actionTypes.GET_WITHDRAWAL_LIMITS);
export const onUpdateMaxWithdrawal = createAction<number>(actionTypes.ON_UPDATE_MAX_WITHDRAWAL);
export const onUpdateMinWithdrawal = createAction<number>(actionTypes.ON_UPDATE_MIN_WITHDRAWAL);
export const onUpdateWithdrawalPeriod = createAction<number>(actionTypes.ON_UPDATE_WITHDRAWAL_PERIOD);
export const updateMaxWithdrawal = createAction<UpdateMaxWithdrawalReq>(actionTypes.UPDATE_MAX_WITHDRAWAL);
export const updateMinWithdrawal = createAction<UpdateMinWithdrawalReq>(actionTypes.UPDATE_MIN_WITHDRAWAL);
export const updateWithdrawalPeriod = createAction<UpdateWithdrawalPeriodReq>(actionTypes.UPDATE_WITHDRAWAL_PERIOD);

export const addAdmin = createAction<AddAdminReq>(actionTypes.ADD_ADMIN);
export const addAdminToList = createAction<Admin>(actionTypes.ON_ADMIN_ADDED);
export const blockAdminFromList = createAction<Admin>(actionTypes.ON_ADMIN_EDITED);
export const blockUser = createAction<BlockUserReq>(actionTypes.BLOCK_USER);
export const demoteAdmin = createAction<DemoteAdminReq>(actionTypes.DEMOTE_ADMIN);
export const onDemoteAdmin = createAction<string>(actionTypes.ON_ADMIN_DEMOTED);
export const promoteAdmin = createAction<PromoteAdminReq>(actionTypes.PROMOTE_ADMIN);
export const onPromoteAdmin = createAction<Admin>(actionTypes.ON_ADMIN_PROMOTED);
export const getAdmins = createAction(actionTypes.GET_ADMINS);
export const updateNewAdmin = createAction(actionTypes.ON_NEW_ADMIN_UPDATED);
export const updateCachedUsers = createAction<SelectedUserInfo>(actionTypes.ON_GET_SELECTED_USER_INFO);

export const addGame = createAction<AddGameReq>(actionTypes.ADD_GAME);
export const addGameToList = createAction<Game>(actionTypes.ON_GAME_ADDED);
export const deleteGame = createAction<DeleteGameReq>(actionTypes.DELETE_GAME);
export const deleteGameFromList = createAction<string>(actionTypes.ON_GAME_DELETED);
export const getGames = createAction(actionTypes.GET_GAMES);

export const addStore = createAction<AddStoreReq>(actionTypes.ADD_STORE);
export const addStoreToList = createAction<Store>(actionTypes.ON_STORE_ADDED);
export const deleteStore = createAction<DeleteStoreReq>(actionTypes.DELETE_STORE);
export const deleteStoreFromList = createAction<string>(actionTypes.ON_STORE_DELETED);
export const editStore = createAction<EditStoreReq>(actionTypes.EDIT_STORE);
export const editStoreFromList = createAction<Store>(actionTypes.ON_STORE_EDITED);
export const getStores = createAction(actionTypes.GET_STORES);

export const clearSelectedUserInfo = createAction(actionTypes.CLEAR_SELECTED_USER_INFO);
export const getAdminPaymentsHistory = createAction<AdminPaymentsHistoryReq>(actionTypes.GET_ADMIN_PAYMENTS_HISTORY);
export const getAdminWithdrawalHistory = createAction<AdminWithdrawalHistoryReq>(
  actionTypes.GET_ADMIN_WITHDRAWAL_HISTORY,
);
export const getPaymentsHistory = createAction(actionTypes.GET_PAYMENTS_HISTORY);
export const getSelectedUserInfo = createAction<SelectedUserInfoReq>(actionTypes.GET_SELECTED_USER_INFO);
export const getWithdrawals = createAction(actionTypes.GET_WITHDRAWALS);
export const onUserStatusChanged = createAction<{ email: string; status: boolean }>(actionTypes.ON_USER_STATUS_CHANGED);

export const addNote = createAction<AddNoteReq>(actionTypes.ADD_NOTE);
export const addNoteToList = createAction<Note>(actionTypes.ON_NOTE_ADDED);
export const getNotes = createAction<{ email: string }>(actionTypes.GET_NOTES);
export const getProfileInfo = createAction(actionTypes.GET_PROFILE_INFO);
export const onUpdateAvatar = createAction<string>(actionTypes.ON_UPDATE_AVATAR);
export const setProfileAvatar = createAction<SetAvaReq>(actionTypes.SET_AVATAR);
export const setProfileInfo = createAction<User | null>(actionTypes.SET_PROFILE_INFO);

export const getConfirmations = createAction(actionTypes.GET_CONFIRMATIONS);
export const deleteConfirmationFromList = createAction<number>(actionTypes.ON_CONFIRMATION_RESOLVED);
export const resolveBlockedTransaction = createAction<ResolveBlockedTransactionReq>(
  actionTypes.RESOLVE_BLOCKED_TRANSACTION,
);

export const takeBalance = createAction<TakeBalanceReq>(actionTypes.TAKE_BALANCE);

export const makeReferralSwap = createAction<MakeReferralSwapReq & RequestWithWeb3Provider>(
  actionTypes.MAKE_REFERRAL_SWAP,
);

export const setIsFetchingUser = createAction<boolean>(actionTypes.ON_FETCH_USER);

export const setSwapExceeded = createAction<boolean>(actionTypes.ON_SWAP_EXCEEDED);
export const setWalletInUse = createAction<boolean>(actionTypes.ON_WALLET_IN_USE);

export const updateSelectedUserBalance = createAction<{ email: string; balance: string }>(actionTypes.ON_TAKE_BALANCE);

export const drop2fa = createAction<Drop2faReq>(actionTypes.DROP_2FA);
export const onDrop2fa = createAction<string>(actionTypes.ON_DROP_2FA);
export const getApprovedWithdrawal = createAction<string>(actionTypes.GET_APPROVED_WITHDRAWAL);

export const request = <T = string, P = never>(defaultType: T, payload?: P) => ({
  type: `${defaultType}_REQUEST`,
  payload,
});

export const success = <T = string, P = never>(defaultType: T, payload?: P) => ({
  type: `${defaultType}_SUCCESS`,
  payload,
});

export const error = <T = string, E = never>(defaultType: T, err?: E) => ({
  type: `${defaultType}_ERROR`,
  payload: err,
  err,
});

export const reset = <T = string>(defaultType: T) => ({
  type: `${defaultType}_RESET`,
});

export default {
  request,
  success,
  error,
  reset,
};
