import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import {
  BG_INPUT_GRAY_DEFAULT,
  BG_INPUT_GRAY_HOVER,
  BORDER_COLOR_INPUT_GRAY_DEFAULT,
  BORDER_RADIUS_SMALL,
  COLOR_TEXT_GRAY_200,
  COLOR_TEXT_WHITE_100,
  FontWeights,
  TRANSITION_DEFAULT_TIME,
} from 'theme';

export const getMuiTextFieldOverrides = (theme: Theme): ComponentsOverrides['MuiTextField'] => ({
  root: {
    boxShadow: 'none',
    border: '1px solid',
    borderColor: 'transparent',
    borderRadius: BORDER_RADIUS_SMALL,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'right',

    '& input::placeholder': {
      opacity: 1,
      color: COLOR_TEXT_GRAY_200,
      fontWeight: FontWeights.fontWeightRegular,
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'right',

      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
    },
    '& .MuiInputBase-input': {
      '&SizeSmall': {
        textAlign: 'left',
        fontSize: 16,
        fontWeight: FontWeights.fontWeightRegular,
        lineHeight: '24px',
        height: '56px !important',
      },
    },
    '& .MuiOutlinedInput-root': {
      color: COLOR_TEXT_WHITE_100,
      borderRadius: BORDER_RADIUS_SMALL,
      fontSize: 40,
      lineHeight: '41px',
      fontWeight: FontWeights.fontWeightExtraBold,

      [theme?.breakpoints.down('md')]: {
        fontSize: 18,
      },

      '& fieldset': {
        background: BG_INPUT_GRAY_DEFAULT,
        transition: TRANSITION_DEFAULT_TIME,
      },
      '&:hover fieldset': {
        borderColor: BORDER_COLOR_INPUT_GRAY_DEFAULT,
        background: BG_INPUT_GRAY_HOVER,
      },
      '&.Mui-focused fieldset': {
        borderColor: BORDER_COLOR_INPUT_GRAY_DEFAULT,
        background: BG_INPUT_GRAY_HOVER,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgba(26, 26, 26, 0.2)',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'rgba(26, 26, 26, 0.2)',
    },
  },
});

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTextField'] => ({
  inputProps: {
    style: {
      height: 82,
      padding: '0 17px',
    },
    autoComplete: 'off',
  },
});
