import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { emailRegex, routes } from 'appConstants';
import { useModal, useShallowSelector } from 'hooks';
import {
  EmailConfirmationPage,
  EmailTwoFaModal,
  GoogleAuthModal,
  InputField,
  LockWithbackGround,
  PageName,
  TwoFaSelectionModal,
} from 'modules/referral/components';
import { useError } from 'modules/referral/hooks';
import { validatePassword } from 'modules/referral/utils';
import {
  activateTwoFa,
  getTwoFaStatus,
  logout,
  requestRegistrationCode,
  sendCode,
  signup,
} from 'store/referral/actions';
import { clearErrors, setError, setTwoFaAction, setTwoFaLoading } from 'store/referral/reducer';
import referralSelector from 'store/referral/selectors';
import { TwoFa } from 'types';
import Cookies from 'universal-cookie';
import { getToastMessage } from 'utils';

import { Spinner } from '../../../../components';

export type RegistrationData = {
  email: string;
  password: string;
  repeatedPassword: string;
  referralCode: string;
};

export const Registration: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const cookies = new Cookies();

  const [registrationData, setRegistrationData] = useState<RegistrationData>({
    email: '',
    password: '',
    repeatedPassword: '',
    referralCode: '',
  });

  const registrationStarted = useShallowSelector(referralSelector.getProp('email')) !== '';
  const { twoFaUpdate, twoFaToken, twoFaAction, twoFaLoading } = useShallowSelector(referralSelector.getReferral);
  const emailConfirmed = cookies.get('logged_in') !== undefined;
  const showEmailConfirmationPage = registrationStarted && !emailConfirmed;
  const [isTwoFaModalVisible, openTwoFaModal, closeTwoFaModal] = useModal(false);

  const requestCode = () => {
    dispatch(activateTwoFa({ method: 'email' }));
  };

  useEffect(() => {
    if (emailConfirmed) {
      if ((twoFaToken !== '' && twoFaAction === 'none') || twoFaAction === 'force') {
        dispatch(setTwoFaAction('finished'));
        navigate(routes.dashboardPage.root.path);
      } else if (twoFaAction === 'finished' || twoFaAction === 'none') {
        dispatch(setTwoFaLoading(true));
        dispatch(getTwoFaStatus());
      } else if (twoFaAction === 'updating') {
        openTwoFaModal();
        requestCode();
      }
    }
  }, [twoFaAction]);

  const [time, setTime] = useState(0);
  useEffect(() => {
    let interval: any;
    if (time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [time]);

  const requestEmailConfirmation = () => {
    setTime(30);
    dispatch(requestRegistrationCode({ email: registrationData.email, password: registrationData.password }));
  };
  const sendEmailConfirmation = (code: string) => {
    dispatch(sendCode({ email: registrationData.email, password: registrationData.password, code, project: '-' }));
  };

  useEffect(() => {
    if (emailConfirmed) {
      openTwoFaModal();
      if (time <= 0) {
        requestCode();
      }
    }
  }, [emailConfirmed, twoFaUpdate]);
  const quitTwoFaSelect = () => {
    dispatch(logout(null));
    closeTwoFaModal();
  };
  const [twoFaMethod, setTwoFaMethod] = useState<TwoFa>('none');
  const [isConfirmTwoFaModalVisible, openConfirmTwoFaModal, closeConfirmTwoFaModal] = useModal(false);

  const selectTwoFaMethod = (method: TwoFa) => {
    setTwoFaMethod(method);
    closeTwoFaModal();
    openConfirmTwoFaModal();
  };

  const registrationCompleted = useShallowSelector(referralSelector.getProp('twoFaType')) !== 'none';
  useEffect(() => {
    if (registrationCompleted) {
      closeConfirmTwoFaModal();

      // TODO: remove this message
      // This message is temporary to show that registration has been completed.
      // Later redirect to personal page should be done.
      getToastMessage('success', 'Registration completed successfully');
    }
  }, [registrationCompleted]);

  const handleChangeInput = (value: string, type: keyof RegistrationData) => {
    setRegistrationData((prevState) => ({ ...prevState, [type]: value }));
  };

  const [errorCheckFlag, setErrorCheckFlag] = useState(false);

  const errors = [
    useError('registrationEmail'),
    useError('registrationPassword'),
    useError('registrationRepeatedPassword'),
    useError('registrationReferralCode'),
  ];

  const handleRegistration = () => {
    dispatch(logout(null));
    dispatch(clearErrors());
    const { email, password, repeatedPassword, referralCode } = registrationData;
    if (!emailRegex.test(email)) {
      dispatch(setError({ registrationEmail: 'badEmail' }));
    } else if (
      validatePassword(
        password,
        repeatedPassword,
        'registrationPassword',
        'registrationRepeatedPassword',
        'registrationPassword',
        dispatch,
      )
    ) {
      dispatch(signup({ email, password, referralCode }));
      if (time <= 0) {
        setTime(30);
      }
    }
    setErrorCheckFlag((prevFlag) => !prevFlag);
  };

  useEffect(() => {
    const firstError = errors.find((error) => error && error !== '');
    if (firstError) {
      getToastMessage('error', firstError);
    }
  }, [...errors, errorCheckFlag]);

  if (twoFaLoading) {
    return <Spinner size="xl" />;
  }

  if (showEmailConfirmationPage) {
    return (
      <EmailConfirmationPage requestEmail={requestEmailConfirmation} sendCode={sendEmailConfirmation} time={time} />
    );
  }

  const alignLabels = isMd ? 'start' : 'center';
  const width = isMd ? 556 : 304;
  const inputTextAlign = isMd ? 'left' : 'center';
  const buttonText = isMd ? t('referralSystem.signup.signup') : t('referralSystem.signup.signupMobile');
  return (
    <Stack direction={{ xs: 'column', md: 'row' }}>
      <Box
        sx={{
          height: {
            xs: 639,
            md: 708,
          },
          width: {
            xs: 342,
            md: 702,
          },
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <PageName
            name={t('referralSystem.signup.signup')}
            onArrowClick={() => navigate(routes.referralLogin.root.path)}
          />
          <Stack justifyContent="center" alignItems="start" sx={{ width: { xs: 342, md: 702 } }}>
            <InputField
              data={registrationData.email}
              setData={(value) => handleChangeInput(value, 'email')}
              name="email"
              label={t('referralSystem.signup.email')}
              type="text"
              errorLabel="registrationEmail"
              alignLabel={alignLabels}
              width={width}
              textAlign={inputTextAlign}
              sx={{ ml: { xs: '19px', md: '73px' } }}
              showHelperText={false}
            />
            <InputField
              data={registrationData.password}
              setData={(value) => handleChangeInput(value, 'password')}
              name="password"
              label={t('referralSystem.signup.password')}
              type="password"
              errorLabel="registrationPassword"
              alignLabel={alignLabels}
              width={width}
              textAlign={inputTextAlign}
              sx={{ ml: { xs: '19px', md: '73px' } }}
              showHelperText={false}
            />
            <InputField
              data={registrationData.repeatedPassword}
              setData={(value) => handleChangeInput(value, 'repeatedPassword')}
              name="repeatedPassword"
              label={t('referralSystem.signup.repeatedPassword')}
              type="password"
              errorLabel="registrationRepeatedPassword"
              alignLabel={alignLabels}
              width={width}
              textAlign={inputTextAlign}
              sx={{ ml: { xs: '19px', md: '73px' } }}
              showHelperText={false}
            />
            <InputField
              data={registrationData.referralCode}
              setData={(value) => handleChangeInput(value, 'referralCode')}
              name="referralCode"
              label={t('referralSystem.signup.referralCode')}
              type="text"
              errorLabel="registrationReferralCode"
              alignLabel="center"
              width={width}
              textAlign={inputTextAlign}
              sx={{ ml: { xs: '19px', md: '73px' } }}
              showHelperText={false}
            />
          </Stack>
          <Button
            onClick={handleRegistration}
            sx={{
              mt: {
                xs: '24px',
                md: '40px',
              },
              height: {
                xs: 44,
                md: 51,
              },
              width: {
                xs: 153,
                md: 200,
              },
            }}
          >
            {buttonText}
          </Button>
        </Stack>
        <TwoFaSelectionModal open={isTwoFaModalVisible} onClose={quitTwoFaSelect} setMethod={selectTwoFaMethod} />
        <EmailTwoFaModal
          open={isConfirmTwoFaModalVisible && twoFaMethod === 'email'}
          onClose={() => {
            openTwoFaModal();
            closeConfirmTwoFaModal();
          }}
          goTo="/referrals/games"
          requestCode={requestCode}
        />
        <GoogleAuthModal
          open={isConfirmTwoFaModalVisible && twoFaMethod === 'app'}
          onClose={() => {
            openTwoFaModal();
            closeConfirmTwoFaModal();
          }}
          goTo="/referrals/games"
        />
      </Box>
      <LockWithbackGround />
    </Stack>
  );
};
