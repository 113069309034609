import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LanguageIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 41 41"
    sx={{
      width: '36px',
      height: '36px',
      color: 'transparent',
      path: {
        fill: '#fff',
      },
      rect: {
        stroke: '#fff',
      },
      ...sx,
    }}
  >
    <path
      d="M11.4091 26V14.3636H18.4318V15.6136H12.8182V19.5455H18.0682V20.7955H12.8182V24.75H18.5227V26H11.4091ZM30.1989 14.3636V26H28.8352L22.4943 16.8636H22.3807V26H20.9716V14.3636H22.3352L28.6989 23.5227H28.8125V14.3636H30.1989Z"
      fill="currentColor"
    />
    <rect x="0.5" y="0.5" width="40" height="39" rx="19.5" stroke="currentColor" />
  </SvgIcon>
);
