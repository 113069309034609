import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { WalletIcon } from 'components';
import { useModal, useShallowSelector } from 'hooks';
import { MainLogo, NavSourceLinks } from 'modules/layout/components';
import { useVerticalAnchor } from 'modules/layout/hooks';
import { formatRoutesToArr } from 'modules/router/utils';
import uiSelector from 'store/ui/selectors';
import actionTypes from 'store/user/actionTypes';
import userSelector from 'store/user/selectors';
import {
  BORDER_BUTTON_BLUE,
  BORDER_BUTTON_GRAY,
  BORDER_RADIUS_DEFAULT,
  BORDER_RADIUS_MEDIUM,
  COLOR_TEXT_WHITE_100,
  TRANSITION_DEFAULT_TIME,
} from 'theme';
import { WalletProviders } from 'types';
import { getRequestStatus, shortenPhrase } from 'utils';

import { Menu } from '../Menu';
import { ConnectWalletModal, DisconnectModal, LanguageModal, LoginModal } from '../NotificationModal';

import { navSourceLinks } from './Header.helpers';

type HeaderProps = {
  onConnectWallet: (provider: WalletProviders) => void;
  onDisconnect: () => void;
  fullHeader: boolean;
};

const GridNavItem = styled(NavLink)({
  color: COLOR_TEXT_WHITE_100,
  border: 'none',
  textDecoration: 'none',
  maxWidth: 'fit-content',
});

export const Header: FC<HeaderProps> = ({ onConnectWallet, onDisconnect, fullHeader }) => {
  const languageRef = useRef(null);
  const { t, i18n } = useTranslation();

  const uiState = useShallowSelector(uiSelector.getUI);
  const { address, email, isVerified } = useShallowSelector(userSelector.getUser);
  const isConnectingToWallet = getRequestStatus(uiState[actionTypes.LOGIN]);

  const [isVisibleConnectModal, onOpenConnectModal, onCloseConnectModal] = useModal(false);
  const [isVisibleDisconnectModal, onOpenDisconnectModal, onCloseDisconnectModal] = useModal(false);
  const [isVisibleLanguagePopover, onOpenLanguagePopover, onCloseLanguagePopover] = useModal(false);
  const [isLoginModalVisible, openLoginModal, closeLoginModal] = useModal(false);

  const verticalAnchor = useVerticalAnchor();

  const languageButton = (
    <IconButton
      onClick={onOpenLanguagePopover}
      ref={languageRef}
      sx={{
        mr: 1,
        p: 1,
        width: 41,
        borderRadius: '50%',
        border: `1px solid ${COLOR_TEXT_WHITE_100}`,
        transition: TRANSITION_DEFAULT_TIME,
        fontSize: 16,
        lineHeight: '20px',
        color: COLOR_TEXT_WHITE_100,

        '&:hover': { border: BORDER_BUTTON_BLUE },
      }}
    >
      {i18n.language.toUpperCase()}
    </IconButton>
  );

  return (
    <>
      <Box
        sx={{
          margin: '0 auto',
          px: 6,
          pt: { xs: 3, md: 2 },
          pb: { xs: 0, md: 2 },
          position: 'absolute',
          left: 0,
          right: 0,
          width: '100%',
          maxWidth: 1440,
          height: { xs: 65, md: 52 },
          transition: TRANSITION_DEFAULT_TIME,
        }}
      >
        {!fullHeader ? (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{languageButton}</Box>
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              container
              justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start' }}
              alignItems="center"
              xs={2}
            >
              <MainLogo />
            </Grid>

            <Grid
              item
              xs={5}
              container
              display={{ xs: 'none', sm: 'none', md: 'flex' }}
              justifyContent="space-around"
              alignItems="center"
              flexWrap="nowrap"
              sx={{ maxWidth: '50%' }}
            >
              <NavSourceLinks linksArr={navSourceLinks} uppercase />
              {formatRoutesToArr(routes).map(
                ({ root: { id, path, title, isNavItem } }) =>
                  isNavItem && (
                    <Grid key={id} item>
                      <GridNavItem to={path}>
                        {({ isActive }) => (
                          <Typography
                            px={1}
                            variant="body2"
                            color={COLOR_TEXT_WHITE_100}
                            borderRadius={BORDER_RADIUS_MEDIUM}
                            border={isActive ? BORDER_BUTTON_GRAY : '1px solid transparent'}
                            sx={{ '&:hover': { border: BORDER_BUTTON_GRAY }, textTransform: 'uppercase' }}
                          >
                            {t(`header.nav.${title}`)}
                          </Typography>
                        )}
                      </GridNavItem>
                    </Grid>
                  ),
              )}
            </Grid>

            <Grid item container display={{ xs: 'none', sm: 'none', md: 'flex' }} justifyContent="flex-end" xs={4}>
              {languageButton}
              <LoadingButton
                onClick={() => (address.length ? onOpenDisconnectModal() : onOpenConnectModal())}
                variant="contained"
                loading={isConnectingToWallet}
                size="small"
                color={address.length ? 'primary' : 'secondary'}
                startIcon={<WalletIcon />}
                sx={{
                  borderRadius: BORDER_RADIUS_DEFAULT,
                  textTransform: 'capitalize',
                  '&:first-letter': {
                    textTransform: 'capitalize',
                  },
                  letterSpacing: '0.03em',
                  svg: { path: { fill: address.length ? COLOR_TEXT_WHITE_100 : '#191836' } },
                  '&:hover': {
                    svg: { path: { fill: COLOR_TEXT_WHITE_100 } },
                  },
                }}
              >
                {address.length ? shortenPhrase(address, 10, 1) : t('header.connectWallet.buttonTitle')}
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Box>

      <Menu onOpenConnectModal={onOpenConnectModal} onOpenDisconnectModal={onOpenDisconnectModal} address={address} />

      {isVisibleConnectModal && (
        <ConnectWalletModal
          open={isVisibleConnectModal}
          onClose={onCloseConnectModal}
          onConnectWallet={onConnectWallet}
        />
      )}

      {isVisibleDisconnectModal && (
        <DisconnectModal
          open={isVisibleDisconnectModal}
          onClose={onCloseDisconnectModal}
          onDisconnect={onDisconnect}
          onLogin={openLoginModal}
          isLoggedIn={!!email && !!isVerified}
          address={address}
        />
      )}
      {isLoginModalVisible && <LoginModal open={isLoginModalVisible} onClose={closeLoginModal} />}

      {isVisibleLanguagePopover && (
        <LanguageModal
          open={isVisibleLanguagePopover}
          onClose={onCloseLanguagePopover}
          anchorEl={languageRef}
          verticalAnchor={verticalAnchor}
        />
      )}
    </>
  );
};
