import React from 'react';

export const RoundedIconButton = ({
  icon,
  text,
  onClick,
}: {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
}) => {
  return (
    <button
      style={{ fontFamily: 'TT Norms, sans-serif', fontSize: '22px', color: '#191836' }}
      className="flex items-center bg-white rounded-full px-4 py-2 shadow hover:bg-gray-100 text-gray-800 transition-colors duration-150 font-medium"
      onClick={onClick}
      type="button"
    >
      <span className="phone:mr-0 mr-2">{icon}</span>
      <span className="phone:hidden">{text}</span>
    </button>
  );
};
