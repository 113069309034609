/* eslint-disable no-console */
import {
  error,
  getSelectedUserInfo,
  request,
  setIsFetchingUser,
  success,
  updateCachedUsers,
} from 'store/dashboard/actions';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { setSelectedUserInfo } from '../reducer';
import selectors from '../selectors';
import { ShowUnauthorizedMessage } from '../utils';

export function* getSelectedUserInfoSaga({ type, payload: { email } }: ReturnType<typeof getSelectedUserInfo>) {
  yield* put(request(type, { email }));
  const { cachedUsers } = yield* select(selectors.getDashboard);
  const user = cachedUsers.find((entry) => entry.user.email === email);
  if (user) {
    yield* put(success(actionTypes.GET_SELECTED_USER_INFO, { user }));
    yield* put(setSelectedUserInfo(user));
  } else {
    try {
      const cookies = new Cookies();
      if (cookies.get('logged_in')) {
        yield put(setIsFetchingUser(true));
        const { data } = yield* call(baseApi.getSelectedUserInfo, { email });
        yield* put(success(actionTypes.GET_SELECTED_USER_INFO, { data }));
        yield* put(setSelectedUserInfo(data));
        yield* put(setIsFetchingUser(false));
        yield* put(updateCachedUsers(data));
      } else {
        ShowUnauthorizedMessage();
      }
    } catch (err) {
      console.error(err);
      errorToastMessage(err);
      yield* put(error(actionTypes.GET_SELECTED_USER_INFO));
      yield* put(setIsFetchingUser(false));
    }
  }
}

export default function* listener() {
  yield all([takeLatest(actionTypes.GET_SELECTED_USER_INFO, getSelectedUserInfoSaga)]);
}
