import { FC } from 'react';
import { Box } from '@mui/material';

import srcIcon from './dashboard.png';
import srcBackground from './dashboardBackground.png';

export const DashboardIcon: FC = () => (
  <Box position="relative" width="62px" height="62px">
    <img
      src={srcBackground}
      alt="DashboardIconBackground"
      style={{ position: 'absolute', top: -8, left: 6, width: 'auto', height: '75px' }}
    />
    <img
      src={srcIcon}
      alt="DashboardIcon"
      style={{
        position: 'absolute',
        top: 8,
        width: 'auto',
        height: '54px',
      }}
    />
  </Box>
);
