import { sha256 } from 'js-sha256';
import { error, request, signup, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';

import actionTypes from '../actionTypes';

export function* requestRegistrationCodeSaga({ type, payload: { email, password } }: ReturnType<typeof signup>) {
  yield* put(request(type));

  try {
    const hashedPassword: string = sha256(password);
    yield* call(baseApi.requestRegistrationCode, {
      email,
      password: hashedPassword,
    });
    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.REQUEST_REGISTRATION_CODE, requestRegistrationCodeSaga);
}
