import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { ReturnIcon } from 'modules/dashboard/components';
import { LockedAmountPopup } from 'modules/dashboard/components/Popups';
import { TableWrapper } from 'modules/dashboard/containers';
import { TableType } from 'modules/dashboard/containers/DashboardTable/useTable';
import { dateComparator, levelComparator, numberComparator, stringComparator } from 'modules/dashboard/util';
import { TableItem } from 'types/dashboard';

import { useShallowSelector } from '../../../../hooks';
import { clearSelectedUserInfo, getSelectedUserInfo } from '../../../../store/dashboard/actions';
import { setSelectedUserInfo } from '../../../../store/dashboard/reducer';
import selector from '../../../../store/dashboard/selectors';
import { LoadingProfilePage } from '../NetworkPage/Profile';

export type ButtonData = {
  id: number;
  cellIndex: number;
  button: ReactNode;
};

export const ConfirmationPage: FC = () => {
  const { t } = useTranslation();
  const { confirmations } = useShallowSelector(selector.getDashboard);
  const { selectedUserInfo, isFetchingUser } = useShallowSelector(selector.getDashboard);
  const [selectedProfileEmail, setProfile] = useState('');
  const [isLockedAmountPopupOppened, setLockedAmountPopupOppened] = useState(false);
  const [unlockId, setUnlockId] = useState(0);
  const [buttonsData, setButtonsData] = useState<ButtonData[]>([]);
  const dispatch = useDispatch();
  const [items, setItems] = useState<TableItem[]>([]);

  const profilePage = selectedUserInfo !== null || isFetchingUser;

  const columns = [
    t('dashboard.columns.email'),
    t('dashboard.columns.referralCode'),
    t('dashboard.columns.userLevel'),
    t('dashboard.columns.lockedAmount'),
    t('dashboard.columns.date'),
  ];

  useEffect(() => {
    if (confirmations) {
      setItems(
        confirmations.map((receipt) => ({
          id: receipt.id,
          date: new Date(receipt.date),
          status: receipt.status,
          data: [
            receipt.email,
            receipt.referralCode,
            t(`dashboard.levels.${receipt.level}`),
            receipt.amount,
            format(new Date(receipt.date), 'dd.MM.yyyy'),
          ],
        })),
      );
    }
  }, [confirmations, t]);

  const comparators = [
    stringComparator,
    stringComparator,
    levelComparator(t),
    numberComparator,
    dateComparator,
    stringComparator,
  ];

  const handleReturn = () => {
    dispatch(clearSelectedUserInfo());
    setProfile('');
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedUserInfo(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedProfileEmail !== '') {
      dispatch(getSelectedUserInfo({ email: selectedProfileEmail }));
    }
  }, [selectedProfileEmail]);

  const onUpdateItems = (updatedItems: TableItem[]) => {
    const newButtonsData: ButtonData[] = [];
    updatedItems.forEach((item) => {
      newButtonsData.push({
        id: item.id,
        cellIndex: 3,
        button: (
          <IconButton
            onClick={() => {
              setLockedAmountPopupOppened(true);
              setUnlockId(item.id);
            }}
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, -50%) translate(70px, 14px)',
            }}
          >
            <ReturnIcon stroke="#0858E8" />
          </IconButton>
        ),
      });
    });
    setButtonsData(newButtonsData);
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedUserInfo(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedProfileEmail !== '') {
      dispatch(getSelectedUserInfo({ email: selectedProfileEmail }));
    }
  }, [selectedProfileEmail]);

  return (
    <Box position="relative">
      <LockedAmountPopup
        isOpen={isLockedAmountPopupOppened}
        closePopup={() => {
          setLockedAmountPopupOppened(false);
          setUnlockId(0);
        }}
        id={unlockId}
      />
      {profilePage && <LoadingProfilePage handleReturn={handleReturn} userInfo={selectedUserInfo} />}
      <Box className={profilePage ? 'hidden' : ''}>
        <TableWrapper
          onUpdateItems={onUpdateItems}
          items={items}
          columns={columns}
          defaultFilename="confirmations"
          columnComparators={comparators}
          viewProfile={(email: string) => setProfile(email)}
          buttonsForCells={buttonsData}
          tableType={TableType.Standart}
          fixDownloadButton
        />
      </Box>
    </Box>
  );
};
