import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, Stack, styled, Typography } from '@mui/material';
import { BackgroundWrapper } from 'components';
import { COLOR_TEXT_WHITE_100 } from 'theme';

const StyledList = styled(List)(({ theme }) => ({
  color: COLOR_TEXT_WHITE_100,
  listStyleType: 'disc',
  paddingLeft: theme.spacing(6),
  '& .MuiListItem-root': {
    display: 'list-item',
    fontSize: 18,
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
}));
export const PrivacyPolicy: FC = () => {
  const { t } = useTranslation();

  return (
    <BackgroundWrapper
      container
      sx={{
        minHeight: '100vh',
      }}
    >
      <Box pt={12} pb={5.5}>
        <Typography
          variant="h1"
          textTransform="uppercase"
          textAlign="center"
          className="gradient s"
          mb={{ xs: 4, md: 8 }}
        >
          {t('privacyPolicy.title')}
        </Typography>

        <Stack spacing={2}>
          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.generalProvisions`)}
          </Typography>
          <Typography className="m"> {t(`privacyPolicy.generalProvisions1.1`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.generalProvisions1.2`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.generalProvisions1.3`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.generalProvisions1.4`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.termsAndAcceptedAbbreviations`)}
          </Typography>
          <Typography className="m"> {t(`privacyPolicy.termsAndAcceptedAbbreviations2.1`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.termsAndAcceptedAbbreviations2.2`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.termsAndAcceptedAbbreviations2.3`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.termsAndAcceptedAbbreviations2.4`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.termsAndAcceptedAbbreviations2.5`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.legalGrounds`)}
          </Typography>
          <Typography className="m"> {t(`privacyPolicy.legalGrounds3.1`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.legalGrounds3.2`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.legalGrounds3.3`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.categoriesOfPersonalData`)}
          </Typography>
          <Typography className="m"> {t(`privacyPolicy.categoriesOfPersonalData4.1`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.categoriesOfPersonalData4.2`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.categoriesOfPersonalData4.3`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.informationExchange`)}
          </Typography>
          <Typography variant="h4" className="m white">
            {t(`privacyPolicy.informationExchange5.1`)}
          </Typography>
          <StyledList>
            <ListItem>{t(`privacyPolicy.informationExchange5.1_1`)}</ListItem>
            <ListItem>{t(`privacyPolicy.informationExchange5.1_2`)}</ListItem>
            <ListItem>{t(`privacyPolicy.informationExchange5.1_3`)}</ListItem>
            <ListItem>{t(`privacyPolicy.informationExchange5.1_4`)}</ListItem>
            <ListItem>{t(`privacyPolicy.informationExchange5.1_5`)}</ListItem>
          </StyledList>
          <Typography className="m"> {t(`privacyPolicy.informationExchange5.2`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.personalData`)}
          </Typography>
          <Typography className="m"> {t(`privacyPolicy.personalData6.1`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.personalData6.2`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.personalData6.3`)}</Typography>
          <Typography variant="h4" className="m white">
            {t(`privacyPolicy.personalData6.4`)}
          </Typography>
          <StyledList>
            <ListItem>{t(`privacyPolicy.personalData6.4_1`)}</ListItem>
            <ListItem>{t(`privacyPolicy.personalData6.4_2`)}</ListItem>
            <ListItem>{t(`privacyPolicy.personalData6.4_3`)}</ListItem>
            <ListItem>{t(`privacyPolicy.personalData6.4_4`)}</ListItem>
            <ListItem>{t(`privacyPolicy.personalData6.4_5`)}</ListItem>
            <ListItem>{t(`privacyPolicy.personalData6.4_6`)}</ListItem>
            <ListItem>{t(`privacyPolicy.personalData6.4_7`)}</ListItem>
          </StyledList>

          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.principlesAndConditions`)}
          </Typography>
          <Typography className="m"> {t(`privacyPolicy.principlesAndConditions7.1`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.principlesAndConditions7.2`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.principlesAndConditions7.3`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.principlesAndConditions7.4`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.principlesAndConditions7.5`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.rightsAndObligations`)}
          </Typography>
          <Typography className="m"> {t(`privacyPolicy.rightsAndObligations8.1`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.rightsAndObligations8.2`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.featuresOfProcessing`)}
          </Typography>
          <Typography className="m"> {t(`privacyPolicy.featuresOfProcessing9.1`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.featuresOfProcessing9.2`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.cookiePolicy`)}
          </Typography>
          <Typography className="m"> {t(`privacyPolicy.cookiePolicy10.1`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.cookiePolicy10.2`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.cookiePolicy10.3`)}</Typography>
          <Typography variant="h4" className="m white">
            {t(`privacyPolicy.cookiePolicy10.4`)}
          </Typography>
          <StyledList>
            <ListItem>{t(`privacyPolicy.cookiePolicy10.4_1`)}</ListItem>
            <ListItem>{t(`privacyPolicy.cookiePolicy10.4_2`)}</ListItem>
          </StyledList>
          <Typography className="m"> {t(`privacyPolicy.cookiePolicy10.5`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.cookiePolicy10.6`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.cookiePolicy10.7`)}</Typography>
          <Typography className="m"> {t(`privacyPolicy.cookiePolicy10.8`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`privacyPolicy.finalProvisions`)}
            <Typography className="m"> {t(`privacyPolicy.finalProvisions11.1`)}</Typography>
            <Typography className="m"> {t(`privacyPolicy.finalProvisions11.2`)}</Typography>
            <Typography className="m"> {t(`privacyPolicy.finalProvisions11.3`)}</Typography>
            <Typography className="m"> {t(`privacyPolicy.finalProvisions11.4`)}</Typography>
            <Typography className="m"> {t(`privacyPolicy.finalProvisions11.5`)}</Typography>
          </Typography>
        </Stack>
      </Box>
    </BackgroundWrapper>
  );
};
