import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button } from '@mui/material';

import { useShallowSelector } from '../../../../../hooks';
import { drop2fa } from '../../../../../store/dashboard/actions';
import { Popup } from '../../../components/Popups';

export const DropPopup = ({ close, email }: { close: () => void; email: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Popup isOpen closePopup={close} header={t('dashboard.profile.drop.title')} height="448px" width="448px">
      <div className="text-center" style={{ marginTop: '24px', maxHeight: 'calc(100vh - 160px)' }}>
        <ErrorIcon sx={{ color: '#4DA3FF', fontSize: '128px' }} />
      </div>
      <div className="text-center text-white font-light text-2xl mt-8">{t('dashboard.profile.drop.description')}</div>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          padding: '10px',
          marginTop: '10px',
        }}
      >
        <Button
          sx={{ borderRadius: '10px', marginTop: '24px', marginRight: '28px' }}
          onClick={() => {
            dispatch(drop2fa({ email }));
            close();
          }}
        >
          {t('dashboard.profile.yes')}
        </Button>
        <Button sx={{ borderRadius: '10px', marginTop: '24px' }} onClick={close}>
          {t('dashboard.profile.no')}
        </Button>
      </Box>
    </Popup>
  );
};
