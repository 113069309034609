/* eslint-disable @typescript-eslint/no-explicit-any */
import { sha256 } from 'js-sha256';
import { Project } from 'modules/dashboard/components';
import { error, request, signup, success } from 'store/game22/actions';
import { baseApi } from 'store/game22/apiRequestBuilder';
import { baseApi as baseApiReferral } from 'store/referral/apiRequestBuilder';
import { updateReferralState } from 'store/referral/reducer';
import { call, put, takeLatest } from 'typed-redux-saga';
import { errorToastMessage, getToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { setEmail } from '../reducer';

export function* signupSaga({
  type,
  payload: { email, password, confirm_password: repeatedPassword, referral_code: referralCode },
}: ReturnType<typeof signup>) {
  yield* put(request(type));

  try {
    const hashedPassword: string = sha256(password);
    yield* call(
      baseApiReferral.signup,
      {
        email,
        password: hashedPassword,
        referralCode,
      },
      Project.GAME22,
    );
    yield* call(baseApi.signup, {
      email,
      password,
      confirm_password: repeatedPassword,
      referral_code: referralCode,
    });
    yield* put(
      updateReferralState({
        email,
      }),
    );
    yield* put(setEmail(email));
    yield* put(success(type));
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(err);
    if (err && err.response && err.response.data) {
      errorToastMessage(err);
    } else {
      getToastMessage('error', 'Error creating game22 account');
    }
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SIGNUP, signupSaga);
}
