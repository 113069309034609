import { createAction } from '@reduxjs/toolkit';
import { Game22RegistrationCodeReq, Game22RegistrationConfirmationReq, Game22RegistrationReq } from 'types/requests';

import actionTypes from './actionTypes';

export const signup = createAction<Game22RegistrationReq>(actionTypes.SIGNUP);
export const sendCode = createAction<Game22RegistrationConfirmationReq>(actionTypes.CONFIRM_EMAIL);
export const requestRegistrationCode = createAction<Game22RegistrationCodeReq>(actionTypes.REQUEST_REGISTRATION_CODE);

export const request = <T = string, P = never>(defaultType: T, payload?: P) => ({
  type: `${defaultType}_REQUEST`,
  payload,
});

export const success = <T = string, P = never>(defaultType: T, payload?: P) => ({
  type: `${defaultType}_SUCCESS`,
  payload,
});

export const error = <T = string, E = never>(defaultType: T, err?: E) => ({
  type: `${defaultType}_ERROR`,
  payload: err,
  err,
});

export const reset = <T = string>(defaultType: T) => ({
  type: `${defaultType}_RESET`,
});
