import { FC } from 'react';
import { BoxProps, Stack, Typography } from '@mui/material';
import { ArrowDownIcon, ArrowUpIcon } from 'modules/dashboard/components';
import { FontFamilies } from 'theme';

import { ProjectWindow } from '../Popups/Components/InfoPopup/ProjectWindow';

type TableColumnProps = {
  name: string;
  setActiveColumn: () => void;
  setAscSort: () => void;
  setDescSort: () => void;
  fontSize?: number;
  dropDownParams?: DropDownParams;
} & BoxProps;

type DropDownParams = {
  selectedOption: string;
  setSelectedOption: (option: string) => void;
};

export const Project = Object.freeze({
  R48: 'R48',
  GAME22: 'Game22',
  DESKTOP: 'DeckStop',
  FLUSH: 'Flush',
  BANK: 'Bank',
  ALL: 'All',
});

export const TableColumn: FC<TableColumnProps> = ({
  name,
  setActiveColumn,
  setAscSort,
  setDescSort,
  sx,
  fontSize = 20,
  dropDownParams = null,
}) => {
  const upClick = () => {
    setActiveColumn();
    setAscSort();
  };
  const downClick = () => {
    setActiveColumn();
    setDescSort();
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={sx}>
      <Typography
        sx={{
          color: '#646778',
          font: FontFamilies.secondary,
          fontSize: { fontSize },
        }}
      >
        {name}
      </Typography>
      {dropDownParams ? (
        <Stack spacing={1} sx={{ p: '3px', width: '10px', position: 'relative' }}>
          <ProjectWindow
            label=""
            options={Object.values(Project)}
            minWidth={115}
            selectedOption={dropDownParams.selectedOption}
            setSelectedOption={dropDownParams.setSelectedOption}
            useWhiteArrow={false}
            sx={{ position: 'absolute', right: -18, top: -18 }}
          />
        </Stack>
      ) : (
        <Stack spacing={1} sx={{ p: '3px' }}>
          <ArrowUpIcon onClick={upClick} sx={{ '&:hover': { cursor: 'pointer' } }} />
          <ArrowDownIcon onClick={downClick} sx={{ '&:hover': { cursor: 'pointer' } }} />
        </Stack>
      )}
    </Stack>
  );
};
