import { fork } from 'redux-saga/effects';

import requestRegistrationCodeSaga from './requestRegistrationCode';
import signup from './signup';
import sendCode from './verifyRegistration';

export default function* game22Sagas() {
  yield fork(signup);
  yield fork(sendCode);
  yield fork(requestRegistrationCodeSaga);
}
