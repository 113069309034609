import { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, BoxProps, Dialog, IconButton, PaperProps, Typography, TypographyProps } from '@mui/material';
import { CrossInRound } from 'components/Icon/components';
import { BackButtonIcon, BackButtonSmall } from 'modules/dashboard/components';
import { BG_BLACK_OPACITY, BG_GRAY_MODAL, COLOR_TEXT_WHITE_100, TRANSITION_DEFAULT_TIME } from 'theme';

export type ModalProps = {
  open: boolean;
  onClose: () => void;
  closable?: boolean;
  title?: string | ReactNode;
  sx?: PaperProps['sx'];
  contentSx?: BoxProps['sx'];
  titleSx?: TypographyProps['sx'];
  useBackButton?: boolean;
} & PropsWithChildren;

export const Modal: FC<ModalProps> = ({
  open,
  onClose,
  closable = true,
  title,
  children,
  sx,
  contentSx,
  titleSx,
  useBackButton = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      transitionDuration={{
        enter: 250,
        exit: 100,
      }}
      PaperProps={{ sx }}
    >
      <Box sx={{ position: 'relative', p: 3 }}>
        {closable && (
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 24,
              top: 24,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              transition: TRANSITION_DEFAULT_TIME,
            }}
          >
            {!useBackButton ? (
              <CrossInRound
                sx={{
                  height: {
                    xs: 22,
                    md: 34,
                  },
                  width: {
                    xs: 22,
                    md: 34,
                  },
                  transition: TRANSITION_DEFAULT_TIME,
                  '&:hover': { color: BG_BLACK_OPACITY, transform: 'rotate(90deg)' },
                }}
              />
            ) : (
              <BackButtonSmall />
            )}
          </IconButton>
        )}
        {title && (
          <Typography variant="h3" color={COLOR_TEXT_WHITE_100} pr={6} sx={titleSx}>
            {title}
          </Typography>
        )}
        <Box
          sx={{
            background: BG_GRAY_MODAL,
            ...contentSx,
          }}
        >
          {children}
        </Box>
      </Box>
    </Dialog>
  );
};
