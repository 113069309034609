import { Dispatch } from 'react';
import { IsValidPassword } from 'modules/game22/components';
import { clearErrors, setError } from 'store/referral/reducer';
import { ReferralError } from 'types/referral';

export const validatePassword = (
  password: string,
  repeatedPassword: string,
  passwordKey: ReferralError['source'],
  repeatedPasswordKey: ReferralError['source'],
  rulesKey: ReferralError['source'],
  dispatch: Dispatch<any>,
) => {
  dispatch(clearErrors());
  if (password !== repeatedPassword) {
    dispatch(setError({ [passwordKey]: 'passwordsNotMatch' }));
    dispatch(setError({ [repeatedPasswordKey]: 'passwordsNotMatch' }));
    return false;
  }
  const isValid = IsValidPassword(password);
  if (!isValid) {
    dispatch(setError({ [rulesKey]: 'passwordRules' }));
  }
  return isValid;
};
