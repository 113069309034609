import { deleteGame, deleteGameFromList, error, request, success } from 'store/dashboard/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

export function* deleteGameSaga({ type, payload: { id } }: ReturnType<typeof deleteGame>) {
  yield* put(request(type, { id }));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      yield* call(baseApi.deleteGame, { id });
      yield* put(deleteGameFromList(id));
      yield* put(success(type));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.DELETE_GAME, deleteGameSaga);
}
