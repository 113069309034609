import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, BoxProps, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { blockUser, demoteAdmin } from 'store/dashboard/actions';
import { FontFamilies, FontWeights } from 'theme';

interface AdminInfoProps {
  email: string;
  isBlocked: boolean;
}

const Email: FC<BoxProps & { email: string; width: string }> = ({ email, width, sx }) => {
  return (
    <Typography
      width={width}
      fontFamily={FontFamilies.secondary}
      fontSize="20px"
      fontWeight={FontWeights.fontWeightMedium}
      color="#4DA3FF"
      sx={sx}
    >
      {email}
    </Typography>
  );
};

export const AdminInfo: FC<AdminInfoProps> = ({ email, isBlocked }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  const buttonStyle = {
    background: 'white',
    height: isSmallScreen ? '35px' : '40px',
    borderRadius: '20px',
    minWidth: isSmallScreen ? '134px' : '154px',
    maxWidth: 'auto',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  };
  const dispatch = useDispatch();

  const handleBlockUserSubmit = (lock: boolean) => {
    dispatch(blockUser({ email, lock }));
  };

  const handleDeleteAdminSubmit = () => {
    dispatch(demoteAdmin({ email }));
  };

  const buttonText = isBlocked ? t('dashboard.settings.addingAdmin.unlock') : t('dashboard.settings.addingAdmin.lock');

  return (
    <Box>
      {isSmallScreen && <Email email={email} width="278px" sx={{ textAlign: 'center', margin: '12px 0px' }} />}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {!isSmallScreen && (
          <Email email={email} width="360px" sx={{ textAlign: 'left', maxWidth: '338px', overflow: 'hidden' }} />
        )}
        <Box
          width={isSmallScreen ? '100%' : 'calc(100% - 360px)'}
          display="flex"
          justifyContent={isSmallScreen ? 'space-between' : 'flex-end'}
        >
          <Button sx={{ ...buttonStyle, marginRight: '10px' }} onClick={handleDeleteAdminSubmit}>
            <Typography
              fontFamily={FontFamilies.secondary}
              fontSize={isSmallScreen ? '15px' : '20px'}
              fontWeight={FontWeights.fontWeightMedium}
              sx={{
                textTransform: 'none',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {t('dashboard.settings.addingAdmin.demote')}
            </Typography>
          </Button>
          <Button sx={buttonStyle} onClick={() => handleBlockUserSubmit(!isBlocked)}>
            <Typography
              fontFamily={FontFamilies.secondary}
              fontSize={isSmallScreen ? '15px' : '20px'}
              fontWeight={FontWeights.fontWeightMedium}
              sx={{ textTransform: 'none' }}
            >
              {buttonText}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          left: 0,
          bottom: 0,
          width: '100%',
          height: '2px',
          backgroundColor: 'white',
          marginTop: isSmallScreen ? '14px' : '7px',
        }}
      />
    </Box>
  );
};
