import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@mui/material';
import { PlusIcon } from 'modules/dashboard/components';
import { StoreAddPopup } from 'modules/dashboard/components/Popups';
import { FontFamilies, FontWeights } from 'theme';

import { DeletePopup } from '../AddingGames';

import { StoresList } from './StoresList';

interface AddingStoreProps {
  gameId: string;
  gameName: string;
  isAdmin: boolean;
}

export const AddingStore: FC<AddingStoreProps> = ({ gameId, gameName, isAdmin }) => {
  const { t } = useTranslation();
  const buttonStyles = {
    marginTop: '24px',
    width: '231px',
    height: '44px',
    padding: '20px',
    borderRadius: '22px',
    gap: '10px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    transition: 'transform 0.15s ease-in-out',
    '&:active': {
      transform: 'scale(0.92)',
    },
  };

  const textStyles = {
    fontFamily: FontFamilies.secondary,
    fontSize: '20px',
    fontWeight: FontWeights.fontWeightMedium,
    color: gameId !== '' ? '#191836' : '#808080',
    textAlign: 'center',
  };

  const [selectedStoreId, setSelectedStoreId] = useState<string | null>(null);
  const [deleting, setDeleting] = useState('');

  const handleSelectStore = (storeId: string) => {
    setSelectedStoreId(storeId);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const closePopup = () => {
    setIsOpen(false);
  };
  const openPopup = (edit: boolean) => {
    setIsEditing(edit);
    setIsOpen(true);
  };

  return (
    <Box sx={{ marginTop: '16px', marginBottom: '38px' }}>
      {isAdmin && (
        <StoreAddPopup
          isOpen={isOpen}
          closePopup={closePopup}
          gameId={gameId}
          isEditing={isEditing}
          storeId={selectedStoreId}
        />
      )}
      {deleting !== '' && <DeletePopup id={deleting} name={gameName} close={() => setDeleting('')} />}
      <StoresList
        gameId={gameId}
        isAdmin={isAdmin}
        openPopup={() => openPopup(true)}
        onSelectStore={handleSelectStore}
      />
      {isAdmin && (
        <div>
          <IconButton sx={buttonStyles} onClick={() => openPopup(false)} disabled={gameId === ''}>
            <PlusIcon width="28px" height="28px" disabled={gameId === ''} />
            <Typography sx={textStyles}>{t('dashboard.settings.addingStore.addStore')}</Typography>
          </IconButton>
          <IconButton
            sx={{ ...buttonStyles, backgroundColor: '#f2c3c9' }}
            onClick={() => setDeleting(gameId)}
            disabled={gameId === ''}
          >
            <Typography sx={textStyles}>{t('dashboard.settings.addingGame.deleteGame')}</Typography>
          </IconButton>
        </div>
      )}
    </Box>
  );
};
