/* eslint-disable jsx-a11y/control-has-associated-label,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';

import './CookiePopup.css';

export const CookiePopup = ({ setCookies }: { setCookies: (cookies: boolean) => void }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div
        className="bg-opacity-100 text-white rounded-xl p-6 transition-opacity duration-200"
        style={{
          backgroundColor: '#333654',
          width: '448px',
          height: expanded ? 'calc(100vh - 64px)' : '448px',
          maxHeight: expanded ? '2368px' : '448px',
        }}
      >
        <div
          className="flex justify-between items-center"
          style={{ marginTop: '22px', marginLeft: '8px', marginRight: '8px', marginBottom: '-16px' }}
        >
          <h2 style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '20pt' }}>{t('cookie.title')}</h2>
        </div>
        <div
          className={`text-center ${expanded ? 'overflow-y-scroll custom-scrollbar' : ''}`}
          style={{ marginTop: '24px', maxHeight: 'calc(100vh - 160px)' }}
        >
          <ErrorIcon sx={{ color: '#4DA3FF', fontSize: '128px' }} />
          <p
            className="mb-4 mt-6"
            style={{ fontFamily: 'TT Norms', fontWeight: 400, fontSize: '18pt', color: 'white' }}
          >
            {t('cookie.subtitle')}
          </p>
          <p
            className="mb-4 text-gray-400"
            style={{ fontFamily: 'Roboto', fontWeight: 'regular', fontSize: '11pt', marginTop: '24px' }}
          >
            {t('cookie.description')}{' '}
            <span
              className="underline cursor-pointer"
              style={{ color: '#4DA3FF' }}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? t('cookie.less') : t('cookie.more')}
            </span>
          </p>
          {expanded && (
            <div className="mt-4 h-[1500px]" style={{ marginTop: '24px', color: 'gray' }}>
              <p>{t('cookie.detail.0')}</p>
              <br />
              <h1 className="font-semibold text-lg">{t('cookie.detail.1')}</h1>
              <p>{t('cookie.detail.2')}</p>
              <br />
              <h1 className="font-semibold text-lg">{t('cookie.detail.3')}</h1>
              <p>{t('cookie.detail.4')}</p>
              <br />
              <h2 className="font-medium">{t('cookie.detail.5')}</h2>
              <p>{t('cookie.detail.6')}</p>
              <br />
              <h2 className="font-medium">{t('cookie.detail.7')}</h2>
              <p>{t('cookie.detail.8')}</p>
              <br />
              <h2 className="font-medium">{t('cookie.detail.9')}</h2>
              <p>{t('cookie.detail.10')}</p>
              <br />
              <h2 className="font-medium">{t('cookie.detail.11')}</h2>
              <p>{t('cookie.detail.12')}</p>
              <br />
              <h1 className="font-bold text-xl">{t('cookie.detail.13')}</h1>
              <p>{t('cookie.detail.14')}</p>
              <br />
              <h1 className="font-bold text-xl">{t('cookie.detail.15')}</h1>
              <p>{t('cookie.detail.16')}</p>
              <br />
              <h1 className="font-bold text-xl">{t('cookie.detail.17')}</h1>
              <p>{t('cookie.detail.18')}</p>
              <br />
              <p>{t('cookie.detail.19')}</p>
            </div>
          )}
          <div className="mt-6 flex justify-center" style={{ marginTop: '24px' }}>
            <button
              type="button"
              className="py-2 px-6 rounded-xl w-52 h-14"
              onClick={() => setCookies(true)}
              style={{
                backgroundColor: '#4DA3FF',
                color: 'white',
                fontFamily: 'TT Norms',
                fontWeight: 500,
                fontSize: '16pt',
              }}
            >
              {t('cookie.button')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
