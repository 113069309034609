import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, styled } from '@mui/material';
import BigNumber from 'bignumber.js/bignumber';
import { Card, Icon, UserBalance } from 'components';
import { useValidateInputField, ValidationTypes } from 'hooks';
import { useFormatTokensListReturnType, useGetConditionals } from 'modules/crowdsale/hooks';
import { getCrowdsaleData, getTokensBalances, onBuyToken, onClaim, onRefund } from 'store/crowdsale/actions';
import {
  BG_CARD_LIGHT_TRANSPARENT,
  BORDER_DEFAULT_GRAY,
  BORDER_RADIUS_PROGRESS_BAR,
  COLOR_TEXT_WHITE_100,
} from 'theme';
import { CrowdsaleDataProps, TokensBalancesProps } from 'types';
import { flexHelper, toAmountWithPrice } from 'utils';
import Web3 from 'web3';

import { BuyTextFields, HeaderInfo, TokensTabs } from './components';

const BackgroundFormStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5, 3.7, 3.7),
  width: '100%',
  minHeight: 473,
  flexDirection: 'column',
  ...flexHelper('flex-start'),
  background: BG_CARD_LIGHT_TRANSPARENT,
  borderBottomLeftRadius: BORDER_RADIUS_PROGRESS_BAR,
  borderBottomRightRadius: BORDER_RADIUS_PROGRESS_BAR,
  border: BORDER_DEFAULT_GRAY,
  borderTop: 'none',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2.5, 2, 3.7),
  },
}));

export type BuyTokenFormProps = {
  userAddress: string;
  crowdsalleContractAddress: string;
  crowdsaleData: CrowdsaleDataProps;
  tyzTokenBalance: string;
  availableTokensOnCrowdsale: string;
  currentTYZTokenPrice: string;
  tokensList: useFormatTokensListReturnType;
  tokensBalances: TokensBalancesProps;
  web3Provider: Web3;
  onOpenConnectModal: () => void;
  isBuyingToken: boolean;
  isClaiming: boolean;
  isRefunding: boolean;
  isTokensListLoading: boolean;
  isBoughtSuccess: boolean;
  isCrowdsaleDataLoading: boolean;
};

export const BuyTokenForm: FC<BuyTokenFormProps> = ({
  userAddress,
  crowdsalleContractAddress,
  crowdsaleData,
  tyzTokenBalance,
  availableTokensOnCrowdsale,
  currentTYZTokenPrice,
  tokensList,
  tokensBalances,
  web3Provider,
  onOpenConnectModal,
  isBuyingToken,
  isClaiming,
  isRefunding,
  isTokensListLoading,
  isBoughtSuccess,
  isCrowdsaleDataLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeSendTokenNumber, setActiveSendTokenNumber] = useState(0);

  const { isAllowToClaim, isAllowToRefund } = useGetConditionals(
    +crowdsaleData.totalBought,
    +crowdsaleData.softCap,
    +crowdsaleData.saleEndTime,
    +tyzTokenBalance,
  );

  const currentSendToken = tokensList[activeSendTokenNumber];
  const currentSendTokenBalance = tokensBalances[tokensList[activeSendTokenNumber]?.symbol] || '0';
  const isCrowdsaleExpired = Date.now() > +crowdsaleData.saleEndTime * 1000;

  const [sendValue, setSendValue, setOriginSendValue] = useValidateInputField({
    maxValue: +currentSendTokenBalance,
    type: ValidationTypes.number,
    decimals: currentSendToken.decimals,
  });

  const [receiveValue, setReceiveValue, setOriginReceiveValue] = useValidateInputField({
    type: ValidationTypes.number,
  });

  const handleChangeActiveSendToken = (newValue: number) => {
    setActiveSendTokenNumber(newValue);
    setOriginSendValue('');
    setOriginReceiveValue('');
  };

  const handleSetMaxSendValue = useCallback(() => {
    const myBalanceInTyz = toAmountWithPrice(currentSendTokenBalance, currentSendToken?.value, currentTYZTokenPrice);

    if (new BigNumber(availableTokensOnCrowdsale).isLessThan(myBalanceInTyz)) {
      setOriginSendValue(
        toAmountWithPrice(
          availableTokensOnCrowdsale,
          currentTYZTokenPrice,
          currentSendToken?.value,
          currentSendToken?.decimals,
          'string',
        ) as string,
      );

      setOriginReceiveValue(String(availableTokensOnCrowdsale));
      return;
    }

    setOriginSendValue(String(currentSendTokenBalance));
    setOriginReceiveValue(
      toAmountWithPrice(currentSendTokenBalance, currentSendToken?.value, currentTYZTokenPrice, 18, 'string') as string,
    );
  }, [
    availableTokensOnCrowdsale,
    currentSendToken?.decimals,
    currentSendToken?.value,
    currentSendTokenBalance,
    currentTYZTokenPrice,
    setOriginReceiveValue,
    setOriginSendValue,
  ]);

  const handleBuyToken = () => {
    dispatch(
      onBuyToken({
        web3Provider,
        sendValue,
        receiveValue,
        sendingTokenAddress: currentSendToken?.address,
        maxSendBalance: currentSendTokenBalance,
        currentSendTokenValue: currentSendToken?.value,
        currentTYZTokenPrice,
      }),
    );
  };

  const handleClaim = () => {
    dispatch(onClaim({ web3Provider }));
  };

  const handleRefund = () => {
    dispatch(onRefund({ web3Provider }));
  };

  useEffect(() => {
    if (crowdsalleContractAddress.length) {
      dispatch(getCrowdsaleData({}));
    }
  }, [crowdsalleContractAddress.length, dispatch]);

  useEffect(() => {
    if (tokensList.length && userAddress.length && crowdsalleContractAddress.length) {
      dispatch(getTokensBalances({ web3Provider }));
    }
  }, [crowdsalleContractAddress.length, dispatch, tokensList.length, userAddress.length, web3Provider]);

  useEffect(() => {
    if (isBoughtSuccess) {
      setOriginSendValue('');
      setOriginReceiveValue('');
    }
  }, [isBoughtSuccess, setOriginReceiveValue, setOriginSendValue]);

  return (
    <Box sx={{ pb: 12, width: '100%', flexDirection: 'column', ...flexHelper('flex-start') }}>
      <Card
        type="lightTransparent"
        sx={{
          p: 0,
          width: { xs: '100%', sm: '100%', md: 848 },
          minHeight: 770,
          background: 'transparent',
          borderRadius: BORDER_RADIUS_PROGRESS_BAR,
        }}
      >
        <HeaderInfo {...crowdsaleData} isLoading={isCrowdsaleDataLoading} />

        <BackgroundFormStyled>
          <TokensTabs
            activeSendToken={activeSendTokenNumber}
            onChangeActiveSendToken={handleChangeActiveSendToken}
            tokensList={tokensList}
            isLoading={isTokensListLoading}
          />

          <UserBalance
            userAddress={userAddress}
            isActive={crowdsaleData.isActive}
            balance={currentSendTokenBalance}
            icon={currentSendToken?.image}
            ticker={currentSendToken?.symbol}
            onSetMax={handleSetMaxSendValue}
            isLoading={!userAddress.length}
            sx={{
              mt: { xs: 2, sm: 2, md: 4 },
              mb: 1,
            }}
          />
          <BuyTextFields
            userAddress={userAddress}
            activeSendToken={currentSendToken}
            sendValue={sendValue}
            receiveValue={receiveValue}
            availableTokensOnCrowdsale={availableTokensOnCrowdsale}
            // ----
            onChangeSendValue={setSendValue}
            onChangeReceiveValue={setReceiveValue}
            onSetOriginSendValue={setOriginSendValue}
            onSetOriginReceiveValue={setOriginReceiveValue}
            //----
            sendingBalance={currentSendTokenBalance}
            tyzTokenPrice={currentTYZTokenPrice}
            isCrowdsaleExpired={isCrowdsaleExpired}
            isBuyingToken={isBuyingToken}
            isCrowdsaleActive={crowdsaleData.isActive}
          />
          <UserBalance balance={tyzTokenBalance} sx={{ mt: 1, pb: 3.5 }} isLoading={!userAddress.length} />

          {userAddress.length ? (
            <Grid item container justifyContent="space-between" alignItems="center" xs={12} columnSpacing={1}>
              {!isAllowToRefund && (
                <Grid item xs={isAllowToClaim ? 6 : 12}>
                  <LoadingButton
                    size="large"
                    fullWidth
                    disabled={sendValue === '' || receiveValue === '' || isCrowdsaleExpired}
                    loading={isBuyingToken}
                    onClick={handleBuyToken}
                  >
                    {t('buyTokenPage.buttonBuy')}
                  </LoadingButton>
                </Grid>
              )}
              {isAllowToClaim && (
                <Grid item xs={6}>
                  <LoadingButton size="large" variant="outlined" fullWidth loading={isClaiming} onClick={handleClaim}>
                    {t('buyTokenPage.buttonClaim')}
                  </LoadingButton>
                </Grid>
              )}
              {isAllowToRefund && (
                <Grid item xs={12}>
                  <LoadingButton size="large" variant="outlined" fullWidth loading={isRefunding} onClick={handleRefund}>
                    {t('buyTokenPage.buttonRefund')}
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          ) : (
            <Button
              size="large"
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<Icon.WalletIcon />}
              onClick={onOpenConnectModal}
              sx={{ '&:hover': { svg: { path: { fill: COLOR_TEXT_WHITE_100 } } } }}
            >
              {t('header.connectWallet.buttonTitle')}
            </Button>
          )}
        </BackgroundFormStyled>
      </Card>
    </Box>
  );
};
