import { sha256 } from 'js-sha256';
import { handleBackendErrors } from 'modules/referral/utils';
import { error, request, signup, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';

import actionTypes from '../actionTypes';
import { setError, updateReferralState } from '../reducer';

export function* signupSaga({ type, payload: { email, password, referralCode } }: ReturnType<typeof signup>) {
  yield* put(request(type));

  try {
    const hashedPassword: string = sha256(password);
    yield* call(baseApi.signup, {
      email,
      password: hashedPassword,
      referralCode,
    });
    yield* put(
      updateReferralState({
        email,
      }),
    );
    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    yield* put(error(type));
    const parsedError = handleBackendErrors(err);
    if (parsedError) {
      yield* put(setError({ [parsedError.source]: parsedError.type }));
    }
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SIGNUP, signupSaga);
}
