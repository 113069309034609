import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const WhitePlusIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 34 34"
    sx={{
      width: '34px',
      height: '34px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 10.6V17M17 17V23.4M17 17L23.4 17M17 17L10.6 17M33 17C33 25.8366 25.8366 33 17 33C8.16344 33 1 25.8366 1 17C1 8.16344 8.16344 1 17 1C25.8366 1 33 8.16344 33 17Z"
      stroke="#F0F4FB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
