import { ChangeEvent, ChangeEventHandler, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { SelectedToken } from 'components';
import { RateTokenProps } from 'modules/crowdsale/hooks';
import { BORDER_COLOR_INPUT_GRAY_DEFAULT } from 'theme';
import { flexHelper, toAmountWithPrice } from 'utils';

type BuyTextFieldsProps = {
  userAddress: string;
  activeSendToken: RateTokenProps;
  sendValue: string;
  receiveValue: string;
  availableTokensOnCrowdsale: string;

  onChangeSendValue: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onChangeReceiveValue: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onSetOriginSendValue: (originValue: string) => void;
  onSetOriginReceiveValue: (originValue: string) => void;
  sendingBalance: string;
  tyzTokenPrice: string;
  isCrowdsaleExpired: boolean;
  isBuyingToken: boolean;
  isCrowdsaleActive: boolean;
};

export const BuyTextFields: FC<BuyTextFieldsProps> = ({
  userAddress,
  activeSendToken,
  sendValue,
  receiveValue,
  availableTokensOnCrowdsale,
  onChangeSendValue,
  onChangeReceiveValue,
  onSetOriginSendValue,
  onSetOriginReceiveValue,
  sendingBalance,
  tyzTokenPrice,
  isCrowdsaleExpired,
  isBuyingToken,
  isCrowdsaleActive,
}) => {
  const { t } = useTranslation();
  const isInputDisabled = !userAddress.length || isCrowdsaleExpired || isBuyingToken || !isCrowdsaleActive;

  const handleChangeSendValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    const maxValue = !(
      toAmountWithPrice(sendingBalance, activeSendToken?.value, tyzTokenPrice, activeSendToken.decimals) as BigNumber
    ).isLessThan(new BigNumber(availableTokensOnCrowdsale))
      ? toAmountWithPrice(
          availableTokensOnCrowdsale,
          tyzTokenPrice,
          activeSendToken?.value,
          activeSendToken.decimals,
          'string',
        )
      : Number(sendingBalance);
    const minValue = new BigNumber(1).dividedBy(new BigNumber(10).pow(activeSendToken.decimals)).toString();
    if (maxValue < Number(value)) {
      return;
    }
    if (new BigNumber(minValue).isGreaterThan(value)) {
      return;
    }

    onChangeSendValue(event);

    if (!value.length) {
      onSetOriginSendValue('');
      onSetOriginReceiveValue('');
      return;
    }

    if (!Number(value) || (value.includes('.') && !value.split('.')[1].length)) {
      return;
    }

    onSetOriginReceiveValue(toAmountWithPrice(value, activeSendToken?.value, tyzTokenPrice, 18, 'string') as string);
  };

  const handleChangeReceiveValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    const maxValue = (toAmountWithPrice(sendingBalance, activeSendToken?.value, tyzTokenPrice) as BigNumber).isLessThan(
      new BigNumber(availableTokensOnCrowdsale),
    )
      ? toAmountWithPrice(sendingBalance, activeSendToken?.value, tyzTokenPrice, 18, 'number')
      : Number(availableTokensOnCrowdsale);
    const minValue = new BigNumber(1).dividedBy(new BigNumber(10).pow(18)).toString();

    if (maxValue < Number(value)) {
      return;
    }
    if (new BigNumber(minValue).isGreaterThan(value)) {
      return;
    }

    onChangeReceiveValue(event);

    if (!value.length) {
      onSetOriginReceiveValue('');
      onSetOriginSendValue('');
      return;
    }

    if (value.includes('.') && +value.split('.')[1] === 0) {
      onSetOriginSendValue('');
      return;
    }

    if (!Number(value) || (value.includes('.') && !value.split('.')[1].length)) {
      return;
    }

    onSetOriginSendValue(
      toAmountWithPrice(value, tyzTokenPrice, activeSendToken?.value, activeSendToken.decimals, 'string') as string,
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        flexDirection: 'column',
        ...flexHelper(),
      }}
    >
      <TextField
        value={sendValue}
        onChange={handleChangeSendValue}
        variant="outlined"
        placeholder="0.00"
        fullWidth
        disabled={isInputDisabled}
        InputProps={{
          sx: {
            borderBottom: `1px solid ${BORDER_COLOR_INPUT_GRAY_DEFAULT}`,
            borderBottomLeftRadius: '0 !important',
            borderBottomRightRadius: '0 !important',
          },
          startAdornment: (
            <InputAdornment position="start">
              <Typography variant="body2" className="white" fontSize={{ xs: 12, sm: 12, md: 16 }}>
                {t('buyTokenPage.send')}
              </Typography>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <SelectedToken symbol={activeSendToken?.symbol} icon={activeSendToken?.image} />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        value={receiveValue}
        onChange={handleChangeReceiveValue}
        variant="outlined"
        placeholder="0.00"
        fullWidth
        disabled={isInputDisabled}
        InputProps={{
          sx: {
            borderTop: `1px solid ${BORDER_COLOR_INPUT_GRAY_DEFAULT}`,
            marginTop: '-3.55px',
            borderTopLeftRadius: '0 !important',
            borderTopRightRadius: '0 !important',
          },
          startAdornment: (
            <InputAdornment position="start">
              <Typography variant="body2" className="white" fontSize={{ xs: 12, sm: 12, md: 16 }}>
                {t('buyTokenPage.receive')}
              </Typography>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <SelectedToken />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
