export * from './routes';
export { default as URL } from './URL';
export * from './resCodes';
export * from './validation';

export const isMainnet = false;
export const baseReferralURL = isMainnet ? 'https://papi.tyz.network/' : 'https://test.tyz.network/';
// export const baseReferralURL = isMainnet ? 'https://papi.tyz.network/' : 'http://localhost:8000/';
export const BNB_TOKEN_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const HIGH_GAS_PRIRCE = 100_180;
export const RECAPTCHA_SITE_KEY_TESTNET = '6LexhGUiAAAAAGXXb5wvwpvF0ybrDAQgyNUpIlJ5'; // LeB_WEiAAAAAFB-WUf9Ru_BXMxfzPU9IihLqbqq - tim key
export const RECAPTCHA_SITE_KEY_MAINNET = '6LexhGUiAAAAAGXXb5wvwpvF0ybrDAQgyNUpIlJ5';
