import { useState } from 'react';

import { UserList } from '../../components';

import { AdminTree } from './AdminTree';

export const NetworkAdmin = ({ openProfile }: { openProfile: (email: string) => void }) => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  return (
    <div className="p-10 phone:p-0 phone:pt-10 phone:pb-5 rounded-lg flex flex-col">
      {selectedUser && (
        <AdminTree user={selectedUser} close={() => setSelectedUser(null)} openProfile={openProfile} info />
      )}
      <div className={selectedUser ? 'hidden' : ''}>
        <UserList selectUser={setSelectedUser} />
      </div>
    </div>
  );
};
