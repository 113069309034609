import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Admin,
  AdminPaymentHistory,
  AdminWithdrawalHistory,
  ConfirmationItem,
  DashboardState,
  Game,
  Note,
  PaymentInfo,
  RawWithdrawal,
  SelectedUserInfo,
  Store,
  User,
  WithdrawalLimits,
} from 'types/store/dashboard';

import {
  addAdminToList,
  addGameToList,
  addNoteToList,
  addStoreToList,
  blockAdminFromList,
  clearSelectedUserInfo,
  deleteConfirmationFromList,
  deleteGameFromList,
  deleteStoreFromList,
  editStoreFromList,
  editWithdrawalLimits,
  onDemoteAdmin,
  onDrop2fa,
  onPromoteAdmin,
  onUpdateAvatar,
  onUpdateMaxWithdrawal,
  onUpdateMinWithdrawal,
  onUpdateWithdrawalPeriod,
  onUserStatusChanged,
  setIsFetchingUser,
  setProfileInfo,
  setSwapExceeded,
  setWalletInUse,
  updateCachedUsers,
  updateNewAdmin,
  updateSelectedUserBalance,
} from './actions';

const initialState: DashboardState = {
  admin: null,
  adminPaymentHistory: null,
  admins: null,
  adminWithdrawalHistory: null,
  authToken: '',
  games: null,
  paymentsHistory: null,
  profile: null,
  profileInfo: { notes: [] },
  selectedUserInfo: null,
  isFetchingUser: false,
  cachedUsers: [],
  stores: null,
  withdrawalLimits: { withdrawalPeriod: 10, maxWithdrawal: 10, minWithdrawal: 10 },
  withdrawals: null,
  confirmations: [],
  swapExceeded: false,
  walletInUse: false,
};

export const dashboardReducer = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateGamesList: (state, action: PayloadAction<Game[]>) => {
      return {
        ...state,
        games: action.payload,
      };
    },
    updateStoresList: (state, action: PayloadAction<Store[]>) => {
      return {
        ...state,
        stores: action.payload,
      };
    },
    updateAdminsList: (state, action: PayloadAction<Admin[]>) => {
      return {
        ...state,
        admins: action.payload,
      };
    },
    updateWithdrawalLimits: (state, action: PayloadAction<WithdrawalLimits>) => {
      return {
        ...state,
        withdrawalLimits: action.payload,
      };
    },
    setWithdrawals: (state, action: PayloadAction<RawWithdrawal[]>) => {
      return {
        ...state,
        withdrawals: action.payload,
      };
    },
    updateNotesList: (state, action: PayloadAction<Note[]>) => {
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          notes: action.payload,
        },
      };
    },
    updateUsersList: (state, action: PayloadAction<User[]>) => {
      return {
        ...state,
        users: action.payload,
      };
    },
    updatePaymentsHistory: (state, action: PayloadAction<PaymentInfo[]>) => {
      return {
        ...state,
        paymentsHistory: action.payload,
      };
    },
    updateConfirmations: (state, action: PayloadAction<ConfirmationItem[]>) => {
      return {
        ...state,
        confirmations: action.payload,
      };
    },

    updateAdminPaymentsHistory: (state, action: PayloadAction<AdminPaymentHistory>) => {
      return {
        ...state,
        adminPaymentHistory: action.payload,
      };
    },
    updateAdminWithdrawalHistory: (state, action: PayloadAction<AdminWithdrawalHistory>) => {
      return {
        ...state,
        adminWithdrawalHistory: action.payload,
      };
    },
    setSelectedUserInfo: (state, action: PayloadAction<SelectedUserInfo | null>) => {
      return {
        ...state,
        selectedUserInfo: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addStoreToList, (state, action: PayloadAction<Store>) => {
        if (state.stores) {
          state.stores.push(action.payload);
        }
      })
      .addCase(deleteStoreFromList, (state, action: PayloadAction<string>) => {
        if (state.stores) {
          state.stores = state.stores.filter((store) => store.id !== action.payload);
        }
      })
      .addCase(editStoreFromList, (state, action: PayloadAction<Store>) => {
        if (state.stores) {
          state.stores = state.stores.map((store) => (store.id === action.payload.id ? action.payload : store));
        }
      })
      .addCase(addAdminToList, (state, action: PayloadAction<Admin>) => {
        if (state.admins) {
          state.admins.push(action.payload);
          state.admin = action.payload;
        }
      })
      .addCase(onDemoteAdmin, (state, action: PayloadAction<string>) => {
        if (state.admins) {
          state.admins = state.admins.filter((admin) => admin.email !== action.payload);
        }
        if (state.cachedUsers) {
          const user = state.cachedUsers.find((u) => u.user.email === action.payload);
          if (user) {
            user.user.role = 'user';
          }
        }
        if (state.selectedUserInfo && state.selectedUserInfo.user.email === action.payload) {
          state.selectedUserInfo.user.role = 'user';
        }
      })
      .addCase(onPromoteAdmin, (state, action: PayloadAction<Admin>) => {
        const adminEmail = action.payload.email;
        if (state.admins) {
          state.admins.push(action.payload);
        }
        if (state.cachedUsers) {
          const user = state.cachedUsers.find((u) => u.user.email === adminEmail);
          if (user) {
            user.user.role = 'admin';
          }
        }
        if (state.selectedUserInfo && state.selectedUserInfo.user.email === adminEmail) {
          state.selectedUserInfo.user.role = 'admin';
        }
      })
      .addCase(blockAdminFromList, (state, action: PayloadAction<Admin>) => {
        if (state.admins) {
          state.admins = state.admins.map((admin) => (admin.id === action.payload.id ? action.payload : admin));
        }
      })
      .addCase(updateNewAdmin, (state) => {
        state.admin = null;
      })
      .addCase(addGameToList, (state, action: PayloadAction<Game>) => {
        if (state.games) {
          state.games.push(action.payload);
        }
      })
      .addCase(deleteGameFromList, (state, action: PayloadAction<string>) => {
        if (state.games) {
          const index = state.games.findIndex((g) => g.id === action.payload);
          state.games.splice(index, 1);
        }
      })
      .addCase(editWithdrawalLimits, (state, action: PayloadAction<WithdrawalLimits>) => {
        state.withdrawalLimits = action.payload;
      })
      .addCase(onUpdateMaxWithdrawal, (state, action: PayloadAction<number>) => {
        state.withdrawalLimits.maxWithdrawal = action.payload;
      })
      .addCase(onUpdateMinWithdrawal, (state, action: PayloadAction<number>) => {
        state.withdrawalLimits.minWithdrawal = action.payload;
      })
      .addCase(onUpdateWithdrawalPeriod, (state, action: PayloadAction<number>) => {
        state.withdrawalLimits.withdrawalPeriod = action.payload;
      })
      .addCase(addNoteToList, (state, action: PayloadAction<Note>) => {
        state.profileInfo.notes.push(action.payload);
      })
      .addCase(setProfileInfo, (state, action: PayloadAction<User | null>) => {
        state.profile = action.payload;
      })
      .addCase(onUpdateAvatar, (state, action: PayloadAction<string>) => {
        if (state.profile) {
          state.profile.avatar = action.payload;
        }
      })
      .addCase(deleteConfirmationFromList, (state, action: PayloadAction<number>) => {
        const index = state.confirmations.findIndex((item) => item.id === action.payload);
        const buffer = state.confirmations;
        buffer.splice(index, 1);
        state.confirmations = buffer;
      })
      .addCase(onUserStatusChanged, (state, action: PayloadAction<{ email: string; status: boolean }>) => {
        if (state.adminPaymentHistory) {
          state.adminPaymentHistory.history.forEach((item) => {
            if (item.email === action.payload.email) {
              item.isBlocked = action.payload.status;
            }
          });
        }
        if (state.selectedUserInfo) {
          if (state.selectedUserInfo.user.email === action.payload.email) {
            state.selectedUserInfo.user.isBlocked = action.payload.status;
          }
          state.selectedUserInfo.paymentsHistory.forEach((item) => {
            if (item.fromWhom === action.payload.email) {
              item.isBlocked = action.payload.status;
            }
          });
        }
        const user = state.cachedUsers.find((u) => u.user.email === action.payload.email);
        if (user) {
          user.user.isBlocked = action.payload.status;
          user.paymentsHistory.forEach((item) => {
            if (item.fromWhom === action.payload.email) {
              item.isBlocked = action.payload.status;
            }
          });
        }
      })
      .addCase(clearSelectedUserInfo, (state) => {
        state.selectedUserInfo = null;
      })
      .addCase(updateCachedUsers, (state, action: PayloadAction<SelectedUserInfo>) => {
        if (state.cachedUsers.length >= 50) {
          state.cachedUsers = state.cachedUsers.slice(1);
        }
        state.cachedUsers.push(action.payload);
      })
      .addCase(setIsFetchingUser, (state, action: PayloadAction<boolean>) => {
        state.isFetchingUser = action.payload;
      })
      .addCase(setSwapExceeded, (state, action: PayloadAction<boolean>) => {
        state.swapExceeded = action.payload;
      })
      .addCase(setWalletInUse, (state, action: PayloadAction<boolean>) => {
        state.walletInUse = action.payload;
      })
      .addCase(updateSelectedUserBalance, (state, action: PayloadAction<{ email: string; balance: string }>) => {
        if (state.selectedUserInfo && state.selectedUserInfo.user.email === action.payload.email) {
          state.selectedUserInfo.user.balance = action.payload.balance;
        }
        const user = state.cachedUsers.find((u) => u.user.email === action.payload.email);
        if (user) {
          user.user.balance = action.payload.balance;
        }
      })
      .addCase(onDrop2fa, (state, action: PayloadAction<string>) => {
        if (state.selectedUserInfo && state.selectedUserInfo.user.email === action.payload) {
          state.selectedUserInfo.twoFaEnabled = false;
        }
        const user = state.cachedUsers.find((u) => u.user.email === action.payload);
        if (user) {
          user.twoFaEnabled = false;
        }
      });
  },
});

export const {
  setSelectedUserInfo,
  setWithdrawals,
  updateAdminPaymentsHistory,
  updateAdminsList,
  updateAdminWithdrawalHistory,
  updateGamesList,
  updateNotesList,
  updatePaymentsHistory,
  updateStoresList,
  updateUsersList,
  updateWithdrawalLimits,
  updateConfirmations,
} = dashboardReducer.actions;

export default dashboardReducer.reducer;
