import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useShallowSelector } from 'hooks';
import { SupportPopup } from 'modules/dashboard/components/Popups';
import { SupportButton, TablePage } from 'modules/dashboard/containers';
import {
  dateComparator,
  levelComparator,
  numberComparator,
  statusComparator,
  stringComparator,
} from 'modules/dashboard/util/comparators';
import selector from 'store/dashboard/selectors';
import { Level } from 'types/store/dashboard';

export const ClientDashboardPage: FC = () => {
  const { t } = useTranslation();

  const { profile } = useShallowSelector(selector.getDashboard);
  const [items, setItems] = useState<{ id: number; date: Date; data: string[] }[]>();
  const { paymentsHistory } = useShallowSelector(selector.getDashboard);

  useEffect(() => {
    if (paymentsHistory) {
      setItems(
        paymentsHistory.map((receipt, index) => {
          let isBlocked = '-';
          if (receipt.isBlocked !== null) {
            isBlocked = t(
              `${receipt.isBlocked ? t('dashboard.clientDashboard.blocked') : t('dashboard.clientDashboard.unlocked')}`,
            );
          }
          return {
            id: index,
            date: receipt.date,
            data: [
              receipt.email,
              t(`dashboard.levels.${Level[receipt.level].toString().toLowerCase()}`),
              receipt.amount.toString(),
              format(new Date(receipt.date), 'dd.MM.yyyy'),
              isBlocked,
              receipt.project,
            ],
          };
        }),
      );
    }
  }, [paymentsHistory, t]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [isSupportPopupOpen, setSupportPopupOpen] = useState(false);

  const columns = [
    t('dashboard.clientDashboard.participants'),
    t('dashboard.clientDashboard.level'),
    t('dashboard.clientDashboard.amount'),
    t('dashboard.clientDashboard.date'),
    t('dashboard.clientDashboard.status'),
    t('dashboard.clientDashboard.project'),
  ];

  const comparators = [
    stringComparator,
    levelComparator(t),
    numberComparator,
    dateComparator,
    statusComparator(t),
    stringComparator,
  ];

  return (
    <Box>
      <SupportPopup isOpen={isSupportPopupOpen} closePopup={() => setSupportPopupOpen(false)} />
      <TablePage items={items ?? []} columns={columns} columnComparators={comparators} defaultFilename="income" />
      {!isSmallScreen && profile && profile.role === 'user' && (
        <>
          <SupportButton
            openPopup={() => setSupportPopupOpen(true)}
            sx={{
              position: 'absolute',
              top: 'calc(100% - 35px)',
              right: 0,
            }}
          />
          <Box sx={{ marginBottom: '25px' }} />
        </>
      )}
    </Box>
  );
};
