import { error, request, success } from 'store/dashboard/actions';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { updateAdminsList } from '../reducer';
import { ShowUnauthorizedMessage } from '../utils';

export function* getAdminsSaga() {
  yield* put(request(actionTypes.GET_ADMINS));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.getAdmins);
      yield* put(success(actionTypes.GET_ADMINS, { data }));
      yield* put(updateAdminsList(data));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    console.error(err);
    errorToastMessage(err);
    yield* put(error(actionTypes.GET_ADMINS));
  }
}

export default function* listener() {
  yield all([takeLatest(actionTypes.GET_ADMINS, getAdminsSaga)]);
}
