import { ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { StakingAbi, UserState } from 'types';
import { getContractDataByItsName } from 'utils';

import { getUserStakeData } from '../actions';
import actionTypes from '../actionTypes';
import { updateStakingState } from '../reducer';

export function* getUserStakeDataSaga({ type, payload: { web3Provider } }: ReturnType<typeof getUserStakeData>) {
  yield* put(apiActions.request(type));
  const { chainType, address: userAddress }: UserState = yield select(userSelector.getUser);
  const [stakingAbi, stakingContractAddress] = getContractDataByItsName(ContractsNames.staking, chainType);

  try {
    const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(stakingAbi, stakingContractAddress);

    const { amount } = yield* call(stakingContract.methods.userInfo(userAddress).call);
    const pendingReward = yield* call(stakingContract.methods.pendingReward(userAddress).call);

    yield* put(
      updateStakingState({
        userStakeData: {
          userStakeAmount: amount,
          pendingReward,
        },
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_STAKE_DATA, getUserStakeDataSaga);
}
