import { handleBackendErrors } from 'modules/referral/utils';
import { error, logout, request, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import { onLogout, setError } from 'store/referral/reducer';
import { call, put, takeLatest } from 'typed-redux-saga';

import actionTypes from '../actionTypes';

export function* logoutSaga({ type, payload }: ReturnType<typeof logout>) {
  yield* put(request(type));

  try {
    yield* call(baseApi.logout);
    yield* put(onLogout());
    if (payload) window.location.href = payload;
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type));
    const parsedError = handleBackendErrors(err);
    if (parsedError) {
      yield* put(setError({ [parsedError.source]: parsedError.type }));
    }
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.LOGOUT, logoutSaga);
}
