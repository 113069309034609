import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StakingState } from 'types';

const initialState: StakingState = {
  stakingStartTime: '0',
  stakingEndTime: '0',
  minStake: '0',
  maxStake: '0',
  apr: '',
  rewardAmount: '',
  rewardPerDay: '',
  totalStaked: '0',

  isStakeAvailable: false,
  isUnstakeAvailable: false,
  isEarlyUnstake: false,
  userStakeData: {
    userStakeAmount: '',
    pendingReward: '',
  },
};

export const stakingReducer = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    updateStakingState: (state, action: PayloadAction<Partial<StakingState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateStakingState } = stakingReducer.actions;

export default stakingReducer.reducer;
