import { isMainnet } from 'appConstants';
// import { URL } from 'appConstants';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { call, CallEffect, put, PutEffect, SelectEffect } from 'redux-saga/effects';

import { finishRequest, startRequest } from './reducer';

const client: AxiosInstance = axios.create({
  baseURL: isMainnet ? 'https://prod.22xgame.com/' : 'https://test.22xgame.com/',
  // baseURL: isMainnet ? 'https://prod.22xgame.com/' : 'http://localhost:8010/',
  withCredentials: true,
});

export default function* ajax(config: AxiosRequestConfig): Generator<SelectEffect | CallEffect | PutEffect> {
  try {
    yield put(startRequest());
    return yield call<(configVar: AxiosRequestConfig) => Promise<AxiosResponse>>(client, config);
  } finally {
    yield put(finishRequest());
  }
}
