import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainLogoIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 50 38"
    sx={{
      width: '50px',
      height: '38px',
      ...sx,
    }}
  >
    <svg width="50" height="39" viewBox="0 0 50 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.3758 36.4218C18.3733 35.6199 13.7968 33.7885 9.7486 30.7249C6.31128 28.1226 3.65521 24.9032 2.38992 20.7115C1.05981 16.3089 1.66108 12.1751 4.42128 8.44682C6.81188 5.21636 10.0913 3.2443 13.863 2.02452C14.5077 1.81628 15.209 1.61839 15.8585 1.42946C15.5096 0.719238 15.5096 0.719238 15.5096 0.719238C12.3599 1.47841 8.82669 2.86989 6.25198 4.86401C3.44766 7.03811 1.41423 9.759 0.493015 13.2267C0.269607 14.0686 0.160661 14.9408 0 15.7979C0 16.5136 0 17.2308 0 17.9465C0.0379242 18.1996 0.0799856 18.4526 0.114462 18.7064C0.619889 22.5388 2.29614 25.8272 4.83431 28.6887C8.97494 33.3568 14.2326 36.1432 20.2122 37.6685C21.27 37.9381 26.0953 39.3151 32.8431 38.227L32.097 36.6087C29.785 36.8838 26.1912 36.8728 23.3758 36.4218Z"
        fill="white"
      />
      <path
        d="M49.7669 19.149C49.0871 15.4338 47.295 12.2888 44.7568 9.55346C40.6941 5.17631 35.6233 2.52989 29.8953 1.05567C28.8286 0.781239 27.7398 0.593686 26.6614 0.366141C25.0341 0.0965342 23.5585 0 22.0829 0L22.4649 0.777102C22.4891 0.777791 22.5139 0.77917 22.5394 0.77986C26.5497 0.843986 30.4118 1.63626 34.1132 3.18564C38.7572 5.12942 42.7172 7.95926 45.598 12.1551C47.3363 14.6863 48.4389 17.4658 48.5161 20.5611C48.6402 25.4865 46.5199 29.3409 42.6868 32.319C42.1035 32.7721 41.4781 33.1699 40.8409 33.5995L41.7422 35.2102C42.2414 34.8896 42.7234 34.5917 43.1805 34.2621C46.4758 31.8832 48.7864 28.8224 49.6711 24.7921C49.8055 24.177 49.8917 23.5496 50 22.929C50 22.1478 50 21.3665 50 20.5853C49.9255 20.104 49.8538 19.6248 49.7669 19.149Z"
        fill="white"
      />
      <path
        d="M3.23391 21.6817C4.58194 25.1411 6.86567 27.9068 9.78997 30.1333C15.6634 34.6063 22.336 36.5604 29.6857 36.2743C30.387 36.2467 31.0862 36.1501 31.8515 36.0743L31.0758 34.3925C29.5016 34.6228 26.4015 34.5808 24.1612 34.2595C19.4889 33.5906 15.2104 31.933 11.4042 29.1293C8.61984 27.0773 6.37334 24.5557 5.0191 21.3356C3.53661 17.8127 3.39043 14.2665 5.07565 10.7568C6.41058 7.97658 8.57019 5.97832 11.2145 4.46756C12.8025 3.56151 14.5539 2.89887 16.3495 2.4362L16.2736 2.27209C15.9709 1.65909 15.9702 1.65771 15.9702 1.65771C13.39 2.35276 10.294 3.61323 8.12613 5.19984C2.70297 9.17154 0.812272 15.469 3.23391 21.6817Z"
        fill="white"
      />
      <path
        d="M4.90739 19.6215C5.9279 23.1312 8.08406 25.9052 10.9098 28.1427C16.366 32.4633 22.6352 34.3023 29.5533 34.0437C29.9774 34.0279 30.3994 33.961 30.8545 33.9079L30.136 32.3482C29.3361 32.384 27.462 32.3626 26.4725 32.3137C22.3201 32.1089 18.4498 30.9188 14.8691 28.8212C11.6338 26.9243 8.9577 24.453 7.30696 21.0205C5.65829 17.5936 5.41627 14.1107 7.17595 10.6459C8.62948 7.78636 10.9897 5.86947 13.863 4.55591C14.7939 4.12978 15.8234 3.79329 16.8039 3.41611L16.4536 2.65625C13.5796 3.39474 10.9373 4.60073 8.74877 6.57417C4.80672 10.1273 3.40973 14.4762 4.90739 19.6215Z"
        fill="white"
      />
      <path
        d="M10.1396 7.34745C6.9491 10.3717 5.73139 14.0228 6.80568 18.3338C7.62277 21.6145 9.53139 24.2217 12.1075 26.3254C17.1735 30.4626 23.0338 32.1933 29.5306 31.8899C29.645 31.8851 29.7588 31.861 29.8981 31.8362L29.2079 30.3392C28.488 30.3695 22.758 30.5267 17.9326 28.1065C14.5788 26.5392 11.7303 24.3575 9.79135 21.1374C8.43504 18.8854 7.76481 16.4534 8.15854 13.8146C8.62949 10.6544 10.4154 8.33831 12.9791 6.55932C14.2643 5.66775 15.7889 5.00373 17.3093 4.51623L16.9218 3.67432C16.8004 3.71362 16.4591 3.82463 16.1509 3.92531C13.9161 4.65966 11.8593 5.7174 10.1396 7.34745Z"
        fill="white"
      />
      <path
        d="M9.0701 18.5324C10.0851 21.5215 12.0544 23.8004 14.5657 25.6332C17.7479 27.9548 21.3279 29.2877 25.2286 29.7372C26.4525 29.8786 27.7826 29.871 29.0196 29.9276L28.3046 28.3768C25.7988 28.4071 23.0462 27.9776 20.6935 27.1212C17.1266 25.8221 14.043 23.8445 11.911 20.6196C10.3319 18.231 9.62931 15.6239 10.3692 12.7803C11.0642 10.1084 12.8039 8.22456 15.129 6.85515C15.9351 6.38144 16.9039 5.97255 17.7844 5.55055L17.4045 4.72656C15.7972 5.22854 14.2809 5.9884 12.957 6.93927C9.03976 9.74842 7.56623 14.1035 9.0701 18.5324Z"
        fill="white"
      />
      <path
        d="M47.648 24.4237C48.7581 20.4671 48.0045 16.7506 45.9662 13.2698C43.4019 8.89059 39.5846 5.91802 35.0482 3.80047C32.3225 2.52759 29.4588 1.68636 26.4815 1.27609C25.1789 1.09613 23.8592 1.03062 22.5463 0.922363L23.0041 1.74084C23.0062 1.74084 23.0069 1.74084 23.009 1.74084C26.9621 1.79255 30.74 2.66274 34.3359 4.29693C38.18 6.04282 41.4739 8.4948 43.9135 11.9907C45.5077 14.2738 46.5475 16.783 46.6627 19.588C46.8426 23.9493 45.0609 27.4383 41.7235 30.1688C41.0933 30.6846 40.4072 31.1328 39.7274 31.6086L40.5879 33.1455C40.6472 33.1111 40.703 33.0794 40.7575 33.0463C44.0997 31.0059 46.5723 28.2561 47.648 24.4237Z"
        fill="white"
      />
      <path
        d="M45.4974 15.6606C44.3038 12.386 42.1766 9.79268 39.4757 7.6572C35.5392 4.54466 31.02 2.81049 26.0712 2.15198C25.0976 2.02235 24.1129 1.97615 23.0938 1.89893L23.5516 2.7174C23.6268 2.72843 23.7089 2.73326 23.7999 2.73119C24.5266 2.71395 25.2589 2.81393 25.9843 2.90081C29.7002 3.34418 33.1685 4.52466 36.3583 6.485C39.3812 8.34191 41.8511 10.7394 43.477 13.9347C44.5506 16.0426 45.0526 18.2794 44.7878 20.6335C44.4196 23.906 42.8103 26.5042 40.2473 28.5286C39.7363 28.932 39.1916 29.2954 38.6455 29.6753L39.4964 31.195C40.2211 30.6807 40.9327 30.2028 41.5974 29.6664C45.8607 26.2277 47.3701 20.8011 45.4974 15.6606Z"
        fill="white"
      />
      <path
        d="M10.7477 16.8571C11.2828 19.3112 12.6377 21.297 14.4609 22.9698C17.1955 25.479 20.4508 26.9546 24.0778 27.6021C25.3886 27.8359 26.8124 27.8903 28.1412 28.0241L27.6916 27.0484C27.5247 26.7292 27.3772 26.4554 27.3772 26.4554C26.6476 26.4203 25.5858 26.3623 24.8666 26.2465C20.9887 25.6183 17.5617 24.0724 14.8442 21.1619C13.3666 19.5787 12.3557 17.7349 12.1447 15.5512C11.8413 12.4028 13.1956 9.99907 15.6931 8.20008C16.4688 7.64156 17.4424 7.17268 18.3064 6.68242L17.9258 5.85498C16.3957 6.38316 14.9277 7.16578 13.7451 8.20766C11.1208 10.519 9.98925 13.375 10.7477 16.8571Z"
        fill="white"
      />
      <path
        d="M44.2314 17.1371C43.5646 13.6487 41.5719 10.9278 38.9392 8.65239C35.6088 5.7736 31.7026 4.09459 27.3992 3.31611C26.1774 3.09546 24.9321 2.99961 23.6268 2.85205L24.1171 3.72707C24.1798 3.7381 24.2467 3.7443 24.3205 3.74775C27.3958 3.85118 30.3318 4.59657 33.1148 5.8984C36.7851 7.61533 39.8108 10.0618 41.7422 13.7087C43.8514 17.6894 43.3529 22.232 40.3507 25.4928C39.5488 26.3637 38.5814 27.0815 37.6388 27.8786L38.4317 29.2949C39.2061 28.7184 39.9852 28.1951 40.6665 27.5662C43.7246 24.7405 45.0209 21.2632 44.2314 17.1371Z"
        fill="white"
      />
      <path
        d="M42.3531 16.3373C41.6408 13.1193 39.6846 10.6625 37.1623 8.6435C34.1104 6.20187 30.5938 4.80074 26.7545 4.18292C25.9167 4.04847 25.0658 3.99123 24.2191 3.90918L24.6832 4.738C26.5904 4.89107 28.4597 5.22343 30.2684 5.84745C33.7064 7.03276 36.7051 8.87174 38.922 11.8236C40.4403 13.844 41.3519 16.0925 41.1754 18.6652C40.9664 21.7012 39.4226 23.9752 36.9913 25.706C36.8568 25.8018 36.7189 25.8942 36.5803 25.9873L37.3774 27.4105C37.7622 27.1505 38.1207 26.9195 38.4524 26.6554C41.8015 23.9932 43.2936 20.5889 42.3531 16.3373Z"
        fill="white"
      />
      <path
        d="M40.7217 16.772C40.4445 14.589 39.4157 12.7355 37.989 11.0999C35.5336 8.28458 32.3949 6.58006 28.8245 5.65402C27.4992 5.30994 26.1291 5.14032 24.7762 4.90381L25.3127 5.86157C27.3461 6.01947 29.3099 6.49732 31.1806 7.3151C34.0939 8.58866 36.5548 10.4159 38.1794 13.2189C40.0742 16.4879 39.7198 20.1314 37.2182 22.8247C36.7396 23.3405 36.1763 23.7777 35.6136 24.2576L36.3707 25.6091C36.6293 25.4339 36.863 25.2788 37.0837 25.1064C39.7915 22.9833 41.165 20.259 40.7217 16.772Z"
        fill="white"
      />
      <path
        d="M31.8246 10.2224L15.4972 13.0888L15.7841 13.8645L19.1448 13.2612L21.8733 19.409L23.109 19.2353L20.3522 13.0881L21.903 12.7716L25.5933 15.4146L26.3835 17.0515L25.7416 17.9541L26.0388 18.6312L35.6902 16.4772L35.2013 15.729L27.7626 17.3094L32.0715 10.8471L31.8246 10.2224ZM25.874 14.6416L23.1186 12.5489L26.1119 12.0462L25.874 14.6416ZM27.0924 16.0055L26.6545 15.2105L27.2903 11.807L30.2573 11.3043L27.0924 16.0055Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
