/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, styled, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { TesIcon } from 'modules/dashboard/assets';
import { TransactionModal } from 'modules/layout/containers';
import { useWalletConnectorContext } from 'services';
import { makeReferralSwap, setSwapExceeded, setWalletInUse } from 'store/dashboard/actions';
import dashboardSelector from 'store/dashboard/selectors';
import { getSwapData } from 'store/swap/actions';
import userSelector from 'store/user/selectors';
import { BG_BUTTON_GRAY, BORDER_BUTTON_GRAY, FontFamilies } from 'theme';
import { getToastMessage } from 'utils';

import { Popup } from '../Popup';
import { PopupProps } from '../PopupProps';

import { TransactionBlockedPopup } from './TransactionBlockedPopup';
import { WalletInUsePopup } from './WalletInUsePopup';

const StyledTextField = styled(TextField)(({ theme }) => ({
  background: BG_BUTTON_GRAY,
  '&:hover': {
    background: BG_BUTTON_GRAY,
  },
  '& .MuiInputBase-root': {
    height: 66,
    borderRadius: '5px',
    fontSize: 24,
    fontFamily: FontFamilies.secondary,
  },
  '& .MuiOutlinedInput-root': {
    border: BORDER_BUTTON_GRAY,
  },
  '& .MuiOutlinedInput-input': {
    textAlign: 'left',
  },
}));

const DarkGrayButton = styled(Button)(({ theme }) => ({
  background: BG_BUTTON_GRAY,
  '&:hover': {
    background: BG_BUTTON_GRAY,
  },
  border: BORDER_BUTTON_GRAY,
  textTransform: 'none',
}));

export const SwapPopup: FC<PopupProps> = ({ isOpen, closePopup }) => {
  const dispatch = useDispatch();
  const address = useShallowSelector(userSelector.getProp('address'));
  const { profile, withdrawalLimits, swapExceeded, walletInUse } = useShallowSelector(dashboardSelector.getDashboard);

  const { walletService } = useWalletConnectorContext();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  const maxAmount = +(profile?.balance ?? '0');
  const [selectedAmount, setSelectedAmount] = useState('');

  useEffect(() => {
    if (address.length) {
      dispatch(getSwapData({ web3Provider: walletService.Web3() }));
    }
  }, [dispatch, address.length, walletService]);

  const setValue = (newValue: string) => {
    if (newValue === '') {
      setSelectedAmount('');
      return;
    }
    const numberRegex = /^([1-9]\d*)$/;
    if (!numberRegex.test(newValue)) {
      return;
    }
    const numberValue = +newValue;
    if (numberValue < 0 || numberValue > maxAmount) {
      return;
    }
    setSelectedAmount(newValue);
  };

  const closeTransactionBlocked = () => {
    dispatch(setSwapExceeded(false));
  };

  const closeWalletInUse = () => {
    dispatch(setWalletInUse(false));
  };

  const makeSwap = () => {
    const value = +selectedAmount;
    if (!value || value <= 0) {
      getToastMessage('error', t('dashboard.info.enterValidNumber'));
      return;
    }
    if (value < withdrawalLimits.minWithdrawal) {
      getToastMessage('error', t('dashboard.info.minimumLimit'));
      return;
    }
    dispatch(makeReferralSwap({ web3Provider: walletService.Web3(), amount: value, wallet_address: address }));
  };

  return (
    <Popup
      isOpen={isOpen}
      closePopup={closePopup}
      header={t('dashboard.info.swapHeader')}
      height={isSmallScreen ? '386px' : '314px'}
      width={isSmallScreen ? '342px' : '448px'}
    >
      <TransactionModal />
      <TransactionBlockedPopup isOpen={swapExceeded} closePopup={closeTransactionBlocked} />
      <WalletInUsePopup isOpen={walletInUse} closePopup={closeWalletInUse} />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          sx={{
            mt: {
              lg: '32px',
              xs: '30px',
            },
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontSize: '16px',
              display: 'inline-block',
            }}
          >
            {t('dashboard.info.tesBalance')}
          </Typography>
          <Box
            sx={{
              width: 'auto',
              height: 36,
              display: 'inline-block',
              float: 'right',
              border: BORDER_BUTTON_GRAY,
              borderRadius: 2,
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                color: 'white',
                display: 'inline-block',
                fontSize: 16,
                ml: '10px',
              }}
            >
              {maxAmount}
            </Typography>
            <TesIcon
              sx={{
                display: 'inline-block',
                ml: '10px',
                verticalAlign: 'middle',
                marginRight: '6px',
              }}
            />
          </Box>
        </Box>
        <StyledTextField
          sx={{
            width: {
              lg: 400,
              xs: 294,
            },
            height: 66,
            mt: '8px',
          }}
          value={selectedAmount}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <DarkGrayButton
                sx={{
                  minWidth: 54,
                  height: 32,
                  borderRadius: 2,
                }}
                onClick={() => setSelectedAmount(maxAmount.toString())}
              >
                MAX
              </DarkGrayButton>
            ),
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              lg: 'row',
              xs: 'column',
            },
            mt: '24px',
          }}
        >
          <Button
            sx={{
              width: 193,
              height: 56,
            }}
            onClick={makeSwap}
          >
            {t('dashboard.info.swap')}
          </Button>
          <DarkGrayButton
            sx={{
              width: 193,
              height: 56,
              fontSize: 20,
              mt: {
                lg: '0px',
                xs: '16px',
              },
              ml: {
                lg: '24px',
                xs: '0px',
              },
            }}
            onClick={closePopup}
          >
            {t('dashboard.info.cancel')}
          </DarkGrayButton>
        </Box>
      </Box>
    </Popup>
  );
};
