const GET_TOKENS_LIST = 'GET_TOKENS_LIST' as const;
const GET_CROWDSALE_DATA = 'GET_CROWDSALE_DATA' as const;
const GET_CROWDSALE_ADDRESS = 'GET_CROWDSALE_ADDRESS' as const;

const BUY_TOKEN = 'BUY_TOKEN' as const;
const CLAIM = 'CLAIM' as const;
const REFUND = 'REFUND' as const;

const GET_TOKENS_BALANCES = 'GET_TOKENS_BALANCES' as const;
const GET_TYZ_TOKEN_BALANCE = 'GET_TYZ_TOKEN_BALANCE' as const;

export default {
  GET_TOKENS_LIST,
  GET_CROWDSALE_DATA,
  GET_CROWDSALE_ADDRESS,

  BUY_TOKEN,
  CLAIM,
  REFUND,

  GET_TOKENS_BALANCES,
  GET_TYZ_TOKEN_BALANCE,
};
