import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, styled, Typography } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { EonaWalletLogo, MetamaskLogo, WalletConnectLogo } from 'modules/layout/assets';
import { BORDER_RADIUS_MEDIUM, COLOR_TEXT_WHITE_100, FontWeights } from 'theme';
import { Chains, WalletProviders } from 'types';

export type ConnectWalletModalProps = {
  onConnectWallet: (provider: WalletProviders, chain: Chains) => void;
} & ModalProps;

const WalletButton = styled(Button)(({ theme }) => ({
  height: 86,
  borderRadius: BORDER_RADIUS_MEDIUM,
  fontWeight: FontWeights.fontWeightBold,
  fontSize: 24,
  lineHeight: '34px',

  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
}));

export const ConnectWalletModal: FC<ConnectWalletModalProps> = ({ onConnectWallet, onClose, open }) => {
  const { t } = useTranslation();

  const handleConnect = (provider: WalletProviders, chain: Chains) => {
    localStorage.clear();
    onConnectWallet(provider, chain);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} title={t('header.connectWallet.title')}>
      <Typography variant="body2" color={COLOR_TEXT_WHITE_100} mt={3}>
        {t('header.connectWallet.subtitle')}
      </Typography>

      <Box mt={2}>
        <WalletButton
          variant="outlined"
          fullWidth
          startIcon={<MetamaskLogo />}
          onClick={() => handleConnect(WalletProviders.metamask, Chains.MultiChain)}
          sx={{ '.MuiButton-startIcon': { mr: 2 } }}
        >
          Metamask
        </WalletButton>

        <WalletButton
          variant="outlined"
          fullWidth
          startIcon={<WalletConnectLogo />}
          onClick={() => handleConnect(WalletProviders.walletConnect, Chains.MultiChain)}
          sx={{ my: 2, '.MuiButton-startIcon': { mr: 2 } }}
        >
          WalletConnect
        </WalletButton>

        <WalletButton
          variant="outlined"
          fullWidth
          startIcon={<EonaWalletLogo />}
          sx={{ '.MuiButton-startIcon': { mr: 2 } }}
        >
          Eona Wallet
        </WalletButton>
      </Box>
    </Modal>
  );
};
