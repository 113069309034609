import { error, getUserInfo, request, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import { updateReferralState } from 'store/referral/reducer';
import { call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';

export function* getUserInfoSaga({ type }: ReturnType<typeof getUserInfo>) {
  yield* put(request(type));

  try {
    const response = yield* call(baseApi.getUserInfo);
    const twoFaResponse = yield* call(baseApi.getTwoFaMethod);
    const twoFaMethod = twoFaResponse.data.find((method: any) => method.is_primary) || { name: 'none' };
    const methodName = twoFaMethod.name;
    yield* put(
      updateReferralState({
        email: response.data.email,
        twoFaType: methodName,
        isEmailConfirmed: response.data.is_email_confirmed,
      }),
    );
    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_INFO, getUserInfoSaga);
}
