import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Game22State } from 'types/store/game22';

const initialState: Game22State = {
  email: '',
  isRegistered: false,
  activeRequests: 0,
};

// createSlice uses Immer library internally to let us write state update logic using "mutating" syntax
export const game22Reducer = createSlice({
  name: 'game22',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<Game22State['email']>) => {
      state.email = action.payload;
    },
    setIsRegistered: (state, action: PayloadAction<Game22State['isRegistered']>) => {
      state.isRegistered = action.payload;
    },
    startRequest: (state) => {
      state.activeRequests += 1;
    },
    finishRequest: (state) => {
      state.activeRequests -= 1;
    },
  },
});

export const { setEmail, setIsRegistered, startRequest, finishRequest } = game22Reducer.actions;
export default game22Reducer.reducer;
