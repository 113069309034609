import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { BG_INPUT_GRAY_DEFAULT, BG_INPUT_GRAY_HOVER, BORDER_RADIUS_SMALL } from 'theme/variables';

export const getMuiIconButtonOverrides = (): ComponentsOverrides['MuiIconButton'] => ({
  root: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
    '&.contained': {
      background: BG_INPUT_GRAY_DEFAULT,
      transition: 'none',
      borderRadius: BORDER_RADIUS_SMALL,
      padding: '12px',

      '&:hover': {
        background: BG_INPUT_GRAY_HOVER,
      },

      '&:active': {
        transform: 'scale(0.9)',
        transition: 'all 0.05s ease-in-out',
      },
    },
  },
});

export const getMuiIconButtonDefaultProps = (): ComponentsProps['MuiIconButton'] => ({
  disableFocusRipple: true,
  disableRipple: true,
});
