/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/control-has-associated-label */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

export const SearchBar = ({ onSearch }: { onSearch: (input: string) => void }) => {
  let input = '';
  const { t } = useTranslation();

  return (
    <div className="relative rounded-full w-full mr-5 phone:mr-0" style={{ maxWidth: '556px' }}>
      <label
        className="absolute -top-9 left-0 text-[#191836] text-2xl font-medium"
        style={{ fontFamily: "'TT Norms', sans-serif" }}
      >
        {t('dashboard.history.search')}
      </label>
      <input
        type="text"
        placeholder={t('dashboard.history.searchPlaceholder')}
        className="w-full rounded-full py-2 px-4 outline-none border-2 bg-transparent placeholder-[#8A90AB] text-lg text-[#191836] font-normal"
        onChange={(e) => {
          input = e.target.value;
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onSearch(input);
          }
        }}
        style={{
          fontFamily: "'TT Norms', sans-serif",
          fontWeight: input === '' ? '500' : '400',
          fontSize: '20px',
          color: input === '' ? '#191836' : '#8A90AB',
          borderColor: '#191836',
        }}
      />
      <button
        onClick={() => onSearch(input)}
        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#191836] hover:text-gray-700"
        type="button"
      >
        <SearchRoundedIcon style={{ fontSize: '28px' }} />
      </button>
    </div>
  );
};
