import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BackButtonSmall: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="11.5" stroke="#fff" strokeWidth="1" fill="rgba(255, 255, 255, 0.125)" />
      <line x1="9" y1="12" x2="14" y2="7" stroke="#fff" strokeWidth="2" />
      <line x1="9" y1="12" x2="14" y2="17" stroke="#fff" strokeWidth="2" />
    </g>
  </SvgIcon>
);
