import { FC } from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FontFamilies, FontWeights } from 'theme';

export const NoteInfo: FC<{ email: string; header: string; date: string; onClick: () => void }> = ({
  email,
  header,
  date,
  onClick,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Button
      onClick={onClick}
      sx={{
        height: '69px',
        width: isSmallScreen ? '306px' : '510px',
        padding: '5px 20px',
        margin: '33px 0px',
        transition: 'transform 0.15s ease-in-out',
        '&:active': {
          transform: 'scale(0.97)',
        },
        borderRadius: '12px',
        background: 'white',
        '&:hover': {
          background: '#f0f0f0',
        },
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography
          fontFamily={FontFamilies.secondary}
          fontSize="16px"
          fontWeight={FontWeights.fontWeightMedium}
          width={isSmallScreen ? '166px' : '236px'}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textTransform="none"
        >
          {email}
        </Typography>
        <Typography fontFamily={FontFamilies.secondary} fontSize="16px" fontWeight={FontWeights.fontWeightMedium}>
          {date}
        </Typography>
      </Box>

      <Typography
        width="100%"
        fontFamily={FontFamilies.secondary}
        fontSize="20px"
        fontWeight={FontWeights.fontWeightBold}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {header}
      </Typography>
    </Button>
  );
};
