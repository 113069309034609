import { createAction } from '@reduxjs/toolkit';
import {
  ApproveReq,
  GetTokenBalanceReq,
  LinkEmailReq,
  LoginReq,
  SendFeedbackReq,
  VerifyEmailReq,
} from 'types/requests';

import actionTypes from './actionTypes';

export const login = createAction<LoginReq>(actionTypes.LOGIN);
export const approve = createAction<ApproveReq>(actionTypes.APPROVE);
export const getTokenBalance = createAction<GetTokenBalanceReq>(actionTypes.GET_TOKEN_BALANCE);
export const sendFeedback = createAction<SendFeedbackReq>(actionTypes.SEND_FEEDBACK);
export const getAccountInfo = createAction(actionTypes.GET_ACCOUNT_INFO);
export const linkEmail = createAction<LinkEmailReq>(actionTypes.LINK_EMAIL);
export const verifyEmail = createAction<VerifyEmailReq>(actionTypes.VERIFY_EMAIL);
