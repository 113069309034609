import { error, getAdminWithdrawalHistory, request, success } from 'store/dashboard/actions';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { updateAdminWithdrawalHistory } from '../reducer';
import { ShowUnauthorizedMessage } from '../utils';

export function* getAdminWithdrawalHistorySaga({
  type,
  payload: { sortColumn, sortOrder, currentPosition, searchString, startDate, endDate, getAll },
}: ReturnType<typeof getAdminWithdrawalHistory>) {
  yield* put(request(type, { sortColumn, sortOrder, currentPosition, searchString, startDate, endDate, getAll }));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.getAdminWithdrawalHistory, {
        sortColumn,
        sortOrder,
        currentPosition,
        searchString,
        startDate,
        endDate,
        getAll,
      });
      yield* put(success(type));
      yield* put(updateAdminWithdrawalHistory(data));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield all([takeLatest(actionTypes.GET_ADMIN_WITHDRAWAL_HISTORY, getAdminWithdrawalHistorySaga)]);
}
