import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowUp: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6485 15.2484C17.1799 15.7171 16.4201 15.7171 15.9515 15.2484L12 11.297L8.04853 15.2484C7.5799 15.7171 6.8201 15.7171 6.35147 15.2484C5.88284 14.7798 5.88284 14.02 6.35147 13.5514L11.1515 8.75137C11.6201 8.28274 12.3799 8.28275 12.8485 8.75137L17.6485 13.5514C18.1172 14.02 18.1172 14.7798 17.6485 15.2484Z"
      fill="#575676"
    />
  </SvgIcon>
);
