import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { SocialLinks } from 'components';
import { LogoSmall } from 'components/Icon/components/LogoSmall';
import { useFormatTokensList } from 'modules/crowdsale/hooks';
import { MainLogo, NavItemStyled, NavSourceLinks } from 'modules/layout/components';
import { formatRoutesToArr } from 'modules/router/utils';
import {
  BG_BLUE_ACCENT_DARK,
  BG_BUTTON_GRAY,
  BORDER_BUTTON_GRAY,
  BORDER_GRAY_FOOTER,
  BORDER_RADIUS_MEDIUM,
  COLOR_TEXT_WHITE_100,
  FontFamilies,
  FontWeights,
} from 'theme';
import { RootRouteProps } from 'types';
import { flexHelper } from 'utils';

import { navSourceLinks } from '../Header/Header.helpers';

import { socialLinks, termsItems } from './Footer.helpers';

const NavigationItem: FC<RootRouteProps> = ({ id, title, path }) => {
  const { t } = useTranslation();
  return (
    <Grid key={id} item>
      <NavItemStyled
        title={t(`footer.nav.${title}`)}
        path={path}
        tSx={{ fontSize: 14, lineHeight: '24px', fontWeight: FontWeights.fontWeightRegular }}
      />
    </Grid>
  );
};

export const Footer: FC = () => {
  const { t } = useTranslation();

  const tokenList = useFormatTokensList();
  const currentTYZTokenPrice = tokenList[tokenList.length - 1]?.value.toString() || 0;
  const orderedRoutes = formatRoutesToArr(routes)
    .map((route) => route.root)
    .sort((item) => item.id)
    .filter((item) => item.isNavItem);

  return (
    <Box
      sx={{
        height: { xs: 430, md: 289 },
        background: BG_BLUE_ACCENT_DARK,
        mt: 'auto',
      }}
    >
      <Container sx={{ pt: 5.5, pb: 7, height: '100%' }}>
        <Grid
          container
          sx={{
            pb: { xs: 3, md: 0 },
            borderBottom: BORDER_GRAY_FOOTER,
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            container
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            alignItems="flex-start"
          >
            <MainLogo />
          </Grid>

          <Grid item xs={12} md={4}>
            <Stack
              direction={{ xs: 'row', md: 'column' }}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              alignItems="center"
            >
              <Box
                sx={{
                  ...flexHelper(),
                  mt: { xs: 3, md: 0 },
                  mb: { xs: 3, md: 2 },
                  mr: { xs: 2, md: 0 },
                  px: 2,
                  height: 64,
                  minWidth: 132,
                  background: BG_BUTTON_GRAY,
                  border: BORDER_BUTTON_GRAY,
                  borderRadius: BORDER_RADIUS_MEDIUM,
                  fontFamily: FontFamilies.secondary,
                  fontWeight: FontWeights.fontWeightRegular,
                }}
              >
                <LogoSmall />
                <Typography ml={0.5} className="white">
                  ${currentTYZTokenPrice}
                </Typography>
              </Box>
              <RouterLink
                to={routes.buyToken.root.path}
                style={{
                  color: COLOR_TEXT_WHITE_100,
                  borderBottom: `1px solid ${COLOR_TEXT_WHITE_100}`,
                  lineHeight: '24px',
                  textDecoration: 'none',
                }}
              >
                {t('footer.buyButton')}
              </RouterLink>
            </Stack>
            <Stack mb={{ xs: 2, md: 4 }} mt={{ xs: 0, md: 1 }} spacing={1} alignItems="center">
              {termsItems.map(({ id, name }) => (
                <RouterLink
                  key={id}
                  to={routes[name].root.path}
                  style={{
                    color: COLOR_TEXT_WHITE_100,
                    lineHeight: '24px',
                    textDecoration: 'none',
                  }}
                >
                  {t(`footer.nav.${name}`)}
                </RouterLink>
              ))}
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            container
            justifyContent={{ xs: 'center', md: 'flex-end' }}
            alignItems="space-between"
          >
            <Grid item xs={12} container direction="column" gap={2}>
              <Grid
                item
                container
                mb={{ xs: 2, md: 0 }}
                justifyContent={{ xs: 'center', md: 'flex-end' }}
                flexWrap="nowrap"
                gap={2}
              >
                {orderedRoutes.slice(0, 3).map(NavigationItem)}
                <NavSourceLinks
                  linksArr={navSourceLinks}
                  linkSx={{ fontSize: 14, lineHeight: '24px', fontWeight: FontWeights.fontWeightRegular }}
                />
              </Grid>
              <Grid
                item
                container
                mb={{ xs: 2, md: 0 }}
                justifyContent={{ xs: 'center', md: 'flex-end' }}
                flexWrap="nowrap"
                gap={2}
              >
                {orderedRoutes.slice(3, 4).map(NavigationItem)}
              </Grid>
            </Grid>

            <SocialLinks links={socialLinks} alignItems="center" />
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 4,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" className="s" color={COLOR_TEXT_WHITE_100}>
            <Trans i18nKey="footer.copyright" values={{ date: new Date().getFullYear() }} />
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
