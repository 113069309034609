export const getCookie = (key: string): string | null => {
  const keyLenPlus = key.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, keyLenPlus) === `${key}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(keyLenPlus));
      })[0] || null
  );
};
