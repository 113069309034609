import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const InfoIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 26 26"
    sx={{
      width: '26px',
      height: '26px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 17.8V12.6312M13 8.2C13 8.2 13.0073 8.2 13.012 8.2M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
      stroke="#191836"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
