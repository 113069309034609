import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ReturnIcon: FC<SvgIconProps & { stroke?: string }> = ({ sx, stroke = '#191836', ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 18"
    sx={{
      width: '18px',
      height: '18px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.33333 17H4C2.34315 17 1 15.6569 1 14L1 4C1 2.34315 2.34315 1 4 1L6.33333 1M12.5556 13.4444L17 9M17 9L12.5556 4.55556M17 9H7.33333"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
