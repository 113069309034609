export const disclaimerStepsArr = [
  {
    title: '',
    subtitle: '',
    text: '',
  },
  {
    title: '',
    text: '',
  },
  {
    title: '',
    text: '',
    link1: 'https://google.com',
    link2: 'https://google.com',
  },
];

export const disclaimerTabItems = [
  {
    value: 0,
    label: '',
  },
  {
    value: 1,
    label: '',
  },
  {
    value: 2,
    label: '',
  },
];
