import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@mui/material';
import { Spinner } from 'components';
import { BlockUserPopup } from 'modules/dashboard/components/Popups/Components/Profile';
import { RemoteParams } from 'modules/dashboard/pages';
import { ButtonData } from 'modules/dashboard/pages/ConfirmationPage';
import { TableInfo, TableItem } from 'types/dashboard';

import { TableWrapper } from '../DashboardTable';
import { TableType } from '../DashboardTable/useTable';

type NamedTable = {
  name: string;
  info: TableInfo;
};

type TableSelectorProps = {
  activeTable: number;
  loadingData: boolean;
  remoteParams: RemoteParams;
  setActiveTable: (index: number) => void;
  tables: NamedTable[];
  viewUser: ((email: string) => void) | null;
  customDownloadHandler?: (() => void) | null;
};

export const TableSelector: FC<TableSelectorProps> = ({
  activeTable,
  loadingData,
  remoteParams,
  setActiveTable,
  tables,
  viewUser,
  customDownloadHandler = null,
}) => {
  const {
    info: { items, columnComparators, columns, defaultFilename },
  } = tables[activeTable];

  const { t } = useTranslation();

  const [selectedUser, setSelectedUser] = useState<{ email: string; blocked: boolean }>();
  const [isBlockUserOpen, setBlockUserIsOpen] = useState(false);
  const [buttonsData, setButtonsData] = useState<ButtonData[]>([]);

  const onUpdateItems = (updatedItems: TableItem[]) => {
    if (activeTable === 1) {
      setButtonsData([]);
      return;
    }
    const newButtonsData: ButtonData[] = [];
    updatedItems.forEach((item) => {
      newButtonsData.push({
        id: item.id,
        cellIndex: 5,
        button: (
          <IconButton
            onClick={() => {
              setSelectedUser({ email: item.data[0], blocked: item.status ?? false });
              setBlockUserIsOpen(true);
            }}
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: '8px',
              left: 0,
              transition: 'transform 0.15s ease-in-out',
              '&:active': {
                transform: 'scale(0.92)',
              },
            }}
          >
            <Typography color="#0858E8" fontSize="12px" textAlign="start">
              {item.status ? t('dashboard.profile.unlock') : t('dashboard.profile.block')}
            </Typography>
          </IconButton>
        ),
      });
    });
    setButtonsData(newButtonsData);
  };

  useEffect(() => {
    if (items) {
      onUpdateItems(items);
    }
  }, [items]);

  const tableSelector = {
    setActiveTable,
    activeTable,
    tables,
  };

  return (
    <Box>
      {loadingData && (
        <Box
          zIndex={1000}
          position="fixed"
          left={0}
          top={0}
          width="100vw"
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ background: 'rgba(0,0,0,0.15)' }}
        >
          <Spinner />
        </Box>
      )}
      {selectedUser && (
        <BlockUserPopup
          isOpen={isBlockUserOpen}
          closePopup={() => setBlockUserIsOpen(false)}
          email={selectedUser.email}
          isBlocked={selectedUser.blocked}
        />
      )}
      <Box>
        <TableWrapper
          dropDownColumnIndex={activeTable === 0 ? 4 : -1}
          onUpdateItems={onUpdateItems}
          buttonsForCells={buttonsData}
          columnComparators={columnComparators}
          columns={columns}
          defaultFilename={defaultFilename}
          items={items}
          viewProfile={viewUser}
          tableType={TableType.Switchable}
          remoteParams={remoteParams}
          tableSelector={tableSelector}
          customDownloadHandler={customDownloadHandler}
        />
      </Box>
    </Box>
  );
};
