import { isMainnet } from 'appConstants';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { call, CallEffect, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import userSelector from 'store/user/selectors';
import { validateStatus } from 'utils';

const client: AxiosInstance = axios.create({
  baseURL: isMainnet ? 'https://papi.tyz.network/' : 'https://test.tyz.network/',
  // baseURL: isMainnet ? 'https://papi.tyz.network/' : 'http://localhost:8001/',
  validateStatus,
});

export default function* ajax(config: AxiosRequestConfig): Generator<SelectEffect | CallEffect | PutEffect> {
  const accessToken = yield select(userSelector.getProp('key'));

  if (accessToken) {
    client.defaults.headers.common.Authorization = `Token ${accessToken}`;
  }

  return yield call<(configVar: AxiosRequestConfig) => Promise<AxiosResponse>>(client, config);
}
