import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FontFamilies, FontWeights } from 'theme';

import { EditButton } from '../Icon';

interface RestrictionBoxProps {
  editedContent: string;
  headerText: string;
  height: string;
  maxLengthOfDecimalPart: number;
  maxLengthOfIntegerPart: number;
  onSubmit?: (value: number) => void;
  postfix: string;
  setEditedContent: (value: string) => void;
  sx?: Record<string, string | number>;
  width: string;
}

export const RestrictionBox: FC<RestrictionBoxProps> = ({
  editedContent,
  headerText,
  height,
  maxLengthOfDecimalPart,
  maxLengthOfIntegerPart,
  onSubmit,
  postfix,
  setEditedContent,
  sx,
  width,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const validateInput = (inputValue: string) => {
    let newValue = inputValue.replace(/\./g, ',').replace(/[^0-9,]/g, '');

    const commaCount = (newValue.match(/,/g) || []).length;

    if (commaCount > 1) {
      const firstCommaIndex = newValue.indexOf(',');
      newValue = newValue.slice(0, firstCommaIndex) + newValue.slice(firstCommaIndex).replace(',', '');
    }

    if (/^,/.test(newValue)) {
      newValue = newValue.slice(1);
    }

    let [integerPart, decimalPart] = newValue.split(',');

    if (integerPart && integerPart.length > 1) {
      integerPart = integerPart.replace(/^0{2,}/, '0');
    }
    if (decimalPart && decimalPart.length > maxLengthOfDecimalPart) {
      decimalPart = decimalPart.slice(0, maxLengthOfDecimalPart);
    }
    if (integerPart && integerPart.length > maxLengthOfIntegerPart) {
      integerPart = integerPart.slice(0, maxLengthOfIntegerPart);
    }
    if (decimalPart) {
      return `${integerPart},${decimalPart}`;
    }
    if (newValue.charAt(newValue.length - 1) === ',') {
      return `${integerPart},`;
    }

    return integerPart;
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = validateInput(e.target.value);
    setEditedContent(newValue);
  };

  const handleEditButtonClick = () => {
    setIsEditing(true);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    if (editedContent === '') {
      setEditedContent('0');
    }
    onSubmit?.(Number(editedContent.replace(',', '.')));
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    }
  };

  const setInput = (input: string) => {
    const parts = input.split(',');
    const integerPart = parts[0];
    const decimalPart = parts[1] || '';

    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if (isSmallScreen) {
      return `${formattedIntegerPart}${postfix}`;
    }

    const formattedValue = decimalPart ? `${formattedIntegerPart},${decimalPart}` : formattedIntegerPart;

    return `${formattedValue}${postfix}`;
  };

  const padding = isSmallScreen ? '10px' : '16px';

  const textStyles = {
    maxWidth: '210px',
    fontWeights: FontWeights.fontWeightMedium,
    fontFamily: FontFamilies.secondary,
    fontSize: '24px',
    color: '#4DA3FF',
    marginTop: '4px',
  };

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'white',
        borderRadius: '10px',
        width: { width },
        height: { height },
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: { padding } }}>
        <Typography
          fontFamily={FontFamilies.secondary}
          fontSize={isSmallScreen ? '17px' : '19px'}
          fontWeight={FontWeights.fontWeightRegular}
          sx={{
            overflowWrap: 'break-word',
            maxWidth: '200px',
            marginBottom: '4px',
            color: '#191836',
            lineHeight: '1.3',
          }}
        >
          {headerText}
        </Typography>
        {isEditing ? (
          <input
            inputMode="numeric"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            value={editedContent}
            style={{
              ...textStyles,
              fontWeight: 500,
            }}
          />
        ) : (
          <Typography sx={{ fontWeight: FontWeights.fontWeightMedium, ...textStyles }}>
            {setInput(`${editedContent}`)}
          </Typography>
        )}
      </Box>
      <IconButton
        sx={{
          position: 'absolute',
          right: padding,
          top: '50%',
          marginTop: '-18px',
          transition: 'transform 0.15s ease-in-out',
          '&:active': {
            transform: 'scale(0.92)',
          },
        }}
        onClick={handleEditButtonClick}
      >
        <EditButton />
      </IconButton>
    </Box>
  );
};
