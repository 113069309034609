export const notifyText = {
  disconnet: {
    info: (t: (text: string) => string) => t('walletNotif.disconnet_info'),
  },
  approve: {
    success: (t: (text: string) => string) => t('walletNotif.approve_success'),
    error: (t: (text: string) => string) => t('walletNotif.approve_error'),
  },
  buyToken: {
    success: (t: (text: string) => string) => t('walletNotif.buyToken_success'),
    error: (t: (text: string) => string) => t('walletNotif.buyToken_error'),
  },
  claim: {
    success: (t: (text: string) => string) => t('walletNotif.claim_success'),
    error: (t: (text: string) => string) => t('walletNotif.claim_error'),
    txError: (t: (text: string) => string) => t('walletNotif.claim_txError'),
  },
  refund: {
    success: (t: (text: string) => string) => t('walletNotif.refund_success'),
    error: (t: (text: string) => string) => t('walletNotif.refund_error'),
  },
  stake: {
    success: (t: (text: string) => string) => t('walletNotif.stake_success'),
    error: (t: (text: string) => string) => t('walletNotif.stake_error'),
  },
  unStake: {
    success: (t: (text: string) => string) => t('walletNotif.unStake_success'),
    error: (t: (text: string) => string) => t('walletNotif.unStake_error'),
  },
  swap: {
    success: (t: (text: string) => string) => t('walletNotif.swap_success'),
    error: (t: (text: string) => string) => t('walletNotif.swap_error'),
    txError: (t: (text: string) => string) => t('walletNotif.swap_txError'),
  },
  changeEmail: {
    success: (t: (text: string) => string) => t('walletNotif.changeEmail_success'),
    error: (t: (text: string) => string) => t('walletNotif.changeEmail_error'),
  },
  verifyEmail: {
    success: (t: (text: string) => string) => t('walletNotif.verifyEmail_success'),
    error: (t: (text: string) => string) => t('walletNotif.verifyEmail_error'),
  },
};
