import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Copy, Modal, Spinner, Success, Warning } from 'components';
import { useShallowSelector } from 'hooks';
import { setActiveModal } from 'store/modals/reducer';
import modalsSelector from 'store/modals/selectors';
import useSelector from 'store/user/selectors';
import { BG_BLUE, COLOR_TEXT_GRAY_200, COLOR_TEXT_WHITE_100 } from 'theme';
import { Modals, ModalsInitialState, State } from 'types/store';

import { modalData, ModalDataValues } from './TransactionModal.helpers';

export const TransactionModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const chainType = useShallowSelector(useSelector.getProp('chainType'));
  const { modalState } = useShallowSelector<State, ModalsInitialState>(modalsSelector.getModals);
  const { key, icon, body1, body2, isCopiable } = modalData[modalState.activeModal] as ModalDataValues;

  const scannerLink =
    chainType === 'mainnet'
      ? `https://bscscan.com/tx/${modalState.txHash}`
      : `https://sepolia.etherscan.io/tx/${modalState.txHash}`;

  const closeModal = useCallback(() => {
    dispatch(
      setActiveModal({
        activeModal: Modals.init,
        txHash: '',
        open: false,
      }),
    );
  }, [dispatch]);

  return (
    <Modal open={modalState.open} onClose={closeModal} sx={{ width: 585 }}>
      <Box
        sx={{
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          align="center"
          lineHeight="40px"
          color={COLOR_TEXT_WHITE_100}
          sx={{
            span: { color: BG_BLUE },
            pt: 1,
            mb: 3,
            fontSize: { xs: 18, sm: 18, md: 20 },
            lineHeight: '20px',
            alignSelf: { xs: 'flex-start', sm: 'flex-start', md: 'center' },
            maxWidth: { xs: 150, sm: 150, md: '100%' },
          }}
        >
          {t(`modals.${key}.title1`)} <span>{t(`modals.${key}.title2`)}</span>
        </Typography>

        {icon === 'spinner' && <Spinner />}
        {icon === 'warning' && <Warning />}
        {icon === 'success' && <Success />}

        {body1 && (
          <Typography my={3} variant="h4" textAlign="center" color={COLOR_TEXT_WHITE_100}>
            {t(`modals.${key}.body1`)}
          </Typography>
        )}

        {body2 && (
          <Typography variant="subtitle1" lineHeight="24px" textAlign="center" color={COLOR_TEXT_GRAY_200}>
            {t(`modals.${key}.body2`)}
          </Typography>
        )}

        {isCopiable && (
          <Copy sx={{ mt: 3, height: 48, maxWidth: '491px' }} copyText={scannerLink}>
            <Typography variant="body1" className="white" textTransform="none" noWrap>
              {scannerLink}
            </Typography>
          </Copy>
        )}
      </Box>
    </Modal>
  );
};
