import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, StackProps, styled, SxProps, Typography } from '@mui/material';
import { useTimeLeft } from 'hooks';
import { COLOR_TEXT_WHITE_100, FontFamilies, FontWeights } from 'theme';
import { getFormatedCounterDate } from 'utils';

const TypographyNumberStyled = styled(Typography)(({ theme }) => ({
  mb: 1,
  fontSize: 40,
  fontFamily: FontFamilies.secondary,
  fontWeight: FontWeights.fontWeightBold,

  [theme.breakpoints.down('md')]: {
    fontSize: 30,
  },
}));

export type CountdownProps = {
  timer: number;
  EndTextComponent: ReactNode | string;
  title?: string;
} & StackProps &
  SxProps;

export const Countdown: FC<CountdownProps> = ({ timer, EndTextComponent, title, sx, ...stackProps }) => {
  const { t } = useTranslation();
  const timeLeft = useTimeLeft(+timer * 1000);

  if (!timeLeft || (+timeLeft.seconds === 0 && +timeLeft.minutes === 0)) {
    return (
      <Typography
        textAlign="center"
        className="white"
        sx={{
          a: {
            color: COLOR_TEXT_WHITE_100,
            textDecoration: 'none',
            borderBottom: `1px solid ${COLOR_TEXT_WHITE_100}`,
          },
        }}
      >
        {EndTextComponent}
      </Typography>
    );
  }

  const { days, hours, minutes, seconds } = timeLeft;

  const Colon = (
    <Typography variant="h3" className="white">
      :
    </Typography>
  );

  return (
    <Stack
      {...stackProps}
      width={{ xs: 250, sm: 250, md: 300 }}
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: '88px !important', ...sx }}
    >
      {title && (
        <Typography mb={1} variant="body1" className="gray">
          {title}
        </Typography>
      )}
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Stack alignItems="center">
          <TypographyNumberStyled className="white">{getFormatedCounterDate(days)}</TypographyNumberStyled>
          <Typography variant="body2" className="s white">
            {t('globalHelpers.days')}
          </Typography>
        </Stack>
        {Colon}
        <Stack alignItems="center">
          <TypographyNumberStyled className="white">{getFormatedCounterDate(hours)}</TypographyNumberStyled>
          <Typography variant="body2" className="s white">
            {t('globalHelpers.hours')}
          </Typography>
        </Stack>
        {Colon}
        <Stack alignItems="center">
          <TypographyNumberStyled className="white">{getFormatedCounterDate(minutes)}</TypographyNumberStyled>
          <Typography variant="body2" className="s white">
            {t('globalHelpers.minutes')}
          </Typography>
        </Stack>
        {Colon}
        <Stack alignItems="center" minWidth={57}>
          <TypographyNumberStyled className="white">{getFormatedCounterDate(seconds)}</TypographyNumberStyled>
          <Typography variant="body2" className="s white">
            {t('globalHelpers.seconds')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
