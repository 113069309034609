import { FC } from 'react';
import { BoxProps, SvgIcon, SvgIconProps, useMediaQuery, useTheme } from '@mui/material';

type ImageBackgroundProps = {
  width: {
    xs: number;
    md: number;
  };
  height: {
    xs: number;
    md: number;
  };
};

export const ImageBackground: FC<ImageBackgroundProps> = ({ width, height }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const widthLength = isMd ? width.md : width.xs;
  const heightLength = isMd ? height.md : height.xs;
  return (
    <SvgIcon
      viewBox={`0 0 ${width} ${height}`}
      sx={{
        width,
        height,
        fill: 'none',
      }}
    >
      <g filter="url(#filter0_b_4216_5820)">
        <rect width={widthLength} height={heightLength} rx="32" fill="white" fillOpacity="0.3" />
        <rect x="0.5" y="0.5" width={widthLength - 1} height={heightLength - 1} rx="31.5" stroke="#575676" />
      </g>
      <defs>
        <filter
          id="filter0_b_4216_5820"
          x="-40"
          y="-40"
          width="422"
          height="1000"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4216_5820" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4216_5820" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  );
};
