import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Triangle: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 15 13"
    sx={{
      width: '15px',
      height: '13px',
      path: {
        fill: '#FFBD57',
      },
      ...sx,
    }}
  >
    <path
      d="M5.74458 1.00884C6.51392 -0.326846 8.44137 -0.326844 9.21072 1.00884L13.9586 9.25177C14.7266 10.5851 13.7642 12.25 12.2255 12.25H2.72979C1.1911 12.25 0.228734 10.5851 0.996722 9.25177L5.74458 1.00884Z"
      fill="currentColor"
    />
  </SvgIcon>
);
