import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import {
  ArrowRight,
  BurgerIcon,
  CheckIcon,
  Close,
  CoinIcon,
  CopyIcon,
  CrossInRound,
  HomeIcon,
  InstagramIcon,
  LanguageIcon,
  Loading,
  LoadingSimple,
  LogoBlackLittle,
  LogoSmall,
  MainLogoIcon,
  MediumIcon,
  OutIcon,
  Success,
  TelegramIcon,
  Triangle,
  TwitterIcon,
  TyzIcon,
  WalletIcon,
  Warning,
} from './components';

/* eslint-disable @typescript-eslint/no-shadow */
export enum IconKey {
  Close = 'Close',
  CoinIcon = 'CoinIcon',
  InstagramIcon = 'InstagramIcon',
  LogoBlackLittle = 'LogoBlackLittle',
  MainLogoIcon = 'MainLogoIcon',
  MediumIcon = 'MediumIcon',
  OutIcon = 'OutIcon',
  TelegramIcon = 'TelegramIcon',
  TwitterIcon = 'TwitterIcon',
  CopyIcon = 'CopyIcon',
  CrossInRound = 'CrossInRound',
  Loading = 'Loading',
  LoadingSimple = 'LoadingSimple',
  Warning = 'Warning',
  LanguageIcon = 'LanguageIcon',
  WalletIcon = 'WalletIcon',
  BurgerIcon = 'BurgerIcon',
  HomeIcon = 'HomeIcon',
  ArrowRight = 'ArrowRight',
  CheckIcon = 'CheckIcon',
  Triangle = 'Triangle',
  TyzIcon = 'TyzIcon',
  LogoSmall = 'LogoSmall',
  Success = 'Success',
}

/* eslint-enable @typescript-eslint/no-shadow */
export const Icon: Record<IconKey, FC<SvgIconProps>> = {
  MainLogoIcon,
  TwitterIcon,
  TelegramIcon,
  MediumIcon,
  InstagramIcon,
  Close,
  LogoBlackLittle,
  CoinIcon,
  OutIcon,
  CopyIcon,
  CrossInRound,
  Loading,
  LoadingSimple,
  Warning,
  LanguageIcon,
  WalletIcon,
  BurgerIcon,
  HomeIcon,
  ArrowRight,
  CheckIcon,
  Triangle,
  TyzIcon,
  LogoSmall,
  Success,
};
