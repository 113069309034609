import { FC } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { TokenTabsSkeleton } from 'modules/crowdsale/components';
import { useFormatTokensListReturnType } from 'modules/crowdsale/hooks/useFormatTokensList/useFormatTokensList.types';
import { BG_BUTTON_BLUE_LIGHT, BG_GRAY_800, BORDER_RADIUS_SMALL, COLOR_TEXT_WHITE_100, FontWeights } from 'theme';

interface TokensTabsProps {
  activeSendToken: number;
  onChangeActiveSendToken: (newValue: number) => void;
  tokensList: useFormatTokensListReturnType;
  isLoading?: boolean;
}

export const TokensTabs: FC<TokensTabsProps> = ({
  activeSendToken,
  onChangeActiveSendToken,
  tokensList,
  isLoading = false,
}) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={1} width="100% !important">
      {isLoading && !tokensList.length && <TokenTabsSkeleton width={124} sx={{ borderRadius: BORDER_RADIUS_SMALL }} />}
      {tokensList.map(({ symbol, image }, index) => (
        <Grid
          // always only 6 array members (not interactive rerender)
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={4}
          sm={4}
          md={2}
        >
          <Button
            color="secondary"
            onClick={() => onChangeActiveSendToken(index)}
            startIcon={
              <Box component="img" src={image} sx={{ mr: { xs: 0.5, sm: 0.5, md: 1 }, width: 26, height: 26 }} />
            }
            sx={{
              px: 0,
              minWidth: { xs: '100%', sm: '100%', md: 124 },
              height: 46,
              background: activeSendToken === index ? BG_BUTTON_BLUE_LIGHT : BG_GRAY_800,
              color: COLOR_TEXT_WHITE_100,
              fontWeight: FontWeights.fontWeightBold,
              borderRadius: BORDER_RADIUS_SMALL,
              fontSize: { xs: 12, sm: 12, md: 14 },
            }}
          >
            {symbol}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
