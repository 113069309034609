import { FC } from 'react';
import { Box } from '@mui/material';

import srcIcon from './settings.png';
import srcBackground from './settingsBackground.png';

export const SettingsIcon: FC = () => (
  <Box position="relative" width="62px" height="62px">
    <img
      src={srcBackground}
      alt="DashboardIconBackground"
      style={{ position: 'absolute', top: -2, left: 0, width: 'auto', height: '62px' }}
    />
    <img
      src={srcIcon}
      alt="DashboardIcon"
      style={{
        position: 'absolute',
        top: 7,
        width: 'auto',
        height: '51px',
      }}
    />
  </Box>
);
