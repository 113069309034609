import { fork } from 'redux-saga/effects';

import activateTwoFa from './activateTwoFa';
import confirmTwoFaActivation from './confirmTwoFaActivation';
import disableTwoFa from './disableTwoFa';
import getTwoFaStatus from './getTwoFaStatus';
import getInfo from './getUserInfo';
import login from './login';
import loginTwoFa from './loginTwoFa';
import logout from './logout';
import requestPasswordResetSaga from './requestPasswordReset';
import requestRegistrationCodeSaga from './requestRegistrationCode';
import resetPasswordSaga from './resetPassword';
import signup from './signup';
import sendCode from './verifyRegistration';

export default function* referralSagas() {
  yield fork(signup);
  yield fork(sendCode);
  yield fork(requestRegistrationCodeSaga);
  yield fork(getInfo);
  yield fork(logout);
  yield fork(login);
  yield fork(loginTwoFa);
  yield fork(activateTwoFa);
  yield fork(confirmTwoFaActivation);
  yield fork(disableTwoFa);
  yield fork(requestPasswordResetSaga);
  yield fork(resetPasswordSaga);
  yield fork(getTwoFaStatus);
}
