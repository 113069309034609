import { FC, PropsWithChildren } from 'react';
import { Close } from '@mui/icons-material';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { SupportButton } from 'modules/dashboard/containers';
import { BORDER_RADIUS_PROGRESS_BAR } from 'theme';

import { IconTyzWhite } from '../../Icon/Components';

export const PanelBackground: FC<
  PropsWithChildren & {
    toMainPage: () => void;
    setPanelOpen: (open: boolean) => void;
    width: number;
    isPanelOpen: boolean;
    role: string;
    openSupportPopup: () => void;
  }
> = ({ children, width, toMainPage, setPanelOpen, isPanelOpen, role, openSupportPopup }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const transformParams = isPanelOpen ? 'translateX(0)' : `translateX(${isSmallScreen ? '100%' : '0'})`;
  const panelPosition = isSmallScreen ? 'right' : 'left';

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 50,
        width: `${width}px`,
        transform: transformParams,
        transition: 'transform 0.3s ease-in-out',
        minHeight: '100%',
        [panelPosition]: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: isSmallScreen
          ? `${BORDER_RADIUS_PROGRESS_BAR} 0 0 ${BORDER_RADIUS_PROGRESS_BAR}`
          : `0 ${BORDER_RADIUS_PROGRESS_BAR} ${BORDER_RADIUS_PROGRESS_BAR} 0`,
        padding: '20px',
        boxShadow: `0px 0px 18px rgba(0, 0, 0, ${isPanelOpen ? 0.5 : 0})`,
        background: `linear-gradient(to left top, rgba(68, 122, 194, 0.8), rgba(22, 22, 54, 0.5), #161636), #141434`,
        backgroundSize: '925px 725px, cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom, top',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          display="flex"
          width="226px"
          sx={
            isSmallScreen
              ? {
                  height: '63px',
                  marginTop: '10px',
                  alignContent: 'flex-start',
                  justifyContent: 'space-between',
                }
              : {
                  height: '50px',
                  marginTop: '23px',
                  justifyContent: 'center',
                }
          }
        >
          <IconButton onClick={toMainPage}>
            <IconTyzWhite sx={isSmallScreen ? { width: 112, height: 39 } : {}} />
          </IconButton>
          {isSmallScreen && (
            <IconButton onClick={() => setPanelOpen(false)}>
              <Close sx={{ color: 'white', width: '44px', height: '44px' }} />
            </IconButton>
          )}
        </Box>

        {children}
        {isSmallScreen && role === 'user' && <SupportButton sx={{ marginTop: '46px' }} openPopup={openSupportPopup} />}
      </Box>
    </Box>
  );
};
