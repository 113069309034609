import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Box, BoxProps, SxProps, Typography } from '@mui/material';
import { useWindowState } from 'hooks';
import { BG_GRAY_600, COLOR_TEXT_WHITE_100 } from 'theme';
import { FontWeights } from 'theme/Typography';
import { BG_BUTTON_BLUE_LIGHT, BORDER_RADIUS_PROGRESS_BAR } from 'theme/variables';

import { ProgressLevel } from './components/Level';

export type ProgressBarProps = {
  current: number;
  total: number;
  hardCapValue?: number;
  softCapValue?: number;
  withLevels?: boolean;
} & BoxProps &
  SxProps;

export const ProgressBar: FC<ProgressBarProps> = ({
  current,
  total,
  withLevels,
  hardCapValue = 0,
  softCapValue = 0,
  sx,
  ...boxProps
}) => {
  const { width } = useWindowState();
  const progress = Math.floor((current / total) * 100);
  const softCapProgressValue = Math.floor(((softCapValue || 0) / total) * 100);

  return (
    <Box sx={{ position: 'relative', mt: 10, width: '100%' }}>
      <Box
        {...boxProps}
        sx={{
          padding: '6px',
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          height: 42,
          background: BG_GRAY_600,
          borderRadius: BORDER_RADIUS_PROGRESS_BAR,
          ...sx,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 6,
            width: `calc(${progress}% - 12px)`,
            height: 30,
            background: BG_BUTTON_BLUE_LIGHT,
            borderTopLeftRadius: BORDER_RADIUS_PROGRESS_BAR,
            borderBottomLeftRadius: BORDER_RADIUS_PROGRESS_BAR,
            borderTopRightRadius: current >= total ? BORDER_RADIUS_PROGRESS_BAR : 0,
            borderBottomRightRadius: current >= total ? BORDER_RADIUS_PROGRESS_BAR : 0,
          }}
        />

        <Typography
          variant="body1"
          sx={{
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            strong: { fontWeight: FontWeights.fontWeightRegular },
            color: COLOR_TEXT_WHITE_100,
            fontSize: { xs: 14, md: 20 },
          }}
        >
          <Trans
            i18nKey="buyTokenPage.progressBar"
            values={{
              soldAmount: current,
              totalAmount: total,
            }}
            components={{ strong: <strong /> }}
          />
        </Typography>
      </Box>

      {withLevels && (
        <>
          <ProgressLevel isMax type="top" title="Hard Cap" subtitle={hardCapValue.toString()} />
          {+width >= 900 ? (
            <ProgressLevel
              position={softCapProgressValue}
              type="bottom"
              title="Soft Cap"
              subtitle={softCapValue.toString()}
            />
          ) : (
            <Typography mt={2} variant="body1" className="white" fontSize={{ xs: 16, md: 18 }}>
              Softcap: {softCapValue || 0} Tyz
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
