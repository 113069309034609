import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { flexHelper } from 'utils';

import { COLOR_TEXT_WHITE_100 } from '../index';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiListItemOverrides = (theme: Theme): ComponentsOverrides['MuiListItem'] => ({
  root: {
    marginBottom: 1,
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
    '& .MuiListItemText-root': {
      color: COLOR_TEXT_WHITE_100,
      fontSize: 16,
      lineHeight: '24px',
      position: 'relative',
      ...flexHelper('flex-start', 'flex-start'),
      margin: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
        lineHeight: '17px',
      },
    },
  },
});

export const getMuiListDefaultProps = (): ComponentsProps['MuiListItem'] => ({
  disablePadding: true,
  // disableGutters: true,
});
export const getMuiListItemTextDefaultProps = (): ComponentsProps['MuiListItemText'] => ({
  disableTypography: true,
});
