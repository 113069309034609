import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 30 31"
    sx={{
      width: '30px',
      height: '31px',
      color: 'transparent',
      ...sx,
    }}
  >
    <path d="M8 16.5L12 20.5L22 10.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="1" y="1.5" width="28" height="28" rx="14" stroke="white" strokeWidth="2" />
  </SvgIcon>
);
