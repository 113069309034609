import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 26 14"
    sx={{
      width: '26px',
      height: '14px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 13L24.6854 7.70711C25.1049 7.31658 25.1049 6.68342 24.6854 6.29289L19 1"
      stroke="#191836"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7 1L1.31461 6.29289C0.895129 6.68342 0.895128 7.31658 1.31461 7.70711L7 13"
      stroke="#191836"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);
