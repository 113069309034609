import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Box, BoxProps, Button, Stack } from '@mui/material';
import { COLOR_TEXT_BLUE_100 } from 'theme';

import { DateDownButtonIcon, DateUpButtonIcon } from '../Icon';

type DateButtonProps = {
  startDate: Date | null;
  endDate: Date | null;
  openCalendars: boolean;
  setOpenCalendars: (value: boolean) => void;
  clearDateFilter: () => void;
} & BoxProps;

export const DateButton: FC<DateButtonProps> = ({
  startDate,
  endDate,
  openCalendars,
  setOpenCalendars,
  clearDateFilter,
  sx,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={sx}>
      <Stack
        direction="row"
        sx={{
          width: 'fit-content',
          mt: {
            lg: '13px',
            xs: '13px',
          },
        }}
      >
        <Button
          onClick={() => setOpenCalendars(!openCalendars)}
          sx={{
            minWidth: '107px',
            height: 26,
            background: '#F0F0F0',
            '&:hover': {
              background: '#F0F0F0',
            },
            color: COLOR_TEXT_BLUE_100,
            textTransform: 'none',
            font: 14,
            borderRadius: '7.5px',
          }}
        >
          <Box sx={{ mr: '3px' }}>{t('dashboard.clientDashboard.date')}</Box>
          {!openCalendars && <DateDownButtonIcon />}
          {openCalendars && <DateUpButtonIcon />}
        </Button>
        {startDate && endDate && (
          <CloseIcon
            onClick={clearDateFilter}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            style={{ color: 'red' }}
          />
        )}
      </Stack>
    </Box>
  );
};
