import { GAS_PRICE_MULTIPLIER } from 'appConstants';
import i18n from 'i18next';
import { takeLatest } from 'redux-saga/effects';
import { erc20Abi } from 'services/WalletService/config/abi';
import { notifyText } from 'services/WalletService/config/constants';
import { error, request, success } from 'store/api/actions';
import { setActiveModal } from 'store/modals/reducer';
import { call, put, select } from 'typed-redux-saga';
import { Erc20Abi, Modals } from 'types';
import { ContractErrors } from 'types/store/errors';
import { getToastMessage, toDecimals } from 'utils';
import { AbiItem } from 'web3-utils';

import { approve } from '../actions';
import actionTypes from '../actionTypes';
import userSelector from '../selectors';

export function* approveSaga({
  type,
  payload: { web3Provider, spenderAddress, amount, tokenAddress, isWithDecimals },
}: ReturnType<typeof approve>) {
  yield* put(request(type));
  const myAddress: string = yield select(userSelector.getProp('address'));

  try {
    const tokenContract: Erc20Abi = yield new web3Provider.eth.Contract(erc20Abi as AbiItem[], spenderAddress);

    let amountWithDecimals = amount;
    let decimals = '18';

    if (!isWithDecimals) {
      decimals = yield* call(tokenContract.methods.decimals().call);
      amountWithDecimals = toDecimals(amount, +decimals);
    }

    const allowance: string = yield call(tokenContract.methods.allowance(myAddress, tokenAddress).call);
    if (+allowance < +amountWithDecimals) {
      yield* put(
        setActiveModal({
          activeModal: Modals.ApprovePending,
          open: true,
        }),
      );

      const gasPrice = yield* call(web3Provider.eth.getGasPrice);
      yield call(tokenContract.methods.approve(tokenAddress, amountWithDecimals as string).send, {
        from: myAddress,
        to: spenderAddress,
        gasPrice: +gasPrice * GAS_PRICE_MULTIPLIER,
      });

      getToastMessage('success', notifyText.approve.success(i18n.t));
    }

    yield* put(success(type));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(err);

    if (err.code === 4001) {
      yield put(
        setActiveModal({
          activeModal: Modals.ApproveRejected,
          open: true,
        }),
      );
      getToastMessage('error', notifyText.approve.error(i18n.t));
      throw new Error(ContractErrors.approveReject);
    }

    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.APPROVE, approveSaga);
}
