import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { useShallowSelector } from 'hooks';
import { useChangeEmailForm } from 'modules/layout/hooks';
import uiSelector from 'store/ui/selectors';
import actionTypes from 'store/user/actionTypes';
import userSelector from 'store/user/selectors';
import { BORDER_RADIUS_DEFAULT, COLOR_TEXT_GRAY_200, COLOR_TEXT_WHITE_100 } from 'theme';
import { RequestStatus } from 'types';
import { getRequestStatus } from 'utils';

export const LoginModal: FC<ModalProps> = ({ onClose, open }) => {
  const { t } = useTranslation();

  const email = useShallowSelector(userSelector.getProp('email'));
  const uiState = useShallowSelector(uiSelector.getUI);

  const isSendCodeSubmitting = getRequestStatus(uiState[actionTypes.LINK_EMAIL]);
  const isVerifySubmitting = getRequestStatus(uiState[actionTypes.VERIFY_EMAIL]);
  const isVerifySuccess = getRequestStatus(uiState[actionTypes.VERIFY_EMAIL], RequestStatus.SUCCESS);

  const { changeEmailData, validationErrors, handleChangeInput, handleError, handleSendCode, handleVerify } =
    useChangeEmailForm(email);

  const isSendCodeDisabled = validationErrors.email || isSendCodeSubmitting || isVerifySubmitting;
  const isLoginDisabled = validationErrors.code || isSendCodeSubmitting || isVerifySubmitting;

  useEffect(() => {
    if (isVerifySuccess) {
      onClose();
    }
  }, [isVerifySuccess, onClose]);

  return (
    <Modal open={open} onClose={onClose} title={t('modals.modalLogin.title')}>
      <Stack spacing={2}>
        <Stack spacing={0.5}>
          <Typography variant="body2" color={COLOR_TEXT_WHITE_100} mt={3}>
            {t('modals.modalLogin.email')}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder={t('modals.modalLogin.email')}
            name="email"
            value={changeEmailData.email}
            fullWidth
            disabled={isSendCodeSubmitting || isVerifySubmitting}
            onChange={(e) => handleChangeInput(e.target.value, 'email')}
            onBlur={(e) => handleError(e.target.value, 'email')}
            error={validationErrors.email}
          />
          {!!email && (
            <Typography variant="subtitle1" color={COLOR_TEXT_GRAY_200} mt={1}>
              {t('modals.modalLogin.emailHelper')}
            </Typography>
          )}
        </Stack>
        <Button
          fullWidth
          variant="contained"
          size="medium"
          disabled={isSendCodeDisabled}
          type="submit"
          onClick={handleSendCode}
          sx={{
            mt: 4.5,
            textTransform: 'none',
            borderRadius: BORDER_RADIUS_DEFAULT,
          }}
        >
          {!email ? t('modals.modalLogin.buttonSendCode') : t('modals.modalLogin.buttonSendCodeAgain')}
        </Button>

        <Stack spacing={0.5}>
          <Typography variant="body2" color={COLOR_TEXT_WHITE_100}>
            {t('modals.modalLogin.codeLabel')}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder={t('modals.modalLogin.codeInput')}
            name="code"
            value={changeEmailData.code}
            fullWidth
            disabled={isSendCodeSubmitting || isVerifySubmitting}
            onChange={(e) => handleChangeInput(e.target.value, 'code')}
            onBlur={(e) => handleError(e.target.value, 'code')}
            error={validationErrors.code}
          />
        </Stack>

        <Button
          fullWidth
          variant="contained"
          size="large"
          disabled={isLoginDisabled}
          type="submit"
          onClick={handleVerify}
        >
          {t('modals.modalLogin.buttonLogin')}
        </Button>
        <Typography variant="subtitle1" color={COLOR_TEXT_GRAY_200} mt={1}>
          <b>{t('modals.modalLogin.important')}</b>
          {t('modals.modalLogin.gameAccountHelper')}
        </Typography>
      </Stack>
    </Modal>
  );
};
