import { FC, ReactNode, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { FontFamilies, FontWeights } from 'theme';

interface BoardProps {
  text: string;
  isActive: boolean;
  onClick: () => void;
  counter?: number;
  buttonIcon: ReactNode;
}

export const DashboardButton: FC<BoardProps> = ({ text, isActive, buttonIcon, onClick, counter = null }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <IconButton
      size="large"
      sx={{
        borderRadius: '10px',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 0,
        height: '50px',
        width: '230px',
        '&:hover': {
          transform: 'scale(1.05)',
        },
        '&:active': {
          transform: 'scale(1)',
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {(isHovered || isActive) && (
        <Box
          sx={{
            position: 'absolute',
            top: '-12%',
            left: '-5px',
            width: '100%',
            height: '124%',
            background: 'rgba(255,255,255,0.15)',
            borderRadius: '10px',
            pointerEvents: 'none',
          }}
        />
      )}
      {counter !== null && (
        <Box>
          <Typography
            textAlign="center"
            width="16px"
            zIndex={1}
            color="rgba(57, 114, 228, 0.67)"
            fontSize="14px"
            fontWeight={FontWeights.fontWeightExtraBold}
            fontFamily={FontFamilies.primary}
            sx={{ position: 'absolute', top: -0.25, left: 28.5, textShadow: '0 0 14px rgba(57, 114, 228, 0.33)' }}
          >
            {counter}
          </Typography>
        </Box>
      )}
      {buttonIcon}
      <Typography
        textAlign="left"
        color="white"
        fontSize="16px"
        fontFamily={FontFamilies.secondary}
        fontWeight={FontWeights.fontWeightMedium}
        sx={{ marginLeft: '6px' }}
      >
        {text}
      </Typography>
    </IconButton>
  );
};
