import { FC } from 'react';
import { Box, BoxProps, Skeleton, SxProps, Typography } from '@mui/material';
import { Icon } from 'components';
import { BORDER_DEFAULT_GRAY, BORDER_RADIUS_MEDIUM, FontWeights } from 'theme';
import { flexHelper } from 'utils/flexHelper';

type TyzTokenPriceInfo = {
  tokenPrice: string;
  isLoading?: boolean;
} & BoxProps &
  SxProps;

export const TyzTokenPriceInfo: FC<TyzTokenPriceInfo> = ({ tokenPrice, isLoading = false, sx, ...boxProps }) => {
  return (
    <Box
      {...boxProps}
      sx={{
        height: 64,
        width: 132,
        flexDirection: 'row',
        ...flexHelper(),
        borderRadius: BORDER_RADIUS_MEDIUM,
        border: BORDER_DEFAULT_GRAY,
        fontWeight: FontWeights.fontWeightRegular,
        ...sx,
      }}
    >
      <Icon.LogoSmall />
      {isLoading ? (
        <Skeleton variant="text" width={45} height={20} sx={{ ml: 1 }} />
      ) : (
        <Typography ml={1} variant="body2" className="s white">
          ${tokenPrice}
        </Typography>
      )}
    </Box>
  );
};
