import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from '@mui/material';
import { BORDER_BUTTON_GRAY, BORDER_RADIUS_MEDIUM, COLOR_TEXT_WHITE_100, FontWeights } from 'theme';

import { SourceLinkType } from './NavSourceLinks.types';

export type NavSourceLinksProps = {
  linksArr: SourceLinkType[];
  linkSx?: LinkProps['sx'];
  uppercase?: boolean;
} & LinkProps;

export const NavSourceLinks: FC<NavSourceLinksProps> = ({ linksArr, linkSx, uppercase = false, ...linkProps }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <>
      {linksArr.map(({ title, links }, index) => (
        <Link
          // not rerendering items
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          {...linkProps}
          href={language === 'ru' ? links.ru : links.en}
          target="_blank"
          rel="norefferer"
          sx={{
            textTransform: uppercase ? 'uppercase' : 'capitalize',
            px: 1,
            height: 'fit-content',
            fontSize: 16,
            lineHeight: '24px',
            fontWeight: FontWeights.fontWeightMedium,
            color: COLOR_TEXT_WHITE_100,
            borderRadius: BORDER_RADIUS_MEDIUM,
            border: '1px solid transparent',
            whiteSpace: 'nowrap',
            '& + &': {
              // ml: 2,
            },

            '&:hover': {
              border: BORDER_BUTTON_GRAY,
            },
            ...linkSx,
          }}
        >
          {t(`header.nav.${title}`)}
        </Link>
      ))}
    </>
  );
};
