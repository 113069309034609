import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';

export type TabPanelProps = {
  index: number;
  value: number;
  sx?: BoxProps['sx'];
} & PropsWithChildren &
  BoxProps;

export const TabPanel: FC<TabPanelProps> = ({ children, value, index, sx, ...boxProps }) => (
  <Box {...boxProps} sx={{ display: value === index ? 'flex' : 'none', ...sx }}>
    {children}
  </Box>
);
