import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DateDownButtonIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 10 7"
    sx={{
      width: '10px',
      height: '7px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.5L4.5286 5.29026C4.78894 5.56991 5.21106 5.56991 5.4714 5.29026L9 1.5"
      stroke="#191836"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);
