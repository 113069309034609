/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';
import { COLOR_TEXT_GRAY_200, COLOR_TEXT_WHITE_100, FontFamilies } from 'theme';

const TypographyResend = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilies.primary,
  fontSize: '14px',
  lineHeight: '14px',
  color: COLOR_TEXT_GRAY_200,
  textAlign: 'center',
  '&:hover': {
    color: COLOR_TEXT_WHITE_100,
    cursor: 'pointer',
  },
}));

type ResendTimerProps = {
  onClick: () => void;
};

export const ResendTimer: FC<ResendTimerProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const [time, setTime] = useState(30);
  const startTimer = () => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
        return;
      }
      clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  };
  useEffect(startTimer);

  const resetTimer = () => {
    if (time > 0) {
      return;
    }
    setTime(30);
    startTimer();
    onClick();
  };

  const timeText = time
    ? `${t('referralSystem.signup.resendTimer')}${time}${t('referralSystem.signup.timerSeconds')}`
    : t('referralSystem.signup.resendTimerExpired');

  return <TypographyResend onClick={resetTimer}>{timeText}</TypographyResend>;
};
