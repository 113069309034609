import { FC } from 'react';
import { Box, BoxProps, SxProps, Typography } from '@mui/material';
import { Icon } from 'components';
import { BORDER_DEFAULT_GRAY, BORDER_RADIUS_DEFAULT } from 'theme';
import { flexHelper } from 'utils/flexHelper';

export type BalanceProps = {
  balance: string;
  icon?: string;
} & BoxProps &
  SxProps;

export const Balance: FC<BalanceProps> = ({ balance, icon, sx, ...boxProps }) => {
  return (
    <Box
      {...boxProps}
      sx={{
        px: 1,
        height: 36,
        flexDirection: 'row',
        ...flexHelper(),
        background: 'transparent',
        border: BORDER_DEFAULT_GRAY,
        borderRadius: BORDER_RADIUS_DEFAULT,
        ...sx,
      }}
    >
      <Typography variant="body2" className="s gray" mr={1} maxWidth={{ xs: 50, sm: 50, md: 102 }} noWrap>
        {balance}
      </Typography>
      {icon ? <Box component="img" src={icon} sx={{ width: 17, height: 16 }} /> : <Icon.TyzIcon />}
    </Box>
  );
};
