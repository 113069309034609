import { createAction } from '@reduxjs/toolkit';
import { BuyTokenReq, GetCrowdsaleDataReq, RequestWithWeb3Provider } from 'types/requests';

import actionTypes from './actionTypes';

export const getTokensList = createAction(actionTypes.GET_TOKENS_LIST);
export const getCrowdsaleData = createAction<GetCrowdsaleDataReq>(actionTypes.GET_CROWDSALE_DATA);
export const getCrowdsaleAddress = createAction(actionTypes.GET_CROWDSALE_ADDRESS);
export const getTokensBalances = createAction<RequestWithWeb3Provider>(actionTypes.GET_TOKENS_BALANCES);
export const getTyzTokenBalance = createAction<RequestWithWeb3Provider>(actionTypes.GET_TYZ_TOKEN_BALANCE);

export const onBuyToken = createAction<BuyTokenReq>(actionTypes.BUY_TOKEN);
export const onClaim = createAction<RequestWithWeb3Provider>(actionTypes.CLAIM);
export const onRefund = createAction<RequestWithWeb3Provider>(actionTypes.REFUND);
