import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { put, takeLatest } from 'typed-redux-saga';
import { UsdRateRequired } from 'types';
import { camelize } from 'utils';

import { getTokensList } from '../actions';
import actionTypes from '../actionTypes';
import { updateCrowdsaleState } from '../reducer';

export function* getTokensListSaga({ type }: ReturnType<typeof getTokensList>) {
  yield* put(apiActions.request(type));

  try {
    const { data } = yield* baseApi.getIdoList();

    const sortingTokensList = (camelize(data) as UsdRateRequired[]).sort(
      (itemOne, itemTwo) => Number(itemOne.id) - Number(itemTwo.id),
    );

    yield* put(
      updateCrowdsaleState({
        tokensList: sortingTokensList,
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_TOKENS_LIST, getTokensListSaga);
}
