/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { BackButtonIcon } from 'modules/dashboard/components';
import { BlockUserPopup } from 'modules/dashboard/components/Popups/Components/Profile';
import { NetworkView, ProfileInfo, TableWrapper } from 'modules/dashboard/containers';
import { TableType } from 'modules/dashboard/containers/DashboardTable/useTable';
import { dateComparator, numberComparator, statusComparator, stringComparator } from 'modules/dashboard/util';
import { TableItem } from 'types/dashboard';
import { SelectedUserInfo } from 'types/store/dashboard';

import { Spinner } from '../../../../../components';
import { ButtonData } from '../../ConfirmationPage';

export const ProfilePage: FC<{ handleReturn: () => void; userInfo: SelectedUserInfo }> = ({
  handleReturn,
  userInfo,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [activeFrameId, setActiveFrame] = useState(0);
  const [totalWithdrawalAmount, setTotalWithdrawalAmount] = useState(0);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const { t } = useTranslation();
  const withdrawalComparators = [numberComparator, dateComparator, statusComparator(t)];

  const paymentsComparators = [numberComparator, dateComparator, stringComparator, stringComparator, stringComparator];

  const [selectedUser, setSelectedUser] = useState<{ email: string; blocked: boolean }>();
  const [isBlockUserOpen, setBlockUserIsOpen] = useState(false);
  const [buttonsData, setButtonsData] = useState<ButtonData[]>([]);
  const [paymentItems, setPaymentItems] = useState<TableItem[]>([]);
  const [withdrawalItems, setWithdrawalItems] = useState<TableItem[]>([]);

  const onUpdateWithdrawalItems = (items: TableItem[]) => {
    setTotalWithdrawalAmount(
      userInfo.withdrawalHistory.reduce((acc, item) => {
        if (items.some((i) => i.id === item.id)) {
          return acc + +item.amount;
        }
        return acc;
      }, 0),
    );
  };

  const onUpdatePaymentsItems = (items: TableItem[]) => {
    const newButtonsData: ButtonData[] = [];
    items.forEach((item) => {
      newButtonsData.push({
        id: item.id,
        cellIndex: 4,
        button: (
          <IconButton
            onClick={() => {
              setSelectedUser({ email: item.data[2], blocked: item.status ?? false });
              setBlockUserIsOpen(true);
            }}
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: '8px',
              left: 0,
              transition: 'transform 0.15s ease-in-out',
              '&:active': {
                transform: 'scale(0.92)',
              },
            }}
          >
            <Typography color="#0858E8" fontSize="12px" textAlign="start">
              {item.status ? t('dashboard.profile.unlock') : t('dashboard.profile.block')}
            </Typography>
          </IconButton>
        ),
      });
    });
    setButtonsData(newButtonsData);
    setTotalPaymentAmount(
      userInfo.paymentsHistory.reduce((acc, item) => {
        if (items.some((i) => i.id === item.id)) {
          return acc + +item.amount;
        }
        return acc;
      }, 0),
    );
  };

  useEffect(() => {
    if (paymentItems) {
      onUpdatePaymentsItems(paymentItems);
    }
  }, [paymentItems]);

  useEffect(() => {
    if (userInfo) {
      setPaymentItems(
        userInfo.paymentsHistory.map((item) => {
          let isBlocked = '-';
          if (item.isBlocked !== null) {
            isBlocked = item.isBlocked ? t('dashboard.confirmation.blocked') : t('dashboard.confirmation.unlocked');
          }

          return {
            id: item.id,
            date: item.date,
            status: item.isBlocked === null ? undefined : item.isBlocked,
            data: [`${item.amount}`, format(new Date(item.date), 'dd.MM.yyyy'), item.fromWhom, item.project, isBlocked],
          };
        }),
      );
      setWithdrawalItems(
        userInfo.withdrawalHistory.map((item) => {
          return {
            id: item.id,
            date: item.date,
            data: [
              `${+item.amount}`,
              format(new Date(item.date), 'dd.MM.yyyy'),
              t(`dashboard.adminDashboard.${item.status.toLowerCase().trim()}`),
            ],
          };
        }),
      );
    }
  }, [userInfo, t]);

  const withdrawalColumns = [
    t('dashboard.adminDashboard.paymentAmount'),
    t('dashboard.adminDashboard.date'),
    t('dashboard.adminDashboard.status'),
  ];

  const paymentColumns = [
    t('dashboard.adminDashboard.paymentAmount'),
    t('dashboard.adminDashboard.date'),
    t('dashboard.adminDashboard.fromWhom'),
    t('dashboard.clientDashboard.project'),
    t('dashboard.adminDashboard.status'),
  ];

  const containerSx = isSmallScreen
    ? {
        width: '342px',
        marginTop: '24px',
      }
    : {
        width: '1028px',
        marginLeft: '67px',
      };
  return (
    <Box sx={containerSx}>
      {selectedUser && (
        <BlockUserPopup
          isOpen={isBlockUserOpen}
          closePopup={() => setBlockUserIsOpen(false)}
          email={selectedUser.email}
          isBlocked={selectedUser.blocked}
        />
      )}
      <IconButton
        sx={{
          marginTop: '35px',
          transition: 'transform 0.15s ease-in-out',
          '&:active': {
            transform: 'scale(0.92)',
          },
        }}
        onClick={handleReturn}
      >
        <BackButtonIcon />
      </IconButton>
      <ProfileInfo changeFrame={setActiveFrame} user={userInfo.user} twoFaEnabled={userInfo.twoFaEnabled} />
      <Box marginTop="16px" height={isSmallScreen ? '696px' : '556px'} width="100%">
        {activeFrameId === 0 && <NetworkView user={userInfo.user} />}
        {activeFrameId === 1 && (
          <TableWrapper
            columnComparators={withdrawalComparators}
            columns={withdrawalColumns}
            defaultFilename="withdrawal"
            items={withdrawalItems}
            itemsPerPage={4}
            onUpdateItems={onUpdateWithdrawalItems}
            tableType={TableType.Compact}
            totalAmount={totalWithdrawalAmount}
            viewProfile={null}
            placeHolder={t('dashboard.profile.searchPlaceholder')}
          />
        )}
        {activeFrameId === 2 && (
          <TableWrapper
            buttonsForCells={buttonsData}
            columnComparators={paymentsComparators}
            columns={paymentColumns}
            defaultFilename="payments"
            dropDownColumnIndex={3}
            items={paymentItems}
            itemsPerPage={4}
            onUpdateItems={onUpdatePaymentsItems}
            tableType={TableType.Compact}
            totalAmount={totalPaymentAmount}
            viewProfile={null}
          />
        )}
      </Box>
    </Box>
  );
};

export const LoadingProfilePage: FC<{ handleReturn: () => void; userInfo: SelectedUserInfo | null }> = ({
  handleReturn,
  userInfo,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return userInfo ? (
    <ProfilePage handleReturn={handleReturn} userInfo={userInfo} />
  ) : (
    <div>
      <IconButton
        sx={{
          marginTop: '35px',
          transition: 'transform 0.15s ease-in-out',
          '&:active': {
            transform: 'scale(0.92)',
          },
        }}
        onClick={handleReturn}
      >
        <BackButtonIcon />
      </IconButton>
      <div
        className="flex justify-center w-full"
        style={{
          height: isSmallScreen ? '696px' : '556px',
          transform: isSmallScreen ? 'translateY(30%)' : 'translateY(40%)',
        }}
      >
        <Spinner size="l" />
      </div>
    </div>
  );
};
