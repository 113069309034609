const LOGIN = 'LOGIN' as const;
const APPROVE = 'APPROVE' as const;
const GET_TOKEN_BALANCE = 'GET_TOKEN_BALANCE' as const;
const GET_NATIVE_BALANCE = 'GET_NATIVE_BALANCE' as const;
const UPDATE_USER_DATA = 'UPDATE_USER_DATA' as const;
const SEND_FEEDBACK = 'SEND_FEEDBACK' as const;
const GET_ACCOUNT_INFO = 'GET_ACCOUNT_INFO' as const;
const LINK_EMAIL = 'LINK_EMAIL' as const;
const VERIFY_EMAIL = 'VERIFY' as const;

export default {
  LOGIN,
  APPROVE,
  GET_TOKEN_BALANCE,
  GET_NATIVE_BALANCE,
  UPDATE_USER_DATA,
  SEND_FEEDBACK,
  GET_ACCOUNT_INFO,
  LINK_EMAIL,
  VERIFY_EMAIL,
};
