import { Box } from '@mui/material';
import { BackgroundWrapper, Spinner } from 'components';

export const LoadingScreen = () => {
  return (
    <BackgroundWrapper sx={{ position: 'fixed', width: '100vw', height: '100vh' }}>
      <Box sx={{ zIndex: 1000 }}>
        <Spinner size="xl" />
      </Box>
    </BackgroundWrapper>
  );
};
