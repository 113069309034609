import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CompareArrows: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 21 18"
    sx={{
      width: '18px',
      height: '16px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.07056 13V1M5.07056 1L1.07056 5M5.07056 1L9.07056 5M15.0706 5V17M15.0706 17L19.0706 13M15.0706 17L11.0706 13"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
