import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

import IconImage from './AvaIcon.jpeg';

export const AvaIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 72 72"
    sx={{
      width: '72px',
      height: '72px',
      fill: 'none',
      backgroundImage: `url(${IconImage})`,
      backgroundSize: 'cover',
      backgroundPosition: '-5px center',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="36" cy="36" r="36" fill="none" />
  </SvgIcon>
);
