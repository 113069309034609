import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { BackgroundWrapper, Card } from 'components';
import { Login } from 'modules/referral/containers';
import { BORDER_DEFAULT_GRAY, BORDER_RADIUS_PROGRESS_BAR } from 'theme';
import { getToastMessage } from 'utils';

export const LoginPage: FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const showToast = localStorage.getItem('showToast');
    if (showToast) {
      const toastData = JSON.parse(showToast);
      if (toastData) {
        const { type, message } = toastData;
        getToastMessage(type, message);
        localStorage.removeItem('showToast');
      }
    }
  }, []);
  return (
    <BackgroundWrapper container>
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Typography
          sx={{
            mt: {
              xs: '64px',
              md: 19,
            },
          }}
          variant="h1"
          textTransform="uppercase"
          textAlign="center"
          className="gradient"
          whiteSpace={{ xs: 'pre-wrap', sm: 'pre-wrap', md: 'nowrap' }}
          fontSize={{ xs: 66, sm: 66, md: 100, lg: 172 }}
        >
          {t('referralSystem.signup.title')}
        </Typography>
        <Card
          type="lightTransparent"
          sx={{
            mt: {
              xs: '52px',
              md: 0,
            },
            width: {
              xs: 342,
              md: 1022,
            },
            p: 0,
            mb: {
              xs: '46px',
              md: '110px',
            },
            flexDirection: 'column',
            border: BORDER_DEFAULT_GRAY,
            borderRadius: BORDER_RADIUS_PROGRESS_BAR,
          }}
        >
          <Login />
        </Card>
      </Stack>
    </BackgroundWrapper>
  );
};
