import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallowSelector } from 'hooks';
import { Logout, RootCard, TextCard } from 'modules/referral/components';
import selector from 'store/referral/selectors';

export const UserInfo: FC = () => {
  const { t } = useTranslation();

  const data = useShallowSelector(selector.getReferral);

  const { email, isEmailConfirmed } = data;

  const emailText = `${t('referralSystem.info.email')}: ${email}`;
  const isEmailConfirmedText = `${t('referralSystem.info.emailConfirmed')}: ${isEmailConfirmed}`;

  return (
    <RootCard>
      <TextCard text={emailText} />
      <TextCard text={isEmailConfirmedText} />
      <Logout />
    </RootCard>
  );
};
