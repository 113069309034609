import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps, Container, ContainerProps } from '@mui/material';
import { ShadowCenter, ShadowLeft, ShadowRight } from 'assets/img';
import { BG_BLUE_ACCENT_DARK } from 'theme';

const BG_BLUE_DARK_SHADOW_CENTER = `url(${ShadowCenter}) center 0/auto 100% no-repeat, url(${ShadowLeft}) left 250px no-repeat, url(${ShadowRight}) right 250px no-repeat, ${BG_BLUE_ACCENT_DARK}`;
const BG_BLUE_DARK_SHADOW_TOP = `url(${ShadowCenter}) center 0/1175px 975px no-repeat, url(${ShadowLeft}) left 250px no-repeat, url(${ShadowRight}) right 250px no-repeat, ${BG_BLUE_ACCENT_DARK}`;

type BackgroundWrapperProps = {
  container?: boolean;
  animate?: boolean;
  isHomePage?: boolean;
  containerSx?: ContainerProps['sx'];
} & PropsWithChildren &
  BoxProps;

export const BackgroundWrapper: FC<BackgroundWrapperProps> = ({
  container = false,
  animate = true,
  containerSx,
  children,
  isHomePage = false,
  ...boxProps
}) => {
  return (
    <>
      <Box
        {...boxProps}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          background: isHomePage ? BG_BLUE_DARK_SHADOW_CENTER : BG_BLUE_DARK_SHADOW_TOP,
          ...boxProps.sx,
        }}
      >
        {animate && (
          <>
            <div className="stars1" />
            <div className="stars2" />
            <div className="stars3" />
          </>
        )}
      </Box>

      {container ? (
        <Container
          sx={{
            pt: 6,
            background: 'transparent',
            ...containerSx,
          }}
        >
          {children}
        </Container>
      ) : (
        children
      )}
    </>
  );
};
