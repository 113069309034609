import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrowdsaleState } from 'types';

const initialState: CrowdsaleState = {
  address: '',
  tokensList: [],
  tokensBalances: {},
  userTyzTokenBalanceOnCrowdsale: '',
  availableTokensOnCrowdsale: '',
  crowdsaleData: {
    maxPurchase: '',
    minPurchase: '',
    hardCap: '',
    softCap: '',
    totalBought: '',
    saleEndTime: '',
    saleStartTime: '',
    isActive: false,
  },
};

export const crowdsaleReducer = createSlice({
  name: 'crowdsale',
  initialState,
  reducers: {
    updateCrowdsaleState: (state, action: PayloadAction<Partial<CrowdsaleState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateCrowdsaleState } = crowdsaleReducer.actions;

export default crowdsaleReducer.reducer;
