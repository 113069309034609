import { sha256 } from 'js-sha256';
import { handleBackendErrors } from 'modules/referral/utils';
import { error, request, signin, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import {
  onLogout,
  setError,
  setLoggedIn,
  setTwoFaAction,
  setTwoFaToken,
  setTwoFaType,
  updateTwoFa,
} from 'store/referral/reducer';
import { call, put, takeLatest } from 'typed-redux-saga';

import actionTypes from '../actionTypes';

export function* loginSaga({ type, payload: { email, password } }: ReturnType<typeof signin>) {
  yield* put(request(type));

  try {
    yield* put(onLogout());
    const hashedPassword: string = sha256(password);
    const response = yield* call(baseApi.login, {
      email,
      password: hashedPassword,
    });

    if ('ephemeral_token' in response.data) {
      yield* put(setTwoFaToken(response.data.ephemeral_token));
      yield* put(setTwoFaType(response.data.method));
      yield* put(setTwoFaAction('none'));
    } else {
      yield* put(setLoggedIn());
      yield* put(setTwoFaToken(''));
      yield* put(setTwoFaAction('updating'));
      yield* put(updateTwoFa());
    }

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type));
    const parsedError = handleBackendErrors(err);
    if (parsedError) {
      yield* put(setError({ [parsedError.source]: parsedError.type }));
    }
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.LOGIN, loginSaga);
}
