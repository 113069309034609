import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { BackgroundWrapper } from 'components';

import { Games } from '../../containers';

export const GamesPage: FC = () => {
  const { t } = useTranslation();
  return (
    <BackgroundWrapper container>
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Typography
          variant="h1"
          textTransform="uppercase"
          textAlign="center"
          whiteSpace={{ xs: 'pre-wrap', sm: 'pre-wrap', md: 'nowrap' }}
          fontSize={{ xs: 58, sm: 78, md: 82, lg: 104 }}
          marginTop={{ xs: '52px', sm: '52px' }}
          marginBottom={{ xs: '32px', sm: '32px' }}
          lineHeight={{ xs: '60px', sm: '80px', md: '84px', lg: '106px' }}
          fontWeight={1000}
        >
          <span
            style={{
              background: 'linear-gradient(180deg, #FFFFFF 30%, rgba(255, 255, 255, 0) 100%)',
              color: 'transparent',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              boxDecorationBreak: 'clone',
              WebkitBoxDecorationBreak: 'clone',
            }}
          >
            {t('referralSystem.games.title')}
          </span>
        </Typography>
        <Games />
      </Stack>
    </BackgroundWrapper>
  );
};
