import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useShallowSelector } from 'hooks';
import selector from 'store/dashboard/selectors';
import { Admin } from 'types/store/dashboard';

import { Popup } from '../../Popup';
import { PopupProps } from '../../PopupProps';

import { AdminInfo } from './AdminInfo';

export const AdminListPopup: FC<PopupProps> = ({ isOpen, closePopup }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  const state = useShallowSelector(selector.getDashboard);

  const [admins, setAdmins] = useState<Admin[]>([]);

  useEffect(() => {
    if (state.admins) {
      setAdmins(state.admins);
    }
  }, [state]);

  const lineSpacing = isSmallScreen ? '21px' : '18px';

  return (
    <Popup
      isOpen={isOpen}
      closePopup={closePopup}
      header={t('dashboard.settings.addingAdmin.adminList')}
      height={isSmallScreen ? '701px' : '460px'}
      width={isSmallScreen ? '342px' : '858px'}
      overflow={false}
    >
      <Box
        sx={{
          marginTop: '20px',
          maxHeight: '86%',
          overflowY: 'auto',
        }}
      >
        {admins.map((admin, index) => (
          <Box key={admin.id} sx={{ marginBottom: index < admins.length - 1 ? lineSpacing : '0', width: '100%' }}>
            <AdminInfo email={admin.email} isBlocked={admin.isLocked} />
          </Box>
        ))}
      </Box>
    </Popup>
  );
};
