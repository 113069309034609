import { DiscordIcon, InstagramIcon, RedditIcon, TelegramIcon, TwitterIcon, VkIcon } from 'components';
import { COLOR_TEXT_GRAY_200 } from 'theme';

export const termsItems = [
  {
    id: 1,
    name: 'termsOfService',
  },
  {
    id: 2,
    name: 'privacyPolicy',
  },
  {
    id: 3,
    name: 'aml',
  },
];

export const socialLinks = [
  {
    name: 'reddit',
    link: 'https://www.reddit.com/user/22SPB',
    Icon: <RedditIcon sx={{ path: { fill: COLOR_TEXT_GRAY_200 } }} />,
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/22xgame',
    Icon: <TwitterIcon sx={{ path: { fill: COLOR_TEXT_GRAY_200 } }} />,
  },
  {
    name: 'telegram',
    link: 'https://t.me/GameOf22CIS',
    Icon: <TelegramIcon sx={{ path: { fill: COLOR_TEXT_GRAY_200 } }} />,
  },
  {
    name: 'discord',
    link: 'https://discord.gg/haveQFFS73',
    Icon: <DiscordIcon sx={{ path: { fill: COLOR_TEXT_GRAY_200 } }} />,
  },
  {
    name: 'vk',
    link: 'https://vk.com/22xgame',
    Icon: <VkIcon sx={{ path: { fill: COLOR_TEXT_GRAY_200 } }} />,
  },
  {
    name: 'instagram',
    link: 'https://instagram.com/22game.official?igshid=MzMyNGUyNmU2YQ==',
    Icon: <InstagramIcon />,
  },
];
