import { FC, lazy, Suspense } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Box, Container } from '@mui/material';
import { RECAPTCHA_SITE_KEY_MAINNET, RECAPTCHA_SITE_KEY_TESTNET } from 'appConstants';
import { BackgroundWrapper } from 'components';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import userSelector from 'store/user/selectors';

import { HOME_BACKGROUND, HOME_BACKGROUND_SM } from './Home.helpers';

const Contracts = lazy(() => import('./sections/Contracts').then((module) => ({ default: module.Contracts })));
const Preview = lazy(() => import('./sections/Preview').then((module) => ({ default: module.Preview })));
const YouWant = lazy(() => import('./sections/YouWant').then((module) => ({ default: module.YouWant })));
const WhyUs = lazy(() => import('./sections/WhyUs').then((module) => ({ default: module.WhyUs })));
const Tokenomics = lazy(() => import('./sections/Tokenomics').then((module) => ({ default: module.Tokenomics })));
const Roadmap = lazy(() => import('./sections/Roadmap').then((module) => ({ default: module.Roadmap })));
const StakingSection = lazy(() =>
  import('./sections/StakingSection').then((module) => ({ default: module.StakingSection })),
);
const Partners = lazy(() => import('./sections/Partners').then((module) => ({ default: module.Partners })));
const Feedback = lazy(() => import('./sections/Feedback').then((module) => ({ default: module.Feedback })));

export const Home: FC = () => {
  const { addTokenToWallet } = useWalletConnectorContext();
  const chainType = useShallowSelector(userSelector.getProp('chainType'));

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={chainType === 'mainnet' ? RECAPTCHA_SITE_KEY_MAINNET : RECAPTCHA_SITE_KEY_TESTNET}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <BackgroundWrapper container>
          <Preview />
        </BackgroundWrapper>

        <Box sx={{ position: 'absolute', top: { xs: '100vh', md: 660 }, width: '100%', background: 'transparent' }}>
          <Container>
            <YouWant />
          </Container>
        </Box>

        <Box
          sx={{
            overflowX: 'hidden',
            pt: { xs: 85, sm: 85, md: 70 },
            background: { xs: HOME_BACKGROUND_SM, md: HOME_BACKGROUND },
            backgroundSize: { xs: '150%', md: '1920px' },
          }}
        >
          <Container>
            <WhyUs />
            <Tokenomics />
            <Roadmap />
            <StakingSection />
            <Contracts addTokenToWallet={addTokenToWallet} />
            <Partners />
            <Feedback />
          </Container>
        </Box>
      </Suspense>
    </GoogleReCaptchaProvider>
  );
};
