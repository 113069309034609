import { GAS_PRICE_MULTIPLIER } from 'appConstants';
import i18next from 'i18next';
import { ContractsNames } from 'services/WalletService/config';
import { notifyText } from 'services/WalletService/config/constants';
import apiActions from 'store/api/actions';
import { setActiveModal } from 'store/modals/reducer';
import userActionTypes from 'store/user/actionTypes';
import { approveSaga } from 'store/user/sagas/approve';
import { getTokenBalanceSaga } from 'store/user/sagas/getTokenBalance';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, StakingAbi, UserState } from 'types';
import { ContractErrors } from 'types/store/errors';
import { getContractDataByItsName, getToastMessage, toDecimals } from 'utils';

import { onStake } from '../actions';
import actionTypes from '../actionTypes';

import { getStakingDataSaga } from './getStakingData';
import { getUserStakeDataSaga } from './getUserStakeData';

export function* stakeSaga({ type, payload: { web3Provider, stakeValue } }: ReturnType<typeof onStake>) {
  yield* put(apiActions.request(type));
  const { t } = i18next;

  const { chainType, address: myAddress }: UserState = yield select(userSelector.getUser);
  const [stakingAbi, stakingContractAddress] = getContractDataByItsName(ContractsNames.staking, chainType);
  const [, tyzTokenAddress] = getContractDataByItsName(ContractsNames.token, chainType);
  try {
    const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(stakingAbi, stakingContractAddress);

    const stakeValueWithDecimals = toDecimals(stakeValue);
    yield* call(approveSaga, {
      type: userActionTypes.APPROVE,
      payload: {
        web3Provider,
        spenderAddress: tyzTokenAddress,
        amount: stakeValueWithDecimals,
        tokenAddress: stakingContractAddress,
        isWithDecimals: true,
      },
    });

    yield* put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const gasPrice = yield* call(web3Provider.eth.getGasPrice);
    const { transactionHash } = yield* call(stakingContract.methods.stake(stakeValueWithDecimals).send, {
      from: myAddress,
      to: stakingContractAddress,
      gasPrice: +gasPrice * GAS_PRICE_MULTIPLIER,
      maxFeePerGas: toDecimals(4, 9),
      maxPriorityFeePerGas: toDecimals(4, 9),
    });

    yield put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );
    getToastMessage('success', notifyText.stake.success(t));

    yield* call(getStakingDataSaga, {
      type: actionTypes.GET_STAKING_DATA,
      payload: undefined,
    });
    yield* call(getTokenBalanceSaga, {
      type: userActionTypes.GET_TOKEN_BALANCE,
      payload: { web3Provider },
    });
    yield* call(getUserStakeDataSaga, {
      type: actionTypes.GET_USER_STAKE_DATA,
      payload: { web3Provider },
    });

    yield* put(apiActions.success(type));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(err);

    if (err.code === 4001 && err.message !== ContractErrors.approveReject) {
      yield put(
        setActiveModal({
          activeModal: Modals.SendRejected,
          open: true,
        }),
      );
    }

    getToastMessage('error', notifyText.buyToken.error(t));
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.STAKE, stakeSaga);
}
