import BigNumber from 'bignumber.js/bignumber';
import { ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import crowdsaleSelector from 'store/crowdsale/selectors';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { CrowdsaleAbi, CrowdsaleState, UserState } from 'types';
import { fromDecimals, getContractDataByItsName } from 'utils';

import { getTyzTokenBalance } from '../actions';
import actionTypes from '../actionTypes';
import { updateCrowdsaleState } from '../reducer';

export function* getTyzTokenBalanceSaga({ type, payload: { web3Provider } }: ReturnType<typeof getTyzTokenBalance>) {
  yield* put(apiActions.request(type));

  const { chainType, address: myAddress }: UserState = yield select(userSelector.getUser);
  const crowdsaleContractAddress: CrowdsaleState['address'] = yield select(crowdsaleSelector.getProp('address'));
  const [crowdsaleAbi] = getContractDataByItsName(ContractsNames.crowdsale, chainType);

  try {
    const crowdsaleContract: CrowdsaleAbi = yield new web3Provider.eth.Contract(crowdsaleAbi, crowdsaleContractAddress);

    const tyzTokenBalanceArray = yield* call(crowdsaleContract.methods.userToBalance(myAddress).call);
    const currentTyzTokenBalance = new BigNumber(tyzTokenBalanceArray[0])
      .minus(new BigNumber(tyzTokenBalanceArray[1]))
      .toString();

    yield* put(updateCrowdsaleState({ userTyzTokenBalanceOnCrowdsale: fromDecimals(currentTyzTokenBalance) }));

    yield* put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_TYZ_TOKEN_BALANCE, getTyzTokenBalanceSaga);
}
