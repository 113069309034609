import { handleBackendErrors } from 'modules/referral/utils';
import { error, loginTwoFa, request, success } from 'store/referral/actions';
import { baseApi } from 'store/referral/apiRequestBuilder';
import { setError, setLoggedIn, setTwoFaAction, updateTwoFa } from 'store/referral/reducer';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import actionTypes from '../actionTypes';
import referralSelector from '../selectors';

// eslint-disable-next-line camelcase
export function* loginTwoFaSaga({ type, payload: { code } }: ReturnType<typeof loginTwoFa>) {
  yield* put(request(type));

  try {
    const twoFaToken = yield* select(referralSelector.getProp('twoFaToken'));
    yield* call(baseApi.loginTwoFa, {
      code,
      // eslint-disable-next-line camelcase
      ephemeral_token: twoFaToken,
    });

    yield* put(setLoggedIn());
    yield* put(setTwoFaAction('force'));
    yield* put(updateTwoFa());
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type));
    const parsedError = handleBackendErrors(err);
    if (parsedError) {
      yield* put(setError({ [parsedError.source]: parsedError.type }));
    }
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.LOGIN_TWO_FA, loginTwoFaSaga);
}
