import i18n from 'i18next';
import { error, getApprovedWithdrawal, request, success } from 'store/dashboard/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage, getToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

export function* getApprovedWithdrawalSaga({ type, payload }: ReturnType<typeof getApprovedWithdrawal>) {
  yield* put(request(type, payload));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.getApprovedWithdrawal, payload);
      if (data.amount > 0) {
        // eslint-disable-next-line no-template-curly-in-string
        getToastMessage('info', i18n.t('dashboard.profile.approvedWithdrawal').replace('${amount}', data.amount));
      }
      yield* put(success(type));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_APPROVED_WITHDRAWAL, getApprovedWithdrawalSaga);
}
