import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconTyzWhite: FC<SvgIconProps & { fillColor?: string; width?: string; height?: string }> = ({
  sx,
  fillColor = 'white',
  width = '180px',
  height = '63px',
  ...props
}) => (
  <SvgIcon
    {...props}
    viewBox="0 0 180 63"
    sx={{
      width: { width },
      height: { height },
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.3084 59.3838C30.1102 58.0763 22.6103 55.0903 15.976 50.0953C10.343 45.8524 5.99017 40.6033 3.9166 33.769C1.73682 26.5907 2.72219 19.8508 7.24561 13.772C11.1633 8.50494 16.5377 5.2896 22.7188 3.30081C23.7753 2.96128 24.9246 2.63863 25.989 2.33058C25.4172 1.17261 25.4172 1.17261 25.4172 1.17261C20.2554 2.4104 14.4652 4.67913 10.2458 7.93045C5.65004 11.4752 2.31764 15.9115 0.807955 21.5653C0.441833 22.938 0.263292 24.3602 0 25.7576C0 26.9246 0 28.0938 0 29.2608C0.0621504 29.6734 0.131081 30.086 0.187581 30.4997C1.01588 36.7483 3.76292 42.1098 7.92248 46.7754C14.7082 54.3866 23.3245 58.9296 33.1239 61.4165C34.8573 61.8561 42.7651 64.1012 53.8234 62.3271L52.6007 59.6885C48.8118 60.1371 42.9222 60.1191 38.3084 59.3838Z"
      fill={fillColor}
    />
    <path
      d="M81.5583 31.2214C80.4441 25.164 77.5072 20.0363 73.3476 15.5764C66.6896 8.43972 58.3796 4.12486 48.9926 1.72122C47.2445 1.27377 45.4602 0.967976 43.6929 0.596974C41.026 0.157394 38.6078 0 36.1896 0L36.8156 1.26703C36.8552 1.26815 36.8959 1.2704 36.9377 1.27152C43.5098 1.37608 49.839 2.66784 55.9048 5.19402C63.5154 8.36327 70.0051 12.9772 74.7262 19.8182C77.575 23.9453 79.3819 28.4772 79.5084 33.5239C79.7118 41.5544 76.2371 47.8389 69.9553 52.6945C68.9994 53.4332 67.9744 54.0819 66.9303 54.7823L68.4072 57.4085C69.2254 56.8857 70.0152 56.4001 70.7644 55.8627C76.1647 51.984 79.9514 46.9935 81.4012 40.4223C81.6215 39.4194 81.7628 38.3964 81.9402 37.3846C81.9402 36.1108 81.9402 34.837 81.9402 33.5632C81.8182 32.7785 81.7006 31.9972 81.5583 31.2214Z"
      fill={fillColor}
    />
    <path
      d="M5.29973 35.3508C7.5089 40.9911 11.2515 45.5005 16.0438 49.1307C25.6692 56.4237 36.6043 59.6098 48.6491 59.1432C49.7983 59.0983 50.9441 58.9409 52.1984 58.8172L50.9272 56.0752C48.3474 56.4507 43.2668 56.3821 39.5955 55.8582C31.9385 54.7677 24.9268 52.065 18.6892 47.4938C14.1262 44.148 10.4447 40.0367 8.22532 34.7864C5.79581 29.0427 5.55624 23.2607 8.31798 17.5382C10.5057 13.0053 14.0449 9.74722 18.3784 7.284C20.9808 5.80674 23.8511 4.72634 26.7936 3.97197L26.6693 3.7044C26.1732 2.70494 26.1721 2.70269 26.1721 2.70269C21.9436 3.83593 16.8699 5.89105 13.3171 8.47794C4.42963 14.9536 1.33115 25.2213 5.29973 35.3508Z"
      fill={fillColor}
    />
    <path
      d="M8.04226 31.9916C9.71467 37.714 13.2482 42.2368 17.879 45.885C26.8207 52.9295 37.0947 55.9279 48.4321 55.5063C49.1271 55.4804 49.8186 55.3714 50.5644 55.2848L49.387 52.7418C48.0761 52.8002 45.0048 52.7654 43.3832 52.6856C36.5783 52.3517 30.2356 50.4112 24.3675 46.9912C19.0655 43.8984 14.6799 39.8692 11.9747 34.2727C9.27284 28.6852 8.87621 23.0066 11.76 17.3572C14.142 12.695 18.0101 9.56959 22.7188 7.4279C24.2443 6.73311 25.9314 6.18448 27.5383 5.56952L26.9642 4.3306C22.2544 5.53467 17.9242 7.50097 14.3375 10.7186C7.87728 16.5118 5.58789 23.6024 8.04226 31.9916Z"
      fill={fillColor}
    />
    <path
      d="M16.6168 11.98C11.3882 16.9109 9.39262 22.8638 11.1532 29.8926C12.4922 35.2418 15.6201 39.4925 19.8418 42.9226C28.144 49.6681 37.7479 52.4899 48.3948 51.9953C48.5824 51.9874 48.7688 51.9481 48.9971 51.9076L47.866 49.4668C46.6862 49.5163 37.2959 49.7726 29.3881 45.8265C23.8917 43.2711 19.2237 39.714 16.0461 34.4638C13.8234 30.792 12.725 26.8268 13.3702 22.5243C14.142 17.3719 17.0688 13.5955 21.2701 10.695C23.3765 9.24131 25.8749 8.15866 28.3666 7.36382L27.7315 5.99111C27.5326 6.05519 26.9733 6.2362 26.4682 6.40034C22.8058 7.59766 19.435 9.32226 16.6168 11.98Z"
      fill={fillColor}
    />
    <path
      d="M14.8641 30.2163C16.5275 35.09 19.7548 38.8056 23.8703 41.7938C29.0853 45.5792 34.9522 47.7523 41.3447 48.4853C43.3505 48.7158 45.5302 48.7034 47.5575 48.7956L46.3857 46.2672C42.2792 46.3167 37.7682 45.6163 33.9126 44.22C28.0671 42.1019 23.0137 38.8775 19.5197 33.6194C16.932 29.725 15.7805 25.4743 16.993 20.8379C18.1321 16.4814 20.9831 13.41 24.7935 11.1772C26.1145 10.4049 27.7021 9.7382 29.1451 9.05016L28.5225 7.70669C25.8885 8.52514 23.4036 9.76406 21.234 11.3144C14.8144 15.8946 12.3996 22.9953 14.8641 30.2163Z"
      fill={fillColor}
    />
    <path
      d="M78.0857 39.8219C79.9051 33.371 78.67 27.3113 75.3297 21.6361C71.1272 14.496 64.8714 9.64939 57.4371 6.19683C52.9702 4.12147 48.2773 2.74989 43.3979 2.08097C41.2633 1.78754 39.1005 1.68073 36.949 1.50423L37.6993 2.83871C37.7027 2.83871 37.7038 2.83871 37.7072 2.83871C44.1855 2.92303 50.3769 4.34183 56.2698 7.00629C62.5696 9.85288 67.9677 13.8507 71.9656 19.5506C74.5782 23.273 76.2823 27.3641 76.471 31.9376C76.7659 39.0484 73.846 44.7371 68.3767 49.1891C67.3439 50.0301 66.2195 50.7608 65.1054 51.5366L66.5156 54.0425C66.6128 53.9863 66.7043 53.9346 66.7936 53.8806C72.2707 50.554 76.3229 46.0705 78.0857 39.8219Z"
      fill={fillColor}
    />
    <path
      d="M74.5613 25.5339C72.6052 20.1948 69.1191 15.9665 64.6929 12.4848C58.2417 7.40991 50.8356 4.58243 42.7256 3.50878C41.13 3.29742 39.5163 3.22209 37.8462 3.09618L38.5965 4.43066C38.7197 4.44865 38.8542 4.45652 39.0033 4.45314C40.1944 4.42504 41.3944 4.58805 42.5832 4.72971C48.6728 5.4526 54.3567 7.37731 59.5841 10.5735C64.5381 13.6011 68.5858 17.5101 71.2503 22.7199C73.0098 26.1567 73.8324 29.8038 73.3985 33.6419C72.7951 38.9776 70.1576 43.2138 65.9574 46.5146C65.12 47.1723 64.2273 47.7647 63.3324 48.3842L64.7268 50.862C65.9144 50.0233 67.0806 49.2442 68.1699 48.3696C75.1568 42.763 77.6304 33.9151 74.5613 25.5339Z"
      fill={fillColor}
    />
    <path
      d="M17.6134 27.4845C18.4903 31.4857 20.7108 34.7235 23.6985 37.4509C28.1801 41.542 33.5149 43.9479 39.4587 45.0036C41.6069 45.3847 43.9403 45.4735 46.1178 45.6916L45.3811 44.1008C45.1076 43.5803 44.8658 43.134 44.8658 43.134C43.6703 43.0766 41.93 42.9822 40.7514 42.7933C34.3963 41.7691 28.7802 39.2486 24.3268 34.5031C21.9052 31.9219 20.2486 28.9156 19.9028 25.3551C19.4056 20.2218 21.6249 16.3027 25.7178 13.3696C26.9891 12.4589 28.5847 11.6944 30.0006 10.8951L29.3768 9.54599C26.8693 10.4072 24.4635 11.6832 22.5256 13.3819C18.2248 17.1504 16.3704 21.807 17.6134 27.4845Z"
      fill={fillColor}
    />
    <path
      d="M72.4866 27.9409C71.3939 22.2533 68.1281 17.817 63.8138 14.107C58.3558 9.4133 51.9543 6.67576 44.902 5.40648C42.8996 5.04673 40.8588 4.89046 38.7197 4.64987L39.5231 6.07654C39.626 6.09452 39.7356 6.10464 39.8565 6.11026C44.8963 6.2789 49.7079 7.49421 54.2686 9.61679C60.2836 12.4162 65.2421 16.405 68.4072 22.3511C71.8639 28.8414 71.0469 36.2479 66.1269 41.5645C64.8127 42.9844 63.2273 44.1548 61.6826 45.4544L62.9821 47.7636C64.2511 46.8237 65.528 45.9704 66.6444 44.9451C71.656 40.3379 73.7804 34.6684 72.4866 27.9409Z"
      fill={fillColor}
    />
    <path
      d="M69.4084 26.6368C68.2411 21.3899 65.0353 17.3842 60.9017 14.0924C55.9003 10.1115 50.1373 7.827 43.8454 6.81967C42.4724 6.60044 41.078 6.50713 39.6904 6.37335L40.4509 7.72469C43.5765 7.97427 46.6399 8.51616 49.6039 9.5336C55.2381 11.4662 60.1525 14.4645 63.7855 19.2774C66.2738 22.5715 67.7677 26.2377 67.4784 30.4322C67.136 35.3823 64.6059 39.09 60.6215 41.9119C60.4011 42.0682 60.1751 42.2188 59.948 42.3706L61.2543 44.691C61.8848 44.2672 62.4724 43.8906 63.016 43.46C68.5044 39.1193 70.9498 33.5689 69.4084 26.6368Z"
      fill={fillColor}
    />
    <path
      d="M66.7348 27.3462C66.2806 23.7868 64.5946 20.7648 62.2566 18.0981C58.2327 13.5078 53.0889 10.7287 47.2377 9.21883C45.0658 8.65783 42.8205 8.38127 40.6034 7.99565L41.4826 9.55723C44.8149 9.81468 48.0332 10.5938 51.0989 11.9271C55.8732 14.0036 59.9062 16.9829 62.5685 21.5529C65.6738 26.883 65.0929 32.8235 60.9933 37.2148C60.209 38.0557 59.2858 38.7685 58.3637 39.551L59.6045 41.7545C60.0282 41.469 60.4113 41.216 60.7729 40.9349C65.2104 37.4734 67.4614 33.0315 66.7348 27.3462Z"
      fill={fillColor}
    />
    <path
      d="M52.1543 16.667L25.3969 21.3404L25.867 22.6052L31.3746 21.6215L35.8461 31.6453L37.8711 31.362L33.3533 21.3393L35.8947 20.8233L41.9425 25.1325L43.2375 27.8015L42.1854 29.2731L42.6725 30.3771L58.4892 26.865L57.688 25.6452L45.4975 28.222L52.5589 17.6855L52.1543 16.667ZM42.4024 23.8722L37.8869 20.4602L42.7922 19.6406L42.4024 23.8722ZM44.3991 26.096L43.6816 24.7998L44.7234 19.2505L49.5858 18.4309L44.3991 26.096Z"
      fill={fillColor}
    />
    <path d="M95.965 16.8013H121.077V22.9475H111.956V48.6197H105.109V22.9475H95.965V16.8013Z" fill={fillColor} />
    <path
      d="M129.753 16.8013L137.291 30.4473L144.829 16.8013H152.144L140.726 37.2592V48.6197H133.857V37.2592L122.438 16.8013H129.753Z"
      fill={fillColor}
    />
    <path
      d="M171.079 22.9475H155.557V16.8013H179.532V22.9475L163.318 42.4957H180V48.6197H154.865V42.4957L171.079 22.9475Z"
      fill={fillColor}
    />
  </SvgIcon>
);
