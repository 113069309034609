import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import crowdsaleActionTypes from 'store/crowdsale/actionTypes';
import userActionTypes from 'store/user/actionTypes';

export const ignoredActions = [
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  ...Object.values(userActionTypes),
  ...Object.values(crowdsaleActionTypes),
];
