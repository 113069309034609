import i18next from 'i18next';
import { addStore, addStoreToList, error, request, success } from 'store/dashboard/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage, getToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

export function* addStoreSaga({ type, payload: { name, link, gameId } }: ReturnType<typeof addStore>) {
  yield* put(request(type, { name, link, gameId }));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.addStore, { name, link, gameId });
      yield* put(addStoreToList(data));
      getToastMessage('info', i18next.t('dashboard.settings.addingStore.onStoreAdded'));
      yield* put(success(type));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ADD_STORE, addStoreSaga);
}
