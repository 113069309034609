import { FC, PropsWithChildren } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { CirclesBackground } from '../Icon/Components/CirclesBackground';

export const Background: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const bigScreenSx = {
    position: 'fixed',
    zIndex: -2,
    top: 0,
    left: 0,
    width: '673px',
    height: '374px',
  };
  const smallScreenSx = {
    position: 'fixed',
    zIndex: -2,
    top: '-102px',
    left: 0,
    width: '336px',
    height: '187px',
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        margin: '0 auto',
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
      }}
    >
      <Box position="fixed" sx={{ backgroundColor: '#F0F4FB', zIndex: -3, width: '100%', height: '100%' }} />
      <CirclesBackground sx={isSmallScreen ? smallScreenSx : bigScreenSx} />
      {isSmallScreen && (
        <CirclesBackground
          angle={120}
          sx={{ position: 'fixed', zIndex: -2, top: '242px', right: '-280px', width: '538px', height: '299px' }}
        />
      )}
      {children}
    </Box>
  );
};
