const FINANCIAL_API = '/api/v1';
const REFERRAL_API = '/ref_api/v1';
const GAME22_API = '/api/v1';

export default {
  linkEmail: `${FINANCIAL_API}/accounts/link_email/`,
  getAccountInfo: `${FINANCIAL_API}/accounts/self/`,
  getMetamaskMessage: `${FINANCIAL_API}/accounts/get_metamask_message/`,
  metamaskLogin: `${FINANCIAL_API}/accounts/metamask_login/`,
  sendFeedback: `${FINANCIAL_API}/accounts/follow/`,
  verifyEmail: `${FINANCIAL_API}/accounts/verify_email/`,

  getBuyingSignature: `${FINANCIAL_API}/sales/signature/`,
  getTokensList: `${FINANCIAL_API}/sales/tokens/`,

  swap: `${FINANCIAL_API}/swap/swap/`,
  claim: `${FINANCIAL_API}/swap/claim/`,
  getSwapCommission: `${FINANCIAL_API}/swap/commission/`,
  getSwapDealer: `${FINANCIAL_API}/swap/dealer/`,

  referralActivateTwoFa: (method: string) => `${REFERRAL_API}/account/2fa/${method}/activate/`,
  referralConfirmActivateTwoFa: (method: string) => `${REFERRAL_API}/account/2fa/${method}/activate/confirm/`,
  referralDisableTwoFa: (method: string) => `${REFERRAL_API}/account/2fa/${method}/deactivate/`,
  referralGetInfo: `${REFERRAL_API}/account/info/`,
  referralGetTwoFa: `${REFERRAL_API}/account/2fa/mfa/user-active-methods/`,
  referralRequestPasswordReset: `${REFERRAL_API}/account/request_reset_password/`,
  referralRequestRegistrationCode: `${REFERRAL_API}/account/send_registration_code/`,
  referralResetPassword: `${REFERRAL_API}/account/reset_password/`,
  referralLogout: `${REFERRAL_API}/account/logout/`,
  referralSignin: `${REFERRAL_API}/account/login/`,
  referralSigninTwoFa: `${REFERRAL_API}/account/2fa/login/code/`,
  referralSignup: `${REFERRAL_API}/account/registration/`,
  referralVerifyCode: (project: string) => `${REFERRAL_API}/account/${project}/confirm_registration/`,
  getTwoFaStatus: `${REFERRAL_API}/account/get_2fa_status/`,

  getLimits: `${REFERRAL_API}/dashboard/settings/get_limits/`,
  updateMaxWithdrawal: `${REFERRAL_API}/dashboard/settings/max_withdrawal/`,
  updateMinWithdrawal: `${REFERRAL_API}/dashboard/settings/min_withdrawal/`,
  updateWithdrawalPeriod: `${REFERRAL_API}/dashboard/settings/withdrawal_period/`,

  addAdmin: `${REFERRAL_API}/dashboard/settings/add_admin/`,
  blockUser: `${REFERRAL_API}/dashboard/settings/block_user/`,
  demoteAdmin: `${REFERRAL_API}/dashboard/settings/demote_admin/`,
  promoteAdmin: `${REFERRAL_API}/dashboard/settings/promote_admin/`,
  getAdmins: `${REFERRAL_API}/dashboard/settings/get_admins/`,

  addStore: `${REFERRAL_API}/dashboard/settings/add_store/`,
  deleteStore: `${REFERRAL_API}/dashboard/settings/delete_store/`,
  editStore: `${REFERRAL_API}/dashboard/settings/edit_store/`,
  getStores: `${REFERRAL_API}/dashboard/settings/get_stores/`,

  getAdminPaymentsHistory: `${REFERRAL_API}/statistics/get_admin_payments_history/`,
  getAdminWithdrawalHistory: `${REFERRAL_API}/statistics/get_admin_withdrawal_history/`,
  getPaymentsHistory: `${REFERRAL_API}/statistics/get_payments_history/`,
  getSelectedUserInfo: `${REFERRAL_API}/statistics/get_selected_user_info/`,
  getWithdrawals: `${REFERRAL_API}/statistics/all_withdrawals/`,

  addGame: `${REFERRAL_API}/dashboard/settings/add_game/`,
  getGames: `${REFERRAL_API}/dashboard/settings/get_games/`,
  deleteGame: `${REFERRAL_API}/dashboard/settings/delete_game/`,

  addNote: `${REFERRAL_API}/profile_info/add_note/`,
  getNotes: `${REFERRAL_API}/profile_info/get_notes/`,
  getProfileInfo: `${REFERRAL_API}/profile_info/get_profile_info/`,
  setAvatar: `${REFERRAL_API}/profile_info/set_avatar/`,
  takeBalance: `${REFERRAL_API}/profile_info/take_balance/`,
  drop2fa: `${REFERRAL_API}/profile_info/drop_2fa/`,
  getApprovedWithdrawal: `${REFERRAL_API}/profile_info/get_approved_withdrawal/`,

  makeReferralSwap: `${REFERRAL_API}/swap/`,
  resolveBlockedTransaction: `${REFERRAL_API}/resolve_blocked_transaction/`,
  getConfirmations: `${REFERRAL_API}/get_confirmations/`,

  game22RequestRegistrationCode: `${GAME22_API}/email/send_code`,
  game22Signup: `${GAME22_API}/registration/`,
  game22VerifyCode: `${GAME22_API}/email/confirm_code`,
};
