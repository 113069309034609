import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CoinIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 22 26"
    sx={{
      width: '22px',
      height: '26px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.18647 4.01085C3.54289 1.68556 5.56368 0 8 0H14C16.4349 0 18.4556 1.68657 19.8122 4.01168C21.1833 6.36167 22 9.54202 22 13C22 16.458 21.1833 19.6383 19.8122 21.9883C18.4556 24.3134 16.4349 26 14 26H8C5.56368 26 3.54289 24.3144 2.18647 21.9892C0.815734 19.6393 0 16.4589 0 13C0 9.54107 0.815734 6.36069 2.18647 4.01085ZM18.5927 6C19.3526 7.65359 19.8592 9.71799 19.9748 12H15.9771C15.8767 9.81366 15.4495 7.76472 14.7646 6H18.5927ZM19.9748 14H15.9771C15.8767 16.1863 15.4495 18.2353 14.7646 20H18.5927C19.3526 18.3464 19.8592 16.282 19.9748 14ZM17.3995 22H13.8072C13.3722 22.7439 12.8692 23.422 12.3063 24H14C15.1625 24 16.3592 23.3364 17.3995 22ZM13.8072 4C13.3722 3.25615 12.8692 2.57804 12.3063 2H14C15.1625 2 16.3592 2.66365 17.3995 4H13.8072Z"
      fill="white"
    />
  </SvgIcon>
);
