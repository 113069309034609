import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Backdrop, Box, Button, Grid, IconButton } from '@mui/material';
import { routes } from 'appConstants';
import { BurgerIcon, Close, HomeIcon, WalletIcon } from 'components/Icon/components';
import { useModal } from 'hooks';
import { NavItemStyled, NavSourceLinks } from 'modules/layout/components';
import { useVerticalAnchor } from 'modules/layout/hooks';
import { formatRoutesToArr } from 'modules/router/utils';
import {
  BG_BLACK_MENU,
  BG_GRAY_LIGHT,
  BORDER_RADIUS_EXTRA_LARGE,
  BORDER_RADIUS_MEDIUM,
  COLOR_TEXT_WHITE_100,
} from 'theme';
import { flexHelper } from 'utils/flexHelper';

import { navSourceLinks } from '../Header/Header.helpers';
import { LanguageModal } from '../NotificationModal';
import { langs } from '../NotificationModal/LanguageModal/LanguageModal.helper';

export type MenuProps = {
  address: string;
  onOpenConnectModal: () => void;
  onOpenDisconnectModal: () => void;
};

export const Menu: FC<MenuProps> = ({ address, onOpenConnectModal, onOpenDisconnectModal }) => {
  const { t } = useTranslation();
  const languageRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const verticalAnchor = useVerticalAnchor();

  const [isMenuOpen, onOpenMenu, onCloseMenu] = useModal(false);
  const [isVisibleLanguagePopover, onOpenLanguagePopover, onCloseLanguagePopover] = useModal(false);

  const { i18n } = useTranslation();
  const currentLanguage = langs.find((lang) => lang.value === i18n.language)?.label;
  const isHomePage = pathname === routes.home.root.path;

  const handleChangeMenuVisible = () => {
    if (isMenuOpen) {
      onCloseMenu();
    } else {
      onOpenMenu();
    }
  };

  return (
    <Box
      sx={{
        display: { xs: 'block', sm: 'block', md: 'none' },
      }}
    >
      <Backdrop open={isMenuOpen} onClick={onCloseMenu} sx={{ zIndex: 3 }} />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          overflowY: 'hidden',
          height: '460px',
          background: BG_BLACK_MENU,
          borderTopLeftRadius: BORDER_RADIUS_MEDIUM,
          borderTopRightRadius: BORDER_RADIUS_MEDIUM,
          transition: 'all 0.5s ease-in-out',
          transform: isMenuOpen ? 'translateY(0)' : 'translateY(385px)',
        }}
      >
        <Box
          sx={{
            px: 7,
            py: 2,
            width: '100%',
            flexDirection: 'row',
            ...flexHelper('space-between', 'center'),
          }}
        >
          <IconButton
            onClick={() => {
              navigate(routes.home.root.path);
              onCloseMenu();
            }}
          >
            <HomeIcon sx={{ path: { fill: isHomePage ? COLOR_TEXT_WHITE_100 : BG_GRAY_LIGHT } }} />
          </IconButton>

          <IconButton onClick={handleChangeMenuVisible} sx={{ width: 175, height: 40 }}>
            {isMenuOpen ? <Close sx={{ color: 'white', width: 36, height: 36 }} /> : <BurgerIcon />}
          </IconButton>

          <IconButton onClick={() => (address.length ? onOpenDisconnectModal() : onOpenConnectModal())}>
            <WalletIcon
              sx={{ width: 30, height: 30, path: { fill: address.length ? COLOR_TEXT_WHITE_100 : BG_GRAY_LIGHT } }}
            />
          </IconButton>
        </Box>

        <Box
          sx={{
            pt: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {formatRoutesToArr(routes).map(
            ({ root: { id, path, title, isNavItem } }) =>
              isNavItem && (
                <Grid key={id} item xs={12}>
                  <NavItemStyled
                    title={t(`footer.nav.${title}`)}
                    path={path}
                    onClick={onCloseMenu}
                    tSx={{
                      mb: 2,
                      px: 2,
                      py: 1,
                      borderRadius: BORDER_RADIUS_EXTRA_LARGE,
                      fontSize: 20,
                      textTransform: title === 'faq' ? 'uppercase' : 'capitalize',
                    }}
                  />
                </Grid>
              ),
          )}
          <NavSourceLinks
            linksArr={navSourceLinks}
            onClick={onCloseMenu}
            linkSx={{ mb: 2, fontSize: 20, py: 1, px: 2 }}
          />

          <Button variant="outlined" onClick={onOpenLanguagePopover} ref={languageRef} sx={{ minWidth: 100 }}>
            {currentLanguage}
          </Button>
        </Box>
      </Box>

      {isVisibleLanguagePopover && (
        <LanguageModal
          open={isVisibleLanguagePopover}
          onClose={onCloseLanguagePopover}
          anchorEl={languageRef}
          verticalAnchor={verticalAnchor}
        />
      )}
    </Box>
  );
};
