import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { DateRangePickerWrapper } from 'modules/dashboard/components';
import { EditIconWhite } from 'modules/dashboard/components/Icon';
import { Page } from 'modules/dashboard/components/Pagination';
import { getNotes } from 'store/dashboard/actions';
import selector from 'store/dashboard/selectors';
import { Note, User } from 'types/store/dashboard';

import { Pages } from '../../../../../../../components';
import { Popup } from '../../Popup';
import { PopupProps } from '../../PopupProps';

import { NewNotePopup } from './NewNotePopup';
import { NoteInfo } from './NoteInfo';
import { NoteInfoPopup } from './NoteInfoPopup';

export const NotesPopup: FC<PopupProps & { user: User }> = ({ isOpen, closePopup, user }) => {
  const itemsPerPage = 6;
  const { t } = useTranslation();
  const [hidePopupHeader, setHidePopupHeader] = useState(false);
  const [noteOpened, setNoteOpened] = useState(false);
  const [isWritingNote, setWritingNote] = useState(false);
  const [popupSize, setPopupSize] = useState({ width: 558, height: 792 });
  const [currentPage, handleChangePage] = useState(1);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [filteredNotes, setFilteredNotes] = useState<Note[]>([]);
  const { notes } = useShallowSelector(selector.getDashboard).profileInfo;
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const dispatch = useDispatch();

  useEffect(() => {}, [isSmallScreen]);

  useEffect(() => {
    if (isSmallScreen) {
      if (noteOpened || isWritingNote) {
        setPopupSize({ width: 354, height: 612 });
      } else {
        setPopupSize({ width: 354, height: 792 });
      }
    } else if (noteOpened || isWritingNote) {
      setPopupSize({ width: 1047, height: 611 });
    } else {
      setPopupSize({ width: 558, height: 792 });
    }
  }, [noteOpened, isWritingNote, isSmallScreen]);

  const handleFilterNotes = () => {
    if (startDate && endDate && notes && notes.length > 0) {
      setFilteredNotes(
        notes.filter((note) => {
          const noteDate = new Date(note.date);
          noteDate.setHours(0, 0, 0, 0);
          return startDate <= noteDate && noteDate <= endDate;
        }),
      );
      handleChangePage(1);
    } else {
      setFilteredNotes(notes);
      handleChangePage(1);
    }
  };

  useEffect(() => {
    handleFilterNotes();
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(getNotes({ email: user.email }));
  }, [dispatch]);

  useEffect(() => {
    handleFilterNotes();
    if (currentPage === 0 && filteredNotes.length > 0) {
      handleChangePage(1);
    }
  }, [notes]);

  const subMenu =
    noteOpened && selectedNote ? (
      <NoteInfoPopup
        handleReturn={() => {
          setHidePopupHeader(false);
          setNoteOpened(false);
        }}
        header={selectedNote.header}
        text={selectedNote.noteText}
        email={selectedNote.noteOwner}
        date={`${selectedNote.date}`}
      />
    ) : (
      <NewNotePopup
        email={user.email}
        handleReturn={() => {
          setHidePopupHeader(false);
          setWritingNote(false);
        }}
      />
    );

  return (
    <Popup
      isOpen={isOpen}
      closePopup={closePopup}
      header={t('dashboard.profile.notes')}
      height={`${popupSize.height}px`}
      width={`${popupSize.width}px`}
      hidePopupHeader={hidePopupHeader}
    >
      {!noteOpened && !isWritingNote ? (
        <Box width="100%">
          <DateRangePickerWrapper
            startDate={startDate}
            endDate={endDate}
            width="98px"
            height="27px"
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            sx={{ position: 'absolute', right: '92px', top: '32px' }}
            rightShift={isSmallScreen ? '-76px' : '0px'}
          />
          <Page
            items={filteredNotes.map((note) => ({
              id: note.id,
              component: (
                <NoteInfo
                  key={note.id}
                  email={note.noteOwner}
                  header={note.header}
                  date={`${note.date}`}
                  onClick={() => {
                    setHidePopupHeader(true);
                    setNoteOpened(true);
                    setSelectedNote(note);
                  }}
                />
              ),
            }))}
            currentPage={currentPage}
            sx={{ marginTop: '26px', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            bottom="32px"
            width={isSmallScreen ? '306px' : '510px'}
          >
            <Pages
              current={currentPage}
              max={Math.ceil(filteredNotes.length / itemsPerPage)}
              callback={handleChangePage}
            />
            <IconButton
              sx={{
                transition: 'transform 0.15s ease-in-out',
                '&:active': {
                  transform: 'scale(0.92)',
                },
              }}
              onClick={() => {
                setHidePopupHeader(true);
                setWritingNote(true);
              }}
            >
              <EditIconWhite />
            </IconButton>
          </Box>
        </Box>
      ) : (
        subMenu
      )}
    </Popup>
  );
};
