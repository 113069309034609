import { FC } from 'react';
import { Grid, Skeleton, SxProps } from '@mui/material';

interface TokenTabsSkeletonProps {
  width?: number;
  sx?: SxProps;
}

export const TokenTabsSkeleton: FC<TokenTabsSkeletonProps> = ({ width = 120, sx }) => {
  return (
    <>
      {Array.from(Array(6).keys()).map((element) => (
        <Grid key={element} item container xs={4} sm={2} md={2}>
          <Skeleton animation="wave" variant="rectangular" width={width} height={46} sx={{ ...sx }} />
        </Grid>
      ))}
    </>
  );
};
