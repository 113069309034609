import { CSSProperties } from 'react';

import { SpinnerSizeProps } from './Spinner.types';

const sizeState: Record<SpinnerSizeProps, CSSProperties> = {
  s: {
    width: 100,
    height: 100,
  },
  m: {
    width: 150,
    height: 150,
  },
  l: {
    width: 250,
    height: 250,
  },
  xl: {
    width: 300,
    height: 300,
  },
};

export const spinnerStyleState = {
  size: sizeState,
};
