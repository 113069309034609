/* eslint-disable no-console */
import { error, request, success } from 'store/dashboard/actions';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { updatePaymentsHistory } from '../reducer';
import { ShowUnauthorizedMessage } from '../utils';

export function* getPaymentsHistorySaga() {
  yield* put(request(actionTypes.GET_PAYMENTS_HISTORY));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.getPaymentsHistory);
      yield* put(success(actionTypes.GET_PAYMENTS_HISTORY, { data }));
      yield* put(updatePaymentsHistory(data));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    console.error(err);
    errorToastMessage(err);
    yield* put(error(actionTypes.GET_PAYMENTS_HISTORY));
  }
}

export default function* listener() {
  yield all([takeLatest(actionTypes.GET_PAYMENTS_HISTORY, getPaymentsHistorySaga)]);
}
