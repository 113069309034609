import { fork } from 'redux-saga/effects';

import buyToken from './buyToken';
import claim from './claim';
import getCrowdsaleAddressSaga from './getCrowdsaleAddress';
import getCrowdsaleDataSaga from './getCrowdsaleDataSaga';
import getTokensBalance from './getTokensBalances';
import getTokensList from './getTokensList';
import getTyzTokenBalance from './getTyzTokenBalance';
import refund from './refund';

export default function* crowdsaleSagas() {
  yield fork(getTokensList);
  yield fork(getTokensBalance);
  yield fork(getTyzTokenBalance);
  yield fork(getCrowdsaleDataSaga);
  yield fork(getCrowdsaleAddressSaga);

  yield fork(buyToken);
  yield fork(claim);
  yield fork(refund);
}
