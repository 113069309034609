export { default as CertikLogo } from './certik.webp';
export { default as CryptoALogo } from './cryptoA.webp';
export { default as EvercodeLogo } from './evercode.webp';
export { default as PatenticaLogo } from './patentica.webp';
export { default as RnbLogo } from './rnb.webp';
export { default as SixhandsLogo } from './sixhands.webp';
export { default as STSGroupLogo } from './sts_group.webp';
export { default as ComonGames } from './comon_games.png';
export { default as ITechLabs } from './i_tech_labs.png';
export { default as Onez } from './onez.png';
export { default as Winwin } from './winwin.png';
export { default as EverPlay } from './ever_play.png';
