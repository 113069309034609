import { fork } from 'redux-saga/effects';

import getStakingData from './getStakingData';
import getUserStakeData from './getUserStakeData';
import stake from './stake';
import unStake from './unStake';

export default function* stakingSagas() {
  yield fork(stake);
  yield fork(getStakingData);
  yield fork(getUserStakeData);
  yield fork(unStake);
}
