/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { Box, Button, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Pages } from 'components';
import { DateRangePickerWrapper, DownloadButton, InputField } from 'modules/dashboard/components';
import { COLOR_TEXT_BLUE_100, FontFamilies, FontWeights } from 'theme';
import { TableItem } from 'types/dashboard';
import { flexHelper } from 'utils';

export enum TableType {
  Switchable,
  Compact,
  Standart,
}

const StyledSelectorButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  '&:hover': {
    background: 'white',
  },
  borderColor: '#C6C7CC',
  borderStyle: 'solid',
  borderTopWidth: '1px',
  borderLeftWidth: '1px',
  borderRightWidth: '1px',
}));

export interface UseTableParams {
  tableType: TableType;
  searchBar: { onSearch: () => void; onChange: (newValue: string) => void; searchString: string; placeHolder: string };
  totalAmount: number;
  dateRangePicker: {
    startDate: Date | null;
    endDate: Date | null;
    setStartDate: (date: Date | null) => void;
    setEndDate: (date: Date | null) => void;
  };
  downloadButton: {
    fileName: string;
    items: TableItem[];
    columns: string[];
    afterSearchBar: boolean;
    customHandler: (() => void) | null;
  };
  pagination: { totalPages: number; pageIndex: number; setPageIndex: (pageIndex: number) => void };
  tableSelector?: {
    setActiveTable: (index: number) => void;
    activeTable: number;
    tables: {
      name: string;
      info: {
        items: TableItem[];
        columns: string[];
        columnComparators: ((a: string, b: string) => number)[];
        defaultFilename: string;
      };
    }[];
  };
}

const useTableStyles = (isSmallScreen: boolean, t: any) => ({
  [TableType.Switchable]: {
    header: t('dashboard.history.search'),
    searchSx: {
      width: isSmallScreen ? '100%' : '555px',
      position: isSmallScreen ? 'absolute' : 'relative',
      top: isSmallScreen ? 80 : 0,
    },
    dateRangePickerSx: { position: 'absolute', bottom: '18px', right: 0 },
    downloadButton: {},
  },
  [TableType.Compact]: {
    header: null,
    searchSx: isSmallScreen
      ? { width: '100%', marginTop: '22px', marginBottom: '10px' }
      : { width: '555px', marginLeft: '113px' },
    dateRangePickerSx: { position: 'absolute', top: isSmallScreen ? '2px' : '22px', right: 0 },
    downloadButton: { marginTop: isSmallScreen ? '-6px' : '8px' },
  },
  [TableType.Standart]: {
    header: t('dashboard.history.search'),
    searchSx: {
      width: isSmallScreen ? '100%' : '555px',
      position: isSmallScreen ? 'absolute' : 'relative',
      top: isSmallScreen ? 80 : 0,
    },
    dateRangePickerSx: { position: 'absolute', bottom: '18px', right: 0 },
    downloadButton: { position: 'absolute', bottom: '18px', left: 0 },
  },
});

export const useTable = (useTableParams: UseTableParams) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const styles = useTableStyles(isSmallScreen, t);

  const searchField = (
    <InputField
      headerText={styles[useTableParams.tableType].header}
      onSearch={useTableParams.searchBar.onSearch}
      value={useTableParams.searchBar.searchString}
      onChange={useTableParams.searchBar.onChange}
      isSmallScreen={isSmallScreen}
      placeholder={useTableParams.searchBar.placeHolder}
      height={48}
      sx={styles[useTableParams.tableType].searchSx}
    />
  );

  const dateRangePicker = (
    <DateRangePickerWrapper
      startDate={useTableParams.dateRangePicker.startDate}
      endDate={useTableParams.dateRangePicker.endDate}
      setStartDate={useTableParams.dateRangePicker.setStartDate}
      setEndDate={useTableParams.dateRangePicker.setEndDate}
      width="98px"
      height="27px"
      sx={styles[useTableParams.tableType].dateRangePickerSx}
    />
  );

  const downloadButton = (
    <DownloadButton
      startDate={useTableParams.dateRangePicker.startDate}
      endDate={useTableParams.dateRangePicker.endDate}
      defaultFilename={useTableParams.downloadButton.fileName}
      data={useTableParams.downloadButton.items.map((item) => item.data)}
      columns={useTableParams.downloadButton.columns}
      searchString={useTableParams.searchBar.searchString}
      sx={styles[useTableParams.tableType].downloadButton}
      customHandler={useTableParams.downloadButton.customHandler}
    />
  );

  const totalAmountField =
    useTableParams.tableType === TableType.Compact ? (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Typography
          fontFamily={FontFamilies.secondary}
          fontSize="20px"
          color="#191836"
          fontWeight={FontWeights.fontWeightMedium}
        >
          {t('dashboard.profile.result')} {useTableParams.totalAmount}
        </Typography>
      </Box>
    ) : null;

  const pagination = (
    <Pages
      current={useTableParams.pagination.pageIndex}
      max={useTableParams.pagination.totalPages}
      callback={useTableParams.pagination.setPageIndex}
    />
  );

  const switchButtons = (
    <Box
      sx={{
        width: 'fit-content',
        ...flexHelper('start', 'start'),
        float: 'left',
        position: 'absolute',
        bottom: 0,
        left: 0,
      }}
    >
      {useTableParams.tableSelector?.tables.map((table, index) => (
        <StyledSelectorButton
          key={table.name}
          onClick={() => useTableParams.tableSelector?.setActiveTable(index)}
          sx={{
            minWidth: {
              lg: 172,
              xs: 115,
            },
            height: {
              lg: 44,
              xs: 33,
            },
            fontSize: {
              lg: 20,
              xs: 14,
            },
            background: useTableParams.tableSelector?.activeTable === index ? 'white' : '#F5F5F5',
            color: useTableParams.tableSelector?.activeTable === index ? COLOR_TEXT_BLUE_100 : '#646778',
            borderRadius: `${index === 0 ? '15' : '0'}px ${
              index + 1 === useTableParams.tableSelector?.tables.length ? '15' : '0'
            }px 0px 0px`,
          }}
        >
          {table.name}
        </StyledSelectorButton>
      ))}
    </Box>
  );

  let headerHeight = isSmallScreen ? '232px' : '184px';
  if (useTableParams.tableType === TableType.Compact) {
    headerHeight = isSmallScreen ? '118px' : '68px';
  }

  const tableHeader = (
    <Box sx={{ height: headerHeight, position: 'relative' }}>
      {isSmallScreen && searchField}
      <Box display="flex" marginBottom="18px" marginTop="24px" width="100%" height="100%" position="relative">
        {useTableParams.tableType !== TableType.Switchable &&
          !useTableParams.downloadButton.afterSearchBar &&
          downloadButton}
        {!isSmallScreen && searchField}
        {dateRangePicker}
        {useTableParams.tableType !== TableType.Switchable &&
          useTableParams.downloadButton.afterSearchBar &&
          downloadButton}
      </Box>
      {useTableParams.tableType === TableType.Switchable && switchButtons}
    </Box>
  );

  const tableFooter = (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '70px', justifyContent: 'space-between' }}>
      {totalAmountField}
      {pagination}
      {useTableParams.tableType === TableType.Switchable && downloadButton}
    </Box>
  );

  return { tableHeader, tableFooter };
};
