import { FC } from 'react';
import { Box, SxProps } from '@mui/material';

import Icon from './lock.png';

type LockImageProps = {
  className?: string;
  sx?: SxProps;
};

export const LockImage: FC<LockImageProps> = ({ className, sx }) => (
  <Box component="img" className={className} src={Icon} alt="Lock" sx={{ ...sx }} />
);
