import { FC, SyntheticEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Collapse, IconButton, Link, Stack, Typography } from '@mui/material';
import { ArrowUp, BackgroundWrapper, ImportIcon } from 'components';
import { useWalletConnectorContext } from 'services';
import {
  BG_BLUE,
  BG_BLUE_ACCENT_DARK,
  BG_GRAY_LIGHT,
  BORDER_RADIUS_MEDIUM,
  COLOR_TEXT_WHITE_100,
  FontWeights,
} from 'theme';
import { flexHelper } from 'utils';

import { faq, Links } from './FAQ.helpers';

export const FAQ: FC = () => {
  const { addTokenToWallet } = useWalletConnectorContext();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [activeFaqIndex, setActiveFaqIndex] = useState<number | null>(null);

  const handleOpenAnswer = (index: number) => {
    if (activeFaqIndex === index) {
      setActiveFaqIndex(null);
    } else {
      setActiveFaqIndex(index);
    }
  };
  const handleImportToken = (event: Event | SyntheticEvent) => {
    event.stopPropagation();
    addTokenToWallet();
  };

  return (
    <BackgroundWrapper
      container
      sx={{
        minHeight: '100vh',
      }}
    >
      <Box pt={12} pb={5.5}>
        <Typography variant="h1" textTransform="uppercase" textAlign="center" className="gradient" mb={10.5}>
          {t('faqPage.title')}
        </Typography>
        {faq.map(({ id, links, withImportBtn = false }) => (
          <Box
            key={id}
            sx={{
              p: 3,
              background: BG_BLUE_ACCENT_DARK,
              border: `1px solid ${BG_GRAY_LIGHT}`,
              borderRadius: BORDER_RADIUS_MEDIUM,
              cursor: 'pointer',
              mb: 2,
            }}
            onClick={() => handleOpenAnswer(id)}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4" className="white" sx={{ ...flexHelper('center', 'flex-start') }}>
                {t(`faqPage.faqList.${id}.question`)}
                {withImportBtn && (
                  <IconButton sx={{ ml: 1, mr: { xs: 3, md: 0 } }} onClick={(e) => handleImportToken(e)}>
                    <ImportIcon sx={{ path: { stroke: COLOR_TEXT_WHITE_100 } }} />
                  </IconButton>
                )}
              </Typography>
              <ArrowUp
                sx={{ transform: id === activeFaqIndex ? '' : 'rotate(180deg)', transition: 'transform 0.33s ease' }}
              />
            </Stack>
            <Collapse collapsedSize={0} in={id === activeFaqIndex}>
              <Typography
                variant="body2"
                color={COLOR_TEXT_WHITE_100}
                fontWeight={FontWeights.fontWeightRegular}
                sx={{ pt: 1.5, pr: 6 }}
              >
                <Trans
                  i18nKey={`faqPage.faqList.${id}.answer`}
                  components={{
                    link1: links ? (
                      <Link
                        sx={{ color: BG_BLUE, textDecoration: 'underline' }}
                        href={links[language as keyof Links].link1}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ) : (
                      <Box />
                    ),
                    link2: links ? (
                      <Link
                        sx={{ color: BG_BLUE, textDecoration: 'underline' }}
                        href={links[language as keyof Links].link2}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ) : (
                      <Box />
                    ),
                    ul: <ul />,
                    li: <li />,
                  }}
                />
              </Typography>
            </Collapse>
          </Box>
        ))}
      </Box>
    </BackgroundWrapper>
  );
};
