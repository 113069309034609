import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FontFamilies, FontWeights } from 'theme';

import { ArrowDownIconWhite, ArrowUpIconWhite } from '../Icon';

interface DateSelectorButtonProps {
  calendarIsOpen: boolean;
  handleOpenCalendar: () => void;
  startDate: Date | null;
  endDate: Date | null;
}

export type DateSelectorButton = FC<DateSelectorButtonProps>;

export const DefaultDateSelectorButton: DateSelectorButton = ({ calendarIsOpen, handleOpenCalendar }) => {
  const { t } = useTranslation();
  return (
    <IconButton
      onClick={handleOpenCalendar}
      sx={{
        width: '100%',
        height: '100%',
        background: '#F0F0F0',
        borderRadius: '8px',
        border: '1px solid #B0B0B0',
        display: 'flex',
        '&:hover': {
          backgroundColor: '#C9C9C9',
        },
        transition: 'transform 0.15s ease-in-out',
        '&:active': {
          transform: 'scale(0.92)',
        },
      }}
    >
      <Typography
        margin="5px"
        fontSize="14px"
        fontFamily={FontFamilies.secondary}
        fontWeight={FontWeights.fontWeightMedium}
      >
        {t('dashboard.profile.date')}
      </Typography>
      {!calendarIsOpen && <KeyboardArrowDown sx={{ width: '20px', marginLeft: '-5px' }} />}
      {calendarIsOpen && <KeyboardArrowUp sx={{ width: '20px', marginLeft: '-5px' }} />}
    </IconButton>
  );
};

export const ProfileDateSelectorButton: DateSelectorButton = ({
  calendarIsOpen,
  handleOpenCalendar,
  startDate,
  endDate,
}) => {
  const dateTextSx = {
    color: 'white',
    fontSize: '18px',
    fontFamily: FontFamilies.secondary,
    fontWeight: FontWeights.fontWeightMedium,
    marginBottom: '5px',
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <IconButton
        onClick={handleOpenCalendar}
        sx={{
          width: '100%',
          height: '100%',
          background: 'transparent',
        }}
      >
        <Box display="flex">
          <Typography sx={dateTextSx}>{startDate ? `${format(startDate, 'dd.MM.yyyy')}-` : '-'}</Typography>
          <Typography sx={{ marginRight: '5px', ...dateTextSx }}>
            {endDate ? `${format(endDate, 'dd.MM.yyyy')}` : ''}
          </Typography>
        </Box>
        {!calendarIsOpen && <ArrowDownIconWhite />}
        {calendarIsOpen && <ArrowUpIconWhite />}
      </IconButton>
      <Box
        sx={{
          marginTop: '-8px',
          width: '218px',
          height: '2px',
          backgroundColor: 'white',
        }}
      />
    </Box>
  );
};
