import { sha256 } from 'js-sha256';
import { addAdmin, addAdminToList, error, request, success } from 'store/dashboard/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

export function* addAdminSaga({ type, payload: { email, password } }: ReturnType<typeof addAdmin>) {
  const hashedPassword: string = sha256(password);
  yield* put(request(type, { email, password: hashedPassword }));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.addAdmin, { email, password: hashedPassword });
      yield* put(addAdminToList(data));
      yield* put(success(type));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.ADD_ADMIN, addAdminSaga);
}
