import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useShallowSelector } from 'hooks';
import selector from 'store/dashboard/selectors';
import { Store } from 'types/store/dashboard';

import { StoreInfo } from './StoreInfo';

interface StoresListProps {
  gameId: string;
  isAdmin: boolean;
  openPopup: () => void;
  onSelectStore: (selectedId: string) => void;
}

export const StoresList: FC<StoresListProps> = ({ gameId, isAdmin, openPopup, onSelectStore }) => {
  const [storesList, setStoresList] = useState<Store[]>([]);
  const { profile, stores } = useShallowSelector(selector.getDashboard);

  useEffect(() => {
    if (stores) {
      setStoresList(stores);
    }
  }, [stores]);

  const filteredStores = storesList.filter((store) => store.gameId === gameId);

  return (
    <Box sx={{ marginTop: '32px' }}>
      {filteredStores.map((store) => (
        <StoreInfo
          key={store.id}
          name={store.name}
          linkText={`${store.link}&code=${profile!.code}`}
          storeId={store.id}
          isAdmin={isAdmin}
          openPopup={openPopup}
          onSelectStore={onSelectStore}
        />
      ))}
    </Box>
  );
};
