import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { routes } from 'appConstants';
import { Countdown } from 'components';
import { ProgressBar } from 'modules/crowdsale/components';
import { BG_GRAY_700, BORDER_RADIUS_PROGRESS_BAR, COLOR_TEXT_WHITE_100 } from 'theme';
import { CrowdsaleDataProps } from 'types';
import { flexHelper, fromDecimals } from 'utils';

type HeaderInfoProps = {
  isLoading: boolean;
} & Omit<CrowdsaleDataProps, 'minPurchase' | 'maxPurchase'>;

export const HeaderInfo: FC<HeaderInfoProps> = ({
  hardCap,
  softCap,
  totalBought,
  saleEndTime,
  saleStartTime,
  isActive,
  isLoading,
}) => {
  const { t } = useTranslation();

  const isNotStartedCrowdsale = useMemo(
    () => !isActive && Date.now() < +saleStartTime * 1000 && !isLoading,
    [isActive, isLoading, saleStartTime],
  );

  const isStartedCrowdsale = useMemo(
    () => isActive && Date.now() > +saleStartTime * 1000 && Date.now() < +saleEndTime * 1000 && !isLoading,
    [isActive, isLoading, saleEndTime, saleStartTime],
  );

  const isCrowdsaleSuccessfull = useMemo(
    () => !isActive && +totalBought >= +softCap && !isLoading,
    [isActive, isLoading, softCap, totalBought],
  );

  const isCrowdsaleErrorfull = useMemo(
    () => !isActive && +totalBought < +softCap && Date.now() > +saleEndTime * 1000 && !isLoading,
    [isActive, isLoading, saleEndTime, softCap, totalBought],
  );

  const EndTextComponent = useMemo(() => {
    if (+totalBought >= +softCap) {
      return (
        <Typography
          variant="body2"
          className="white"
          sx={{
            a: {
              color: COLOR_TEXT_WHITE_100,
              textDecoration: 'none',
              borderBottom: `1px solid ${COLOR_TEXT_WHITE_100}`,
            },
          }}
        >
          {t('buyTokenPage.countdownTextOne')}
          <Link to={routes.staking.root.path}>Staking</Link>
        </Typography>
      );
    }

    if (+totalBought < +softCap) {
      return (
        <Typography variant="body2" className="white">
          {t('buyTokenPage.countdownTextTwo')}
        </Typography>
      );
    }

    return t('buyTokenPage.countdownTextThree');
  }, [softCap, t, totalBought]);

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 2, md: 3.5 },
        pt: 1.5,
        width: '100%',
        minHeight: 296,
        flexDirection: 'column',
        ...flexHelper('flex-start'),
        background: BG_GRAY_700,
        backdropFilter: 'blur(4px)',
        borderTopLeftRadius: BORDER_RADIUS_PROGRESS_BAR,
        borderTopRightRadius: BORDER_RADIUS_PROGRESS_BAR,
      }}
    >
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: '100%', height: 88 }}>
        {isNotStartedCrowdsale && (
          <Countdown
            title={t('buyTokenPage.countdownTitleStart')}
            timer={+saleStartTime}
            EndTextComponent={t('buyTokenPage.countdownTextThree')}
          />
        )}

        {isStartedCrowdsale && (
          <Countdown
            title={t('buyTokenPage.countdownTitleEnd')}
            timer={+saleEndTime}
            EndTextComponent={EndTextComponent}
          />
        )}

        {isCrowdsaleSuccessfull && (
          <Typography
            variant="body2"
            className="white"
            sx={{
              a: {
                color: COLOR_TEXT_WHITE_100,
                textDecoration: 'none',
                borderBottom: `1px solid ${COLOR_TEXT_WHITE_100}`,
              },
            }}
          >
            {t('buyTokenPage.countdownTextOne')}
            <Link to={routes.staking.root.path}>Staking</Link>
          </Typography>
        )}

        {isCrowdsaleErrorfull && (
          <Typography variant="body2" className="white">
            {t('buyTokenPage.countdownTextTwo')}
          </Typography>
        )}
      </Stack>

      <ProgressBar
        withLevels
        hardCapValue={+fromDecimals(hardCap)}
        softCapValue={+fromDecimals(softCap)}
        current={+fromDecimals(totalBought)}
        total={+fromDecimals(hardCap)}
      />
    </Box>
  );
};
