import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CopyIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.59086 14.4H6.5C5.11929 14.4 4 13.2807 4 11.9V6.5C4 5.11929 5.11929 4 6.5 4H11.9C13.2807 4 14.4 5.11929 14.4 6.5V6.56184M12.1 20H17.5C18.8807 20 20 18.8807 20 17.5V12.1C20 10.7193 18.8807 9.6 17.5 9.6H12.1C10.7193 9.6 9.6 10.7193 9.6 12.1V17.5C9.6 18.8807 10.7193 20 12.1 20Z"
      stroke="#4DA3FF"
      strokeWidth="1.5px"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
