import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SupportPopup } from 'modules/dashboard/components/Popups';
import { AddingGames, AddingStore, SupportButton } from 'modules/dashboard/containers';
import { COLOR_TEXT_BLUE_GRADIENT, FontFamilies, FontWeights } from 'theme';

export const ProjectsPage: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [gameId, setGameId] = useState<string>('');
  const handleGameSelection = (selectedGameId: string) => {
    setGameId(selectedGameId);
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [isSupportPopupOpen, setSupportPopupOpen] = useState(false);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: 'calc(100vh - 90px)' }}>
      <SupportPopup isOpen={isSupportPopupOpen} closePopup={() => setSupportPopupOpen(false)} />
      <Typography
        sx={{
          mt: '80px',
          fontSize: 58,
          fontFamily: FontFamilies.secondary,
          fontWeight: FontWeights.fontWeightExtraBold,
          background: COLOR_TEXT_BLUE_GRADIENT,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          lineHeight: 1.1,
          textTransform: 'uppercase',
        }}
      >
        {t('dashboard.projects.ourProjects')}
      </Typography>
      <AddingGames onSelectGame={handleGameSelection} isAdmin={false} sx={{ mt: '90px' }} />
      <AddingStore gameId={gameId} gameName="" isAdmin={false} />
      {!isSmallScreen && (
        <SupportButton
          openPopup={() => setSupportPopupOpen(true)}
          sx={{
            position: 'absolute',
            bottom: 20,
            right: 0,
          }}
        />
      )}
    </Box>
  );
};
