import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const WalletIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      width: '20px',
      height: '20px',
      path: {
        fill: '#191836',
      },
      ...sx,
    }}
  >
    <path
      d="M16.6641 5.83333V4.16667C16.6641 3.2475 15.9166 2.5 14.9974 2.5H4.16406C2.78573 2.5 1.66406 3.62167 1.66406 5V15C1.66406 16.8342 3.15906 17.5 4.16406 17.5H16.6641C17.5832 17.5 18.3307 16.7525 18.3307 15.8333V7.5C18.3307 6.58083 17.5832 5.83333 16.6641 5.83333ZM14.9974 13.3333H13.3307V10H14.9974V13.3333ZM4.16406 5.83333C3.9495 5.82374 3.7469 5.73174 3.59846 5.57651C3.45002 5.42129 3.36717 5.21478 3.36717 5C3.36717 4.78522 3.45002 4.57871 3.59846 4.42349C3.7469 4.26826 3.9495 4.17626 4.16406 4.16667H14.9974V5.83333H4.16406Z"
      fill="currentColor"
    />
  </SvgIcon>
);
