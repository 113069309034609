import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { routes } from 'appConstants';
import { useShallowSelector } from 'hooks';
import { IconTyzWhite } from 'modules/dashboard/components';
import { Background } from 'modules/dashboard/components/Background';
import { SupportPopup } from 'modules/dashboard/components/Popups';
import { InfoButon } from 'modules/dashboard/containers';
import { DashboardPanel } from 'modules/dashboard/containers/DashboardPanel';
import { LoadingScreen } from 'modules/layout/containers';
import {
  getAdmins,
  getApprovedWithdrawal,
  getConfirmations,
  getGames,
  getPaymentsHistory,
  getProfileInfo,
  getStores,
  getWithdrawalLimits,
} from 'store/dashboard/actions';
import selector from 'store/dashboard/selectors';
import { logout } from 'store/referral/actions';
import { Admin, Game, PaymentInfo, Store, User, WithdrawalLimits } from 'types/store/dashboard';

import { ClientPage } from '../ClientPage';
import { ConfirmationPage } from '../ConfirmationPage';
import { HistoryPage } from '../HistoryPage';
import { NetworkPage } from '../NetworkPage';
import { ProjectsPage } from '../ProjectsPage';
import { SettingsPage } from '../SettingsPage';

type TabConfig = {
  path: string;
  component: FC<any>;
  props?: any;
};

export enum Page {
  client = 'client',
  network = 'network',
  confirmation = 'confirmation',
  settings = 'settings',
  history = 'history',
  projects = 'projects',
  root = 'root',
}

type PageTabConfigDictionary = Record<Page, TabConfig>;

const tabs: PageTabConfigDictionary = {
  [Page.client]: {
    path: Page.client,
    component: ClientPage,
  },
  [Page.network]: {
    path: Page.network,
    component: NetworkPage,
  },
  [Page.confirmation]: {
    path: Page.confirmation,
    component: ConfirmationPage,
  },
  [Page.settings]: {
    path: Page.settings,
    component: SettingsPage,
  },
  [Page.history]: {
    path: Page.history,
    component: HistoryPage,
  },
  [Page.projects]: {
    path: Page.projects,
    component: ProjectsPage,
  },
  [Page.root]: {
    path: Page.projects,
    component: () => <Box />,
  },
};

export const DashboardPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activePage, setActivePage] = useState<Page | null>(null);
  const [isSupportPopupOpen, setSupportPopupOpen] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const almostBlack = '#191836';
  const dashBoardPanelWidth = 276;
  const margin = isSmallScreen ? 22 : 35;

  const dispatch = useDispatch();

  const state = useShallowSelector(selector.getDashboard);

  const [profile, setProfile] = useState<User | null>(null);
  const [withdrawalLimits, setWithdrawalLimits] = useState<WithdrawalLimits | null>(null);
  const [games, setGames] = useState<Game[] | null>(null);
  const [admins, setAdmins] = useState<Admin[] | null>(null);
  const [stores, setStores] = useState<Store[] | null>(null);
  const [paymentsHistory, setPaymentsHistory] = useState<PaymentInfo[] | null>(null);
  const [isRequestsProcessing, setRequestsProcessing] = useState(true);

  const { t } = useTranslation();

  const handleChangePage = (page: Page) => {
    if (page === Page.root) {
      dispatch(logout('/referrals/signin'));
    } else {
      setActivePage(page);
      navigate(tabs[page].path);
    }
  };

  useEffect(() => {
    if (profile === null) {
      setProfile(state.profile);
    }
    if (state.withdrawalLimits !== null) {
      setWithdrawalLimits(state.withdrawalLimits);
    }
    if (state.games !== null) {
      setGames(state.games);
    }
    if (state.admins !== null) {
      setAdmins(state.admins);
    }
    if (state.stores !== null) {
      setStores(state.stores);
    }
    if (state.paymentsHistory !== null) {
      setPaymentsHistory(state.paymentsHistory);
    }
  }, [state]);

  useEffect(() => {
    if (profile === null) return;
    if (profile.level.toString().toLowerCase() === 'bronze') {
      localStorage.setItem(
        'showToast',
        JSON.stringify({
          type: 'info',
          message: t('referralSystem.info.noAccess'),
        }),
      );
      dispatch(logout('/referrals/signin'));
      return;
    }
    dispatch(getWithdrawalLimits());

    let fullPath = location.pathname;
    if (fullPath.endsWith('*')) {
      fullPath = fullPath.slice(0, -1);
    }
    if (fullPath.endsWith('/')) {
      fullPath = fullPath.slice(0, -1);
    }

    if (fullPath === '/referrals/dashboard') {
      handleChangePage(Page.client);
    } else {
      const validPages = new Set(Object.values(Page));
      const path: string = fullPath.split('/').pop() as string;
      if (!validPages.has(path as Page)) {
        navigate(`${routes.home.root.path}404`);
      }
    }
    dispatch(getGames());
    dispatch(getStores());
    dispatch(getApprovedWithdrawal(profile.email));
    if (profile.role === 'admin') {
      dispatch(getAdmins());
      dispatch(getConfirmations());
    } else {
      dispatch(getPaymentsHistory());
    }
  }, [profile]);

  useEffect(() => {
    if (profile === null) return;
    if (withdrawalLimits !== null && games !== null && stores !== null) {
      if (profile.role === 'admin') {
        if (admins !== null) {
          setRequestsProcessing(false);
        }
      } else if (paymentsHistory !== null) {
        setRequestsProcessing(false);
      }
    }
  }, [games, admins, stores, withdrawalLimits, paymentsHistory]);

  const handleToMainPage = () => navigate(routes.home.root.path);

  useEffect(() => {
    dispatch(getProfileInfo());
  }, [dispatch]);

  useEffect(() => {
    const path: Page = location.pathname.split('/').pop() as Page;
    const activeTab = path ? tabs[path] : undefined;
    if (activeTab) {
      setActivePage(path);
    }
  }, [location]);

  const ActivePageComponent = activePage === null ? undefined : tabs[activePage]?.component;
  const activePageProps = activePage === null ? undefined : tabs[activePage]?.props || {};

  return isRequestsProcessing ? (
    <Box sx={{ zIndex: 2 ** 31 - 1 }}>
      <LoadingScreen />
    </Box>
  ) : (
    <Background>
      <SupportPopup isOpen={isSupportPopupOpen} closePopup={() => setSupportPopupOpen(false)} />
      <DashboardPanel
        confirmationCount={state.confirmations.length}
        setActivePage={handleChangePage}
        activePage={activePage ?? Page.client}
        width={dashBoardPanelWidth}
        toMainPage={handleToMainPage}
        margin={margin}
        openSupportPopup={() => setSupportPopupOpen(true)}
      />
      {isSmallScreen && (
        <IconButton onClick={handleToMainPage}>
          <IconTyzWhite
            fillColor={almostBlack}
            widths="108px"
            height="39px"
            sx={{ position: 'absolute', top: '8px', left: '-12px' }}
          />
        </IconButton>
      )}
      <Box
        position="relative"
        sx={{
          marginLeft: isSmallScreen === false ? `${dashBoardPanelWidth + margin}px` : `${margin}px`,
          width:
            isSmallScreen === false
              ? `calc(100% - ${margin * 2 + dashBoardPanelWidth}px)`
              : `calc(100% - ${margin * 2}px)`,
          marginRight: `${margin}px`,
        }}
      >
        {ActivePageComponent && <ActivePageComponent {...activePageProps} />}
      </Box>

      {activePage !== Page.network && activePage !== Page.root && !state.selectedUserInfo && (
        <InfoButon
          sx={{
            position: 'absolute',
            top: isSmallScreen ? '80px' : '56px',
            right: `${margin}px`,
          }}
          putInColumn={activePage === Page.settings}
        />
      )}
    </Background>
  );
};
