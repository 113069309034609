import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { MainLogo } from 'modules/layout/components';
import { ImageBackground, LoginLogo } from 'modules/referral/assets';

const MainLogoStyled = styled(MainLogo)(({ theme }) => ({
  position: 'absolute',
  bottom: 74,
  right: 104,
}));

export const LoginWithbackGround = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        height: {
          xs: 155,
          md: 515,
        },
        width: {
          xs: 342,
          md: 320,
        },
        position: 'relative',
        padding: 0,
      }}
    >
      <ImageBackground
        height={{
          xs: 155,
          md: 515,
        }}
        width={{
          xs: 342,
          md: 320,
        }}
      />
      <LoginLogo
        sx={{
          position: 'absolute',
          height: {
            xs: 181,
            md: 328,
          },
          width: {
            xs: 163,
            md: 295,
          },
          top: {
            xs: -20,
            md: 0,
          },
          left: {
            xs: 89.5,
            md: 12.5,
          },
        }}
      />
      {isMd && <MainLogoStyled />}
    </Box>
  );
};
