import { fork } from 'redux-saga/effects';
import crowdsaleSaga from 'store/crowdsale/sagas';
import dashboardSaga from 'store/dashboard/sagas';
import game22Saga from 'store/game22/sagas';
/* PLOP_INJECT_IMPORT_SAGA */
import referralSaga from 'store/referral/sagas';
import stakingSaga from 'store/staking/sagas';
import swapSaga from 'store/swap/sagas';
import userSaga from 'store/user/sagas';

export default function* rootSaga() {
  yield fork(userSaga);
  /* PLOP_INJECT_FORK_SAGA */
  yield fork(stakingSaga);
  yield fork(crowdsaleSaga);
  yield fork(swapSaga);
  yield fork(referralSaga);
  yield fork(dashboardSaga);
  yield fork(game22Saga);
}
