import BigNumber from 'bignumber.js/bignumber';
import { ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import crowdsaleSelector from 'store/crowdsale/selectors';
import userSelector from 'store/user/selectors';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { CrowdsaleAbi, CrowdsaleState, UserState } from 'types';
import { createContract, fromDecimals, getContractDataByItsName } from 'utils';

import { getCrowdsaleData } from '../actions';
import actionTypes from '../actionTypes';
import { updateCrowdsaleState } from '../reducer';

import { getCrowdsaleAddressSaga } from './getCrowdsaleAddress';

export function* getCrowdsaleDataSaga({
  type,
  payload: { updateCrowdsaleAddress },
}: ReturnType<typeof getCrowdsaleData>) {
  yield* put(apiActions.request(type));

  const { chainType }: UserState = yield select(userSelector.getUser);
  const crowdsaleContractAddress: CrowdsaleState['address'] = yield select(crowdsaleSelector.getProp('address'));
  const [crowdsaleAbi] = getContractDataByItsName(ContractsNames.crowdsale, chainType);

  try {
    const crowdsaleContract: CrowdsaleAbi = yield createContract(crowdsaleAbi, crowdsaleContractAddress, chainType);

    if (updateCrowdsaleAddress) {
      yield* call(getCrowdsaleAddressSaga, { type: actionTypes.GET_CROWDSALE_ADDRESS, payload: undefined });
    }

    const crowdsaleParamsArr = yield* all([
      call(crowdsaleContract.methods.maxPurchase().call), // 0
      call(crowdsaleContract.methods.minPurchase().call), // 1
      call(crowdsaleContract.methods.hardcap().call), // 2
      call(crowdsaleContract.methods.softcap().call), // 3
      call(crowdsaleContract.methods.totalBought().call), // 4
      call(crowdsaleContract.methods.sellEnd().call), // 5
      call(crowdsaleContract.methods.sellStart().call), // 6
    ]);
    const isActive = yield* call(crowdsaleContract.methods.isActive().call);

    const differHardcapTotalBought = new BigNumber(crowdsaleParamsArr[2]).minus(new BigNumber(crowdsaleParamsArr[4]));
    const maxPurchaseBN = new BigNumber(crowdsaleParamsArr[0]);

    const availableCalcValue = differHardcapTotalBought.isLessThan(maxPurchaseBN)
      ? differHardcapTotalBought
      : maxPurchaseBN;

    const availableTokensOnCrowdsale = fromDecimals(availableCalcValue.toFixed(0, 1));

    yield* put(
      updateCrowdsaleState({
        availableTokensOnCrowdsale,
        crowdsaleData: {
          maxPurchase: crowdsaleParamsArr[0],
          minPurchase: crowdsaleParamsArr[1],
          hardCap: crowdsaleParamsArr[2],
          softCap: crowdsaleParamsArr[3],
          totalBought: crowdsaleParamsArr[4],
          saleEndTime: crowdsaleParamsArr[5],
          saleStartTime: crowdsaleParamsArr[6],
          isActive,
        },
      }),
    );

    yield* put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_CROWDSALE_DATA, getCrowdsaleDataSaga);
}
