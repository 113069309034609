import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ModalProps } from 'components';
import { CodeModal } from 'modules/referral/components';
import { loginTwoFa } from 'store/referral/actions';

export const ConfirmLoginModal: FC<ModalProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const sendCode = (code: string) => {
    dispatch(loginTwoFa({ code }));
  };
  return <CodeModal open={open} onClose={onClose} sendCode={sendCode} />;
};
