import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TelegramIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 24"
    sx={{
      width: '25px',
      height: '24px',
      path: {
        fill: '#8A90AB',
      },
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0601 12C24.0601 18.6274 18.6875 24 12.0601 24C5.43264 24 0.0600586 18.6274 0.0600586 12C0.0600586 5.37258 5.43264 0 12.0601 0C18.6875 0 24.0601 5.37258 24.0601 12ZM12.4901 8.85893C11.3229 9.3444 8.9902 10.3492 5.49195 11.8733C4.92389 12.0992 4.62632 12.3202 4.59923 12.5363C4.55344 12.9015 5.01077 13.0453 5.63353 13.2411C5.71824 13.2678 5.80601 13.2954 5.896 13.3246C6.5087 13.5238 7.33288 13.7568 7.76135 13.766C8.15 13.7744 8.58379 13.6142 9.0627 13.2853C12.3312 11.079 14.0184 9.96381 14.1244 9.93977C14.1991 9.92281 14.3027 9.90148 14.3728 9.96385C14.443 10.0262 14.4361 10.1443 14.4287 10.176C14.3834 10.3691 12.5882 12.0381 11.6592 12.9018C11.3696 13.171 11.1641 13.362 11.1221 13.4056C11.0281 13.5033 10.9322 13.5958 10.84 13.6846C10.2708 14.2333 9.84397 14.6448 10.8637 15.3168C11.3537 15.6397 11.7458 15.9067 12.137 16.1731C12.5642 16.4641 12.9903 16.7543 13.5417 17.1157C13.6821 17.2077 13.8163 17.3034 13.9469 17.3965C14.4441 17.751 14.8908 18.0694 15.4426 18.0186C15.7633 17.9891 16.0945 17.6876 16.2627 16.7884C16.6603 14.6631 17.4417 10.0585 17.6223 8.16097C17.6381 7.99473 17.6182 7.78197 17.6022 7.68857C17.5862 7.59518 17.5528 7.46211 17.4314 7.3636C17.2877 7.24694 17.0657 7.22234 16.9664 7.22408C16.5151 7.23203 15.8226 7.47282 12.4901 8.85893Z"
      fill="currentColor"
    />
  </SvgIcon>
);
