import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CrossInRound: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 34 34"
    sx={{
      width: '34px',
      height: '34px',
      color: 'transparent',
      path: {
        fill: '#fff',
      },
      rect: {
        stroke: '#fff',
      },
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2929 10.2929C10.6834 9.90237 11.3166 9.90237 11.7071 10.2929L17 15.5858L22.2929 10.2929C22.6834 9.90237 23.3166 9.90237 23.7071 10.2929C24.0976 10.6834 24.0976 11.3166 23.7071 11.7071L18.4142 17L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L17 18.4142L11.7071 23.7071C11.3166 24.0976 10.6834 24.0976 10.2929 23.7071C9.90237 23.3166 9.90237 22.6834 10.2929 22.2929L15.5858 17L10.2929 11.7071C9.90237 11.3166 9.90237 10.6834 10.2929 10.2929Z"
      fill="currentColor"
    />
    <rect x="0.5" y="0.5" width="33" height="33" rx="16.5" stroke="currentColor" />
  </SvgIcon>
);
