import i18n from 'i18next';
import { demoteAdmin, error, onPromoteAdmin, request, success } from 'store/dashboard/actions';
import { call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';
import { errorToastMessage, getToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

export function* promoteAdminSaga({ type, payload: { email } }: ReturnType<typeof demoteAdmin>) {
  yield* put(request(type, { email }));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.promoteAdmin, { email });
      yield* put(onPromoteAdmin(data));
      getToastMessage('info', i18n.t('dashboard.profile.onAdminPromoted'));
      yield* put(success(type));
    } else {
      ShowUnauthorizedMessage();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.PROMOTE_ADMIN, promoteAdminSaga);
}
