import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, styled, Typography } from '@mui/material';
import { Icon } from 'components';
import { useModal } from 'hooks';
import { onUnstake } from 'store/staking/actions';
import { BORDER_POOL_DARK, BORDER_RADIUS_CARD_SM, BORDER_RADIUS_MEDIUM, FontWeights } from 'theme';
import { StakingState } from 'types';
import { fromDecimals } from 'utils';
import Web3 from 'web3';

import { UnstakePopup } from './components';

const GridStyledWrapper = styled(Grid)(({ theme }) => ({
  padding: theme.spacing('11px', 3),
  marginTop: theme.spacing(3),
  width: '100% !important',
  height: 80,
  background: 'rgba(255, 255, 255, 0.03)',
  border: BORDER_POOL_DARK,
  borderRadius: BORDER_RADIUS_CARD_SM,

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 2),
    height: 165,
  },
}));

const LoadingBtnStyled = styled(LoadingButton)(({ theme }) => ({
  minWidth: '100%',
  borderRadius: BORDER_RADIUS_MEDIUM,

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export type PoolInfoProps = {
  web3Provider: Web3;
  isUnstaking: boolean;
} & Pick<StakingState, 'userStakeData' | 'isUnstakeAvailable' | 'isEarlyUnstake'>;

export const PoolInfo: FC<PoolInfoProps> = ({
  web3Provider,
  userStakeData,
  isUnstaking,
  isUnstakeAvailable,
  isEarlyUnstake,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const unstakePopupRef = useRef(null);

  const [isUnstakePopupVisible, openUnstakePopup, closeUnstakePopup] = useModal(false);

  const { pendingReward, userStakeAmount } = userStakeData;

  const showUnstakeBtn = +userStakeAmount > 0 && isUnstakeAvailable && isEarlyUnstake;
  const showClaimBtn = +userStakeAmount > 0 && isUnstakeAvailable && !isEarlyUnstake;

  const handleUnstake = () => {
    dispatch(onUnstake({ web3Provider }));
  };

  return (
    <GridStyledWrapper container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="body2" className="s gray">
          {t('stakingPage.tokenStaked')}
        </Typography>

        <Stack mt={0.5} direction="row" alignItems="center">
          <Icon.TyzIcon sx={{ mr: 1, width: 30, height: 30 }} />

          <Typography variant="body2" className="white" fontSize={18} fontWeight={FontWeights.fontWeightBold}>
            {fromDecimals(userStakeAmount)}
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="body2" className="s gray">
          {t('stakingPage.reward')}
        </Typography>

        <Stack mt={0.5} direction="row" alignItems="center">
          <Icon.TyzIcon sx={{ mr: 1, width: 30, height: 30 }} />

          <Typography variant="body2" className="white" fontSize={18} fontWeight={FontWeights.fontWeightBold}>
            {fromDecimals(pendingReward)}
          </Typography>
        </Stack>
      </Grid>

      <Grid item container justifyContent={{ xs: 'center', md: 'flex-end' }} flexWrap="nowrap" xs={12} sm={12} md={4}>
        {showUnstakeBtn && (
          <LoadingBtnStyled ref={unstakePopupRef} loading={isUnstaking} onClick={openUnstakePopup}>
            {t('stakingPage.buttonUnstake')}
          </LoadingBtnStyled>
        )}

        {showClaimBtn && (
          <LoadingBtnStyled variant="outlined" loading={isUnstaking} onClick={handleUnstake} sx={{ ml: 2 }}>
            {t('stakingPage.buttonClaim')}
          </LoadingBtnStyled>
        )}
      </Grid>

      {isUnstakePopupVisible && (
        <UnstakePopup
          visible={isUnstakePopupVisible}
          anchorEl={unstakePopupRef}
          onClose={closeUnstakePopup}
          onUnstake={handleUnstake}
        />
      )}
    </GridStyledWrapper>
  );
};
