import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { Card } from 'components';
import { onClaim } from 'store/swap/actions';
import { BORDER_DEFAULT_GRAY, BORDER_RADIUS_PROGRESS_BAR } from 'theme';
import { flexHelper } from 'utils';
import Web3 from 'web3';

export type ClaimBlockProps = {
  web3Provider: Web3;
  commissionAmount: string | number;
  dealerAmount: string | number;
  isClaiming: boolean;
  isSwapping: boolean;
  isClaimAvailable: boolean;
};

export const ClaimBlock: FC<ClaimBlockProps> = ({
  web3Provider,
  commissionAmount,
  dealerAmount,
  isClaiming,
  isSwapping,
  isClaimAvailable,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClaim = () => {
    dispatch(onClaim({ web3Provider }));
  };

  return (
    <Box sx={{ width: '100%', flexDirection: 'column', ...flexHelper('flex-start') }}>
      <Card
        type="lightTransparent"
        sx={{
          p: { xs: 2, md: 4 },
          mb: 10,
          width: { xs: '100%', sm: '100%', md: 448 },
          minHeight: 200,
          flexDirection: 'column',
          ...flexHelper('center', 'flex-start'),
          border: BORDER_DEFAULT_GRAY,
          borderRadius: BORDER_RADIUS_PROGRESS_BAR,
        }}
      >
        <Stack direction="column" spacing={1}>
          <Stack direction="row">
            <Typography variant="body2" className="gray">
              {t('swapTokenPage.commissionAmount')} :&nbsp;&nbsp;
            </Typography>
            <Typography variant="body2" className="white">
              {commissionAmount}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="body2" className="gray">
              {t('swapTokenPage.dealerAmount')} :&nbsp;&nbsp;
            </Typography>
            <Typography variant="body2" className="white">
              {dealerAmount}
            </Typography>
          </Stack>
        </Stack>
        <LoadingButton
          size="large"
          variant="outlined"
          fullWidth
          loading={isClaiming}
          disabled={isSwapping || (!dealerAmount && !commissionAmount) || !isClaimAvailable}
          onClick={handleClaim}
          sx={{ maxWidth: '385px', mt: 4 }}
        >
          {t('swapTokenPage.buttonClaim')}
        </LoadingButton>
      </Card>
    </Box>
  );
};
