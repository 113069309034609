import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useShallowSelector } from 'hooks';
import { RestrictionBox } from 'modules/dashboard/components/Settings';
import { updateMaxWithdrawal, updateMinWithdrawal, updateWithdrawalPeriod } from 'store/dashboard/actions';
import selector from 'store/dashboard/selectors';
import { getToastMessage } from 'utils';

export const LimitsPanel: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { withdrawalLimits } = useShallowSelector(selector.getDashboard);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const width = isSmallScreen ? '230px' : '300px';

  const [editedContentMin, setEditedContentMin] = useState<string>('-');
  const [editedContentMax, setEditedContentMax] = useState<string>('-');
  const [editedContentPeriod, setEditedContentPeriod] = useState<string>('-');

  const handleMinWithdrawalSubmit = (minWithdrawal: number) => {
    if (!withdrawalLimits) return;
    if (withdrawalLimits.maxWithdrawal >= minWithdrawal) {
      dispatch(updateMinWithdrawal({ minWithdrawal }));
    } else {
      getToastMessage('error', t('dashboard.settings.withdrawalLimits.minUpdateError'));
      setEditedContentMin(`${withdrawalLimits.minWithdrawal}`);
    }
  };

  const handleMaxWithdrawalSubmit = (maxWithdrawal: number) => {
    if (!withdrawalLimits) return;
    if (withdrawalLimits.minWithdrawal <= maxWithdrawal) {
      dispatch(updateMaxWithdrawal({ maxWithdrawal }));
    } else {
      getToastMessage('error', t('dashboard.settings.withdrawalLimits.maxUpdateError'));
      setEditedContentMax(`${withdrawalLimits.maxWithdrawal}`);
    }
  };

  const handlePeriodWithdrawalSubmit = (withdrawalPeriod: number) => {
    dispatch(updateWithdrawalPeriod({ withdrawalPeriod }));
  };

  useEffect(() => {
    if (!withdrawalLimits) return;
    setEditedContentMin(`${withdrawalLimits.minWithdrawal}`);
    setEditedContentMax(`${withdrawalLimits.maxWithdrawal}`);
    setEditedContentPeriod(`${withdrawalLimits.withdrawalPeriod}`);
  }, [withdrawalLimits]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <RestrictionBox
        headerText={t('dashboard.settings.withdrawalLimits.min')}
        editedContent={editedContentMin}
        setEditedContent={setEditedContentMin}
        onSubmit={handleMinWithdrawalSubmit}
        width={width}
        height="70px"
        sx={{ marginBottom: '24px' }}
        postfix=" TES"
        maxLengthOfIntegerPart={7}
        maxLengthOfDecimalPart={2}
      />
      <RestrictionBox
        headerText={t('dashboard.settings.withdrawalLimits.max')}
        editedContent={editedContentMax}
        setEditedContent={setEditedContentMax}
        onSubmit={handleMaxWithdrawalSubmit}
        width={width}
        height="100px"
        sx={{ marginBottom: '24px' }}
        postfix=" TES"
        maxLengthOfIntegerPart={7}
        maxLengthOfDecimalPart={2}
      />
      <RestrictionBox
        headerText={t('dashboard.settings.withdrawalLimits.period')}
        editedContent={editedContentPeriod}
        setEditedContent={setEditedContentPeriod}
        onSubmit={handlePeriodWithdrawalSubmit}
        width={width}
        height="100px"
        postfix={` ${t('dashboard.settings.withdrawalLimits.hour')}`}
        maxLengthOfIntegerPart={4}
        maxLengthOfDecimalPart={3}
      />
    </Box>
  );
};
