import { error, request, sendCode, success } from 'store/game22/actions';
import { baseApi } from 'store/game22/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';
import { setIsRegistered } from '../reducer';

export function* sendRegistrationCodeSaga({ type, payload: { email, otp_code: code } }: ReturnType<typeof sendCode>) {
  yield* put(request(type));

  try {
    yield* call(baseApi.sendCode, { email, otp_code: code });
    yield* put(setIsRegistered(true));
    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CONFIRM_EMAIL, sendRegistrationCodeSaga);
}
