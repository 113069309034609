import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 18 18"
    sx={{
      width: '18px',
      height: '18px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0318 1.68084C13.0318 1.68084 13.5039 1.29376 13.7859 1.17694C14.0679 1.06013 14.3702 1 14.6755 1C14.9807 1 15.283 1.06013 15.565 1.17694C15.8471 1.29376 16.1033 1.46499 16.3192 1.68084C16.535 1.89669 16.7062 2.15295 16.8231 2.43497C16.9399 2.717 17 3.01927 17 3.32453C17 3.6298 16.9399 3.93207 16.8231 4.21409C16.7062 4.49612 16.3192 4.96823 16.3192 4.96823C16.3192 4.96823 8.85204 14.6 5.22423 16.0632C4.32903 16.4242 3.12036 16.7474 2.1781 16.9722C1.47586 17.1397 0.860265 16.5241 1.0278 15.8219C1.2526 14.8796 1.5758 13.671 1.93684 12.7758C3.39999 9.14796 13.0318 1.68084 13.0318 1.68084Z"
      stroke="#191836"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
