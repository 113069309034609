import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { BG_BUTTON_BLUE, BG_BUTTON_GRAY, BG_BUTTON_WHITE, BORDER_RADIUS_DEFAULT } from 'theme';

export const getMuiToggleButtonOverrides = (theme: Theme): ComponentsOverrides['MuiToggleButton'] => ({
  root: {
    margin: `${theme.spacing(0.5)} !important`,
    padding: 0,
    height: 4,
    minWidth: 44,
    background: BG_BUTTON_GRAY,
    border: 'none',
    borderRadius: BORDER_RADIUS_DEFAULT,
    '&:hover': {
      background: BG_BUTTON_WHITE,
    },

    '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
      borderRadius: BORDER_RADIUS_DEFAULT,
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      borderRadius: BORDER_RADIUS_DEFAULT,
    },

    '&.MuiToggleButton-root.Mui-selected': {
      background: BG_BUTTON_BLUE,
      border: 'none',
      borderRadius: BORDER_RADIUS_DEFAULT,
    },
  },
});

export const getMuiToggleButtonDefaultProps = (): ComponentsProps['MuiToggleButton'] => ({});
