import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { BackgroundWrapper } from 'components';

export const AML: FC = () => {
  const { t } = useTranslation();

  return (
    <BackgroundWrapper
      container
      sx={{
        minHeight: '100vh',
      }}
    >
      <Box pt={12} pb={5.5}>
        <Typography
          variant="h1"
          textTransform="uppercase"
          textAlign="center"
          className="gradient s"
          mb={{ xs: 4, md: 8 }}
        >
          {t('aml.title')}
        </Typography>

        <Stack spacing={2}>
          <Typography variant="h2" className="m white">
            {t('aml.subtitle')}
          </Typography>
          <Typography variant="h3" className="m white">
            {t('aml.generalProvisions.title')}
          </Typography>
          <Typography className="m"> {t('aml.generalProvisions.text1')}</Typography>
          <Typography className="m"> {t('aml.generalProvisions.text2')}</Typography>
          <Typography className="m"> {t('aml.generalProvisions.text3')}</Typography>
          <Typography className="m"> {t('aml.generalProvisions.text4')}</Typography>
          <Typography className="m"> {t('aml.generalProvisions.text5')}</Typography>

          <Typography variant="h3" className="m white">
            {t('aml.identification.title')}
          </Typography>
          <Typography className="m"> {t('aml.identification.text1')}</Typography>
          <Typography className="m"> {t('aml.identification.text2')}</Typography>

          <Typography variant="h3" className="m white">
            {t('aml.customerVerification.title')}
          </Typography>
          <Typography className="m"> {t('aml.customerVerification.text1')}</Typography>
          <Typography className="m"> {t('aml.customerVerification.text2')}</Typography>
          <Typography className="m"> {t('aml.customerVerification.text3')}</Typography>
          <Typography className="m"> {t('aml.customerVerification.text4')}</Typography>
          <Typography className="m"> {t('aml.customerVerification.text5')}</Typography>

          <Typography variant="h3" className="m white">
            {t('aml.monitoring.title')}
          </Typography>
          <Typography className="m"> {t('aml.monitoring.text1')}</Typography>
          <Typography className="m"> {t('aml.monitoring.text2')}</Typography>
          <Typography className="m"> {t('aml.monitoring.text3')}</Typography>

          <Typography variant="h3" className="m white">
            {t('aml.accounting.title')}
          </Typography>
          <Typography className="m"> {t('aml.accounting.text1')}</Typography>
          <Typography className="m"> {t('aml.accounting.text2')}</Typography>
          <Typography className="m"> {t('aml.accounting.text3')}</Typography>

          <Typography variant="h3" className="m white">
            {t('aml.compliance.title')}
          </Typography>
          <Typography className="m"> {t('aml.compliance.text1')}</Typography>
          <Typography className="m"> {t('aml.compliance.text2')}</Typography>

          <Typography variant="h3" className="m white">
            {t('aml.obligations.title')}
          </Typography>
          <Typography className="m"> {t('aml.obligations.text1')}</Typography>

          <Typography variant="h3" className="m white">
            {t('aml.implementation.title')}
          </Typography>
          <Typography className="m"> {t('aml.implementation.text1')}</Typography>
          <Typography className="m"> {t('aml.implementation.text2')}</Typography>

          <Typography variant="h3" className="m white">
            {t('aml.supervision.title')}
          </Typography>
          <Typography className="m"> {t('aml.supervision.text1')}</Typography>
          <Typography className="m"> {t('aml.supervision.text2')}</Typography>
        </Stack>
      </Box>
    </BackgroundWrapper>
  );
};
