/* PLOP_INJECT_IMPORT_REDUCER */
import crowdsale from './crowdsale/reducer';
import dashboard from './dashboard/reducer';
import game22 from './game22/reducer';
import modals from './modals/reducer';
import referral from './referral/reducer';
import staking from './staking/reducer';
import swap from './swap/reducer';
import ui from './ui/reducer';
import user from './user/reducer';

export default {
  ui,
  user,
  modals,
  /* PLOP_INJECT_PLACE_REDUCER */
  staking,
  crowdsale,
  swap,
  referral,
  dashboard,
  game22,
};
