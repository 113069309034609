import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import IconButton from '@mui/material/IconButton';

import { Spinner } from '../../../../components';

export const LoadingTree = ({
  error,
  reload,
  close,
}: {
  error: boolean;
  reload: () => void;
  close: (() => void) | null;
}) => {
  return (
    <div
      id="treeWrapper"
      className="w-full bg-white rounded-3xl mt-4 mb-4 shadow-lg -z-100 overflow-hidden relative"
      style={{ height: '36rem' }}
    >
      {close && (
        <div className="absolute top-0 left-0 pt-5 pl-4">
          <IconButton
            onClick={(e) => {
              if (close) close();
              e.stopPropagation();
            }}
            aria-label="zoom in"
          >
            <HighlightOffRoundedIcon htmlColor="#191836" style={{ fontSize: '40px', marginRight: '6px' }} />
          </IconButton>
        </div>
      )}
      {error && (
        <div className="absolute top-0 right-0 pt-5 pr-4 cursor-pointer">
          <IconButton onClick={reload} aria-label="zoom out">
            <ReplayRoundedIcon fontSize="large" htmlColor="#191836" style={{ fontSize: '40px' }} />
          </IconButton>
        </div>
      )}
      <div
        className="w-full h-full flex justify-center items-center text-4xl font-medium text-red-400 pointer-events-none"
        style={{ transform: 'translateY(-1rem)' }}
      >
        {error ? <p className="select-none">An error occurred while loading the tree</p> : <Spinner size="m" />}
      </div>
    </div>
  );
};
