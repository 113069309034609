import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ImportIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      color: 'transparent',
      ...sx,
    }}
  >
    <path d="M10.5 19.1245V13.4995H4.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.75 20.2495L10.5 13.4995" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15 17.9995H19.5C19.6989 17.9995 19.8897 17.9205 20.0303 17.7798C20.171 17.6392 20.25 17.4484 20.25 17.2495V4.49951C20.25 4.3006 20.171 4.10983 20.0303 3.96918C19.8897 3.82853 19.6989 3.74951 19.5 3.74951H6.75C6.55109 3.74951 6.36032 3.82853 6.21967 3.96918C6.07902 4.10983 6 4.3006 6 4.49951V8.99951"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
