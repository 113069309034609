import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SwapState } from 'types';

const initialState: SwapState = {
  isClaimAvailable: false,
  isSwapOwner: false,
  commissionAmount: '',
  dealerAmount: '',
  totalCommissionAmount: '',
  totalDealerAmount: '',
};

export const swapReducer = createSlice({
  name: 'swap',
  initialState,
  reducers: {
    updateSwapState: (state, action: PayloadAction<Partial<SwapState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateSwapState } = swapReducer.actions;

export default swapReducer.reducer;
