/* eslint-disable camelcase,no-console,@typescript-eslint/no-explicit-any */
import { isMainnet } from 'appConstants';
import i18n from 'i18next';
import { notifyText } from 'services/WalletService/config/constants';
import { error, makeReferralSwap, request, setSwapExceeded, setWalletInUse } from 'store/dashboard/actions';
import { setActiveModal } from 'store/modals/reducer';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import { Modals } from 'types';
import { ContractErrors } from 'types/store/errors';
import Cookies from 'universal-cookie';
import { getToastMessage, toDecimals } from 'utils';

import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';
import { ShowUnauthorizedMessage } from '../utils';

import { getProfileInfoSaga } from './getProfileInfo';

export function* makeSwapSaga({
  type,
  payload: { web3Provider, amount, wallet_address },
}: ReturnType<typeof makeReferralSwap>) {
  yield* put(request(type));
  const { t } = i18n;
  try {
    const cookies = new Cookies();
    if (!cookies.get('logged_in')) {
      ShowUnauthorizedMessage();
      return;
    }

    const { data } = yield* call(baseApi.makeReferralSwap, { amount, wallet_address });
    if (!data.initial_tx) {
      getToastMessage('error', notifyText.swap.txError(t));

      yield* put(
        setActiveModal({
          activeModal: Modals.init,
          txHash: '',
          open: false,
        }),
      );

      yield* put(error(type));
      return;
    }

    yield* put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const gasPrice = isMainnet ? toDecimals(4, 9) : toDecimals(300, 9);
    const { transactionHash } = yield call(web3Provider.eth.sendTransaction, {
      data: data.initial_tx.data,
      to: data.initial_tx.to,
      value: data.initial_tx.value,
      from: wallet_address,
      maxFeePerGas: gasPrice,
      maxPriorityFeePerGas: gasPrice,
    });
    yield getProfileInfoSaga();
    yield put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );
  } catch (err: any) {
    console.error(err);
    if (err.response && err.response.data && err.response.data.detail) {
      yield* put(
        setActiveModal({
          activeModal: Modals.init,
          txHash: '',
          open: false,
        }),
      );
      if (err.response.data.detail === 'SwapExceeded') {
        yield* put(setSwapExceeded(true));
      } else if (err.response.data.detail === 'WalletInUse') {
        yield* put(setWalletInUse(true));
      } else if (err.response.data.detail === 'SwapTooSmall') {
        getToastMessage('error', i18n.t('dashboard.swap.tooSmall'));
      } else if (err.response.data.detail === 'User account is locked from making claims') {
        getToastMessage('error', i18n.t('dashboard.swap.balanceLocked'));
      }
      yield* put(error(type));
      return;
    }
    getToastMessage('error', notifyText.swap.txError(t));
    if (err.code === 4001 && err.message !== ContractErrors.approveReject) {
      yield* put(
        setActiveModal({
          activeModal: Modals.SendRejected,
          open: true,
        }),
      );
      return;
    }
    yield* put(
      setActiveModal({
        activeModal: Modals.init,
        txHash: '',
        open: false,
      }),
    );

    yield* put(error(type));
  }
}

export default function* listener() {
  yield all([takeLatest(actionTypes.MAKE_REFERRAL_SWAP, makeSwapSaga)]);
}
