import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { InputField, ResendTimer } from 'modules/referral/components';
import { confirmTwoFaActivation } from 'store/referral/actions';
import { COLOR_TEXT_GRAY_200, FontFamilies } from 'theme';

const TypographyText = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilies.primary,
  fontSize: 14,
  lineHeight: '14px',
  color: COLOR_TEXT_GRAY_200,
  textAlign: 'center',
}));

export const EmailTwoFaModal: FC<ModalProps & { goTo?: string | null; requestCode: () => void }> = ({
  onClose,
  open,
  goTo = null,
  requestCode,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [code, setCode] = useState('');
  const sendCode = () => {
    dispatch(confirmTwoFaActivation({ code, method: 'email', goTo }));
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const inputWidth = isMd ? 251 : 210;
  return (
    <Modal
      useBackButton
      open={open}
      onClose={onClose}
      title={t('referralSystem.signup.enterCode')}
      sx={{
        height: {
          xs: 355,
          md: 311,
        },
        width: {
          xs: 342,
          md: 448,
        },
        overflow: 'hidden',
      }}
      titleSx={{
        lineHeight: '24px',
      }}
    >
      <Box component="form" noValidate onSubmit={sendCode}>
        <Stack justifyContent="center" alignItems="center">
          <InputField
            data={code}
            setData={setCode}
            name="code"
            label={t('referralSystem.signup.code')}
            type="text"
            errorLabel="registrationTwoFaConfirmationCode"
            alignLabel="center"
            width={inputWidth}
            textAlign="center"
            sx={{ mt: '24px' }}
            showMessageOnError
          />
          <Box sx={{ mt: '24px', padding: 0 }}>
            <ResendTimer onClick={requestCode} />
          </Box>

          <Button sx={{ mt: '24px', height: 44, width: 153 }} onClick={sendCode}>
            OK
          </Button>
          <TypographyText sx={{ mt: '8px' }}>{t('referralSystem.signup.emailWasSent')}</TypographyText>
        </Stack>
      </Box>
    </Modal>
  );
};
