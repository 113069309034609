import type { State } from 'types';
import { DashboardState } from 'types/store/dashboard';

export default {
  getDashboard: (state: State): DashboardState => state.dashboard,
  getProp:
    <T extends keyof DashboardState>(propKey: T) =>
    (state: State) =>
      state.dashboard[propKey],
};
