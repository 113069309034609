import { fork } from 'redux-saga/effects';

import claim from './claim';
import getSwapData from './getSwapData';
import swap from './swap';

export default function* swapSagas() {
  yield fork(claim);
  yield fork(swap);
  yield fork(getSwapData);
}
