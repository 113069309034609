import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AvaIcon } from 'modules/dashboard/components';
import { FontFamilies, FontWeights } from 'theme';

import { CustomBox } from '../CustomBox';

import { DropPopup } from './DropPopup';

export const MainProfileBox: FC<{
  height: number;
  isActive: boolean;
  email: string;
  referralCode: string;
  avatar: string;
  twoFaEnabled: boolean;
}> = ({ height, isActive, email, referralCode, avatar, twoFaEnabled }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  const [dropPopup, setDropPopup] = useState(false);

  return (
    <CustomBox width={isSmallScreen ? 163 : 332} height={height} sx={{ minWidth: `${isSmallScreen ? 163 : 332}px` }}>
      <Box width="100%" height="100$" display="flex" flexDirection="column" alignItems="center">
        {dropPopup && <DropPopup close={() => setDropPopup(false)} email={email} />}
        <Box
          sx={{
            marginTop: isSmallScreen ? '4px' : '14px',
            position: 'relative',
            background: 'rgba(0, 0, 0, 0.15)',
            width: isSmallScreen ? '75px' : '100px',
            height: isSmallScreen ? '75px' : '100px',
            overflow: 'hidden',
            borderRadius: '50%',
            border: isActive ? '3px solid #00FF19' : '1px',
            filter: !isActive ? 'grayscale(100%)' : '',
          }}
          component="label"
        >
          {avatar !== '' ? (
            <Box
              borderRadius="50%"
              overflow="hidden"
              width={isSmallScreen ? '75px' : '100px'}
              height={isSmallScreen ? '75px' : '100px'}
              display="flex"
              alignItems="center"
              justifyContent="center"
              component="img"
              src={avatar}
              alt="Profile"
              sx={{
                width: 100,
                height: 100,
                objectFit: 'cover',
              }}
            />
          ) : (
            <AvaIcon sx={{ width: isSmallScreen ? '75px' : '100px', height: isSmallScreen ? '75px' : '100px' }} />
          )}
        </Box>
        <Typography
          textAlign="center"
          width="86%"
          fontFamily={FontFamilies.secondary}
          fontSize={isSmallScreen ? '14px' : '16px'}
          fontWeight={FontWeights.fontWeightBold}
          marginTop={isSmallScreen ? '0px' : '14px'}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {email}
        </Typography>
        <Typography
          textAlign="center"
          width="86%"
          fontFamily={FontFamilies.secondary}
          fontSize={isSmallScreen ? '14px' : '16px'}
          fontWeight={FontWeights.fontWeightBold}
          marginTop={isSmallScreen ? '-4px' : '2px'}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {referralCode}
        </Typography>
        <IconButton
          onClick={() => setDropPopup(true)}
          disabled={!twoFaEnabled}
          sx={{
            width: '128px',
            height: '28px',
            marginTop: isSmallScreen ? '0px' : '4px',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#4DA3FF',
            transition: 'transform 0.15s ease-in-out',
            '&:active': { transform: 'scale(0.92)' },
            '&:hover': { backgroundColor: '#4DA3FF' },
            fontSize: 18,
            fontWeight: 'medium',
            color: '#000',
            '&:disabled': { backgroundColor: '#E0E0E0' },
          }}
        >
          {t('dashboard.profile.drop.short')}
        </IconButton>
      </Box>
    </CustomBox>
  );
};
