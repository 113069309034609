import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { RemoteParams } from 'modules/dashboard/pages';
import { ButtonData } from 'modules/dashboard/pages/ConfirmationPage';
import { TableItem } from 'types/dashboard';

import { DashboardTable } from './DashboardTable';
import { TableType, useTable } from './useTable';

export const TableWrapper: FC<{
  buttonsForCells?: ButtonData[];
  columnComparators: ((a: string, b: string) => number)[];
  columns: string[];
  defaultFilename: string;
  dropDownColumnIndex?: number;
  items: TableItem[];
  itemsPerPage?: number;
  onUpdateItems?: (items: TableItem[]) => void;
  totalAmount?: number;
  viewProfile: ((email: string) => void) | null;
  remoteParams?: RemoteParams | null;
  tableType: TableType;
  tableSelector?: {
    setActiveTable: (index: number) => void;
    activeTable: number;
    tables: {
      name: string;
      info: {
        items: TableItem[];
        columns: string[];
        columnComparators: ((a: string, b: string) => number)[];
        defaultFilename: string;
      };
    }[];
  };
  fixDownloadButton?: boolean;
  customDownloadHandler?: (() => void) | null;
  placeHolder?: string;
}> = ({
  onUpdateItems = null,
  totalAmount = null,
  items,
  columns,
  defaultFilename,
  dropDownColumnIndex = -1,
  itemsPerPage = 5,
  columnComparators,
  viewProfile,
  buttonsForCells = [],
  remoteParams = null,
  tableType,
  tableSelector = null,
  fixDownloadButton = false,
  customDownloadHandler = null,
  placeHolder = null,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedData, setDisplayedData] = useState<TableItem[]>(items);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isDatesInitialized, setDatesInitialized] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [sortColumn, setSortColumn] = useState<number | null>(null);
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('ASC');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>();

  const { t } = useTranslation();

  function getFiltredByDate(data: TableItem[]) {
    return data.filter((item) => {
      const noteDate = new Date(item.date);
      noteDate.setHours(0, 0, 0, 0);
      return (!startDate || startDate <= noteDate) && (!endDate || noteDate <= endDate);
    });
  }

  function getSortedItems(data: TableItem[]) {
    return sortColumn !== null
      ? data.sort(
          (a, b) =>
            columnComparators[sortColumn](a.data[sortColumn], b.data[sortColumn]) * (sortOrder === 'ASC' ? -1 : 1),
        )
      : data;
  }

  const getSearchedItems = (data: TableItem[]) => {
    return data.filter(
      (item) =>
        searchString === '' ||
        item.data.some((element) => element.toString() === searchString || item.referralCode === searchString),
    );
  };

  function getSelectedByOption(data: TableItem[]) {
    if (selectedOption === undefined || selectedOption === '' || selectedOption === 'All') {
      return data;
    }
    return data.filter((item) => item.data[dropDownColumnIndex] === selectedOption);
  }

  const getPagesCount = (itemsCount: number) => Math.max(Math.ceil(itemsCount / itemsPerPage), 1);

  const handleSetDisplayedData = () => {
    if (remoteParams) return;
    const filteredbyDate = getFiltredByDate(items);
    const selectedByOption = getSelectedByOption(filteredbyDate);
    const searched = getSearchedItems(selectedByOption);
    const sorted = getSortedItems(searched);
    const itemsCountAfterFiltering = sorted.length;
    const pageCount = getPagesCount(itemsCountAfterFiltering);

    if (pageCount < currentPage) {
      setCurrentPage(pageCount);
    }

    setDisplayedData(sorted);
  };

  useEffect(() => {
    if (!isDatesInitialized) {
      setDatesInitialized(true);
      return;
    }
    handleSetDisplayedData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (remoteParams) {
      remoteParams.setStartDate(startDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (remoteParams) {
      remoteParams.setStartDate(endDate);
    }
  }, [endDate]);

  useEffect(() => {
    if (sortColumn === null) return;
    handleSetDisplayedData();
  }, [sortColumn, sortOrder]);

  useEffect(() => handleSetDisplayedData(), [selectedOption, items]);

  useEffect(() => {
    if (onUpdateItems) {
      onUpdateItems(displayedData);
    }
  }, [displayedData]);

  const { tableHeader, tableFooter } = useTable({
    tableType,
    searchBar: remoteParams
      ? {
          onSearch: () => remoteParams.setSearchQuery(searchString),
          searchString,
          onChange: setSearchString,
          placeHolder: placeHolder ?? t('dashboard.history.searchPlaceholder'),
        }
      : {
          onSearch: handleSetDisplayedData,
          searchString,
          onChange: setSearchString,
          placeHolder: placeHolder ?? t('dashboard.history.searchPlaceholder'),
        },
    totalAmount: totalAmount ?? 0,
    dateRangePicker: remoteParams
      ? {
          startDate: remoteParams.startDate,
          endDate: remoteParams.endDate,
          setStartDate: remoteParams.setStartDate,
          setEndDate: remoteParams.setEndDate,
        }
      : {
          startDate,
          endDate,
          setStartDate,
          setEndDate,
        },
    downloadButton: {
      fileName: defaultFilename,
      items: displayedData,
      columns,
      afterSearchBar: fixDownloadButton,
      customHandler: customDownloadHandler,
    },
    pagination: remoteParams
      ? {
          totalPages: remoteParams.totalPages,
          pageIndex: remoteParams.pageIndex,
          setPageIndex: remoteParams.setPageIndex,
        }
      : {
          totalPages: getPagesCount(displayedData.length),
          pageIndex: currentPage,
          setPageIndex: setCurrentPage,
        },
    tableSelector: tableSelector ?? undefined,
  });

  const [activeItemsWithButtons, setActiveItemsWithButtons] = useState<
    {
      data: { text: string; button: ReactNode }[];
      id: number;
    }[]
  >([]);

  const handleSetSortOrder = (order: 'ASC' | 'DESC') => {
    if (remoteParams) {
      remoteParams.setSortOrder(order);
    } else {
      setSortOrder(order);
    }
  };

  const handleSetSortColumn = (index: number) => {
    if (remoteParams) {
      remoteParams.setSortColumn(index);
    } else {
      setSortColumn(index);
    }
  };

  const handleSelectOption = (option: string) => {
    if (remoteParams) {
      remoteParams.setSelectedOption(option);
    } else {
      setSelectedOption(option);
    }
  };

  useEffect(() => {
    const itemData = remoteParams ? remoteParams.activeItems : displayedData;
    let newActiveItemsWithButtons = itemData.map(({ id, data }) => ({
      id,
      data: data.map((text, cellIndex) => ({
        text,
        button: buttonsForCells.find((item) => item.id === id && item.cellIndex === cellIndex)?.button,
      })),
    }));
    if (!remoteParams) {
      newActiveItemsWithButtons = newActiveItemsWithButtons.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
      );
    }
    setActiveItemsWithButtons(newActiveItemsWithButtons);
  }, [displayedData, buttonsForCells, remoteParams, currentPage]);

  return (
    <Box width="100%" height="100%" position="relative">
      {tableHeader}
      <DashboardTable
        dropDownColumnIndex={dropDownColumnIndex}
        setSelectedOption={handleSelectOption}
        selectedOption={selectedOption ?? ''}
        items={activeItemsWithButtons}
        columns={columns}
        viewUser={viewProfile}
        setSortColumn={handleSetSortColumn}
        setSortOrder={handleSetSortOrder}
      />
      {tableFooter}
    </Box>
  );
};
