import { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Popover, Stack, Typography } from '@mui/material';
import { BG_GRAY_MODAL, BORDER_RADIUS_LARGE } from 'theme';

type UnstakePopupProps = {
  anchorEl: RefObject<HTMLElement>;
  visible: boolean;
  onClose: () => void;
  onUnstake: () => void;
};

export const UnstakePopup: FC<UnstakePopupProps> = ({ anchorEl, visible, onClose, onUnstake }) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onUnstake();
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl.current}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={visible}
      onClose={onClose}
      sx={{
        '& .MuiPopover-paper': {
          mt: { xs: 2, sm: 2, md: 1 },
          p: 3,
          width: { xs: '100%', sm: 448, md: 448 },
          height: { xs: 'auto', sm: 'auto', md: 174 },
          background: BG_GRAY_MODAL,
          borderRadius: BORDER_RADIUS_LARGE,
        },
      }}
    >
      <Stack justifyContent="space-between" alignItems="center" height="100%">
        <Typography variant="body1" className="white" textAlign="center">
          {t('stakingPage.popupText')}
        </Typography>

        <Grid container justifyContent="space-between" alignItems="center" width="100% !important" spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Button variant="contained" fullWidth onClick={handleConfirm}>
              {t('stakingPage.buttonConfirm')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Button variant="outlined" fullWidth onClick={onClose}>
              {t('stakingPage.buttonCancel')}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Popover>
  );
};
