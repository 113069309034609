import { BNB_TOKEN_ADDRESS } from 'appConstants';
import { ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import { getTokensBalances } from 'store/crowdsale/actions';
import crowdsaleSelector from 'store/crowdsale/selectors';
import userSelector from 'store/user/selectors';
import { all, call, put, select, takeEvery } from 'typed-redux-saga';
import { CrowdsaleState, Erc20Abi, UserState } from 'types';
import { fromDecimals, getContractDataByItsName } from 'utils';

import actionTypes from '../actionTypes';
import { updateCrowdsaleState } from '../reducer';

import { getTyzTokenBalanceSaga } from './getTyzTokenBalance';

export function* getTokensBalancesSaga({ type, payload: { web3Provider } }: ReturnType<typeof getTokensBalances>) {
  yield put(apiActions.request(type));
  const { address: myAddress, chainType }: UserState = yield select(userSelector.getUser);
  const tokensList: CrowdsaleState['tokensList'] = yield select(crowdsaleSelector.getProp('tokensList'));

  const [tokenAbi] = getContractDataByItsName(ContractsNames.token, chainType);

  try {
    const tokenContract: Erc20Abi = yield new web3Provider.eth.Contract(tokenAbi);

    const balancePromises = tokensList.map(({ address }) => {
      tokenContract.options.address = address;
      if (address === BNB_TOKEN_ADDRESS) {
        return web3Provider.eth.getBalance(myAddress);
      }
      return tokenContract.methods.balanceOf(myAddress).call();
    });
    const decimalsPromises = tokensList.map(({ address }) => {
      tokenContract.options.address = address;
      if (address === BNB_TOKEN_ADDRESS) {
        return '18';
      }
      return tokenContract.methods.decimals().call();
    });

    const balancesAndDecimals = yield* all([...balancePromises, ...decimalsPromises]);
    const balances = balancesAndDecimals.slice(0, tokensList.length) as string[];
    const decimals = balancesAndDecimals.slice(tokensList.length) as string[];

    const balancesObject: { [k: string]: string } = {};
    for (let i = 0; i < tokensList.length; i += 1) {
      balancesObject[tokensList[i].showSymbol] = fromDecimals(balances[i], +decimals[i]);
    }

    yield* call(getTyzTokenBalanceSaga, {
      type: actionTypes.GET_TYZ_TOKEN_BALANCE,
      payload: { web3Provider },
    });

    yield* put(updateCrowdsaleState({ tokensBalances: balancesObject }));

    yield put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.GET_TOKENS_BALANCES, getTokensBalancesSaga);
}
