import { FC } from 'react';
import { Grid, GridProps, Link } from '@mui/material';

import { LinksProps } from './SocialLinks.types';

type SocialLinksProps = {
  links: LinksProps[];
} & GridProps;

export const SocialLinks: FC<SocialLinksProps> = ({ links, ...gridProps }) => {
  return (
    <Grid {...gridProps} container spacing={2}>
      {links.map(({ name, link, Icon }) => (
        <Grid item key={name}>
          <Link href={link} target="_blank" rel="noreferrer">
            {Icon}
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};
