/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,@typescript-eslint/no-non-null-assertion,@typescript-eslint/ban-ts-comment */
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DefaultAvatar from './ava.svg';
import MoreIcon from './more.svg';

export interface NodeInfo {
  name: string;
  attributes: {
    code: string;
    collapsed: boolean;
    blocked: boolean;
    online: boolean;
    avatar: string;
  };
  children: NodeInfo[];
}

export const collapse = (node: NodeInfo, recursive: boolean) => {
  if (recursive) {
    node.attributes.collapsed = true;
    node.children.forEach((child) => collapse(child, true));
    return;
  }
  if (!node.attributes.collapsed) {
    node.attributes.collapsed = true;
    node.children.forEach((child) => collapse(child, true));
  } else {
    node.attributes.collapsed = false;
  }
};

const truncate = (data: string, maxLength: number) => {
  if (data.length > maxLength) {
    return `${data.substring(0, maxLength - 3)}...`;
  }
  return data;
};

const useOuterClick = (
  callback: () => void,
): [MutableRefObject<HTMLDivElement | undefined>, MutableRefObject<HTMLDivElement | undefined>] => {
  const callbackRef = useRef<() => void>();
  const avatarRef = useRef<HTMLDivElement>();
  const popupRef = useRef<HTMLDivElement>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleClick = (e: Event) => {
      if (
        callbackRef.current &&
        avatarRef.current &&
        !avatarRef.current.contains(e.target as Node) &&
        popupRef.current &&
        !popupRef.current.contains(e.target as Node)
      )
        callbackRef.current();
    };
    document.addEventListener('mousedown', handleClick, true);
    document.addEventListener('touchstart', handleClick, true);
    document.addEventListener('contextmenu', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick, true);
      document.removeEventListener('touchstart', handleClick, true);
      document.removeEventListener('contextmenu', handleClick);
    };
  }, []);

  return [avatarRef, popupRef];
};

export const TreeNode = ({
  info,
  toggle,
  openProfile,
}: {
  info: NodeInfo;
  toggle: () => void;
  openProfile: ((email: string) => void) | null;
}) => {
  const { t } = useTranslation();
  const expandable = info.attributes.collapsed && info.children.length > 0;
  const [popup, setPopup] = useState(false);
  const [avatarRef, popupRef] = useOuterClick(() => setPopup(false));

  const showPopup = popup && openProfile;

  const node = (
    <div
      className="flex justify-center items-center w-full h-full bg-white cursor-default"
      style={{ filter: info.attributes.blocked ? 'grayscale(100%)' : '' }}
    >
      <div
        ref={avatarRef as MutableRefObject<HTMLDivElement>}
        className="w-10 h-10 cursor-pointer rounded-full"
        style={{ backgroundColor: '#00FF19', marginLeft: '2.5rem', marginTop: '-1.5rem' }}
        onClick={() => {
          collapse(info, false);
          setPopup(false);
          toggle();
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          setPopup(true);
        }}
      >
        <div
          className={`${info.attributes.online ? 'w-9 h-9 ml-0.5 mt-0.5' : 'w-10 h-10'} rounded-full overflow-hidden`}
        >
          <img
            src={info.attributes.avatar.length === 0 ? DefaultAvatar : info.attributes.avatar}
            alt={info.name}
            onDragStart={(e) => e.preventDefault()}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full mt-1" style={{ minWidth: '120px', marginLeft: '-2.5rem' }}>
          <p
            className="leading-tight font-normal text-gray-800 text-center"
            style={{ fontFamily: 'TT Norms', fontSize: '8px' }}
          >
            {truncate(info.name, 38)}
          </p>
          <p
            className="leading-tight font-medium text-gray-800 text-center"
            style={{ fontFamily: 'TT Norms', fontSize: '10px', marginTop: '0.0625rem' }}
          >
            {info.attributes.code}
          </p>
        </div>
        q{' '}
      </div>
      <div
        className={`rounded-full p-1 w-6 h-6 content-center m ${expandable ? 'opacity-100' : 'opacity-0'}`}
        onClick={(e) => {
          collapse(info, false);
          e.stopPropagation();
          toggle();
        }}
        style={{ backgroundColor: '#bdbdbd', marginLeft: '-1rem' }}
      >
        <img
          src={MoreIcon}
          alt=""
          style={{ width: '24px', height: '24px', marginTop: '-0.225rem', objectFit: 'cover' }}
        />
      </div>

      <div
        ref={popupRef as MutableRefObject<HTMLDivElement>}
        className={`bg-white rounded-lg pl-1 cursor-pointer ${showPopup ? 'opacity-100' : 'opacity-0'}`}
        onClick={() => {
          if (showPopup && openProfile) openProfile(info.name);
        }}
        style={{
          top: '2rem',
          left: '1rem',
          width: '66px',
          height: '27px',
          marginLeft: '-2rem',
          marginTop: '0.2rem',
          boxShadow:
            '0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1), 4px 0 8px rgba(0, 0, 0, 0.1), -4px 0 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <button
          type="button"
          onClick={() => {
            if (openProfile) openProfile(info.name);
          }}
          className={`hover:underline font-light ${showPopup ? '' : 'pointer-events-none cursor-pointer'} ml-0.5`}
          style={{ fontSize: '0.6rem', marginTop: '0.06125rem', textAlign: 'center' }}
        >
          {t('dashboard.adminDashboard.viewProfile')}
        </button>
      </div>
    </div>
  );

  return (
    <foreignObject width="160" height="80" x="-80" y="-40">
      {node}
    </foreignObject>
  );
};
