import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { InputField } from 'modules/referral/components';
import { usePopupError } from 'modules/referral/hooks';
import { validatePassword } from 'modules/referral/utils';
import { resetPassword } from 'store/referral/actions';

type ResetPasswordModalProps = {
  email: string;
  token: string;
} & ModalProps;

export const ResetPasswordModal: FC<ResetPasswordModalProps> = ({ email, token, onClose, open }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  usePopupError();
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const handleSend = () => {
    const isValid = validatePassword(
      password,
      repeatedPassword,
      'resetPassword',
      'resetRepeatedPassword',
      'popupError',
      dispatch,
    );
    if (isValid) {
      dispatch(resetPassword({ email, password, token }));
    }
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const inputWidth = isMd ? 251 : 210;
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('referralSystem.signin.passwordRecovery')}
      sx={{
        height: {
          xs: 355,
          md: 374,
        },
        width: {
          xs: 342,
          md: 448,
        },
      }}
      titleSx={{
        lineHeight: '24px',
      }}
    >
      <Box>
        <Stack justifyContent="center" alignItems="center">
          <InputField
            data={password}
            setData={setPassword}
            name="password"
            label={t('referralSystem.signin.password')}
            type="password"
            errorLabel="resetPassword"
            alignLabel="center"
            width={inputWidth}
            textAlign="center"
            sx={{ mt: '24px' }}
          />

          <InputField
            data={repeatedPassword}
            setData={setRepeatedPassword}
            name="password"
            label={t('referralSystem.signin.password')}
            type="password"
            errorLabel="resetRepeatedPassword"
            alignLabel="center"
            width={inputWidth}
            textAlign="center"
            sx={{ mt: '24px' }}
          />

          <Button onClick={handleSend} sx={{ mt: '24px', height: 44, width: 153 }}>
            OK
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
