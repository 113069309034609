import { Box } from '@mui/material';

import Game22 from './22game.png';
import Game22Popup from './22gamePopup.png';
import Bank from './bank.png';
import BankPopup from './bankPopup.png';
import Cube from './cube.png';
import { data } from './data';
import DS from './ds.png';
import DSPopup from './dsPopup.png';
import FL from './fl.png';
import FLPopup from './flPopup.png';
import { GameButton } from './GameButton';
import R48 from './r48.png';
import R48Popup from './r48Popup.png';

export const Games = () => {
  return (
    <Box className="mb-6" position="relative" width="fit-content" mx="auto">
      <img src={Cube} alt="cube" style={{ display: 'block', width: '100%' }} />

      <GameButton img={DS} popupImg={DSPopup} data={data.ds} x="43%" y="17.5%" />
      <GameButton img={R48} popupImg={R48Popup} data={data.r48} x="73.2%" y="17%" />
      <GameButton img={Game22} popupImg={Game22Popup} data={data.game22} x="58.5%" y="33.7%" />
      <GameButton img={FL} popupImg={FLPopup} data={data.fl} x="43.3%" y="51.7%" />
      <GameButton img={Bank} popupImg={BankPopup} data={data.bank} x="74.5%" y="50.3%" />
    </Box>
  );
};
