import { FC, ReactNode } from 'react';
import { Box, BoxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FontFamilies, FontWeights } from 'theme';

import { CustomBox } from '../CustomBox';

export const CustomButton: FC<
  {
    text: string;
    icon?: ReactNode;
    textAlign?: 'left' | 'center';
    onClick?: () => void;
    selected?: boolean;
    width: number;
    height?: number;
  } & BoxProps
> = ({ text, icon, textAlign = 'left', onClick, selected = false, width, sx, height = 40 }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isButton = true;

  return (
    <CustomBox
      width={width}
      height={height}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: textAlign === 'center' ? 'center' : 'flex-start',
        padding: isSmallScreen ? '0 8px' : '0 20px',
        boxSizing: 'border-box',
        backgroundColor: selected ? '#4BA2F2' : 'white',
        '&:hover': {
          backgroundColor: selected ? '#4BA2F2' : '#f0f0f0',
        },
        borderRadius: isSmallScreen ? '8px' : '12px',
        ...sx,
      }}
      isButton={isButton}
      onClickEvent={onClick}
    >
      {icon && <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '4px' }}>{icon}</Box>}
      <Typography
        fontFamily={FontFamilies.secondary}
        fontWeight={FontWeights.fontWeightMedium}
        fontSize={isSmallScreen ? '15px' : '19px'}
        textAlign={textAlign}
        whiteSpace="nowrap"
      >
        {text}
      </Typography>
    </CustomBox>
  );
};
