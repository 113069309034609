import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SupportIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 94 108"
    sx={{
      width: '94px',
      height: '108px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_bd_4207_9002)">
      <rect x="29" width="60.7143" height="82.1429" rx="2" fill="url(#paint0_linear_4207_9002)" />
    </g>
    <g filter="url(#filter1_bd_4207_9002)">
      <rect
        x="4"
        y="17.8574"
        width="60.7143"
        height="82.1429"
        rx="2"
        fill="url(#paint1_linear_4207_9002)"
        shapeRendering="crispEdges"
      />
      <rect
        x="5"
        y="18.8574"
        width="58.7143"
        height="80.1429"
        rx="1"
        stroke="url(#paint2_linear_4207_9002)"
        strokeWidth="2"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter2_di_4207_9002)">
      <rect
        x="11.1426"
        y="28.5713"
        width="42.8571"
        height="7.14286"
        rx="0.8"
        fill="url(#paint3_linear_4207_9002)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter3_di_4207_9002)">
      <rect
        x="11.1426"
        y="42.8574"
        width="32.1429"
        height="7.14286"
        rx="0.8"
        fill="url(#paint4_linear_4207_9002)"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter4_di_4207_9002)">
      <rect
        x="11.1426"
        y="57.1426"
        width="21.4286"
        height="7.14286"
        rx="0.8"
        fill="url(#paint5_linear_4207_9002)"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_bd_4207_9002"
        x="25"
        y="-4"
        width="68.7148"
        height="94.1426"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4207_9002" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="effect1_backgroundBlur_4207_9002" result="effect2_dropShadow_4207_9002" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4207_9002" result="shape" />
      </filter>
      <filter
        id="filter1_bd_4207_9002"
        x="-12"
        y="1.85742"
        width="92.7148"
        height="114.143"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4207_9002" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="effect1_backgroundBlur_4207_9002" result="effect2_dropShadow_4207_9002" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4207_9002" result="shape" />
      </filter>
      <filter
        id="filter2_di_4207_9002"
        x="9.64258"
        y="28.0713"
        width="45.8574"
        height="11.6426"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4207_9002" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4207_9002" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_4207_9002" />
      </filter>
      <filter
        id="filter3_di_4207_9002"
        x="9.64258"
        y="42.3574"
        width="35.1426"
        height="11.6426"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4207_9002" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4207_9002" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_4207_9002" />
      </filter>
      <filter
        id="filter4_di_4207_9002"
        x="9.64258"
        y="56.6426"
        width="24.4277"
        height="11.6426"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4207_9002" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4207_9002" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_4207_9002" />
      </filter>
      <linearGradient
        id="paint0_linear_4207_9002"
        x1="51.7679"
        y1="41.0714"
        x2="75.2642"
        y2="-57.4832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3C8CE7" />
        <stop offset="1" stopColor="#00EAFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4207_9002"
        x1="-5.10714"
        y1="108.215"
        x2="84.0379"
        y2="45.1895"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4207_9002"
        x1="58.6429"
        y1="108.215"
        x2="-15.8766"
        y2="42.4251"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4207_9002"
        x1="4.71401"
        y1="36.4284"
        x2="7.49496"
        y2="20.4682"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4207_9002"
        x1="6.32115"
        y1="50.7146"
        x2="9.94601"
        y2="35.1119"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4207_9002"
        x1="7.92829"
        y1="64.9997"
        x2="13.0385"
        y2="50.3357"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
