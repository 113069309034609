import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Copy, Modal, ModalProps } from 'components';
import { OutIcon } from 'components/Icon/components';
import { shortenPhrase } from 'utils';

export interface DisconnectModalProps extends ModalProps {
  address: string;
  isLoggedIn: boolean;
  onDisconnect: () => void;
  onLogin: () => void;
}

export const DisconnectModal: FC<DisconnectModalProps> = ({
  address,
  isLoggedIn,
  onDisconnect,
  onLogin,
  open,
  onClose: onCloseDisconnect,
}) => {
  const { t } = useTranslation();

  const handleDisconnect = () => {
    onDisconnect();
    onCloseDisconnect();
  };
  const handleLogin = () => {
    onLogin();
    onCloseDisconnect();
  };

  return (
    <Modal open={open} onClose={onCloseDisconnect} title={t('header.wallet.title')}>
      <Copy copyText={address} sx={{ mt: 3 }}>
        {shortenPhrase(address, 10, 1)}
      </Copy>

      <Button
        variant="contained"
        fullWidth
        onClick={handleDisconnect}
        endIcon={<OutIcon />}
        sx={{ mt: 2, letterSpacing: '0.03em' }}
      >
        {t('header.wallet.disconnect')}
      </Button>
      {!isLoggedIn && (
        <Button variant="outlined" fullWidth onClick={handleLogin} sx={{ mt: 2, letterSpacing: '0.03em' }}>
          {t('header.wallet.login')}
        </Button>
      )}
    </Modal>
  );
};
