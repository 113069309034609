const SIGNUP = 'REFERRAL_SIGNUP' as const;
const CONFIRM_EMAIL = 'REFERRAL_CONFIRM_EMAIL' as const;
const REQUEST_REGISTRATION_CODE = 'REFERRAL_REQUEST_REGISTRATION_CODE' as const;
const GET_INFO = 'REFERRAL_GET_INFO' as const;
const LOGOUT = 'REFERRAL_LOGOUT' as const;
const LOGIN = 'REFERRAL_LOGIN' as const;
const LOGIN_TWO_FA = 'REFERRAL_LOGIN_TWO_FA' as const;
const DISABLE_TWO_FA = 'REFERRAL_DISABLE_TWO_FA' as const;
const ACTIVATE_TWO_FA = 'REFERRAL_ACTIVATE_TWO_FA' as const;
const CONFIRM_TWO_FA_ACTIVATION = 'REFERRAL_CONFIRM_TWO_FA_ACTIVATION' as const;
const REQUEST_PASSWORD_RESET = 'REFERRAL_REQUEST_PASSWORD_RESET' as const;
const RESET_PASSWORD = 'REFERRAL_RESET_PASSWORD' as const;
const SET_TWO_FA_TOKEN = 'REFERRAL_SET_TWO_FA' as const;
const SET_AUTH_TOKEN = 'REFERRAL_SET_AUTH_TOKEN' as const;
const GET_TWO_FA_STATUS = 'GET_TWO_FA_STATUS' as const;

export default {
  SIGNUP,
  CONFIRM_EMAIL,
  REQUEST_REGISTRATION_CODE,
  GET_INFO,
  LOGOUT,
  LOGIN,
  LOGIN_TWO_FA,
  DISABLE_TWO_FA,
  ACTIVATE_TWO_FA,
  CONFIRM_TWO_FA_ACTIVATION,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  SET_TWO_FA_TOKEN,
  SET_AUTH_TOKEN,
  GET_TWO_FA_STATUS,
};
