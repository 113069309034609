/* eslint-disable react/no-array-index-key,jsx-a11y/control-has-associated-label,no-plusplus,jsx-a11y/no-autofocus */
import React, { ChangeEvent, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FontWeights } from 'theme';

export const Pages = ({
  current,
  max,
  callback,
}: {
  current: number;
  max: number;
  callback: (page: number) => void;
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const getPages = () => {
    const pages = [];
    const maxVisiblePages = isSmallScreen ? 7 : 9;

    if (max <= maxVisiblePages) {
      for (let i = 1; i <= max; i++) {
        pages.push(i);
      }
    } else {
      const sidePages = Math.floor((maxVisiblePages - 1) / 2);

      if (current <= sidePages) {
        for (let i = 1; i < maxVisiblePages - 1; i++) {
          pages.push(i);
        }
        pages.push('...', max);
      } else if (current > max - sidePages) {
        pages.push(1, '...');
        for (let i = max - (maxVisiblePages - 3); i <= max; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1, '...');
        for (let i = current - sidePages + 2; i <= current + sidePages - 2; i++) {
          pages.push(i);
        }
        pages.push('...', max);
      }
    }

    return pages;
  };

  const handlePageClick = (page: number | string, index: number) => {
    if (typeof page === 'number') {
      callback(page);
    } else {
      setIsEditing(index);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value === '') {
      setInputValue('');
    } else if (
      !Number.isNaN(Number(value)) &&
      Number(value) >= 1 &&
      Number(value) <= max &&
      value.search('\\.') === -1
    ) {
      setInputValue(value);
    }
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (e.key === 'Enter' && inputValue && isEditing === index) {
      callback(Number(inputValue));
      setIsEditing(null);
      setInputValue('');
    }
  };

  const handleBlur = () => {
    setIsEditing(null);
    setInputValue('');
  };

  const pages = getPages();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="6px"
      boxShadow="0px 8px 10px rgba(0, 0, 0, 0.1)"
      minWidth={`min(calc(34 * ${max} + 54px), 362px)`}
      sx={{ backgroundColor: 'white' }}
    >
      <IconButton
        onClick={() => callback(Math.max(1, current - 1))}
        disabled={current === 1}
        sx={{
          color: 'rgba(0, 0, 0, 0.25)',
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.1)',
          },
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.4)',
          },
          width: '28px',
        }}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      {pages.map((page, index) => (
        <Box key={index} display="inline-block">
          {isEditing === index ? (
            <TextField
              value={inputValue}
              onChange={(e) => handleInputChange(e)}
              onKeyUp={(e) => handleInputKeyPress(e, index)}
              onBlur={handleBlur}
              autoFocus
              inputProps={{
                style: {
                  fontWeight: FontWeights.fontWeightRegular,
                  fontSize: '14px',
                  textAlign: 'center',
                  padding: 0,
                  color: 'rgba(0, 0, 0, 0.25)',
                  marginTop: '6px',
                },
              }}
              sx={{ width: '34px', height: '34px' }}
            />
          ) : (
            <Button
              variant={page === current ? 'contained' : 'text'}
              color="primary"
              onClick={() => handlePageClick(page, index)}
              sx={{
                width: '34px',
                height: '34px',
                minWidth: '34px',
                padding: 0,
                borderRadius: 0,
                margin: 0,
                background: page === current ? '#3b82f6' : 'none',
                border: 'none',
                '&:hover': {
                  backgroundColor: page === current ? 'bg-blue-500' : 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <Typography sx={{ fontSize: '14px', color: page === current ? 'white' : 'rgba(0, 0, 0, 0.25)' }}>
                {page}
              </Typography>
            </Button>
          )}
        </Box>
      ))}
      <IconButton
        onClick={() => callback(Math.min(max, current + 1))}
        disabled={current === max}
        sx={{
          color: 'rgba(0, 0, 0, 0.25)',
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.1)',
          },
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.4)',
          },
          width: '28px',
        }}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Box>
  );
};
