import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { emailRegex } from 'appConstants';
import { linkEmail, verifyEmail } from 'store/user/actions';

type LoginFormData = {
  code: string;
  email: string;
};

export const useChangeEmailForm = (initialEmail = '') => {
  const dispatch = useDispatch();
  const [changeEmailData, setLoginData] = useState<LoginFormData>({ code: '', email: initialEmail });
  const [validationErrors, setValidationErrors] = useState({ code: false, email: false });

  const handleSetError = (value: string | number, type: string) => {
    if (type === 'code') {
      setValidationErrors((prevState) => ({ ...prevState, [type]: value === '' }));
    }
    if (type === 'email') {
      setValidationErrors((prevState) => ({ ...prevState, [type]: !emailRegex.test(value.toString()) }));
    }
  };

  const handleChangeInput = (value: string | number, type: string) => {
    setLoginData((prevState) => ({ ...prevState, [type]: value }));
  };

  const handleError = (value: string, type: string) => {
    handleSetError(value, type);
  };

  const handleSendCode = () => {
    if (!emailRegex.test(changeEmailData.email)) {
      handleSetError(changeEmailData.email, 'email');
      return false;
    }
    dispatch(linkEmail({ email: changeEmailData.email }));
    return true;
  };

  const handleVerify = () => {
    if (changeEmailData.code === '') {
      handleSetError(changeEmailData.code, 'code');
      return false;
    }
    dispatch(verifyEmail({ code: changeEmailData.code }));
    return true;
  };

  return {
    changeEmailData,
    validationErrors,
    handleChangeInput,
    handleError,
    handleSendCode,
    handleVerify,
  };
};
