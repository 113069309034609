import { createAction, PrepareAction } from '@reduxjs/toolkit';
import {
  ReferralActivateTwoFaReq,
  ReferralConfirmTwoFaActivationReq,
  ReferralDisableTwoFaReq,
  ReferralGetUserInfoReq,
  ReferralRegistrationConfirmationReq,
  ReferralRegistrationReq,
  ReferralRequestPasswordResetEmailReq,
  ReferralRequestRegistrationCodeReq,
  ReferralResetPasswordReq,
  ReferralSigninReq,
  ReferralSigninTwoFaReq,
} from 'types/requests';

import actionTypes from './actionTypes';

export const signup = createAction<ReferralRegistrationReq>(actionTypes.SIGNUP);
export const sendCode = createAction<ReferralRegistrationConfirmationReq>(actionTypes.CONFIRM_EMAIL);
export const requestRegistrationCode = createAction<ReferralRequestRegistrationCodeReq>(
  actionTypes.REQUEST_REGISTRATION_CODE,
);
export const getUserInfo = createAction<ReferralGetUserInfoReq>(actionTypes.GET_INFO);
export const logout = createAction<string | null>(actionTypes.LOGOUT);
export const signin = createAction<ReferralSigninReq>(actionTypes.LOGIN);
export const loginTwoFa = createAction<ReferralSigninTwoFaReq>(actionTypes.LOGIN_TWO_FA);
export const activateTwoFa = createAction<ReferralActivateTwoFaReq>(actionTypes.ACTIVATE_TWO_FA);
export const confirmTwoFaActivation = createAction<ReferralConfirmTwoFaActivationReq>(
  actionTypes.CONFIRM_TWO_FA_ACTIVATION,
);
export const disableTwoFa = createAction<ReferralDisableTwoFaReq>(actionTypes.DISABLE_TWO_FA);
export const requestPasswordReset = createAction<ReferralRequestPasswordResetEmailReq>(
  actionTypes.REQUEST_PASSWORD_RESET,
);
export const resetPassword = createAction<ReferralResetPasswordReq>(actionTypes.RESET_PASSWORD);

export const getTwoFaStatus = createAction(actionTypes.GET_TWO_FA_STATUS);

export const setTwoFaToken = createAction<PrepareAction<string>>(actionTypes.SET_TWO_FA_TOKEN, (payload) => ({
  payload,
}));
export const setAuthToken = createAction<PrepareAction<string>>(actionTypes.SET_AUTH_TOKEN, (payload) => ({ payload }));

export const request = <T = string, P = never>(defaultType: T, payload?: P) => ({
  type: `${defaultType}_REQUEST`,
  payload,
});

export const success = <T = string, P = never>(defaultType: T, payload?: P) => ({
  type: `${defaultType}_SUCCESS`,
  payload,
});

export const error = <T = string, E = never>(defaultType: T, err?: E) => ({
  type: `${defaultType}_ERROR`,
  payload: err,
  err,
});

export const reset = <T = string>(defaultType: T) => ({
  type: `${defaultType}_RESET`,
});
