import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowLeftIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 32 32"
    sx={{
      width: '32px',
      height: '32px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 24C0 28.4183 3.5817 32 8 32H32L32 0L8 2.09815e-06C3.5817 2.48441e-06 0 3.58172 0 8V24Z" fill="white" />
    <path
      d="M19 22L13.3146 16.7071C12.8951 16.3166 12.8951 15.6834 13.3146 15.2929L19 10"
      stroke="#BFBFBF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);
