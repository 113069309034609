// ===== Borders
export const COLOR_BORDER_CONTAINED = '#BFBFBF';
export const BORDER_GRAY_FOOTER = '1px solid rgba(87, 86, 118, 0.5)';
export const BORDER_DEFAULT_GRAY = '1px solid rgba(87, 86, 118, 1)';
export const BORDER_GRAY_200 = '1px solid #8A90AB';
export const BORDER_CARD_GRAY_200 = '1px solid rgba(25, 24, 54, 0.2)';
export const BORDER_COLOR_SLIDER = 'rgba(255,255,255,0.2)';
export const BORDER_COLOR_NAV = 'rgba(255,255,255, 0.3)';
export const BORDER_POOL_DARK = '1px solid #575676';
export const BORDER_COLOR_RED_ERROR = '1px solid #B60000';

// ===== Border-Radius
export const BORDER_RADIUS_POOL_CARD = '14px';
export const BORDER_RADIUS_DEFAULT = '8px';
export const BORDER_RADIUS_MEDIUM = '16px';
export const BORDER_RADIUS_SMALL = '12px';
export const BORDER_RADIUS_LARGE = '20px';
export const BORDER_RADIUS_EXTRA_LARGE = '24px';
export const BORDER_RADIUS_PROGRESS_BAR = '30px';
export const BORDER_RADIUS_CARD = '28px';
export const BORDER_RADIUS_CARD_XS = '13px';
export const BORDER_RADIUS_CARD_SM = '20px';
export const BORDER_RADIUS_CARD_FULL = '28px';
export const BORDER_RADIUS_ROAD_CARD = '24px';
export const BORDER_RADIUS_TABS = '96px';
export const BORDER_RADIUS_TABS_OUTLINED = '12px';

// ===== Box-Shadow
export const SHADOW_SLIDER = '0px 13px 20px rgba(0, 0, 0, 0.14)';

export const BOX_SHADOW_MODAL = '0px 64px 64px -48px #1F2F461F';
// ===== Transitions
export const TRANSITION_DEFAULT_TIME = 'all 0.3s ease-in-out';
