import { FC, useState } from 'react';
import {
  Box,
  BoxProps,
  createTheme,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  ThemeProvider,
} from '@mui/material';
import { ArrowDownIcon, ArrowDownIconWhite } from 'modules/dashboard/components/Icon';

interface ProjectWindowProps {
  options: string[];
  label: string;
  setSelectedOption: (option: string) => void;
  selectedOption: string;
  useWhiteArrow?: boolean;
}

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          border: '1px solid white',
          borderRadius: '8px',
          height: '44px',
          color: 'white',
        },
      },
    },
  },
});

const CustomSelect = styled(Select)({
  '&:hover': {
    background: 'transparent',
  },
  '&:focus': {
    background: 'transparent',
  },
  '& .MuiSelect-select': {
    '&:focus': {
      background: 'transparent',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  textAlign: 'center',
  '& .MuiSelect-icon': {
    right: 'calc(50% - 42px)',
    top: 'calc(50% - 4px)',
    pointerEvents: 'none',
    position: 'absolute',
  },
});

export const ProjectWindow: FC<ProjectWindowProps & BoxProps> = ({
  options,
  label,
  minWidth = 194,
  sx,
  setSelectedOption,
  selectedOption,
  useWhiteArrow = true,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedOption(event.target.value as string);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minWidth: { minWidth }, height: 44, ...sx }}>
        <FormControl fullWidth>
          <CustomSelect
            value={selectedOption}
            onChange={handleChange}
            displayEmpty
            renderValue={() => label}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            IconComponent={useWhiteArrow ? ArrowDownIconWhite : ArrowDownIcon}
          >
            <MenuItem value="" disabled>
              {label}
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
};
