import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useShallowSelector } from 'hooks';
import { SupportPopup } from 'modules/dashboard/components/Popups';
import { SupportButton, TablePage } from 'modules/dashboard/containers';
import { dateComparator, numberComparator, statusComparator } from 'modules/dashboard/util/comparators';
import { getWithdrawals } from 'store/dashboard/actions';
import selector from 'store/dashboard/selectors';
import { TableItem } from 'types/dashboard';

export const HistoryPage: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [items, setItems] = useState<TableItem[] | null>(null);

  const dispatch = useDispatch();

  const { withdrawals } = useShallowSelector(selector.getDashboard);

  useEffect(() => {
    dispatch(getWithdrawals());
  }, [dispatch]);

  useEffect(() => {
    if (withdrawals) {
      setItems(
        withdrawals.map((withdrawal, index) => {
          return {
            id: index,
            date: withdrawal.date,
            data: [
              `${+withdrawal.amount}`,
              format(new Date(withdrawal.date), 'dd.MM.yyyy'),
              t(`dashboard.adminDashboard.${withdrawal.status.toLowerCase().trim()}`),
            ],
          };
        }),
      );
    }
  }, [withdrawals, t]);

  const [isSupportPopupOpen, setSupportPopupOpen] = useState(false);

  const { profile } = useShallowSelector(selector.getDashboard);

  const columns = [
    isSmallScreen ? t('dashboard.history.tesWithdrawal') : t('dashboard.history.tesWithdrawalAdaptive'),
    t('dashboard.history.date'),
    t('dashboard.history.status'),
  ];

  const comparators = [numberComparator, dateComparator, statusComparator(t)];
  return (
    <Box>
      <SupportPopup isOpen={isSupportPopupOpen} closePopup={() => setSupportPopupOpen(false)} />
      <Box marginBottom="35px">
        <TablePage
          items={items ?? []}
          columns={columns}
          columnComparators={comparators}
          defaultFilename="history"
          placeHolder={t('dashboard.profile.searchPlaceholder')}
        />
      </Box>
      {profile && profile.role === 'user' && !isSmallScreen && (
        <SupportButton
          sx={{
            position: 'absolute',
            top: 'calc(100% - 35px)',
            right: 0,
          }}
          openPopup={() => setSupportPopupOpen(true)}
        />
      )}
    </Box>
  );
};
