import { ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { FactoryAbi } from 'types';
import { createContract, getContractDataByItsName } from 'utils';

import { getCrowdsaleAddress } from '../actions';
import actionTypes from '../actionTypes';
import { updateCrowdsaleState } from '../reducer';

export function* getCrowdsaleAddressSaga({ type }: ReturnType<typeof getCrowdsaleAddress>) {
  yield* put(apiActions.request(type));

  const { chainType } = yield select(userSelector.getUser);

  try {
    const [factoryAbi, factoryAddress] = getContractDataByItsName(ContractsNames.factory, chainType);
    const factoryContract: FactoryAbi = yield createContract(factoryAbi, factoryAddress, chainType);

    const crowdsaleAddress = yield* call(factoryContract.methods.currentCrowdsale().call);

    yield* put(updateCrowdsaleState({ address: crowdsaleAddress }));

    yield* put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield* put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_CROWDSALE_ADDRESS, getCrowdsaleAddressSaga);
}
