import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { BG_BLACK_OPACITY, BG_GRAY_MODAL, BORDER_RADIUS_LARGE, BOX_SHADOW_MODAL } from 'theme';

export const getMuiDialogOverrides = (): ComponentsOverrides['MuiDialog'] => ({
  root: {
    background: BG_BLACK_OPACITY,
  },

  paper: {
    width: 448,
    background: BG_GRAY_MODAL,
    borderRadius: BORDER_RADIUS_LARGE,
    boxShadow: BOX_SHADOW_MODAL,
  },
});

export const getMuiDialogDefaultProps = (): ComponentsProps['MuiDialog'] => ({});
