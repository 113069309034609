import { FC } from 'react';
import { Typography } from '@mui/material';
import { BackgroundWrapper } from 'components';
import { flexHelper } from 'utils/flexHelper';

export const ComingSoon: FC = () => {
  return (
    <BackgroundWrapper
      container
      sx={{
        minHeight: '100vh',
      }}
      containerSx={{
        height: '100%',
        ...flexHelper(),
        flexDirection: 'column',
        display: { xs: 'flex', md: 'block' },
        flexGrow: 1,
        py: { xs: 0, md: 11 },
      }}
    >
      <Typography variant="h1" textTransform="uppercase" textAlign="center" className="gradient" pt={11}>
        COMING SOON
      </Typography>
    </BackgroundWrapper>
  );
};
