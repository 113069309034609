import { error, request, signup, success } from 'store/game22/actions';
import { baseApi } from 'store/game22/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { errorToastMessage } from 'utils';

import actionTypes from '../actionTypes';

export function* requestRegistrationCodeSaga({ type, payload: { email } }: ReturnType<typeof signup>) {
  yield* put(request(type));

  try {
    yield* call(baseApi.requestRegistrationCode, {
      email,
    });
    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    errorToastMessage(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.REQUEST_REGISTRATION_CODE, requestRegistrationCodeSaga);
}
