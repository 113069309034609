import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useModal } from 'hooks';
import { InfoIcon } from 'modules/dashboard/components';
import { InfoPopup } from 'modules/dashboard/components/Popups';
import { LanguageModal } from 'modules/layout/containers';
import { useVerticalAnchor } from 'modules/layout/hooks';
import { FontFamilies, FontWeights } from 'theme';

export const InfoButon: FC<BoxProps & { putInColumn?: boolean }> = ({ sx, putInColumn = false }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const closePopup = () => {
    setIsOpen(false);
  };
  const openPopup = () => {
    setIsOpen(true);
  };

  const baseButtonStyle = {
    borderRadius: '27px',
    height: '54px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 10px rgba(19, 146, 255, 0.4)',
    backdropFilter: 'blur(10px)',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    width: isSmallScreen ? '72px' : '195px',
    transition: 'transform 0.15s ease-in-out',
    '&:active': {
      transform: 'scale(0.92)',
    },
  };
  const infoButtonSytle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '0 24px',
    gap: '12px',
  };
  const verticalAnchor = useVerticalAnchor();
  const [isVisibleLanguagePopover, onOpenLanguagePopover, onCloseLanguagePopover] = useModal(false);
  const languageRef = useRef(null);
  const { i18n } = useTranslation();
  const infoButton = (
    <IconButton onClick={openPopup} sx={{ ...infoButtonSytle, ...baseButtonStyle }}>
      <InfoIcon />
      {isSmallScreen === false && (
        <Typography
          fontFamily={FontFamilies.secondary}
          fontSize="20px"
          fontWeight={FontWeights.fontWeightMedium}
          color="#191836"
        >
          {t('dashboard.infoButton')}
        </Typography>
      )}
    </IconButton>
  );

  const languageButton = (
    <IconButton
      onClick={onOpenLanguagePopover}
      ref={languageRef}
      sx={{
        ...baseButtonStyle,
        width: '54px',
      }}
    >
      {i18n.language.toUpperCase()}
    </IconButton>
  );

  return (
    <Box sx={sx}>
      <InfoPopup isOpen={isOpen} closePopup={closePopup} />
      {isVisibleLanguagePopover && (
        <LanguageModal
          open={isVisibleLanguagePopover}
          onClose={onCloseLanguagePopover}
          anchorEl={languageRef}
          verticalAnchor={verticalAnchor}
        />
      )}
      {putInColumn ? (
        <Box display="flex" alignItems="flex-end" gap="12px" flexDirection="column">
          {infoButton}
          {languageButton}
        </Box>
      ) : (
        <Box display="flex" alignItems="center" gap="12px">
          {languageButton}
          {infoButton}
        </Box>
      )}
    </Box>
  );
};
