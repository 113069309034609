import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EmailIcon, TgIcon } from 'modules/dashboard/assets';
import { ExclamationMarkIcon, SupportBackground } from 'modules/dashboard/components/Icon';
import { FontFamilies, FontWeights } from 'theme';

import { Popup } from '../Popup';
import { PopupProps } from '../PopupProps';

export const TransactionBlockedPopup: FC<PopupProps> = ({ isOpen, closePopup }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();
  const supportEmail = 'support@tyz.network';
  const supportTelegramUrl = 'https://t.me/tyzsupport_bot';
  return (
    <Popup
      isOpen={isOpen}
      closePopup={closePopup}
      header=""
      height={isSmallScreen ? '518px' : '430px'}
      width={isSmallScreen ? '342px' : '585px'}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box width="96px" height="92px" position="relative" marginTop={1}>
          <ExclamationMarkIcon sx={{ position: 'absolute', top: 0, left: 0, zIndex: 2 }} />
        </Box>
        <Typography
          textAlign="center"
          fontWeight={FontWeights.fontWeightBold}
          fontSize="22px"
          fontFamily={FontFamilies.secondary}
          color="white"
          mt={5}
        >
          {t('dashboard.info.transactionBlocked')}
        </Typography>
        <Typography
          textAlign="center"
          fontWeight={FontWeights.fontWeightMedium}
          fontSize="16px"
          fontFamily={FontFamilies.secondary}
          color="white"
          mt={1}
        >
          {t('dashboard.info.transactionBlockedBody')}
        </Typography>
        <Box display="flex" mt={2} flexDirection={isSmallScreen ? 'column' : 'row'}>
          <Button
            onClick={() => {
              window.location.href = `mailto:${supportEmail}`;
            }}
            sx={{
              margin: 2,
              width: 215,
              height: 54,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={EmailIcon} alt="EmailIcon" style={{ width: 58, height: 44, marginTop: 7 }} />
            <Typography
              marginLeft="10px"
              textTransform="none"
              color="white"
              fontFamily={FontFamilies.secondary}
              fontWeight={FontWeights.fontWeightMedium}
              fontSize="22px"
            >
              Email
            </Typography>
          </Button>
          <Button
            onClick={() => window.open(supportTelegramUrl, '_blank')}
            sx={{
              margin: 2,
              width: 215,
              height: 54,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={TgIcon} alt="TgIcon" style={{ width: 59, height: 47, marginTop: 5 }} />
            <Typography
              marginLeft="10px"
              textTransform="none"
              color="white"
              fontFamily={FontFamilies.secondary}
              fontWeight={FontWeights.fontWeightMedium}
              fontSize="22px"
            >
              Telegram
            </Typography>
          </Button>
        </Box>
      </Box>
    </Popup>
  );
};
