import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { camelize } from 'utils';

import { getAccountInfo } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getAccountInfoSaga({ type }: ReturnType<typeof getAccountInfo>) {
  yield* put(request(type));

  try {
    const {
      data: { balance: tesBalance, ...accountData },
    } = yield* call(baseApi.getAccountInfo);

    yield* put(updateUserState(accountData.username ? camelize({ tesBalance, ...accountData }) : {}));
    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_ACCOUNT_INFO, getAccountInfoSaga);
}
