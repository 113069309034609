import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const HomeIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      path: {
        fill: '#575676',
      },
      ...sx,
    }}
  >
    <path
      d="M13.0607 0.43934C12.4749 -0.146447 11.5251 -0.146447 10.9393 0.43934L0.43934 10.9393C-0.146447 11.5251 -0.146447 12.4749 0.43934 13.0607C1.02513 13.6464 1.97487 13.6464 2.56066 13.0607L3 12.6213V22.5C3 23.3284 3.67157 24 4.5 24H7.5C8.32843 24 9 23.3284 9 22.5V19.5C9 18.6716 9.67157 18 10.5 18H13.5C14.3284 18 15 18.6716 15 19.5V22.5C15 23.3284 15.6716 24 16.5 24H19.5C20.3284 24 21 23.3284 21 22.5V12.6213L21.4393 13.0607C22.0251 13.6464 22.9749 13.6464 23.5607 13.0607C24.1464 12.4749 24.1464 11.5251 23.5607 10.9393L13.0607 0.43934Z"
      fill="currentColor"
    />
  </SvgIcon>
);
