import { BackgroundCircles, ShadowLeft, ShadowRight } from 'assets/img';

export const heightOnTopCircleOneSm = 670;
export const heightOnTopCircleOne = 100;
export const heightOnTopCircleTwoSm = 5800;
export const heightOnTopCircleTwo = 2600;
export const heightOnTopShadowLeftOne = heightOnTopCircleOne + 1000;
export const heightOnTopShadowLeftTwo = heightOnTopCircleTwo + 1000;

export const HOME_BACKGROUND_SM = `
  url(${BackgroundCircles}) center ${heightOnTopCircleOneSm}px / 100%  no-repeat,
  url(${ShadowLeft}) left ${heightOnTopShadowLeftOne}px no-repeat,
  url(${BackgroundCircles}) center ${heightOnTopCircleTwoSm}px / 100%  no-repeat,
  url(${ShadowRight}) right ${heightOnTopShadowLeftTwo}px no-repeat,
  #ffffff
 `;

export const HOME_BACKGROUND = `
  url(${BackgroundCircles}) center ${heightOnTopCircleOne}px / 100%  no-repeat,
  url(${ShadowLeft}) left ${heightOnTopShadowLeftOne}px no-repeat,
  url(${BackgroundCircles}) center ${heightOnTopCircleTwo}px / 100%  no-repeat,
  url(${ShadowRight}) right ${heightOnTopShadowLeftTwo}px no-repeat,
  #ffffff
 `;
