import { URL } from 'appConstants';
import {
  ClaimReq,
  GetBuyingSignatureReq,
  LinkEmailReq,
  LoginReq,
  SendFeedbackReq,
  SwapReq,
  VerifyEmailReq,
} from 'types/requests';
import { snakeize } from 'utils';

import ajax from './ajax';

export const baseApi = {
  getBuyingSignature(data: GetBuyingSignatureReq) {
    return ajax({
      method: 'post',
      url: URL.getBuyingSignature,
      data,
    });
  },
  getIdoList() {
    return ajax({
      method: 'get',
      url: URL.getTokensList,
    });
  },
  getMetamaskMessage() {
    return ajax({
      method: 'get',
      url: URL.getMetamaskMessage,
    });
  },
  metamaskLogin(data: Omit<LoginReq, 'web3Provider'>) {
    return ajax({
      method: 'post',
      url: URL.metamaskLogin,
      data,
    });
  },
  sendFeedback(data: SendFeedbackReq) {
    return ajax({
      method: 'post',
      url: URL.sendFeedback,
      data,
    });
  },
  getAccountInfo() {
    return ajax({
      method: 'get',
      url: URL.getAccountInfo,
    });
  },
  linkEmail(data: LinkEmailReq) {
    return ajax({
      method: 'post',
      url: URL.linkEmail,
      data,
    });
  },
  verifyEmail(data: VerifyEmailReq) {
    return ajax({
      method: 'post',
      url: URL.verifyEmail,
      data,
    });
  },
  swap({ ...data }: SwapReq) {
    return ajax({
      method: 'post',
      url: URL.swap,
      data,
    });
  },
  claim(data: ClaimReq) {
    return ajax({
      method: 'post',
      url: URL.claim,
      data: snakeize(data),
    });
  },
  getSwapDealer() {
    return ajax({
      method: 'get',
      url: URL.getSwapDealer,
    });
  },
  getSwapCommission() {
    return ajax({
      method: 'get',
      url: URL.getSwapCommission,
    });
  },
};
