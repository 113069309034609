import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditIconWhite: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 34 34"
    sx={{
      width: '34px',
      height: '34px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0636 2.36168C25.0636 2.36168 26.0078 1.58753 26.5718 1.35389C27.1359 1.12025 27.7404 1 28.3509 1C28.9615 1 29.566 1.12025 30.1301 1.35389C30.6941 1.58753 31.2066 1.92997 31.6383 2.36168C32.07 2.79339 32.4125 3.3059 32.6461 3.86995C32.8798 4.434 33 5.03854 33 5.64907C33 6.25959 32.8798 6.86414 32.6461 7.42819C32.4125 7.99224 31.6383 8.93646 31.6383 8.93646C31.6383 8.93646 16.7041 28.2 9.44846 31.1263C7.65807 31.8484 5.24072 32.4948 3.35619 32.9444C1.95173 33.2795 0.72053 32.0483 1.0556 30.6438C1.5052 28.7593 2.1516 26.3419 2.87369 24.5515C5.79999 17.2959 25.0636 2.36168 25.0636 2.36168Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
