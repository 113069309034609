import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, styled, Typography } from '@mui/material';
import { calculateApr, getRewardPerDay } from 'modules/staking/utils';
import { BORDER_DEFAULT_GRAY, BORDER_RADIUS_MEDIUM, COLOR_TEXT_WHITE_100, FontWeights } from 'theme';
import { StakingState } from 'types';
import { fromDecimals } from 'utils';

const BoxItemStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  height: 102,
  borderRadius: BORDER_RADIUS_MEDIUM,
  border: BORDER_DEFAULT_GRAY,
}));

const ValueTextStyled = styled(Typography)(({ theme }) => ({
  mt: theme.spacing(2),
  color: COLOR_TEXT_WHITE_100,
  fontSize: 18,
  fontWeight: FontWeights.fontWeightBold,

  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  },
}));

export type StakingInfoProps = Pick<StakingState, 'apr' | 'totalStaked' | 'rewardPerDay'>;

export const StakingInfo: FC<StakingInfoProps> = ({ totalStaked, rewardPerDay }) => {
  const { t } = useTranslation();

  const getAprValue = useMemo(() => calculateApr(rewardPerDay, totalStaked), [rewardPerDay, totalStaked]);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid mt={2} container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <BoxItemStyled>
            <Typography variant="body2" className="gray" fontSize={14}>
              {t('stakingPage.apr')}
            </Typography>
            <ValueTextStyled>{getAprValue} %</ValueTextStyled>
          </BoxItemStyled>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <BoxItemStyled>
            <Typography variant="body2" className="gray" fontSize={14}>
              {t('stakingPage.unstakeFee')}
            </Typography>
            <ValueTextStyled>0 % {t('stakingPage.unstakeFeeText')}</ValueTextStyled>
          </BoxItemStyled>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <BoxItemStyled>
            <Typography variant="body2" className="gray" fontSize={14}>
              {t('stakingPage.dailyRewards')}
            </Typography>
            <ValueTextStyled>{getRewardPerDay(rewardPerDay)} TYZ</ValueTextStyled>
          </BoxItemStyled>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <BoxItemStyled>
            <Typography variant="body2" className="gray" fontSize={14}>
              {t('stakingPage.totalStaked')}
            </Typography>
            <ValueTextStyled>{fromDecimals(totalStaked)} TYZ</ValueTextStyled>
          </BoxItemStyled>
        </Grid>
      </Grid>
    </Box>
  );
};
