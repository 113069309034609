import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import { QuestionMark } from 'modules/dashboard/components';
import { FontFamilies, FontWeights } from 'theme';

export const SupportButton: FC<BoxProps & { openPopup: () => void }> = ({ sx, openPopup }) => {
  const { t } = useTranslation();

  const buttonSytle = {
    width: '154px',
    height: '40px',
    borderRadius: '27px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 14px rgba(28, 146, 255, 0.4)',
    padding: '0 24px',
    gap: '3px',
    backdropFilter: 'blur(10px)',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    transition: 'transform 0.15s ease-in-out',
    '&:active': {
      transform: 'scale(0.92)',
    },
  };
  return (
    <Box sx={sx}>
      <IconButton onClick={openPopup} sx={buttonSytle}>
        <QuestionMark sx={{ marginRight: '4px' }} />
        <Typography
          fontFamily={FontFamilies.secondary}
          fontSize="18px"
          fontWeight={FontWeights.fontWeightMedium}
          color="#191836"
        >
          {t('dashboard.supportButton')}
        </Typography>
      </IconButton>
    </Box>
  );
};
