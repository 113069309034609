import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DateUpButtonIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 10 7"
    sx={{
      width: '10px',
      height: '7px',
      fill: 'none',
      ...sx,
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.5L4.5286 1.70974C4.78894 1.43009 5.21106 1.43009 5.4714 1.70974L9 5.5"
      stroke="#191836"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);
