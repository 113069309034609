import { all, call, put, takeLatest } from 'typed-redux-saga';
import Cookies from 'universal-cookie';

import { logout } from '../../referral/actions';
import { error, request, setProfileInfo, success } from '../actions';
import actionTypes from '../actionTypes';
import { baseApi } from '../apiRequestBuilder';

export function* getProfileInfoSaga() {
  yield* put(request(actionTypes.GET_PROFILE_INFO));
  try {
    const cookies = new Cookies();
    if (cookies.get('logged_in')) {
      const { data } = yield* call(baseApi.getProfileInfo);
      data.role = data.role === 1 ? 'user' : 'admin';
      yield* put(setProfileInfo(data));
      yield* put(success(actionTypes.GET_PROFILE_INFO, { data }));
    } else {
      yield* put(logout('/referrals/signin'));
    }
  } catch (err) {
    yield* put(logout('/referrals/signin'));
    yield* put(error(actionTypes.GET_PROFILE_INFO));
  }
}

export default function* listener() {
  yield all([takeLatest(actionTypes.GET_PROFILE_INFO, getProfileInfoSaga)]);
}
