import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';

import { sendFeedback } from '../actions';
import actionTypes from '../actionTypes';

export function* sendFeedbackSaga({ type, payload: { name, email } }: ReturnType<typeof sendFeedback>) {
  yield* put(request(type));

  try {
    yield* call(baseApi.sendFeedback, {
      name,
      email,
    });

    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SEND_FEEDBACK, sendFeedbackSaga);
}
