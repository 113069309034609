import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, Stack, styled, Typography } from '@mui/material';
import { BackgroundWrapper } from 'components';
import { COLOR_TEXT_WHITE_100 } from 'theme';

const StyledList = styled(List)(({ theme }) => ({
  color: COLOR_TEXT_WHITE_100,
  listStyleType: 'disc',
  paddingLeft: theme.spacing(6),
  '& .MuiListItem-root': {
    display: 'list-item',
    fontSize: 18,
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
}));
export const TermsOfService: FC = () => {
  const { t } = useTranslation();

  return (
    <BackgroundWrapper
      container
      sx={{
        minHeight: '100vh',
      }}
    >
      <Box pt={12} pb={5.5}>
        <Typography
          variant="h1"
          textTransform="uppercase"
          textAlign="center"
          className="gradient s"
          mb={{ xs: 4, md: 8 }}
        >
          {t('termsOfService.title')}
        </Typography>

        <Stack spacing={2}>
          <Typography variant="h3" className="m white">
            {t(`termsOfService.introduction.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.introduction.text1`)}</Typography>
          <Typography className="m"> {t(`termsOfService.introduction.text2`)}</Typography>
          <Typography className="m"> {t(`termsOfService.introduction.text3`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.eligibility.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.eligibility.text`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.ownership.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.ownership.text`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.useOfTheSite.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.useOfTheSite.text1`)}</Typography>
          <Typography className="m"> {t(`termsOfService.useOfTheSite.text2`)}</Typography>
          <StyledList>
            <ListItem>{t(`termsOfService.useOfTheSite.text2_1`)}</ListItem>
            <ListItem>{t(`termsOfService.useOfTheSite.text2_2`)}</ListItem>
            <ListItem>{t(`termsOfService.useOfTheSite.text2_3`)}</ListItem>
            <ListItem>{t(`termsOfService.useOfTheSite.text2_4`)}</ListItem>
            <ListItem>{t(`termsOfService.useOfTheSite.text2_5`)}</ListItem>
            <ListItem>{t(`termsOfService.useOfTheSite.text2_6`)}</ListItem>
            <ListItem>{t(`termsOfService.useOfTheSite.text2_7`)}</ListItem>
          </StyledList>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.acceptableUse.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.acceptableUse.text1`)}</Typography>
          <StyledList>
            <ListItem>{t(`termsOfService.acceptableUse.text1_1`)}</ListItem>
            <ListItem>{t(`termsOfService.acceptableUse.text1_2`)}</ListItem>
            <ListItem>{t(`termsOfService.acceptableUse.text1_3`)}</ListItem>
            <ListItem>{t(`termsOfService.acceptableUse.text1_4`)}</ListItem>
            <ListItem>{t(`termsOfService.acceptableUse.text1_5`)}</ListItem>
            <ListItem>{t(`termsOfService.acceptableUse.text1_6`)}</ListItem>
          </StyledList>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.restrictedAccess.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.restrictedAccess.text1`)}</Typography>
          <Typography className="m"> {t(`termsOfService.restrictedAccess.text2`)}</Typography>
          <Typography className="m"> {t(`termsOfService.restrictedAccess.text3`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.rightToChange.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.rightToChange.text`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.userContent.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.userContent.text1`)}</Typography>
          <Typography className="m"> {t(`termsOfService.userContent.text2`)}</Typography>
          <Typography className="m"> {t(`termsOfService.userContent.text3`)}</Typography>
          <Typography className="m"> {t(`termsOfService.userContent.text4`)}</Typography>
          <Typography className="m"> {t(`termsOfService.userContent.text5`)}</Typography>
          <Typography className="m"> {t(`termsOfService.userContent.text6`)}</Typography>
          <Typography className="m"> {t(`termsOfService.userContent.text7`)}</Typography>
          <Typography className="m"> {t(`termsOfService.userContent.text8`)}</Typography>
          <Typography className="m"> {t(`termsOfService.userContent.text9`)}</Typography>
          <Typography className="m"> {t(`termsOfService.userContent.text10`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.noWarranties.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.noWarranties.text1`)}</Typography>
          <Typography className="m"> {t(`termsOfService.noWarranties.text2`)}</Typography>
          <Typography className="m"> {t(`termsOfService.noWarranties.text3`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.limitationsOfLiability.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.limitationsOfLiability.text1`)}</Typography>
          <Typography className="m"> {t(`termsOfService.limitationsOfLiability.text2`)}</Typography>
          <Typography className="m"> {t(`termsOfService.limitationsOfLiability.text3`)}</Typography>
          <Typography className="m"> {t(`termsOfService.limitationsOfLiability.text4`)}</Typography>
          <Typography className="m"> {t(`termsOfService.limitationsOfLiability.text5`)}</Typography>
          <Typography className="m"> {t(`termsOfService.limitationsOfLiability.text6`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.indemnity.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.indemnity.text1`)}</Typography>
          <StyledList>
            <ListItem>{t(`termsOfService.indemnity.text1_1`)}</ListItem>
            <ListItem>{t(`termsOfService.indemnity.text1_2`)}</ListItem>
            <ListItem>{t(`termsOfService.indemnity.text1_3`)}</ListItem>
            <ListItem>{t(`termsOfService.indemnity.text1_4`)}</ListItem>
          </StyledList>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.breaches.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.breaches.text1`)}</Typography>
          <Typography className="m"> {t(`termsOfService.breaches.text2`)}</Typography>
          <Typography className="m"> {t(`termsOfService.breaches.text3`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.paymentInformation.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.paymentInformation.text`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.variation.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.variation.text`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.assignment.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.assignment.text1`)}</Typography>
          <Typography className="m"> {t(`termsOfService.assignment.text2`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.severability.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.severability.text`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.links.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.links.text1`)}</Typography>
          <Typography className="m"> {t(`termsOfService.links.text2`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.entireAgreement.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.entireAgreement.text`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.classActionWaiver.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.classActionWaiver.text`)}</Typography>

          <Typography variant="h3" className="m white">
            {t(`termsOfService.otherDisclaimers.title`)}
          </Typography>
          <Typography className="m"> {t(`termsOfService.otherDisclaimers.text1`)}</Typography>
          <Typography className="m"> {t(`termsOfService.otherDisclaimers.text2`)}</Typography>
          <Typography className="m"> {t(`termsOfService.otherDisclaimers.text3`)}</Typography>
          <Typography className="m"> {t(`termsOfService.otherDisclaimers.text4`)}</Typography>
          <Typography className="m"> {t(`termsOfService.otherDisclaimers.text5`)}</Typography>
          <Typography className="m"> {t(`termsOfService.otherDisclaimers.text6`)}</Typography>
          <Typography className="m"> {t(`termsOfService.otherDisclaimers.text7`)}</Typography>
          <Typography className="m"> {t(`termsOfService.otherDisclaimers.text8`)}</Typography>
          <Typography className="m"> {t(`termsOfService.otherDisclaimers.text9`)}</Typography>

          <Typography variant="h3" className="m white bold">
            {t(`termsOfService.affirm`)}
          </Typography>
        </Stack>
      </Box>
    </BackgroundWrapper>
  );
};
