import i18next from 'i18next';
import { notifyText } from 'services/WalletService/config/constants';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { getToastMessage } from 'utils';

import { linkEmail } from '../actions';
import actionTypes from '../actionTypes';

import { getAccountInfoSaga } from './getAccountInfo';

export function* linkEmailSaga({ type, payload: { email } }: ReturnType<typeof linkEmail>) {
  yield* put(request(type));

  try {
    const { data } = yield* call(baseApi.linkEmail, {
      email,
    });

    if (data?.detail) {
      getToastMessage('error', data?.detail);
    } else {
      getToastMessage('success', notifyText.changeEmail.success(i18next.t));
    }

    yield* call(getAccountInfoSaga, {
      type: actionTypes.GET_ACCOUNT_INFO,
      payload: undefined,
    });

    yield* put(success(type));
  } catch (err) {
    getToastMessage('error', notifyText.changeEmail.error(i18next.t));

    // eslint-disable-next-line no-console
    console.error(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.LINK_EMAIL, linkEmailSaga);
}
