/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Card } from 'components';
import { InputField } from 'modules/referral/components';
import {
  BORDER_DEFAULT_GRAY,
  BORDER_RADIUS_PROGRESS_BAR,
  COLOR_TEXT_GRAY_200,
  COLOR_TEXT_WHITE_100,
  FontFamilies,
} from 'theme';

const TypographyText = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilies.primary,
  fontSize: 14,
  color: COLOR_TEXT_GRAY_200,
  textAlign: 'center',
}));

type ConfirmEmailPageProps = {
  requestEmail: () => void;
  sendCode: (code: string) => void;
  time: number;
};

export const CodeVerificationForm: FC<ConfirmEmailPageProps> = ({ requestEmail, sendCode, time }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const inputWidth = isMd ? 251 : 210;
  return (
    <Card
      type="lightTransparent"
      sx={{
        mt: 0,
        width: {
          xs: 342,
          md: '100%',
        },
        height: {
          xs: 354,
          md: 419,
        },
        p: 0,
        flexDirection: 'column',
        border: BORDER_DEFAULT_GRAY,
        borderRadius: BORDER_RADIUS_PROGRESS_BAR,
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <InputField
          data={code}
          setData={setCode}
          name="code"
          label={t('referralSystem.signup.code')}
          type="text"
          errorLabel="registrationEmailConfirmationCode"
          alignLabel="center"
          width={inputWidth}
          textAlign="center"
          showMessageOnError
        />
        <Button sx={{ mt: '24px', height: 44, width: 153 }} onClick={() => sendCode(code)}>
          OK
        </Button>
        <TypographyText>{t('referralSystem.signup.emailWasSent')}</TypographyText>
        <TypographyText
          onClick={requestEmail}
          sx={{
            pointerEvents: time > 0 ? 'none' : 'auto',
            fontFamily: FontFamilies.primary,
            fontSize: '14px',
            lineHeight: '14px',
            color: COLOR_TEXT_GRAY_200,
            textAlign: 'center',
            '&:hover': {
              color: COLOR_TEXT_WHITE_100,
              cursor: 'pointer',
            },
          }}
        >
          {time
            ? `${t('referralSystem.signup.resendTimer')}${time}${t('referralSystem.signup.timerSeconds')}`
            : t('referralSystem.signup.resendTimerExpired')}
        </TypographyText>
      </Stack>
    </Card>
  );
};
