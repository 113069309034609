import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Box, Button, InputAdornment, Skeleton, Stack, TextField, Typography } from '@mui/material';
import BigNumber from 'bignumber.js/bignumber';
import { Card, Countdown, Icon, UserBalance } from 'components';
import { useValidateInputField, ValidationTypes } from 'hooks';
import { onStake } from 'store/staking/actions';
import {
  BORDER_DEFAULT_GRAY,
  BORDER_RADIUS_PROGRESS_BAR,
  BORDER_RADIUS_SMALL,
  COLOR_TEXT_RED,
  COLOR_TEXT_WHITE_100,
} from 'theme';
import { StakingState } from 'types';
import { flexHelper, fromDecimals, fromDecimalsDisplay, toDecimals } from 'utils';
import Web3 from 'web3';

export type StakingFormProps = {
  userBalance: string;
  web3Provider: Web3;
  userAddress: string;
  onOpenConnectModal: () => void;
  isStaking: boolean;
  isStakeSuccessful: boolean;
  isStakingDataLoading: boolean;
} & Pick<
  StakingState,
  'stakingStartTime' | 'stakingEndTime' | 'maxStake' | 'minStake' | 'isStakeAvailable' | 'userStakeData'
>;

export const StakingForm: FC<StakingFormProps> = ({
  stakingStartTime,
  stakingEndTime,
  maxStake,
  minStake,
  userBalance,
  web3Provider,
  userAddress,
  isStakeAvailable,
  userStakeData,
  onOpenConnectModal,
  isStaking,
  isStakeSuccessful,
  isStakingDataLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [stakeValue, setStakeValue, setOriginStakeValue] = useValidateInputField({
    type: ValidationTypes.number,
  });

  const haveNotActiveStaking = useMemo(
    () => Number(stakingStartTime) === 0 && !isStakingDataLoading,
    [isStakingDataLoading, stakingStartTime],
  );

  const stakingWillStart = useMemo(
    () => Date.now() <= Number(stakingStartTime) * 1000 && !isStakingDataLoading,
    [isStakingDataLoading, stakingStartTime],
  );

  const isStakingPeriodNow = useMemo(
    () =>
      Date.now() > Number(stakingStartTime) * 1000 &&
      Date.now() < Number(stakingEndTime) * 1000 &&
      !isStakingDataLoading,
    [isStakingDataLoading, stakingEndTime, stakingStartTime],
  );

  const isStakingEnded = useMemo(
    () => Date.now() > Number(stakingEndTime) * 1000 && Number(stakingEndTime) !== 0 && !isStakingDataLoading,
    [isStakingDataLoading, stakingEndTime],
  );

  const isStakeLessThenMin = useMemo(
    () => new BigNumber(toDecimals(stakeValue)).isLessThan(new BigNumber(minStake)),
    [minStake, stakeValue],
  );

  const availableStakeAmount = useMemo(() => {
    const calculatedValue = new BigNumber(maxStake).minus(new BigNumber(userStakeData.userStakeAmount)).toString();
    if (!Number.isNaN(+calculatedValue)) {
      return calculatedValue;
    }
    return 0;
  }, [maxStake, userStakeData.userStakeAmount]);

  const haveAmountToStake = Number(availableStakeAmount.toString()) !== 0;

  const handleSetMaxValue = useCallback(() => {
    if (new BigNumber(availableStakeAmount).isLessThan(new BigNumber(toDecimals(userBalance)))) {
      setOriginStakeValue(fromDecimals(availableStakeAmount));
      return;
    }
    setOriginStakeValue(String(userBalance));
  }, [availableStakeAmount, setOriginStakeValue, userBalance]);

  const handleChangeStakeValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    const maxValue = !new BigNumber(toDecimals(userBalance)).isLessThan(availableStakeAmount)
      ? fromDecimals(availableStakeAmount)
      : Number(userBalance);

    if (maxValue < Number(value)) {
      return;
    }

    setStakeValue(event);

    if (!value.length) {
      setOriginStakeValue('');
    }
  };

  const handleStake = () => {
    dispatch(onStake({ web3Provider, stakeValue }));
  };

  useEffect(() => {
    if (isStakeSuccessful) {
      setOriginStakeValue('');
    }
  }, [isStakeSuccessful, setOriginStakeValue]);

  return (
    <Card
      type="lightTransparent"
      sx={{
        mt: 4.5,
        p: { xs: 2, md: 3 },
        width: '100%',
        minHeight: 430,
        flexDirection: 'column',
        ...flexHelper(),
        border: BORDER_DEFAULT_GRAY,
        borderRadius: BORDER_RADIUS_PROGRESS_BAR,
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', sm: '100%', md: 550 },
          flexDirection: 'column',
          ...flexHelper(),
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems="center" height={{ xs: 110, sm: 110, md: 80 }}>
          {isStakingDataLoading && <Skeleton variant="text" width={300} height={70} />}

          {haveNotActiveStaking && (
            <Typography variant="body1" className="white" textAlign="center">
              {t('stakingPage.stakingComingSoon')}
            </Typography>
          )}

          {stakingWillStart && (
            <Countdown
              title={t('stakingPage.subtitleStart')}
              timer={+stakingStartTime}
              EndTextComponent={t('stakingPage.countDownUpdateText')}
            />
          )}

          {isStakingPeriodNow && (
            <Countdown
              title={t('stakingPage.subtitleEnd')}
              timer={+stakingEndTime}
              EndTextComponent={t('stakingPage.countDownUpdateText')}
            />
          )}

          {isStakingEnded && (
            <Typography variant="body1" className="white" textAlign="center">
              {t('stakingPage.nextStageTitle')}
            </Typography>
          )}
        </Stack>

        <UserBalance
          balance={userBalance}
          isLoading={!userAddress.length}
          sx={{ mt: 6, mb: 2, width: '100%', flexDirection: 'row', ...flexHelper('space-between') }}
        />

        <TextField
          value={stakeValue}
          onChange={handleChangeStakeValue}
          variant="outlined"
          placeholder="0.00"
          fullWidth
          disabled={isStaking || !userAddress.length || !isStakingPeriodNow || !haveAmountToStake || !isStakeAvailable}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="body2" className="white" fontSize={{ xs: 12, sm: 12, md: 16 }}>
                  {t('stakingPage.amount')}
                </Typography>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!isStakingPeriodNow || !haveAmountToStake || !isStakeAvailable}
                  sx={{ textTransform: 'uppercase' }}
                  onClick={handleSetMaxValue}
                >
                  {t('globalHelpers.buttonMax')}
                </Button>
              </InputAdornment>
            ),
          }}
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mt: 2,
            mb: 3,
            px: 2,
            width: '100%',
            height: { xs: 60, sm: 40, md: 40 },
            border: BORDER_DEFAULT_GRAY,
            borderRadius: BORDER_RADIUS_SMALL,
          }}
        >
          <Typography variant="body2" className="s white">
            {t('stakingPage.min')}: {fromDecimalsDisplay(minStake)} TYZ
          </Typography>

          <Typography
            variant="body2"
            className="s white"
            sx={{
              color: `${!haveAmountToStake ? COLOR_TEXT_RED : COLOR_TEXT_WHITE_100} !important`,
            }}
          >
            {t('stakingPage.max')}: {fromDecimalsDisplay(availableStakeAmount)} TYZ
          </Typography>
        </Stack>

        {!!userAddress.length && (
          <LoadingButton
            size="large"
            loading={isStaking}
            sx={{ width: { xs: '100%', sm: 300, md: 300 } }}
            disabled={
              stakeValue === '' || !isStakingPeriodNow || isStakeLessThenMin || !haveAmountToStake || !isStakeAvailable
            }
            onClick={handleStake}
          >
            {t('stakingPage.buttonStake')}
          </LoadingButton>
        )}

        {!userAddress.length && (
          <Button
            size="large"
            variant="contained"
            color="secondary"
            fullWidth
            startIcon={<Icon.WalletIcon />}
            onClick={onOpenConnectModal}
            sx={{
              width: { xs: '100%', sm: 300, md: 300 },
              '&:hover': { svg: { path: { fill: COLOR_TEXT_WHITE_100 } } },
            }}
          >
            {t('header.connectWallet.buttonTitle')}
          </Button>
        )}
      </Box>
    </Card>
  );
};
