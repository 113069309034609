import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TwitterIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 22"
    sx={{
      width: '24px',
      height: '22px',
      ...sx,
    }}
  >
    <path
      d="M7.55016 21.5C16.6045 21.5 21.5583 13.4198 21.5583 6.41488C21.5583 6.18773 21.5536 5.95552 21.5442 5.72837C22.5079 4.97789 23.3395 4.04832 24 2.98333C23.1025 3.41333 22.1496 3.69416 21.1739 3.81623C22.2013 3.15305 22.9705 2.11124 23.3391 0.883908C22.3726 1.50074 21.3156 1.93586 20.2134 2.17062C19.4708 1.3209 18.489 0.758289 17.4197 0.569764C16.3504 0.381239 15.2532 0.577302 14.2977 1.12764C13.3423 1.67798 12.5818 2.55193 12.1338 3.61439C11.6859 4.67685 11.5754 5.86864 11.8195 7.00549C9.86249 6.89973 7.94794 6.35225 6.19998 5.39856C4.45203 4.44487 2.90969 3.10624 1.67297 1.46946C1.0444 2.63651 0.852057 4.01751 1.13503 5.33179C1.418 6.64606 2.15506 7.795 3.19641 8.54509C2.41463 8.51836 1.64998 8.2917 0.965625 7.88382V7.94944C0.964925 9.17417 1.3581 10.3614 2.07831 11.3092C2.79852 12.2571 3.80132 12.9071 4.91625 13.1488C4.19206 13.3621 3.43198 13.3932 2.69484 13.2396C3.00945 14.2929 3.62157 15.2141 4.44577 15.8747C5.26997 16.5353 6.26512 16.9024 7.29234 16.9246C5.54842 18.3998 3.39417 19.1999 1.17656 19.1961C0.783287 19.1955 0.390399 19.1695 0 19.1184C2.25286 20.6749 4.87353 21.5015 7.55016 21.5Z"
      fill="#8A90AB"
    />
  </SvgIcon>
);
