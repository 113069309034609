/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { CopyIcon, Modal, ModalProps } from 'components';
import { useShallowSelector } from 'hooks';
import { InputField } from 'modules/referral/components';
import { QRCodeCanvas } from 'qrcode.react';
import { activateTwoFa, confirmTwoFaActivation } from 'store/referral/actions';
import referralSelector from 'store/referral/selectors';

export const GoogleAuthModal: FC<ModalProps & { goTo?: string | null }> = ({ onClose, open, goTo = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { twoFaDetails, twoFaAlternativeDetails } = useShallowSelector(referralSelector.getReferral);
  const [code, setCode] = useState('');
  const [isQR, setIsQR] = useState(true);
  const [accountName, setAccountName] = useState('');
  const [key, setKey] = useState('');

  useEffect(() => {
    if (open) {
      dispatch(activateTwoFa({ method: 'app' }));
    }
  }, [open]);

  useEffect(() => {
    if (twoFaAlternativeDetails) {
      const [account, secretKey] = twoFaAlternativeDetails.split('\n');
      setAccountName(account);
      setKey(secretKey);
    }
  }, [twoFaAlternativeDetails]);

  const sendCode = () => {
    dispatch(confirmTwoFaActivation({ code, method: 'app', goTo }));
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  return (
    <Modal
      useBackButton
      open={open}
      onClose={onClose}
      title={t('referralSystem.signup.googleAuthenticator')}
      sx={{
        height: {
          xs: 575,
          md: 515,
        },
        width: {
          xs: 342,
          md: 448,
        },
      }}
      titleSx={{
        lineHeight: '24px',
        fontSize: '24px',
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        {isQR ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: '24px',
            }}
          >
            <QRCodeCanvas
              value={twoFaDetails}
              style={{
                height: 200,
                width: 200,
              }}
            />
            <Typography sx={{ mt: 2, cursor: 'pointer', color: '#4DA3FF' }} onClick={() => setIsQR(false)}>
              {t('referralSystem.signup.useKeyInstead')}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: '24px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
              <Typography sx={{ fontSize: '24px', fontFamily: '"TT Norms", sans-serif', color: 'white' }}>
                {t('referralSystem.signup.accountName')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  paddingX: '10px',
                  paddingY: '5px',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <Typography sx={{ fontSize: '18px', color: 'white', overflowWrap: 'anywhere' }}>
                    {accountName}
                  </Typography>
                  <IconButton
                    onClick={() => copyToClipboard(accountName)}
                    size="small"
                    sx={{ position: 'absolute', right: '-1.5rem' }}
                  >
                    <CopyIcon sx={{ fontSize: 'medium', color: '#4DA3FF' }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
              <Typography sx={{ fontSize: '24px', fontFamily: '"TT Norms", sans-serif', color: 'white' }}>
                {t('referralSystem.signup.key')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  paddingX: '10px',
                  paddingY: '5px',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <Typography sx={{ fontSize: '18px', color: 'white', overflowWrap: 'anywhere' }}>{key}</Typography>
                  <IconButton
                    onClick={() => copyToClipboard(key)}
                    size="small"
                    sx={{ position: 'absolute', right: '-1.5rem' }}
                  >
                    <CopyIcon sx={{ fontSize: 'medium', color: '#4DA3FF' }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Typography sx={{ mt: 2, cursor: 'pointer', color: '#4DA3FF' }} onClick={() => setIsQR(true)}>
              {t('referralSystem.signup.useQRInstead')}
            </Typography>
          </Box>
        )}
        <InputField
          data={code}
          setData={setCode}
          name="code"
          label={t('referralSystem.signup.code')}
          type="text"
          errorLabel="registrationTwoFaConfirmationCode"
          alignLabel="center"
          width={210}
          textAlign="center"
          sx={{ mt: 2 }}
          showMessageOnError
        />
        <Button
          sx={{
            height: 44,
            width: 153,
            display: 'flex',
            justifyContent: 'center',
            marginTop: '24px',
          }}
          onClick={sendCode}
        >
          OK
        </Button>
      </Stack>
    </Modal>
  );
};
