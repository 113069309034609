import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { BackgroundWrapper } from 'components';
import { useModal, useShallowSelector } from 'hooks';
import { BuyTokenForm, PricesInfo } from 'modules/crowdsale/containers';
import { useFormatTokensList } from 'modules/crowdsale/hooks';
import { ConnectWalletModal } from 'modules/layout/containers';
import { useWalletConnectorContext } from 'services';
import actionTypes from 'store/crowdsale/actionTypes';
import crowdsaleSelector from 'store/crowdsale/selectors';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { CrowdsaleState, RequestStatus, State, UserState } from 'types';
import { fromDecimals, getRequestStatus } from 'utils';

export const BuyToken: FC = () => {
  const { t } = useTranslation();
  const { walletService, connect } = useWalletConnectorContext();

  const {
    tokensBalances,
    crowdsaleData,
    userTyzTokenBalanceOnCrowdsale,
    availableTokensOnCrowdsale,
    address: crowdsaleContractAddress,
  } = useShallowSelector<State, CrowdsaleState>(crowdsaleSelector.getCrowdsale);
  const userAddress: UserState['address'] = useShallowSelector(userSelector.getProp('address'));
  const uiState = useShallowSelector(uiSelector.getUI);

  const isTokensListLoading = getRequestStatus(uiState[actionTypes.GET_TOKENS_LIST]);
  const isCrowdsaleDataLoading = getRequestStatus(uiState[actionTypes.GET_CROWDSALE_DATA]);
  const isBuyingToken = getRequestStatus(uiState[actionTypes.BUY_TOKEN]);
  const isClaiming = getRequestStatus(uiState[actionTypes.CLAIM]);
  const isRefunding = getRequestStatus(uiState[actionTypes.REFUND]);
  const isBoughtSuccess = getRequestStatus(uiState[actionTypes.BUY_TOKEN], RequestStatus.SUCCESS);

  const [isConnectModalVisible, onOpenConnectModal, onCloseConnectModal] = useModal(false);

  const tokensList = useFormatTokensList();
  const currentTYZTokenPrice = tokensList[tokensList.length - 1]?.value.toString() || '0';
  const tokensListForView = tokensList.filter(({ symbol }) => symbol !== 'TYZ');

  return (
    <BackgroundWrapper container sx={{ minHeight: '100vh' }}>
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Typography
          mt={19}
          variant="h1"
          textTransform="uppercase"
          textAlign="center"
          className="gradient"
          whiteSpace={{ xs: 'pre-wrap', sm: 'pre-wrap', md: 'nowrap' }}
          fontSize={{ xs: 56, sm: 56, md: 100, lg: 172 }}
        >
          {t('buyTokenPage.title')}
        </Typography>

        <PricesInfo
          tokensList={tokensListForView}
          min={fromDecimals(crowdsaleData.minPurchase)}
          max={fromDecimals(crowdsaleData.maxPurchase)}
          currentTYZTokenPrice={currentTYZTokenPrice}
          isLoading={isTokensListLoading}
          sx={{ mb: 4, maxWidth: 781 }}
        />

        <BuyTokenForm
          userAddress={userAddress}
          crowdsalleContractAddress={crowdsaleContractAddress}
          crowdsaleData={crowdsaleData}
          tyzTokenBalance={userTyzTokenBalanceOnCrowdsale}
          availableTokensOnCrowdsale={availableTokensOnCrowdsale}
          currentTYZTokenPrice={currentTYZTokenPrice}
          tokensList={tokensListForView}
          tokensBalances={tokensBalances}
          web3Provider={walletService.Web3()}
          onOpenConnectModal={onOpenConnectModal}
          isBuyingToken={isBuyingToken}
          isClaiming={isClaiming}
          isRefunding={isRefunding}
          isTokensListLoading={isTokensListLoading}
          isBoughtSuccess={isBoughtSuccess}
          isCrowdsaleDataLoading={isCrowdsaleDataLoading}
        />
      </Stack>

      {isConnectModalVisible && (
        <ConnectWalletModal open={isConnectModalVisible} onClose={onCloseConnectModal} onConnectWallet={connect} />
      )}
    </BackgroundWrapper>
  );
};
