import { FC } from 'react';
import { SxProps, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import { BG_BLACK_OPACITY, BORDER_RADIUS_DEFAULT } from 'theme';

export interface ToggleBtnsProps {
  buttonsItems: Array<{
    value: number;
    label: string;
    sx?: SxProps;
  }>;
}

export const ToggleBtns: FC<ToggleBtnsProps & ToggleButtonGroupProps> = ({ buttonsItems, sx, ...toggleBtnsProps }) => {
  /** 
   @example usage:
  const [activeValue, setActiveValue] = useState(2);
  const handleChangeActiveValue = (event: MouseEvent<HTMLElement>) => {
  const { value } = event.target as HTMLButtonElement;
  setActiveValue(+value);
  };
  */

  return (
    <ToggleButtonGroup
      {...toggleBtnsProps}
      sx={{
        background: BG_BLACK_OPACITY,
        border: BORDER_RADIUS_DEFAULT,
        ...sx,
        // width: '100%',
      }}
    >
      {buttonsItems.map((item) => (
        <ToggleButton key={item.value} value={item.value}>
          {item.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
