import { FC, ReactNode } from 'react';
import {
  BoxProps,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TableColumn } from 'modules/dashboard/components';
import { COLOR_TEXT_BLUE_100, FontFamilies } from 'theme';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: FontFamilies.secondary,
  fontSize: 20,
  color: COLOR_TEXT_BLUE_100,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  height: '80px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  height: '80px',
}));

type TableProps = {
  columns: string[];
  dropDownColumnIndex: number;
  setSelectedOption: (option: string) => void;
  selectedOption: string;
  items: {
    id: number;
    data: { text: string; button: ReactNode }[];
  }[];
  setSortColumn: (index: number) => void;
  setSortOrder: (oreder: 'ASC' | 'DESC') => void;
  tableContainerSx?: BoxProps['sx'];
  viewUser: ((email: string) => void) | null;
} & BoxProps;

export const DashboardTable: FC<TableProps> = ({
  columns,
  dropDownColumnIndex,
  setSelectedOption,
  selectedOption,
  items,
  setSortColumn,
  setSortOrder,
  tableContainerSx,
  viewUser,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: '100%',
        '::-webkit-scrollbar': {
          height: '8px',
          width: '8px',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#2463EB',
          borderRadius: '4px',
        },
        boxShadow: 0,
        borderRadius: '5px 5px 5px 5px',
        borderWidth: 1,
        borderColor: '#C6C7CC',
        borderStyle: 'solid',
        ...tableContainerSx,
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <StyledTableCell key={column}>
                <TableColumn
                  name={column}
                  setActiveColumn={() => setSortColumn(index)}
                  setAscSort={() => setSortOrder('DESC')}
                  setDescSort={() => setSortOrder('ASC')}
                  fontSize={isSmallScreen ? 16 : 20}
                  dropDownParams={dropDownColumnIndex !== index ? undefined : { selectedOption, setSelectedOption }}
                />
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <StyledTableRow
              key={item.id}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <StyledTableCell
                key={item.data[0].text}
                sx={{
                  textAlign: 'center',
                  width: `calc(100% / ${item.data.length})`,
                  fontSize: {
                    lg: 20,
                    xs: 14,
                  },
                }}
                className={viewUser ? 'hover:underline cursor-pointer' : ''}
                onClick={() => {
                  if (viewUser) viewUser(item.data[0].text);
                }}
              >
                {item.data[0].text}
              </StyledTableCell>
              {item.data.slice(1).map((value, i) => (
                <StyledTableCell
                  key={`${item.id}_${value}_${index.toString()}_${i.toString()}`}
                  sx={{
                    textAlign: 'center',
                    width: `calc(100% / ${item.data.length})`,
                    position: 'relative',
                    fontSize: {
                      lg: 20,
                      xs: 14,
                    },
                  }}
                >
                  {value.text}
                  {value.button}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
