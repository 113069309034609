import { URL } from 'appConstants';
import { Game22RegistrationCodeReq, Game22RegistrationConfirmationReq, Game22RegistrationReq } from 'types/requests';

import ajax from './ajax';

export const baseApi = {
  signup(data: Game22RegistrationReq) {
    return ajax({
      method: 'post',
      url: URL.game22Signup,
      data,
    });
  },
  sendCode(data: Game22RegistrationConfirmationReq) {
    return ajax({
      method: 'patch',
      url: URL.game22VerifyCode,
      data,
    });
  },
  requestRegistrationCode(data: Game22RegistrationCodeReq) {
    return ajax({
      method: 'post',
      url: URL.game22RequestRegistrationCode,
      data,
    });
  },
};
