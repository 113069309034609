import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Success: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 100 100"
    sx={{
      width: '100px',
      height: '100px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.5 92C73.696 92 92.5 73.196 92.5 50C92.5 26.804 73.696 8 50.5 8C27.304 8 8.5 26.804 8.5 50C8.5 73.196 27.304 92 50.5 92ZM50.5 100C78.1142 100 100.5 77.6142 100.5 50C100.5 22.3858 78.1142 0 50.5 0C22.8858 0 0.5 22.3858 0.5 50C0.5 77.6142 22.8858 100 50.5 100ZM23.6105 54.2668L23.6857 54.3558L39.078 69.9443C41.4283 72.3246 45.2714 72.3232 47.62 69.9411L77.315 39.8228L77.3895 39.7347C78.1493 38.8352 78.5411 37.6856 78.4966 36.516C78.452 35.3463 77.9737 34.2291 77.1458 33.3898C76.3168 32.5494 75.1975 32.0501 74.0092 32.0036C72.8207 31.957 71.6651 32.3675 70.7725 33.1423L70.6856 33.2178L43.3168 60.9306L30.3151 47.7505L30.2275 47.6745C29.3349 46.8996 28.1793 46.4892 26.9908 46.5357C25.8025 46.5822 24.6832 47.0816 23.8542 47.922C23.0263 48.7612 22.548 49.8785 22.5034 51.0482C22.4589 52.2177 22.8507 53.3674 23.6105 54.2668Z"
      fill="#4DA3FF"
    />
  </SvgIcon>
);
