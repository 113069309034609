import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const VkIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      width: '24px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.684 1.684C0 3.368 0 6.0792 0 11.5V12.5C0 17.92 0 20.632 1.684 22.316C3.368 24 6.0792 24 11.5 24H12.5C17.92 24 20.632 24 22.316 22.316C24 20.632 24 17.9208 24 12.5V11.5C24 6.08 24 3.368 22.316 1.684C20.632 0 17.9208 0 12.5 0H11.5C6.08 0 3.368 0 1.684 1.684ZM4.75039 7.5H6.49999C6.94719 7.5 7.11519 7.696 7.28319 8.1808C8.13919 10.6736 9.58239 12.8528 10.1752 12.8528C10.3984 12.8528 10.5 12.7504 10.5 12.1848V9.608C10.4599 8.87446 10.2036 8.55715 10.0138 8.32214C9.89664 8.17705 9.80479 8.06333 9.80479 7.9024C9.80479 7.7064 9.97199 7.5 10.2496 7.5H13C13.3712 7.5 13.5 7.6984 13.5 8.1432V11.6104C13.5 11.9808 13.6608 12.1112 13.772 12.1112C13.9944 12.1112 14.18 11.9808 14.588 11.5728C15.8488 10.164 16.7384 7.9952 16.7384 7.9952C16.8496 7.7352 17.0552 7.5 17.5 7.5H19.2496C19.7792 7.5 19.8912 7.7728 19.7792 8.1432C19.5568 9.1632 17.4248 12.1664 17.4248 12.1664C17.2392 12.4632 17.1648 12.6112 17.4248 12.9448C17.5165 13.0733 17.7123 13.2652 17.9361 13.4846C18.165 13.7089 18.4233 13.9621 18.6296 14.2056C19.3816 15.0512 19.948 15.7648 20.1056 16.256C20.2504 16.7496 20 17 19.5 17H17.7496C17.281 17 17.0452 16.7376 16.5353 16.17C16.3192 15.9295 16.0539 15.6342 15.7008 15.2808C14.6616 14.28 14.2176 14.1504 13.9576 14.1504C13.6056 14.1504 13.5 14.2504 13.5 14.7504V16.32C13.5 16.7496 13.3624 17 12.2496 17C10.396 17 8.35839 15.8744 6.91279 13.7976C4.74319 10.7576 4.15039 8.4584 4.15039 7.9952C4.15039 7.7352 4.25039 7.5 4.75039 7.5Z"
      fill="#8A90AB"
    />
  </SvgIcon>
);
