import i18next from 'i18next';
import { notifyText } from 'services/WalletService/config/constants';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { call, put, takeLatest } from 'typed-redux-saga';
import { getToastMessage } from 'utils';

import { verifyEmail } from '../actions';
import actionTypes from '../actionTypes';

import { getAccountInfoSaga } from './getAccountInfo';

export function* verifyEmailSaga({ type, payload: { code } }: ReturnType<typeof verifyEmail>) {
  yield* put(request(type));

  const { t } = i18next;

  try {
    yield* call(baseApi.verifyEmail, {
      code,
    });

    yield* call(getAccountInfoSaga, {
      type: actionTypes.GET_ACCOUNT_INFO,
      payload: undefined,
    });

    getToastMessage('success', notifyText.verifyEmail.success(t));

    yield* put(success(type));
  } catch (err) {
    getToastMessage('error', notifyText.verifyEmail.error(t));

    // eslint-disable-next-line no-console
    console.error(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.VERIFY_EMAIL, verifyEmailSaga);
}
