import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Modal, ModalProps } from 'components';
import { InputField, ResendTimer } from 'modules/referral/components';
import { COLOR_TEXT_GRAY_200, FontFamilies } from 'theme';

import { useShallowSelector } from '../../../../hooks';
import selectors from '../../../../store/referral/selectors';

const TypographyText = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilies.primary,
  fontSize: 14,
  lineHeight: '14px',
  color: COLOR_TEXT_GRAY_200,
  textAlign: 'center',
}));

type CodeModalProps = {
  requestCode?: () => void;
  sendCode: (code: string) => void;
} & ModalProps;

export const CodeModal: FC<CodeModalProps> = ({ open, onClose, requestCode, sendCode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    if (open && requestCode) {
      requestCode();
    }
  }, [open]);

  const [code, setCode] = useState('');

  const { twoFaType } = useShallowSelector(selectors.getReferral);

  console.log(`TwoFa: ${twoFaType}`);

  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const inputWidth = isMd ? 251 : 210;
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('referralSystem.signup.enterCode')}
      sx={{
        height: {
          xs: 355,
          md: 311,
        },
        width: {
          xs: 342,
          md: 448,
        },
      }}
      titleSx={{
        lineHeight: '24px',
      }}
    >
      <Box component="form" noValidate>
        <Stack justifyContent="center" alignItems="center">
          <InputField
            data={code}
            setData={setCode}
            name="code"
            label={t('referralSystem.signup.code')}
            type="text"
            errorLabel="registrationTwoFaConfirmationCode"
            alignLabel="center"
            width={inputWidth}
            textAlign="center"
            sx={{ mt: '24px' }}
          />
          {requestCode && (
            <Box sx={{ mt: '24px', padding: 0 }}>
              <ResendTimer onClick={requestCode} />
            </Box>
          )}

          <Button sx={{ mt: '24px', height: 44, width: 153 }} onClick={() => sendCode(code)}>
            OK
          </Button>
          <TypographyText sx={{ mt: '24px', lineHeight: '16px' }}>
            {twoFaType === 'email' ? t('referralSystem.signup.emailWasSent') : t('referralSystem.signup.appWasSent')}
          </TypographyText>
        </Stack>
      </Box>
    </Modal>
  );
};
