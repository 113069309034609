import { fork } from 'redux-saga/effects';

import addAdmin from './addAdmin';
import addGame from './addGame';
import addNote from './addNote';
import addStore from './addStore';
import blockUser from './blockUser';
import deleteGame from './deleteGame';
import deleteStore from './deleteStore';
import demoteAdmin from './demoteAdmin';
import drop2fa from './drop2fa';
import editStore from './editStore';
import getAdminPaymentsHistory from './getAdminPaymentsHistory';
import getAdmins from './getAdmins';
import getAdminWithdrawalHistory from './getAdminWithdrawalHistory';
import getApprovedWithdrawal from './getApprovedWithdrawal';
import getConfirmations from './getConfirmations';
import getGames from './getGames';
import getNotes from './getNotes';
import getPaymentsHistory from './getPaymentsHistory';
import getProfileInfo from './getProfileInfo';
import getSelectedUserInfo from './getSelectedUserInfo';
import getStores from './getStores';
import getWithdrawals from './getUserWithdrawals';
import getWithdrawalLimits from './getWithdrawalLimits';
import makeSwap from './makeSwap';
import promoteAdmin from './promoteAdmin';
import resolveBlockedTransaction from './resolveBlockedTransaction';
import setProfileAvatar from './setProfileAvatar';
import takeBalance from './takeBalance';
import updateMaxWithdrawal from './updateMaxWithdrawal';
import updateMinWithdrawal from './updateMinWithdrawal';
import updateWithdrawalPeriod from './updateWithdrawalPeriod';

export default function* dashboardSagas() {
  yield fork(addAdmin);
  yield fork(addGame);
  yield fork(addNote);
  yield fork(addStore);
  yield fork(blockUser);
  yield fork(demoteAdmin);
  yield fork(promoteAdmin);
  yield fork(deleteGame);
  yield fork(deleteStore);
  yield fork(editStore);
  yield fork(getAdminPaymentsHistory);
  yield fork(getAdmins);
  yield fork(getAdminWithdrawalHistory);
  yield fork(getGames);
  yield fork(getNotes);
  yield fork(getPaymentsHistory);
  yield fork(getProfileInfo);
  yield fork(getSelectedUserInfo);
  yield fork(getStores);
  yield fork(getWithdrawalLimits);
  yield fork(getWithdrawals);
  yield fork(makeSwap);
  yield fork(setProfileAvatar);
  yield fork(takeBalance);
  yield fork(updateMaxWithdrawal);
  yield fork(updateMinWithdrawal);
  yield fork(updateWithdrawalPeriod);
  yield fork(resolveBlockedTransaction);
  yield fork(getConfirmations);
  yield fork(drop2fa);
  yield fork(getApprovedWithdrawal);
}
