import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { BackgroundWrapper } from 'components';
import { useModal, useShallowSelector } from 'hooks';
import { ConnectWalletModal } from 'modules/layout/containers';
import { PoolInfo, StakingForm, StakingInfo } from 'modules/staking/containers';
import { StakingDisclaimer } from 'modules/staking/containers/StakingDisclaimer';
import { useWalletConnectorContext } from 'services';
import { getStakingData, getUserStakeData } from 'store/staking/actions';
import actionTypes from 'store/staking/actionTypes';
import stakingSelector from 'store/staking/selectors';
import uiSelector from 'store/ui/selectors';
import { getTokenBalance } from 'store/user/actions';
import userSelector from 'store/user/selectors';
import { RequestStatus, State, UserState } from 'types';
import { StakingState } from 'types/store/staking';
import { getRequestStatus } from 'utils';

const COOKIES_EXPIRATION_TIME_DEFAULT = 86400; // 24h
const COOKIES_NAME_DEFAULT = 'TYZ_SESSION';

export const Staking: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { walletService, connect } = useWalletConnectorContext();

  const { tokenBalance, address: userAddress } = useShallowSelector<State, UserState>(userSelector.getUser);
  const stakingState = useShallowSelector<State, StakingState>(stakingSelector.getStaking);
  const uiState = useShallowSelector(uiSelector.getUI);

  const isStaking = getRequestStatus(uiState[actionTypes.STAKE]);
  const isStakeSuccessful = getRequestStatus(uiState[actionTypes.STAKE], RequestStatus.SUCCESS);
  const isUnstaking = getRequestStatus(uiState[actionTypes.UNSTAKE]);
  const isStakingDataLoading = getRequestStatus(uiState[actionTypes.GET_STAKING_DATA]);

  const [isConnectModalVisible, openConnectModal, closeConnectModal] = useModal(false);
  const [isUserMustApproveDisc, , approveReject] = useModal(true);

  const isStakingStarted = Date.now() > Number(stakingState.stakingStartTime) * 1000;

  const handleApproveDisclaimer = useCallback(() => {
    const cookiesId = Math.floor(Math.random() * 100);
    document.cookie = `${COOKIES_NAME_DEFAULT}=${cookiesId}; max-age=${COOKIES_EXPIRATION_TIME_DEFAULT}`;
    approveReject();
  }, [approveReject]);

  const isDisclaimerVisible = useMemo(() => {
    const hasCookieApproved = document.cookie.split('; ').some((cookie) => cookie.includes('TYZ_SESSION'));
    if (!hasCookieApproved && isUserMustApproveDisc) {
      return true;
    }
    return false;
  }, [isUserMustApproveDisc]);

  useEffect(() => {
    dispatch(getStakingData());
  }, [dispatch]);

  useEffect(() => {
    if (userAddress.length) {
      dispatch(getTokenBalance({ web3Provider: walletService.Web3(), forDisplay: false }));
      dispatch(getUserStakeData({ web3Provider: walletService.Web3() }));
    }
  }, [userAddress.length, dispatch, walletService]);

  return (
    <BackgroundWrapper container sx={{ minHeight: '100vh' }}>
      <Stack pb={15.5} alignItems="center">
        <Typography
          mt={19}
          variant="h1"
          textTransform="uppercase"
          textAlign="center"
          className="gradient"
          whiteSpace={{ xs: 'pre-wrap', sm: 'pre-wrap', md: 'nowrap' }}
          fontSize={{ xs: 56, sm: 56, md: 100, lg: 172 }}
        >
          {t('stakingPage.title')}
        </Typography>

        <StakingForm
          {...stakingState}
          userBalance={tokenBalance}
          web3Provider={walletService.Web3()}
          userAddress={userAddress}
          onOpenConnectModal={openConnectModal}
          isStaking={isStaking}
          isStakeSuccessful={isStakeSuccessful}
          isStakingDataLoading={isStakingDataLoading}
        />

        {!!userAddress.length && isStakingStarted && (
          <>
            <StakingInfo {...stakingState} />
            <PoolInfo {...stakingState} web3Provider={walletService.Web3()} isUnstaking={isUnstaking} />
          </>
        )}
      </Stack>

      {isConnectModalVisible && (
        <ConnectWalletModal open={isConnectModalVisible} onConnectWallet={connect} onClose={closeConnectModal} />
      )}
      {isDisclaimerVisible && (
        <StakingDisclaimer open={isDisclaimerVisible} onClose={approveReject} onSetAgree={handleApproveDisclaimer} />
      )}
    </BackgroundWrapper>
  );
};
