import { FC, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { AddingAdmin, AddingGames, AddingStore, LimitsPanel } from 'modules/dashboard/containers';

export const SettingsPage: FC = () => {
  const [gameId, setGameId] = useState<string>('');
  const [gameName, setGameName] = useState<string>('');
  const handleGameSelection = (selectedGameId: string, selectedGameName: string) => {
    setGameId(selectedGameId);
    setGameName(selectedGameName);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Box>
      <Box
        sx={{
          marginTop: isSmallScreen ? '74px' : '46px',
          display: 'flex',
        }}
      >
        <LimitsPanel />
        {!isSmallScreen && <AddingAdmin />}
      </Box>
      <AddingGames onSelectGame={handleGameSelection} isAdmin />
      <AddingStore gameId={gameId} gameName={gameName} isAdmin />
      {isSmallScreen && <AddingAdmin />}
    </Box>
  );
};
