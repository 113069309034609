/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { baseReferralURL } from 'appConstants';
import Cookies from 'universal-cookie';

import { User } from '../../../../../types/store/dashboard';
import { LoadingTree, ReferralTree, ReferralTreeData } from '../../../../referral/components';

export const NetworkView = ({ user }: { user: User }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [data, setData] = useState<ReferralTreeData | null>(null);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<ReferralTreeData | null> => {
      const url = `${baseReferralURL}ref_api/v1/account/tree?code=${user.code}`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          return null;
        }

        return await response.json();
      } catch (err) {
        return null;
      }
    };

    setError(false);
    setData(null);
    fetchData().then((tree) => {
      setError(!tree);
      setData(tree);
    });
  }, [reload, user.code]);

  return (
    <Box
      sx={{
        borderRadius: '12px',
        background: 'white',
        ...(isSmallScreen
          ? { width: '342px', height: '464px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.175)', marginTop: '32px' }
          : { width: '1028px', height: '551px' }),
      }}
    >
      {!data ? (
        <LoadingTree error={error} reload={() => setReload(!reload)} close={null} />
      ) : (
        <ReferralTree data={data} focus={[]} reload={() => setReload(!reload)} close={null} openProfile={null} />
      )}
    </Box>
  );
};
